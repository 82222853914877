import React, {Fragment, useEffect, useState} from 'react';

import {
    CloudUploadIcon,
    CollectionIcon,
    DocumentReportIcon,
    DownloadIcon,
    PencilAltIcon,
    PlusIcon,
    XIcon
} from '@heroicons/react/outline';
import TableComp from '../../components/TableComp';
import {
    activateAccount,
    activateOrganisation,
    createOrganisation,
    createUser,
    customGraphRequest,
    deactivateAccount,
    deactivateOrganisation,
    deactivateUser,
    generateSupplierDeliveryCostsSheet,
    generateSupplierQuotesCatalogSheet,
    importSupplierDeliveryCostWorkbook,
    importSupplierQuotesCatalogWorkbook,
    queryCategoriesBasic,
    queryClaimsMinimal,
    queryOrganisationOptions,
    queryRegions,
    queryRolesForOrganisation,
    queryUserFull,
    updateOrganisation,
    updateUser,
    uploadFile,
} from '../../utils/coreApi';
import {classNames, formatAsTitle, isEmpty} from '../../utils/helpers';
import moment from 'moment';
import AlertModal from '../modals/AlertModal';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import DataTable from '../../components/DataTable';
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";
import {CountryTypes, OrganisationTypes} from "../../utils/constants";
import {QueryStatus, useQuery} from "../../components/useQuery";
import Tooltip from "../../components/Tooltip";
import FilterSelect from "../../components/base/FilterSelect";
import {ArrowDownIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import {Combobox, Dialog, Transition} from "@headlessui/react";
import Safe from "../../components/Safe";
import LimitedOrganisationAccess, {OrganisationType} from "../../components/LimitedOrganisationAccess";
import {onViewHistoryRecordsClicked} from "../../components/AuditTrail";
import ApiButton from "../../components/base/ApiButton";


const tabStyle = 'transition-all inline-block w-full p-4 text-gray-900   hover:bg-gray-200   focus:ring-4 focus:ring-blue-300 ';
const tabSelectedStyle = '     bg-gray-200 active shadow-[inset_1px_2px_4px_rgba(0,0,0,0.1)] ';
const tabUnselectedStyle = 'shadow-lg  bg-gray-50';


export default function AdminOrganisations(props){

    useDocumentTitle("SLVRCLD Admin");

    const organisationsHook = useQuery({initialValue: null});
    const {state: organisations, setState: setOrganisations} = organisationsHook;

    const [includeUsersInOrganisationQuery, setIncludeUsersInOrganisationQuery] = useState(false); // use a local state for organisations here, and not the global organisations (due to adding root org)

    const [tabForUserOrgTables, setTabForUserOrgTables] = useState(0);

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '',
        'message': ''
    });


    // modal
    const [showAddOrganisationModal, setShowAddOrganisationModal] = useState(0);
    const [showEditOrgProfileModal, setShowEditOrgProfileModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const [deleteModalOptions, setDeleteModalOptions] = useState({
        title: null,
        message: null,
        challenge: 'DELETE',
        successCallback: null
    });

    const [activeUserInModal, setActiveUserInModal] = useState(null); // this is the user for the EditUserModal
    const [activeOrgInModal, setActiveOrgInModal] = useState(null); // this is the organisation for the EditOrganisationProfileModal
    const [crossModalCommunication_ActiveOrgInModal, setCrossModalCommunication_ActiveOrgInModal] = useState(null); // this is the organisation for the EditOrganisationProfileModal
    const [orgsForDropdown, setOrgsForDropdown] = useState([]);

    const [orgTableData, setOrgTableData] = useState([]);
    const [userTableData, setUserTableData] = useState([]);

    // CONSTANTS
    const OrgType = OrganisationTypes
    const CountryType = CountryTypes;

    const TableColumns_organizations = [
        {
            Header: 'Logo', accessor: 'Logo',
            Filter: () =>
                <span className="h-10 tailwind-tooltip-container text-xs text-gray-300 font-light">
                    Organisation <br></br> Order
                    <span className='tailwind-tooltip lowercase'>Click <b>LOGO</b> to change alphabetical order</span>
                </span> // Do not render a filter
        },
        {
            Header: 'Company', accessor: 'Company' // accessor is the "key" in the data
        },
        {
            Header: 'Country', accessor: 'Country',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                                placeholder={`Search (${column.length})`}
                        >
                            <option value="">ALL</option>
                            <option value="ZA">ZA</option>
                            <option value="NL">NL</option>
                            <option value="AU">AU</option>
                            <option value="US">US</option>
                            <option value="BE">BE</option>
                            <option value="GB">GB</option>
                        </select>
                    </div>
                );
            }
        },
        {
            Header: 'Type', accessor: 'Type',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                                placeholder={`Search (${column.length})`}
                        >
                            <option value="">ALL</option>
                            <option value="ADMIN">ADMIN</option>
                            <option value="INSURER">INSURER</option>
                            <option value="SUPPLIER">SUPPLIER</option>
                        </select>
                    </div>
                );
            }
        },
        {
            Header: 'View Profile',
            accessor: 'View Profile',
            Filter: () => <div className="h-10"></div> // Do not render a filter
        }
    ];

    const TableColumns_users = [
        {
            Header: 'Avatar', accessor: 'Avatar'
        },
        {
            Header: 'User Name', accessor: 'User Name' // accessor is the "key" in the data
        },
        {
            Header: 'Company', accessor: 'Company' // accessor is the "key" in the data
        },
        {
            Header: 'Country', accessor: 'Country', width: 50
        },
        {
            Header: 'Type', accessor: 'Type'
        },
        {
            Header: 'Edit', accessor: 'Edit', searchable: false,
        }
    ];

    const allProps = {
        ...props,
        organisations, setOrganisations,
        tabForUserOrgTables, setTabForUserOrgTables,
        alertOpen, setAlertOpen,
        alertOptions, setAlertOptions,
        showAddOrganisationModal, setShowAddOrganisationModal,
        showEditOrgProfileModal, setShowEditOrgProfileModal,
        showEditUserModal, setShowEditUserModal,
        showAddUserModal, setShowAddUserModal,
        showConfirmDeleteModal, setShowConfirmDeleteModal,
        deleteModalOptions, setDeleteModalOptions,
        activeUserInModal, setActiveUserInModal,
        activeOrgInModal, setActiveOrgInModal,
        crossModalCommunication_ActiveOrgInModal, setCrossModalCommunication_ActiveOrgInModal,
        orgsForDropdown, setOrgsForDropdown,
        orgTableData, setOrgTableData,
        userTableData, setUserTableData
    };

    useEffect(() => {
        /** Retrieve a list of organisations **/

        if (isEmpty(organisations)) {
            getOrganisations();
        }

        if (organisations) {
            initialiseOrgAndUserData();
        }

    }, [props.user, organisations]);

    useEffect(() => {
        if(includeUsersInOrganisationQuery)
            getOrganisations();
    }, [includeUsersInOrganisationQuery]);

    function getOrganisations(){
        /** The organisation and account data (for logged in user's organisation) needs to be retrieved */
        /** Get all the ['ADMIN', 'INSURER'] organisations that are connected to this organisation */

        if(organisationsHook.queryStatus === QueryStatus.QUERYING) return;

        let queryArgs = "";

        let orgTypes = ['INSURER', 'ADMINISTRATOR', 'SUPPLIER']
        queryArgs += ` types:[${orgTypes.join(", ")}]`;

        let status = 'ACTIVE'
        queryArgs += ` status: ${status}`;

        let minimalOrganisationsQuery = `
            query CRUD_AllOrganisations{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  account_type
                  info {
                    name
                    country
                    country_text
                    website
                    currency
                    logo_image_url
                    snappy_info{
                      status_text
                      thank_you_text
                      landing_text
                      snappy_logo_url
                    }
                  }
                  ${!includeUsersInOrganisationQuery ?
                    '' 
                    :
                  `users {
                    id
                    username
                    info {
                      first_name
                      last_name
                      full_name
                      profile_image_url
                    }
                    checked_actions
                  }`
                }
                
                }
              }
            }
        `
        minimalOrganisationsQuery = minimalOrganisationsQuery.replace('|placeholder|', queryArgs);

        organisationsHook.setQueryStatus(QueryStatus.QUERYING);

        customGraphRequest(
            minimalOrganisationsQuery,
            (data) => {
                organisationsHook.setQueryStatus(QueryStatus.QUERIED);

                const allOrgs = data['organisations'];
                const alphabeticallyOrderedOrganisations = allOrgs.sort((a, b) => {
                    let nameA = a.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison
                    let nameB = b.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                setOrganisations(alphabeticallyOrderedOrganisations)

            },
            (error) => {
                props.onError(error)
                organisationsHook.setQueryStatus(QueryStatus.QUERIED);
            }
        );
    }

    useEffect(() => {
        /** Add the current logged-in user's organisation to the list of organisations - this is needed for root organisation **/

        if (!props.mainOrganisation) return;
        if (!organisations) return;

        if (organisations.find(o => o.id === props.mainOrganisation.id)) return;

        if (organisations)
            setOrganisations([props.mainOrganisation, ...organisations]);
        else
            setOrganisations([props.mainOrganisation]);

    }, [props.mainOrganisation, organisations]);

    const initialiseOrgAndUserData = () => {

        let organisationsData = [];
        let userData = [];
        let OrganisationsForSelectDropdown = [];

        if (isEmpty(organisations)) {
            return;
        }

        organisations.forEach(org => {
            organisationsData.push({
                'Logo': (
                    <BasicImage
                        src={org.info.logo_image_url}
                        fallbackSrc={'/org-logo-placeholder.jpg'}
                        alt="logo"
                        sizeWidthRem="3"
                        sizeHeightRem="3"
                        className="ml-4 rounded-full"
                    />),
                'OrgID': org.id,
                'Company': org.info.name,
                'Type': org.type,
                'Country': org.info.country,
                'View Profile': (
                    <button className="btn-light" onClick={() => {
                        setActiveOrgInModal(org);
                        setCrossModalCommunication_ActiveOrgInModal(org);
                        setShowEditOrgProfileModal(true);
                    }}>
                        View profile
                    </button>)
            });

            OrganisationsForSelectDropdown.push({value: org.info.name, label: org.info.name});
        });

        organisations.forEach(org => {

            OrganisationsForSelectDropdown.push({value: org.info.name, label: org.info.name});

            if(org.users){
                org.users.forEach(user => {
                    userData.push({
                        'Avatar': (
                            <BasicImage
                            src={user.info.profile_image_url}
                            fallbackSrc={'/profile-picture.jpg'}
                            alt="logo"
                            sizeWidthRem="3"
                            sizeHeightRem="3"
                            className="ml-4 rounded-full"
                        />),
                        'User Name': user.info.full_name,
                        'Company': org.info.name,
                        'Type': org.type,
                        'Country': org.info.country,
                        'Edit': (
                            <button className="btn-light" onClick={() => {
                                setActiveUserInModal(user);
                                setShowEditUserModal(true);
                            }}>
                                Edit
                            </button>)
                    });
                });
            }
        });

        setOrgTableData(organisationsData);
        setUserTableData(userData);
        setOrgsForDropdown(OrganisationsForSelectDropdown);

    };

    if (organisations == null)
        return (
            <div className="h-screen w-full flex justify-center content-center"><LoadingSpinner/></div>
        );

    return (
        <section className="body">

            <div className="">

                {/* HEADER */}
                <div className="page-header-bar">

                    <h2 className="page-header-title">Organisation Admin</h2>

                    <div className={'transition-all ' + (tabForUserOrgTables === 0 ? 'opacity-1' : 'opacity-0 pointer-events-none')}>
                        <div className="w-full flex items-center justify-end">
                            <button onClick={() => setShowAddOrganisationModal(true)} className="btn-light m-0">
                                + Create Organisation
                            </button>
                        </div>
                    </div>

                </div>

                {/* TABS */}
                <ul className="mt-7 mb-5 hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex">
                    <li className="w-full">
                        <a onClick={() => setTabForUserOrgTables(0)}
                           className={tabStyle + (tabForUserOrgTables === 0 ? tabSelectedStyle : tabUnselectedStyle)}
                           aria-current="page">Organisations</a>
                    </li>
                    <li className="w-full">
                        <a onClick={() => {
                            setIncludeUsersInOrganisationQuery(true);
                            setTabForUserOrgTables(1)
                        }}
                           className={tabStyle + (tabForUserOrgTables === 1 ? tabSelectedStyle : tabUnselectedStyle)}
                        >Users</a>
                    </li>
                </ul>

                {/* ORGANSATIONS TABLE & USERS TABLE */}
                <div className='widget'>
                    {
                        tabForUserOrgTables === 0 ?
                            <TableComp data={orgTableData} columns={TableColumns_organizations}/>
                            :
                            <TableComp data={userTableData} columns={TableColumns_users}/>
                    }
                </div>

            </div>

            <AlertModal
                {...allProps}

                open={alertOpen}
                setOpen={setAlertOpen}
                options={alertOptions}
            />

            <EditOrganisationProfileModal
                {...allProps}

                showModal={showEditOrgProfileModal}
                setShowModal={setShowEditOrgProfileModal}
                activeOrgInModal={activeOrgInModal}

                setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                setDeleteModalOptions={setDeleteModalOptions}
            />

            <EditUserModal
                {...allProps}

                showModal={showEditUserModal}
                setShowModal={setShowEditUserModal}
                activeUserInModal={activeUserInModal}
                setActiveUserInModal={setActiveUserInModal}
                setActiveOrgInModal={setActiveOrgInModal}

                setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                setDeleteModalOptions={setDeleteModalOptions}
            />

            <AddUserModal
                {...allProps}

                showModal={showAddUserModal}
                setShowModal={setShowAddUserModal}

                activeOrgInProfileModal={activeOrgInModal}

                OrgSelect={orgsForDropdown}
                OrgType={OrgType}
                CountryType={CountryType}
            />

            <AddOrganisationModal
                {...allProps}

                showModal={showAddOrganisationModal}
                setShowModal={setShowAddOrganisationModal}

                OrgSelect={orgsForDropdown}
                OrgType={OrgType}
                CountryType={CountryType}
            />

            <DeleteConfirmationModal
                {...allProps}

                showModal={showConfirmDeleteModal}
                setShowModal={setShowConfirmDeleteModal}

                options={deleteModalOptions}
            />


        </section>);
}


export function EditOrganisationProfileModal(props){

    const organisationsHook = useQuery({initialValue: null});
    const {state: organisations, setState: setOrganisations} = organisationsHook;

    const [tab, setTab] = useState(0);

    // == Organisation fields
    // root
    const [orgName, setOrgName] = useState(null);
    const [orgWebsite, setOrgWebsite] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);

    const [orgAccountsTable_data, setOrgAccountsTable_data] = useState([]);

    const [showAccountCreationModal, setShowAccountCreationModal] = useState(false);
    const [showCategoryCreationModal, setShowCategoryCreationModal] = useState(false);
    const [showRegionCreationModal, setShowRegionCreationModal] = useState(false);

    const [orgUsersTable_data, setOrgUsersTable_data] = useState([]);
    const orgUsersTable_columns = [
        {Header: 'Full Name', accessor: 'full_name'},
        {Header: 'Username', accessor: 'username'},
        {Header: 'MFA Enabled', accessor: 'mfa_enabled'},
        {Header: 'Edit', accessor: 'edit'}
    ];

    const [accountsForOrganisation, setAccountsForOrganisation] = useState(null);

    // Invoice
    const [generationType, setGenerationType] = useState(null);
    const [xeroId, setXeroId] = useState(null);
    const [creditFacilityAmount, setCreditFacilityAmount] = useState(null);
    const [paymentTermDays, setPaymentTermDays] = useState(null);
    const [discountPercentage, setDiscountPercentage] = useState(null);
    const [billingEmail, setBillingEmail] = useState(null);

    // Voucher
    const [defaultName, setDefaultName] = useState(null);
    const [validityPeriodMonths, setValidityPeriodMonths] = useState(null);
    const [supportRequestType, setSupportRequestType] = useState(null);

    // Claim
    const [replacementPatternType, setReplacementPatternType] = useState(null);
    const [notificationType, setNotificationType] = useState(null);
    const [notificationEmail, setNotificationEmail] = useState(null);
    const [reducedTenderPercent, setReducedTenderPercent] = useState(null);
    const [reducedTenderSupplierPercent, setReducedTenderSupplierPercent] = useState(null);


    // Snappy Claims fields
    const [snappyLogoBase64, setSnappyLogoBase64] = useState(null);
    const [snappyLogoFilename, setSnappyLogoFilename] = useState(null);
    const [landingPageText, setLandingPageText] = useState(null);
    const [thankPageYouText, setThankYouPageText] = useState(null);
    const [statusPageText, setStatusPageText] = useState(null);

    const [orgOptions, setOrgOptions] = useState(null);

    useEffect(() => {
        /** If this Organisation Profile Modal is opened outside of the CRUD page, then the organisations state will be null
         * If props.organisations is null, then we must fetch the organisations **/

        if (isEmpty(props.organisations)) {
            getOrganisations();
        }
        else {
            setOrganisations(props.organisations);
        }

    }, []);

    useEffect(() => {
        /** When the modal is closed - all the states remain, but the states must be cleared (incase another org is viewed but the previous org's states remain)
         * This is because the modal is "hidden" via CSS(for a smooth fade transition), rather than conditionally rendered (e.g: if a ? modalHere : '' )
         * So, when the modal is closed, we must clear all the states **/

        // Don't clear the states if the modal is being shown
        if (props.showModal) return;

        props.setActiveOrgInModal(null);
        setTab(0); // set the 'Profile' back as the selected option

        clearAllFields();

    }, [props.showModal]);

    useEffect(() => {
        /** Retrieve this organisations "options" data **/
        /** when a new organisation is set for the EditOrganisationModal, run this effect
         * when the details of this organisation in this modal have been updated via orgOptions, run this effect */

        if (!props.activeOrgInModal) return;

        // Get this organisation's "options" data
        if (!orgOptions || orgOptions.id !== props.activeOrgInModal.id) {
            queryOrganisationOptions(
                props.activeOrgInModal.id,
                (data) => {
                    setOrgOptions(data['organisations'][0]);
                },
                props.onError
            );
        }

        if (orgOptions) {
            populate_options();
            populate_UsersTableData();
        }

    }, [props.activeOrgInModal, orgOptions]);

    useEffect(() => {
        /** Retrieve this organisation's accounts data **/

        if (!props.activeOrgInModal) return;

        // Get this organisation's accounts
        if (isEmpty(accountsForOrganisation)) {
            // fetch
            getAccounts();
        } else {
            // populate
            populate_AccountsTableData();
        }

    }, [props.activeOrgInModal, accountsForOrganisation]);


    function getOrganisations(){
        /** The organisation and account data (for logged in user's organisation) needs to be retrieved */

        if(organisationsHook.queryStatus === QueryStatus.QUERYING) return;

        let queryArgs = "";

        let orgTypes = ['INSURER', 'ADMINISTRATOR', 'SUPPLIER']
        queryArgs += ` types:[${orgTypes.join(", ")}]`;

        let status = 'ACTIVE'
        queryArgs += ` status: ${status}`;


        let minimalOrganisationsQuery = `
            query OrgProfileModal_Organisations{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  account_type
                  info {
                    name
                    country
                    country_text
                    website
                    currency
                    logo_image_url
                    snappy_info{
                      status_text
                      thank_you_text
                      landing_text
                      snappy_logo_url
                    }
                  }
                }
              }
            }
        `
        minimalOrganisationsQuery = minimalOrganisationsQuery.replace('|placeholder|', queryArgs);

        organisationsHook.setQueryStatus(QueryStatus.QUERYING);

        customGraphRequest(
            minimalOrganisationsQuery,
            (data) => {

                const allOrgs = data['organisations'];
                const alphabeticallyOrderedOrganisations = allOrgs.sort((a, b) => {
                    let nameA = a.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison
                    let nameB = b.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                setOrganisations(alphabeticallyOrderedOrganisations)

            },
            props.onError
        );
    }

    function getFullOrganisation(){
        /** The organisation and account data (for logged in user's organisation) needs to be retrieved */

        let queryArgs = "";

        queryArgs += `unique_reference_name: "${props.activeOrgInModal.unique_reference_name}"`

        let minimalOrganisationsQuery = `
            query OrgProfileModal_GetOrganisationUsers{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  account_type
                  info {
                    name
                    country
                    country_text
                    website
                    currency
                    logo_image_url
                    snappy_info{
                      status_text
                      thank_you_text
                      landing_text
                      snappy_logo_url
                    }
                  }
                  users {
                    id
                    username
                    info {
                      first_name
                      last_name
                      full_name
                      profile_image_url
                    }
                    checked_actions
                }
                
                }
              }
            }
        `
        minimalOrganisationsQuery = minimalOrganisationsQuery.replace('|placeholder|', queryArgs);

        customGraphRequest(
            minimalOrganisationsQuery,
            (data) => {
                props.setActiveOrgInModal(data['organisations'][0]);
            },
            props.onError
        );
    }


    function getAccounts(){
        /** The organisation and account data (for logged in user's organisation) needs to be retrieved */

        let queryArgs = "";

        queryArgs += ` organisation: "${props.activeOrgInModal.id}"`;

        let minimalOrganisationsQuery = `
            query OrganisationProfileModal_GetAccounts{
              accounts(|placeholder|) {
                error {
                  type
                  message
                }
                
                accounts {
                  id
                  status
                  type
                  organisation_1 {
                    unique_reference_name
                    id
                    type
                    users {
                      id
                    }
                    info {
                      name
                      country
                      country_text
                      website
                      currency
                      logo_image_url
                    }
                  }
                  organisation_2 {
                    unique_reference_name
                    id
                    type
                    users {
                      id
                    }
                    info {
                      name
                      country
                      country_text
                      website
                      currency
                      logo_image_url
                    }
                  }
                  
                }
                
              }
            }
        `
        minimalOrganisationsQuery = minimalOrganisationsQuery.replace('|placeholder|', queryArgs);

        customGraphRequest(
            minimalOrganisationsQuery,
            (data) => {
                setAccountsForOrganisation(data['accounts']);
            },
            props.onError
        );
    }


    const populate_options = () => {
        /** This method pre-populates all the states in this class(for the org profile ), from the data in the organisation query **/
        let org = props.activeOrgInModal;
        let o = orgOptions.options;

        setOrgName(org.info.name);
        setOrgWebsite(org.info.website);

        setGenerationType(o.invoice ? o.invoice.generation_type : null);
        setXeroId(o.invoice ? o.invoice.xero_id : '');
        setCreditFacilityAmount(o.invoice ? o.invoice.credit_facility_amount : '');
        setPaymentTermDays(o.invoice ? o.invoice.payment_term_days : '');
        setDiscountPercentage(o.invoice ? o.invoice.discount_percentage : '');

        setBillingEmail(o.email_notification_address_billing);

        setDefaultName(o.voucher ? o.voucher.default_name : '');
        setValidityPeriodMonths(o.voucher ? o.voucher.validity_period_months : 0);
        setSupportRequestType(o.voucher ? o.voucher.supported_request_type : '');

        setReplacementPatternType(o.replacement_pattern);
        setNotificationType(o.email_notification_option);

        setNotificationEmail(o.email_notification_address_claims);
        setReducedTenderPercent(o.reduced_tender_threshold_percentage);
        setReducedTenderSupplierPercent(o.reduced_tender_minimum_suppliers);

        setLandingPageText(org.info.snappy_info ? org.info.snappy_info.landing_text : '');
        setThankYouPageText(org.info.snappy_info ? org.info.snappy_info.thank_you_text : '');
        setStatusPageText(org.info.snappy_info ? org.info.snappy_info.status_text : '');


    };

    const checkUserMFAStatus = (user) => {

        if(props.mainOrganisation.type !== 'ROOT') {
            console.log('Querying MFA canceled due to invalid permissions: Superadmin required.')
            props.showAlertModal(
                'error',
                'Invalid permissions',
                'You are not allowed to directly query MFA status'
            )
            return;
        }

        let query = `
            query GetMFAStatus{
              user_mfa_enabled(
                user_id: "${user.id}"
              ){
                error{type, message}
                mfa_enabled
              }
            }
        `

        customGraphRequest(
            query,
            (data) => {
                console.log(data)

                const mfaEnabled = data['user_mfa_enabled'].mfa_enabled;

                props.showNotificationModal(
                    'success',
                    'MFA is ' + (mfaEnabled ? 'Enabled' : 'Disabled'),
                    'This users MFA status has been checked directly with the Incognito API.',
                )

                // find this user in the props.activeOrgInModal.users and change its user.info.mfa_enabled to data.mfa_enabled
                let users = props.activeOrgInModal.users;
                let user = users.find(u => u.id === user.id);
                user.info.mfa_enabled = mfaEnabled;

                // update the organisation in the props.activeOrgInModal
                props.setActiveOrgInModal({...props.activeOrgInModal, users: users});

            },
            props.onError
        )
    }

    const populate_UsersTableData = () => {
        /** This function creates the Data for the "this org's users table" in the org profile **/
        let tableData = [];

        // if the users have not been fetched, then fetch them
        if(!props.activeOrgInModal.users) {
            getFullOrganisation();
            return
        }

        props.activeOrgInModal.users.forEach(user => {
            let tableRow = {
                'full_name': user.info.full_name,
                'username': user.username,
                'mfa_enabled': (
                    <Tooltip
                        message={'Click to check MFA status directly with Incognito API'}
                    >
                        <p
                            className='py-4 pr-20'
                            onClick={() => checkUserMFAStatus(user)}
                        >
                            {user.info.mfa_enabled ? 'Enabled' : 'Disabled'}
                        </p>
                    </Tooltip>

                ),
                'edit': (
                    props.setActiveUserInModal ?
                    <button className="btn-light" onClick={() => {
                        props.setShowModal(false);
                        props.setActiveUserInModal(user);
                        props.setShowEditUserModal(true);
                    }}>
                        Edit
                    </button>
                    :
                        <Tooltip content="Please view this organisation in the Organisation Admin page">
                            <button className="btn disabled" disabled>
                                Edit
                            </button>
                        </Tooltip>
                )
            };
            tableData.push(tableRow);
        });
        setOrgUsersTable_data(tableData);
    };

    const populate_AccountsTableData = () => {

        const humanReadableAccountTypes = {
            'ADMINISTRATOR_INSURER': 'Admin Insurer',
            'ADMINISTRATOR_SUPPLIER': 'Admin Supplier',
            'INSURER_INSURER': 'Insurer Insurer',
            'INSURER_SUPPLIER': 'Insurer Supplier',
            'SELF': 'Self'
        };

        let tabledata = [];
        accountsForOrganisation.forEach(account => {
            let row = {
                'type': humanReadableAccountTypes[account.type],
                'organisation_1': account.organisation_1.info.name,
                'organisation_2': account.organisation_2.info.name,
                'status': account.status,
                'account': account
            };
            tabledata.push(row);
        });
        setOrgAccountsTable_data(tabledata);
    };

    const refreshOrganisationModal = () => {
        clearAllFields();
        getFullOrganisation();
    };
    const clearAllFields = () => {
        setOrgOptions(null);
        setOrgName(null);
        setOrgWebsite(null);
        setGenerationType(null);
        setXeroId(null);
        setCreditFacilityAmount(null);
        setPaymentTermDays(null);
        setDiscountPercentage(null);
        setBillingEmail(null);
        setDefaultName(null);
        setValidityPeriodMonths(null);
        setSupportRequestType(null);
        setReplacementPatternType(null);
        setNotificationType(null);
        setNotificationEmail(null);
        setReducedTenderPercent(null);
        setReducedTenderSupplierPercent(null);
        setLandingPageText(null);
        setThankYouPageText(null);
        setStatusPageText(null);
        setOrgUsersTable_data([]);
        setOrgAccountsTable_data([]);
        setAccountsForOrganisation(null);
    };

    const saveOrganisationModal = () => {
        if (props.activeOrgInModal.status !== 'ACTIVE') {
            props.setShowModal(false);
            alert('This organisation is Inactive. It can not be modified.');
            return;
        }

        if (!orgName || !orgOptions) {
            // do not run the mutation if the fields have not yet been populated
            return;
        }

        let root = `
            organisation:"${props.activeOrgInModal.id}"
            ${orgName ? `name:"${orgName}"` : ''}
            ${orgWebsite ? `website:"${orgWebsite}"` : ''}
        `;


        let voucher = updateOrganisationMutation_voucher();

        let invoice = updateOrganisationMutation_invoice();

        let options_root = updateOrganisationMutation_rootOption();


        let options = '';
        if (options_root || voucher || invoice) {
            // there are some options fields. set them
            options += `
                options: {
                    ${options_root}
                    ${voucher}
                    ${invoice}
                }
            `;
        }


        let queryArgs = `
            ${root}
            ${options}
        `;


        /** ORGANISATION OPTIONS
         organisation:"${props.activeOrgInModal.id}"
         name:${orgName ? `"${orgName}"` : null}
         website:${orgWebsite ? `"${orgWebsite}"` : null}

         options:{
         email_notification_option:${notificationType || null}
         email_notification_address_billing:${billingEmail ? `"${billingEmail}"` : null}
         email_notification_address_claims:${notificationEmail ? `"${notificationEmail}"` : null}
         replacement_pattern:${replacementPatternType || null}
         reduced_tender_threshold_percentage:${reducedTenderPercent || null}
         reduced_tender_minimum_suppliers:${reducedTenderSupplierPercent || null}
         voucher: {
         default_name:${defaultName ? `"${defaultName}"` : null}
         supported_request_type:${supportRequestType || null}
         validity_period_months:${validityPeriodMonths || null}
         }
         invoice:{
         generation_type:${generationType || null}
         xero_id:${xeroId ? `"${xeroId}"` : null}
         credit_facility_amount:${creditFacilityAmount || null}
         payment_term_days:${paymentTermDays || null}
         discount_percentage:${discountPercentage || null}
         }
         }
         **/

        updateOrganisation(
            queryArgs,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Organisation Updated Successfully', 'The organisation infomation has been updated successfully');
                refreshOrganisationModal();
            },
            props.onError
        );
    };

    function updateOrganisationMutation_voucher(){
        let voucher = '';
        if (defaultName || supportRequestType || validityPeriodMonths) {
            voucher += `
                  voucher:{
                    ${defaultName ? `default_name:"${defaultName}"` : ''}
                    ${supportRequestType ? `supported_request_type:${supportRequestType}` : ''}
                    ${validityPeriodMonths ? `validity_period_months:${validityPeriodMonths}` : ''}
                  }
            `;
        }
        return voucher;
    }

    function updateOrganisationMutation_invoice() {
        let invoice = '';
        if (generationType || xeroId || creditFacilityAmount || paymentTermDays || discountPercentage) {
            invoice += `
              invoice:{
                ${generationType ? `generation_type:${generationType}` : ''}
                ${xeroId ? `xero_id:"${xeroId}"` : ''}
                ${creditFacilityAmount ? `credit_facility_amount:${creditFacilityAmount}` : ''}
                ${paymentTermDays ? `payment_term_days:${paymentTermDays}` : ''}
                ${discountPercentage ? `discount_percentage:${discountPercentage}` : ''}
              }
        `;
        }
        return invoice;
    }

    function updateOrganisationMutation_rootOption() {
        let options_root = '';
        if (notificationType || billingEmail || notificationEmail || replacementPatternType || reducedTenderPercent || reducedTenderSupplierPercent) {
            options_root += `
              ${notificationType ? `email_notification_option:${notificationType}` : ''}
              ${billingEmail ? `email_notification_address_billing:"${billingEmail}"` : ''}
              ${notificationEmail ? `email_notification_address_claims:"${notificationEmail}"` : ''}
              ${replacementPatternType ? `replacement_pattern:${replacementPatternType}` : ''}
              ${reducedTenderPercent ? `reduced_tender_threshold_percentage:${reducedTenderPercent}` : ''}
              ${reducedTenderSupplierPercent ? `reduced_tender_minimum_suppliers:${reducedTenderSupplierPercent}` : ''}
            `;
        }
        return options_root;
    }

    const onDeactivateOrganisation = () => {
        let superAdmin = props.user.roles.filter(role => role.name === 'superadmin');
        if (superAdmin) {
            deactivateOrganisation(
                props.activeOrgInModal.id,
                null,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    alert('Successfully deactivated the organisation');
                    props.setShowModal(false);
                    window.location.reload();
                },
                props.onError
            );
        }
    };

    const onActivateOrganisation = () => {
        let superAdmin = props.user.roles.filter(role => role.name === 'superadmin');
        if (superAdmin) {
            activateOrganisation(
                props.activeOrgInModal.id,
                null,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    alert('Successfully activated the organisation');
                    props.setShowModal(false);
                    window.location.reload();
                },
                props.onError
            );
        }
    };

    const allProps = {
        ...props,
        organisations, setOrganisations,
        showAccountCreationModal, setShowAccountCreationModal,
        showCategoryCreationModal, setShowCategoryCreationModal,
        showRegionCreationModal, setShowRegionCreationModal,
        tab, setTab,
        orgName, setOrgName,
        orgWebsite, setOrgWebsite,
        orgLogo, setOrgLogo,
        orgAccountsTable_data, setOrgAccountsTable_data,
        orgUsersTable_data, setOrgUsersTable_data,
        accountsForOrganisation, setAccountsForOrganisation,
        generationType, setGenerationType,
        xeroId, setXeroId,
        creditFacilityAmount, setCreditFacilityAmount,
        paymentTermDays, setPaymentTermDays,
        discountPercentage, setDiscountPercentage,
        billingEmail, setBillingEmail,
        defaultName, setDefaultName,
        validityPeriodMonths, setValidityPeriodMonths,
        supportRequestType, setSupportRequestType,
        replacementPatternType, setReplacementPatternType,
        notificationType, setNotificationType,
        notificationEmail, setNotificationEmail,
        reducedTenderPercent, setReducedTenderPercent,
        reducedTenderSupplierPercent, setReducedTenderSupplierPercent,
        snappyLogoBase64, setSnappyLogoBase64,
        snappyLogoFilename, setSnappyLogoFilename,
        landingPageText, setLandingPageText,
        thankPageYouText, setThankYouPageText,
        statusPageText, setStatusPageText,
        orgOptions, setOrgOptions,
        refreshOrganisationModal, clearAllFields,
    };

    if (!organisations || !props.activeOrgInModal || !orgOptions) {
        return <></>;
    }

    return (

        // == MODAL ==
        <div
            className={('transition-all fixed top-0 left-0 z-5 h-modal w-full h-full bg-zinc-600/75 opacity-0 pointer-events-none ') + (props.showModal ? 'opacity-100 pointer-events-auto' : ' ')}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white min-w-3/4 self-center rounded-lg shadow-2xl overflow-y-scroll w-3/4 h-[90%] p-6"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}>

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-2xl font-semibold text-gray-600">
                            {props.activeOrgInModal && props.activeOrgInModal.info.name} Profile
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span onClick={() => props.setShowModal(false)}></span>
                        </button>
                    </div>

                    {/* TABS */}
                    <div>
                        <ul className="mt-10 mb-5 hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex">
                            <li className="w-full">
                                <a onClick={() => setTab(0)}
                                   className={tabStyle + (tab === 0 ? tabSelectedStyle : tabUnselectedStyle)}
                                   aria-current="page">Profile</a>
                            </li>
                            {/* hide a tab for a specific org type  <li className={`w-full ${props.activeOrgInModal.type === 'SUPPLIER' && 'hidden'}`}>*/}
                            <li className={classNames(
                                'w-full',
                                ('ROOTADMINISTRATOR'.includes(props.activeOrgInModal.type) && 'hidden')
                            )}
                            >
                                <a onClick={() => setTab(1)}
                                   className={classNames(
                                       tabStyle,
                                       (tab === 1 ? tabSelectedStyle : tabUnselectedStyle)
                                   )}
                                    // className={tab === 1 ? "inline-block w-full p-4 text-gray-900 bg-gray-100 rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none " : "inline-block w-full p-4 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none "}
                                >
                                    {formatAsTitle(props.activeOrgInModal.type) + ' Settings'}
                                </a>
                            </li>
                        </ul>
                    </div>


                    {/* BODY - Profile */}
                    {tab === 0 &&
                        <div className={
                            ('transition-all flex flex-col justify-around space-y-5 text-gray-700 ')
                            + (props.showModal && tab === 0 ? ' opacity-100 pointer-events-auto' : ' opacity-0 pointer-events-none')
                        }>

                            {/* CONTENT */}
                            <div className="px-[5rem] divide-y">

                                {Section_Explanation_Logo(allProps)}

                                {Section_Basic(allProps)}

                                <Section_Accounts {...allProps} />

                                {Section_Users(allProps)}

                            </div>

                        </div>
                    }


                    {/* BODY - Organisation type */}
                    {tab === 1 &&
                        <div className={
                            ('transition-all flex flex-col justify-around space-y-6 text-gray-700 ')
                            + (props.showModal && tab === 1 ? 'opacity-100 pointer-events-auto' : ' opacity-0 pointer-events-none')
                        }>

                            {/* CONTENT */}
                            <div className="px-[5rem] divide-y">

                                {/* INSURER */}
                                {Section_SnappyClaims(allProps)}

                                {/* INSURER */}
                                {Section_Claims(allProps)}

                                {/* SUPPLIER */}
                                {Section_Voucher(allProps)}

                                {/* INSURER SUPPLIER */}
                                {Section_Invoice(allProps)}

                                {/* SUPPLIER */}
                                <Section_SalesRegions {...allProps} />

                                {/* SUPPLIER */}
                                <Section_SalesCategories {...allProps} />

                                {/* SUPPLIER */}
                                <Section_CatalogueItems_DeliveryFees {...allProps} />

                            </div>


                        </div>
                    }

                    <Safe>
                        <Modal_AccountCreation
                            {...allProps}
                            open={showAccountCreationModal}
                            setOpen={setShowAccountCreationModal}
                        />
                    </Safe>

                    <Safe>
                        <Modal_CategoryCreation
                            {...allProps}
                            open={showCategoryCreationModal}
                            setOpen={setShowCategoryCreationModal}
                        />
                    </Safe>

                    <Safe>
                        <Modal_RegionCreation
                            {...allProps}
                            open={showRegionCreationModal}
                            setOpen={setShowRegionCreationModal}
                        />
                    </Safe>


                    {/* CANCEL SAVE */}
                    <div className="flex justify-between">

                        {props.activeOrgInModal.status === 'ACTIVE' ?
                            <button className="mt-10 btn btn-outline-light ml-0"
                                    onClick={() => {
                                        props.setDeleteModalOptions({
                                            title: 'Deactivate Organisation',
                                            message: 'You are about to deactivate the "' + props.activeOrgInModal.unique_reference_name + '" organisation',
                                            challenge: 'DEACTIVATE',
                                            successCallback: () => {

                                                // clear all local(cache) storage
                                                localStorage.clear();

                                                onDeactivateOrganisation();
                                            }
                                        });
                                        props.setShowModal(false);
                                        props.setShowConfirmDeleteModal(true);

                                    }}
                            >Deactivate Organisation
                            </button>

                            :

                            <button className="mt-10 btn btn-success ml-0" onClick={onActivateOrganisation}>
                                Activate Organisation
                            </button>
                        }

                        <div className="flex">

                            <button className="mt-10 btn btn" onClick={saveOrganisationModal}>
                                Update Organisation Info
                            </button>

                            <button className="mt-10 btn btn-light mr-0"
                                    onClick={() => props.setShowModal(false)}>Cancel
                            </button>
                        </div>

                    </div>


                </div>

            </div>
        </div>

    );

    function Section_Explanation_Logo(props){
        return (
            <>
                <div className="py-10">

                    <p className="text-gray-500 mb-10 text-center">
                        Below allows for the modifications of this organisation
                    </p>

                    <div className="flex justify-around align-center h-34">

                        <div className="self-center hidden">

                            {/* the selector input */}
                            <input type="button" className="btn"
                                   value="Select logo"
                                   onClick={() => document.getElementById('logo_file_input').click()}/>

                            {/* the file input */}
                            <input type="file" id="logo_file_input" className="hidden"
                                   onChange={(e) => {

                                       document.getElementById('image').src = URL.createObjectURL(e.target.files[0]); // set preview image

                                       let logoFile = e.target.files[0];
                                       if (!logoFile) return;

                                       const FR = new FileReader();
                                       FR.addEventListener('load', function (FRevent){ // attach event listener to run when filereader is done processing
                                           let base64 = FRevent.target.result;
                                           setOrgLogo(base64);
                                       });
                                       FR.readAsDataURL(e.target.files[0]); // trigger FileReader to create base64

                                   }}/>

                        </div>

                        <BasicImage
                            src={props.activeOrgInModal.info.logo_image_url}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="6"
                            sizeHeightRem="6"
                            className="ml-4 shadow-md"
                        />

                    </div>

                </div>
            </>
        );
    }

    function Section_SnappyClaims(props){

        // TODO finish upload snappy logo
        const onSaveSnappyClaimsTemplate = () => {

            let queryArgs = `
                organisation:"${props.activeOrgInModal.id}"
    
                snappy_info:{
                  landing_text:${landingPageText ? `"${landingPageText}"` : null}
                  status_text:${statusPageText ? `"${statusPageText}"` : null}
                  thank_you_text:${thankPageYouText ? `"${thankPageYouText}"` : null}
                }
            `;

            updateOrganisation(
                queryArgs,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    //TODO uploadSnappyLogo();
                    props.showNotificationModal('success', 'Snappy Claims Updated', 'The Snappy Claims template has been updated successfully');

                    // Because the SnappyInfo comes from the organisations state, which is not updated, we must manually update the SnappyInfo
                    let copy = {...props.activeOrgInModal};
                    copy.info.snappy_info = {
                        status_text: statusPageText,
                        thank_you_text: thankPageYouText,
                        landing_text: landingPageText,
                        snappy_logo_url: null
                    };
                    props.setActiveOrgInModal(copy);

                    props.refreshOrganisationModal();
                },
                props.onError
            );

        };

        // TODO finish the uploading of organisation logo/snappy logo
        /**const uploadSnappyLogo = () => {
            uploadSnappyClaimsLogo(
                snappyLogoBase64,
                snappyLogoFilename,
                props.activeOrgInModal,
                (data) => {

                },
                (error) => {

                }
            );
        };*/


        // List of organisations that can see this section.
        const allowedOrgTypes = ['INSURER'];

        // Check if the active organisation type is in the allowed list.
        const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

        if (!canSeeSection) {
            return <></>;
        }

        return (

            <div className="py-10 flex flex-col gap-6">

                {/* EXPLANATION */}
                <p className="text-gray-500 mb-4 text-center">
                    Below allows for the modification of this organisation's Snappy Claims template
                </p>

                {/* LANDING */}
                <div className="">
                    <label className="">Landing page</label>
                    <div className="mt-1">
                    <textarea
                        type="text"
                        rows="6"
                        placeholder="This is an easy method to capture the claimed items so that your claim can be processed. Please click the Next button beneath. We have made it resemble an ecommerce site so you will type the product in the Search bar and then on the Add to claim button. When all the items have been added just click on the Cart (top right) to review and then the Submit button. Your insurer will then start processing the claim and revert back to you when done."
                        className="textarea"
                        value={landingPageText} onChange={e => setLandingPageText(e.target.value)}
                    />

                    </div>
                </div>

                {/* THANK YOU */}
                <div className="">
                    <label className="">Thank you page</label>
                    <div className="mt-1">
                    <textarea
                        type="text"
                        rows="3"
                        placeholder="Thank you for submitting your claim."
                        className="textarea"
                        value={thankPageYouText} onChange={e => setThankYouPageText(e.target.value)}
                    />
                    </div>
                </div>

                {/* STATUS */}
                <div className="">
                    <label className="">Status page</label>
                    <div className="mt-1">
                    <textarea
                        type="text"
                        rows="4"
                        placeholder="Your claim is being processed and your insurer or broker will contact you once done. If you have any questions please do not hesitate to discuss with the agent handling teh claim and you can use the claim number above as reference."
                        className="textarea"
                        value={statusPageText} onChange={e => setStatusPageText(e.target.value)}
                    />

                    </div>
                </div>

                {/* CANCEL SAVE */}
                <div className="flex justify-between mb-8">
                    <button className="btn btn-light"
                            onClick={() => props.setShowModal(false)}>Cancel
                    </button>
                    <button className="btn" onClick={onSaveSnappyClaimsTemplate}>
                        Update Snappy Claims template
                    </button>
                </div>

            </div>

        );
    }

    function Section_Basic(props){
        return (
            <>
                <div className="w-full py-10">
                    <h1 className="text-2xl mb-8 font-bold">Organisation Profile</h1>
                    <div className="flex justify-between">
                        <p className="font-semibold">Organisation name</p>
                        <input className="input w-1/2"
                               value={orgName}
                               onChange={(e) => setOrgName(e.target.value)}/>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Organisation website</p>
                        <input className="input w-1/2"
                               placeholder="https:// ..."
                               value={orgWebsite}
                               onChange={(e) => setOrgWebsite(e.target.value)}/>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Reference</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.unique_reference_name}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Type</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.type}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Country</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.info.country}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Currency</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.info.currency}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">ID</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.id}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Status</p>
                        <p>{props.activeOrgInModal && props.activeOrgInModal.status}</p>
                    </div>
                </div>

            </>
        );
    }

    function Section_Invoice(props){

        // List of organisations that can see this section.
        const allowedOrgTypes = ['INSURER', 'SUPPLIER'];

        // Check if the active organisation type is in the allowed list.
        const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

        if (!canSeeSection) {
            return <></>;
        }

        return (
            <>
                <div className="w-full py-10">
                    <h1 className="text-2xl mb-8 font-bold">Billing</h1>
                    <div className="flex justify-between">
                        <p className="font-semibold">Generation</p>
                        <select onChange={(e) => setGenerationType(e.target.value)}
                                value={generationType}>
                            <option value="">-</option>
                            <option value="REQUEST">Request</option>
                            <option value="RECEIVE">Receive</option>
                            <option value="REDEEM">Redeem</option>
                        </select>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Xero ID</p>
                        <input className="input w-1/4"
                               placeholder="-"
                               value={xeroId}
                               onChange={(e) => setXeroId(e.target.value)}
                        />
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Credit facility</p>
                        <input className="input w-1/4"
                               placeholder="0"
                               value={creditFacilityAmount}
                               onChange={(e) => setCreditFacilityAmount(e.target.value)}/>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Payment terms (days)</p>
                        <input className="input w-1/4"
                               type="number"
                               placeholder="0"
                               value={paymentTermDays}
                               onChange={(e) => setPaymentTermDays(e.target.value)}/>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Discount %</p>
                        <input className="input w-1/4"
                               value={discountPercentage}
                               placeholder="0"
                               onChange={(e) => setDiscountPercentage(e.target.value)}/>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Billing Email</p>
                        <div className="thisistopreventlastpassbreakingtheui w-5/12">
                            <input className="input"
                                   placeholder="-"
                                   value={billingEmail}
                                   onChange={(e) => setBillingEmail(e.target.value)}/>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    function Section_Claims(props) {

        // List of organisations that can see this section.
        const allowedOrgTypes = ['INSURER'];

        // Check if the active organisation type is in the allowed list.
        const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

        if (!canSeeSection) {
            return <></>;
        }

        return (
            <>
                <div className="w-full py-10 divide">
                    <h1 className="text-2xl mb-8 font-bold">Claims</h1>
                    <div className="flex justify-between">
                        <p className="font-semibold">Replacement pattern</p>
                        <select onChange={(e) => setReplacementPatternType(e.target.value)}
                                value={replacementPatternType}>
                            <option value="">-</option>
                            <option value="CHEAPEST_QUOTE">Cheapest Quote</option>
                            <option value="CHEAPEST_QUOTES_ALL_SUPPLIERS">Cheapest Quote All
                                Suppliers
                            </option>
                        </select>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Notification</p>
                        <select onChange={(e) => setNotificationType(e.target.value)}
                                value={notificationType}>
                            <option value="">-</option>
                            <option value="DISABLED">Disabled</option>
                            <option value="ALL_AGENTS">All agents</option>
                            <option value="SINGLE_ADDRESS">Single address</option>
                        </select>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Notification email</p>
                        <div className="thisistopreventlastpassbreakingtheui w-5/12">
                            <input className="input w-full"
                                   autoComplete="off"
                                   placeholder="...@....com"
                                   value={notificationEmail}
                                   onChange={(e) => setNotificationEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Reduced tender %</p>
                        <input className="input w-1/4"
                               type="number"
                               placeholder="(0~100)"
                               value={reducedTenderPercent}
                               onChange={(e) => setReducedTenderPercent(e.target.value)}
                        />
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Reduced tender suppliers</p>
                        <input className="input w-1/4"
                               type="number"
                               placeholder="(0~100)"
                               value={reducedTenderSupplierPercent}
                               onChange={(e) => setReducedTenderSupplierPercent(e.target.value)}
                        />
                    </div>
                </div>
            </>
        );
    }


    function Section_CatalogueItems_DeliveryFees(props){

        const [supplierDeliverySheetDownloadUrl, setSupplierDeliverySheetDownloadUrl] = useState('');
        const [supplierCatalogSheetDownloadUrl, setSupplierCatalogSheetDownloadUrl] = useState('');
        const [catalogueSheetIsGenerating, setCatalogueSheetIsGenerating] = useState(false);
        const [deliverySheetIsGenerating, setDeliverySheetIsGenerating] = useState(false);


        const onGenerateAndDownloadCatalogItems = () => {
            // Generate and set the download button URL for the Catalogue spreadsheet

            if(catalogueSheetIsGenerating) return props.showAlertModal('info', 'Sheet Generating', 'A catalogue sheet is currently being generated')
            else setCatalogueSheetIsGenerating(true);

            generateSupplierQuotesCatalogSheet(
                props.activeOrgInModal.unique_reference_name,
                (data) => {
                    setSupplierCatalogSheetDownloadUrl(data.urls[0]);
                    setCatalogueSheetIsGenerating(false);
                }, (e) => {
                    props.onError(e);
                    setCatalogueSheetIsGenerating(false);
                }
            );
        };
        const onGenerateAndDownloadDeliveryFees = () => {
            // Generate and set the download button URL for the Catalogue spreadsheet

            if(deliverySheetIsGenerating) return props.showAlertModal('info', 'Sheet Generating', 'A delivery sheet is currently being generated')
            else setDeliverySheetIsGenerating(true);

            generateSupplierDeliveryCostsSheet(
                props.activeOrgInModal.unique_reference_name,
                (data) => {
                    setSupplierDeliverySheetDownloadUrl(data.urls[0]);
                    setDeliverySheetIsGenerating(false);
                }, (e) => {
                    props.onError(e);
                    setDeliverySheetIsGenerating(false);
                }
            );
        };

        const onUploadCatalogSheet = (inputElement) => {
            let file = inputElement.target.files[0];

            uploadFile(
                {
                    objectId: props.activeOrgInModal.id,
                    objectType: 'organisations',
                    contentType: 'SUPPLIER_QUOTES_CATALOGUE',
                    fileName: file.name,
                    file: file,
                },
                (data) => {
                    props.showToastNotificationModal('success', 'File Uploaded', 'The catalogue item spreadsheet has uploaded successfully');

                    let fileId = data.file.id;

                    // New ingestion method
                    importSupplierQuotesCatalogWorkbook(
                        props.activeOrgInModal.id,
                        fileId,
                        (data) => {
                            props.showNotificationModal('success', 'Spreadsheet loading begun', 'The spreadsheet ingestion has successfully been triggered');
                        },
                        props.onError
                    );

                },
                props.onError
            );

        };
        const onUploadDeliverySheet = (inputElement) => {
            let file = inputElement.target.files[0];

            uploadFile(
                {
                    objectId: props.activeOrgInModal.id,
                    objectType: 'organisations',
                    contentType: 'DATA',
                    fileName: file.name,
                    file: file,
                },
                (data) => {
                    props.showToastNotificationModal('success', 'File Uploaded', 'The delivery item spreadsheet has uploaded successfully');

                    let fileId = data.file.id;

                    importSupplierDeliveryCostWorkbook(
                        props.activeOrgInModal.id,
                        fileId,
                        (data) => {
                            props.showNotificationModal('success', 'Spreadsheet loading begun', 'The spreadsheet ingestion has successfully been triggered');
                        },
                        props.onError
                    );

                },
                props.onError
            );
        };


        // List of organisations that can see this section.
        const allowedOrgTypes = ['SUPPLIER'];

        // Check if the active organisation type is in the allowed list.
        const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

        if (!canSeeSection) {
            return <></>;
        }

        return (
            <>

                {/* Catalog items */}
                <div className="w-full py-10">

                    <div className="w-full flex justify-between">
                        <h1 className="text-2xl mb-8 font-bold">Catalogue items</h1>
                    </div>

                    <div className="w-full flex justify-start gap-12">

                        {/* GENERATE SHEET */}
                        <button className="btn" onClick={onGenerateAndDownloadCatalogItems}>

                            {/* Icons or Loading spinner */}
                            {catalogueSheetIsGenerating ?
                                <LoadingSpinner color='darkcyan' size='6' body=' ' />
                                :
                                <>
                                    <PlusIcon className="w-5 h-5"/>
                                    <DocumentReportIcon className="w-5 h-5 mr-3"/>
                                </>
                            }

                            Generate catalogue sheet
                        </button>

                        {/* DOWNLOAD SHEET */}
                        <a className={`btn gap-3 ${supplierCatalogSheetDownloadUrl ? '' : 'btn-disabled'}`} href={supplierCatalogSheetDownloadUrl} download="delivery_fees">
                            <DownloadIcon className="w-5 h-5"/>
                            Download catalogue sheet
                        </a>

                        {/* UPLOAD SHEET */}
                        <div>
                            <input className="invisible" hidden type="file" id="catalog_sheet_file_selection" onChange={onUploadCatalogSheet}/>
                            <label className="btn gap-3" htmlFor="catalog_sheet_file_selection">
                                <CloudUploadIcon className="w-5 h-5"/>
                                Upload catalogue sheet
                            </label>
                        </div>

                        {/* AUDIT TRAIL */}
                        <LimitedOrganisationAccess
                            types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                            mainOrganisation={props.mainOrganisation}
                        >
                            <Tooltip
                                content="View the upload_file history records of this organisation"
                            >
                                <button
                                    className='btn'
                                    onClick={() => onViewHistoryRecordsClicked(props, `organisation_id: "${props.activeOrgInModal.id}", object_type: upload_files`)}
                                >
                                    <CollectionIcon className="h-5 w-5 mr-1"/>
                                    Audit Trail
                                </button>
                            </Tooltip>
                        </LimitedOrganisationAccess>

                    </div>
                </div>


                {/* Delivery */}
                <div className="w-full py-10">

                    <div className="w-full flex justify-between">
                        <h1 className="text-2xl mb-8 font-bold">Delivery fees</h1>
                    </div>

                    <div className="w-full flex justify-start gap-12">
                        <button className="btn" onClick={onGenerateAndDownloadDeliveryFees}>

                            {/* Icons or Loading spinner */}
                            {deliverySheetIsGenerating ?
                                <LoadingSpinner color='darkcyan' size='6' body=' ' />
                                :
                                <>
                                    <PlusIcon className="w-5 h-5"/>
                                    <DocumentReportIcon className="w-5 h-5 mr-3"/>
                                </>
                            }

                            Generate delivery sheet
                        </button>
                        <a className={`btn gap-3 ${supplierDeliverySheetDownloadUrl ? '' : 'btn-disabled'}`} href={supplierDeliverySheetDownloadUrl} download="delivery_fees">
                            <DownloadIcon className="w-5 h-5"/>
                            Download delivery sheet
                        </a>
                        <div>
                            <input className="invisible" hidden type="file" id="delivery_sheet_file_selection" onChange={onUploadDeliverySheet}/>
                            <label className="btn gap-3" htmlFor="delivery_sheet_file_selection">
                                <CloudUploadIcon className="w-5 h-5"/>
                                Upload delivery sheet
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function Section_Voucher(props){

        // List of organisations that can see this section.
        const allowedOrgTypes = ['SUPPLIER'];

        // Check if the active organisation type is in the allowed list.
        const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

        if (!canSeeSection) {
            return <></>;
        }

        return (
            <>
                <div className="w-full py-10">
                    <h1 className="text-2xl mb-8 font-bold">Voucher</h1>
                    <div className="flex justify-between">
                        <p className="font-semibold">Name</p>
                        <div className="thisistopreventlastpassbreakingtheui w-1/4">
                            <input className="input"
                                   autoComplete="off"
                                   placeholder="-"
                                   value={defaultName}
                                   onChange={(e) => setDefaultName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Validility Period</p>
                        <input className="input w-1/4"
                               type="number"
                               placeholder="0"
                               value={validityPeriodMonths}
                               onChange={(e) => setValidityPeriodMonths(e.target.value)}
                        />
                    </div>
                    <div className="divider_CRUD"/>
                    <div className="flex justify-between">
                        <p className="font-semibold">Request type</p>
                        <select onChange={(e) => setSupportRequestType(e.target.value)}
                                value={supportRequestType}>
                            <option value="">-</option>
                            <option value="INTERNAL">Internal</option>
                            <option value="EMAIL_LINK">Email link</option>
                            <option value="TUTUKA">Tutuka</option>
                            <option value="TAKEALOT">Takealot</option>
                            <option value="PURCHASE_ORDER">Purchase order</option>
                        </select>
                    </div>
                </div>

            </>
        );
    }

    function Section_Users(props){
        return (
            <>
                <div className="w-full py-10">

                    {/* title */}
                    <div className="w-full flex justify-between">
                        <h1 className="text-2xl mb-8 font-bold">Users</h1>
                        <button className="btn-outline h-min p-2"
                                onClick={() => {
                                    props.setShowAddUserModal(true);
                                }}>
                            Create user
                        </button>
                    </div>

                    {/* table */}
                    <div className="w-full">
                        <TableComp data={orgUsersTable_data} columns={orgUsersTable_columns}/>
                    </div>
                </div>
            </>
        );
    }

}


function Section_Accounts(props){


    const orgAccountsTable_columns = [
        {Header: 'Account', accessor: 'type'},
        {Header: 'Organisation 1', accessor: 'organisation_1'},
        {Header: 'Organisation 2', accessor: 'organisation_2'},
        {Header: 'Status', accessor: 'status'},
        {
            Header: 'Actions',
            searchable: false,
            headerStyle: 'text-right',
            accessor: 'actions',
            id: 'edit',
            width: '150%',
            Cell: ({cell}) => (
                <div className="text-sm flex items-end justify-center">

                    <button
                        className="btn-outline-primary text-xs py-1.5 px-2 mx-1"
                        onClick={() => onOpenAccountInModal(cell.row.original.account)}
                    >
                        <PencilAltIcon className="w-4 h-4"/>
                    </button>

                    {
                        cell.row.original.status === 'ACTIVE' ?

                            <button
                                className="btn-outline-light text-xs py-1.5 px-2 mx-1"
                                onClick={() => onDeactivateAccount(cell)}
                            >
                                Deactivate
                            </button>

                            :

                            <button
                                className="btn-success text-xs py-1.5 px-2 mx-1"
                                onClick={() => onActivateAccount(cell)}
                            >
                                Activate
                            </button>

                    }

                </div>
            )
        }
    ];


    // =========  ACCOUNT CREATE/ACTIVATE/DEACTIVATE  =========

    const onDeactivateAccount = (rowData) => {

        let accountId = rowData.row?.original?.account?.id;
        if (!accountId) return props.showAlertModal('error', 'Failed', 'Unable to obtain the account ID');

        let superAdmin = props.user.roles.filter(role => role.name === 'superadmin');
        if (superAdmin) {
            deactivateAccount(
                accountId,
                null,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    props.showNotificationModal('success', 'Deactivation successful', 'The account has been deactivated successfully');
                    // getAccountsForOrganisation();
                    props.refreshOrganisationModal();
                },
                props.onError
            );
        }
    };
    const onActivateAccount = (rowData) => {

        let accountId = rowData.row?.original?.account?.id;
        if (!accountId) return props.showAlertModal('error', 'Failed', 'Unable to obtain the account ID');

        let superAdmin = props.user.roles.filter(role => role.name === 'superadmin');
        if (superAdmin) {
            activateAccount(
                accountId,
                null,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    props.showNotificationModal('success', 'Activation successful', 'The account has been activated successfully');
                    props.refreshOrganisationModal();
                },
                (error) => props.onError(error)
            );
        }
    };

    const [showCreateAccountInputs, setShowCreateAccountInputs] = useState(false);
    const [createAccount_selectedOrg1_Id, setCreateAccount_selectedOrg1_Id] = useState(null);
    const [createAccount_selectedOrg2_Id, setCreateAccount_selectedOrg2_Id] = useState(null);
    const onCreateAccount = () => {

        // Toggle/show the "create organisation inputs"
        if (!showCreateAccountInputs) {
            setShowCreateAccountInputs(true);
            setCreateAccount_selectedOrg1_Id(props.activeOrgInModal.id);
            setCreateAccount_selectedOrg2_Id(props.activeOrgInModal.id);
            return;
        }

        let org1 = props.organisations.find(org => org.id === createAccount_selectedOrg1_Id);
        let org2 = props.organisations.find(org => org.id === createAccount_selectedOrg2_Id);

        if(!org1 || !org2) return props.showAlertModal('error', 'Invalid', 'Please select two organisations to link');

        console.log('org1:', org1, 'org1id', createAccount_selectedOrg1_Id);
        console.log('org2:', org2, 'org2id', createAccount_selectedOrg2_Id);

        let org1Id = org1.id;
        let org2Id = org2.id;

        let mutationName = `CreateAccount___PRI_${org1.unique_reference_name}___SUB_${org2.unique_reference_name}`;
        mutationName = mutationName.replace(/[^a-zA-Z_]/g, '');
        console.log('mutationName:', mutationName);

        if (org1.type === 'ADMINISTRATOR' && org2.type === 'ADMINISTRATOR') return props.showAlertModal('error', 'Invalid Types', 'An administrator cannot be linked to another administrator');

        /** Account Types
         *  ADMINISTRATOR_INSURER
         *  ADMINISTRATOR_SUPPLIER
         *  INSURER_INSURER
         *  INSURER_SUPPLIER
         **/

        let accountType = '';
        if (org1.type === 'ADMINISTRATOR' && org2.type === 'INSURER') accountType = 'ADMINISTRATOR_INSURER';
        if (org1.type === 'ADMINISTRATOR' && org2.type === 'SUPPLIER') accountType = 'ADMINISTRATOR_SUPPLIER';
        if (org1.type === 'INSURER' && org2.type === 'INSURER') accountType = 'INSURER_INSURER';
        if (org1.type === 'INSURER' && org2.type === 'SUPPLIER') accountType = 'INSURER_SUPPLIER';

        if(!accountType) return props.showAlertModal('error', 'Invalid Types',
            `Unable to determine the account type. 
            Available types: 
            ADMINISTRATOR_INSURER, 
            ADMINISTRATOR_SUPPLIER, 
            INSURER_INSURER, 
            INSURER_SUPPLIER.`
        );

        let createAccountMutation = `
            mutation ${mutationName}{
              |mutation|(|placeholder|) {
                error{
                  type
                  message
                }
                account{
                  id
                  status
                  organisation_1{
                    id
                  }
                  organisation_2{
                    id
                  }
                  type
                }
              }
            }
        `

        let queryArgs = '';
        let mutation = '';

        if (accountType === "ADMINISTRATOR_INSURER") queryArgs = `administrator:"${org1Id}" insurer:"${org2Id}"`;
        if (accountType === "ADMINISTRATOR_SUPPLIER") queryArgs = `administrator:"${org1Id}" supplier:"${org2Id}"`;
        if (accountType === "INSURER_INSURER") queryArgs = `insurer_1:"${org1Id}" insurer_2:"${org2Id}"`;
        if (accountType === "INSURER_SUPPLIER") queryArgs = `insurer:"${org1Id}" supplier:"${org2Id}"`;

        if (accountType === "ADMINISTRATOR_INSURER") mutation = 'create_administrator_insurer_account';
        if (accountType === "ADMINISTRATOR_SUPPLIER") mutation = 'create_administrator_supplier_account';
        if (accountType === "INSURER_INSURER") mutation = 'create_insurer_insurer_account';
        if (accountType === "INSURER_SUPPLIER") mutation = 'create_insurer_supplier_account';

        createAccountMutation = createAccountMutation.replace('|mutation|', mutation).replace('|placeholder|', queryArgs);

        if (!accountType) return alert(`Unable to determine the account type:${accountType} - props.activeOrgInModal.type: ${props.activeOrgInModal.type} - org2.type: ${org2.type}`);

        customGraphRequest(
            createAccountMutation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Account created', `
                    The account has been created successfully. Between ${org1.unique_reference_name} and ${org2.unique_reference_name}. As type: ${accountType}.`);
                setShowCreateAccountInputs(false);
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );

    };


    // =========  ACCOUNT MODAL  =========

    const [accountInModal, setAccountInModal] = useState(null);
    const [paymentFlowType, setPaymentFlowType] = useState(null);

    useEffect(() => {
        /** Retrieve this accounts "options" data **/

        if (!props.activeOrgInModal || !accountInModal) return;

        console.log('accountInModal:', accountInModal, 'paymentFlowType:', paymentFlowType)

        let paymentFlowTypeDefault = 'DIRECTLY'

        let name = accountInModal.organisation_1.unique_reference_name + '__' + accountInModal.organisation_2.unique_reference_name;
        name = name.replace(/[^abc_]/g, "");

        if (!paymentFlowType){

            let query = `
                query account__${name}{
                  accounts(
                    organisations: ["${accountInModal.organisation_1.id}", "${accountInModal.organisation_2.id}"]
                  ){
                    accounts{
                       id
                      options{
                        voucher{
                          payment_flow_type
                        }
                      }
                    }
                  }
                }
            `;

            customGraphRequest(
                query,
                (data) => {
                    console.log(data);
                    setPaymentFlowType(data.accounts[0].options?.voucher?.payment_flow_type || paymentFlowTypeDefault);
                },
                props.onError,
            );
        }
    }, [accountInModal]);

    function updateAccount () {

        hideAccountDataModal();

        const selfAccount = props.accountsForOrganisation.find(account => account.organisation_1.id === props.activeOrgInModal.id && account.type === 'SELF');

        console.log('selfAccount:', selfAccount, 'paymentFlowType:', paymentFlowType)
        if(!paymentFlowType) return props.showAlertModal('error', 'Invalid', 'No options selected');

        let mutation = `
            mutation {
              update_account(
                account: "${accountInModal.id}"
                options: {
                  voucher: {
                    ${paymentFlowType ? `payment_flow_type: ${paymentFlowType}` : ''}
                  }
                }
              ){
                account{
                  id,
                  options{
                    voucher{
                      payment_flow_type
                    }
                  }
                }
              }
            }
        `;

        // get claim data
        customGraphRequest(
            mutation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Account Updated Successfully', 'The account has been updated successfully');
            },
            props.onError,
        );

    }


    useEffect(() => {
        /** re-render the CustomContentModal when the fields change*/
        showAccountDataModal()
    }, [paymentFlowType]);

    function onOpenAccountInModal(account) {
        setPaymentFlowType(null);

        setAccountInModal(account);
        showAccountDataModal()
    }

    function hideAccountDataModal() {
        setAccountInModal(null);
        props.setCustomModalOpen(false);
    }

    const showAccountDataModal = () => {

        if(!accountInModal) return;

        let disabled = accountInModal.type.toUpperCase() !== 'INSURER_SUPPLIER';
        // let disabled = false;

        let content = (

            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl'>Account meta data modification</h1>

                <div className='w-full border-b-2 border-gray-200 my-4'></div>

                <table>

                    <tbody>


                    <tr className={classNames(
                        'tailwind-tooltip-container',
                        disabled ? 'bg-gray-100 text-gray-100 font-light' : '',
                    )}>
                        <td className="font-semibold">Payment flow</td>

                        {!paymentFlowType ?
                            <td><LoadingSpinner color='darkcyan' size='6' body=' ' /></td>
                            :
                            <td className="h-full">
                                <div
                                    className="flex justify-end items-center h-full">
                                    <select onChange={(e) => {
                                        setPaymentFlowType(e.target.value)
                                    }}
                                            value={paymentFlowType}
                                            className={classNames(
                                                'w-fit',
                                                disabled ? 'bg-gray-100 text-gray-100 font-light' : '',
                                            )}
                                    disabled={disabled}>
                                        <option value="DIRECTLY">Directly</option>
                                        <option value="PASSTHROUGH">Passthrough</option>
                                    </select>
                                    {disabled && <span className='tailwind-tooltip left-[0%]'>Only applicable to INSURER_SUPPLIER accounts</span>}
                                </div>
                            </td>
                        }


                    </tr>

                    </tbody>


                </table>

                <div className="divider_CRUD"/>

                <div className='flex gap-4 mt-4 w-full justify-end'>

                    <button
                        className='btn'
                        onClick={updateAccount}
                    >
                        Save account
                    </button>
                    <button
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Cancel
                    </button>
                </div>

            </div>
        );

        props.showCustomModal(content, () => {
            hideAccountDataModal();
        });
    }


    // UI
    function accountTypeCreation() {
        let org1 = props.organisations.find(org => org.id === createAccount_selectedOrg1_Id);
        let org2 = props.organisations.find(org => org.id === createAccount_selectedOrg2_Id);

        if (!org1 || !org2) return <></>;

        let type = `${org1.type}_${org2.type}`;

        let types = ['ADMINISTRATOR_INSURER', 'ADMINISTRATOR_SUPPLIER', 'INSURER_INSURER', 'INSURER_SUPPLIER']
        if (types.includes(type)) return <span className=''>{type}</span>;
        else return <span className='text-red-400'>{type}</span>;

    }

    return (
        <>

            <div className="w-full py-10">

                {/* title */}
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Accounts</h1>

                    <div className="flex gap-5">
                        <div
                            className={`w-fit flex flex-col flex-wrap justify-end items-center gap-3 ${showCreateAccountInputs ? '' : 'hidden'} `}>

                            <div className="flex flex-wrap items-center">
                                <p className="w-fit font-bold mr-2 ">Organisation 1:</p>

                                {/* ACCOUNT SELECTION */}
                                <FilterSelect
                                    selectableOptions={props.organisations}
                                    onChange={(selected) => setCreateAccount_selectedOrg1_Id(selected.id)}
                                    defaultValue={props.activeOrgInModal}

                                    filterOptions={(selectableOptions, query) =>
                                        query === '' ?
                                            selectableOptions
                                            :
                                            selectableOptions.filter((option) =>
                                                option.unique_reference_name
                                                    .toLowerCase()
                                                    .replace(/\s+/g, '') // remove all whitespace
                                                    .includes(query.toLowerCase().replace(/\s+/g, ''))
                                            )}

                                    renderMainInput={(selected, query, setQuery) => (
                                        <div className="w-full relative">
                                            <Combobox.Button as="div" className="flex w-full items-center">
                                                <div className="absolute left-2 flex items-center pr-2">
                                                    <BasicImage
                                                        src={selected?.info?.logo_image_url}
                                                        fallbackSrc="/org-logo-placeholder.jpg"
                                                        alt="logo"
                                                        sizeWidthRem="1.8"
                                                        className="rounded-full"
                                                    />
                                                </div>
                                                <Combobox.Input
                                                    className="input pl-12 pr-10 w-full"
                                                    displayValue={(selected) => selected ? selected?.unique_reference_name : ''}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <div className="absolute right-0 flex items-center pr-2">
                                                    <ArrowDownIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </Combobox.Button>
                                        </div>
                                    )}

                                    renderOption={(option) =>
                                        <Combobox.Option
                                            key={option.id}
                                            className='relative'
                                            value={option}
                                        >
                                            {({selected, active}) => (
                                                <div className={classNames(
                                                    'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                                    selected ? 'font-bold' : 'font-normal', // Is the option selected
                                                    active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                                )}>
                                                    {/* SELECTED CHECK */}
                                                    {selected &&
                                                        <div className={classNames(
                                                            'absolute inset-y-0 left-0 flex items-center pl-1',
                                                            active ? 'text-white' : 'text-sky-600'
                                                        )}>
                                                            <CheckIcon className={`h-6 w-6`}/>
                                                        </div>
                                                    }
                                                    {/* ORG LOGO */}
                                                    <BasicImage
                                                        src={option?.info?.logo_image_url}
                                                        fallbackSrc={'/org-logo-placeholder.jpg'}
                                                        alt="logo"
                                                        sizeWidthRem="1.8"
                                                        className="rounded-full"
                                                    />
                                                    {/* ORG NAME */}
                                                    {option?.unique_reference_name}
                                                </div>
                                            )}
                                        </Combobox.Option>
                                    }
                                />

                            </div>

                            <div className="flex flex-wrap items-center">
                                <p className="w-fit font-bold mr-2 ">Organisation 2:</p>
                                {/* ACCOUNT SELECTION */}
                                <FilterSelect
                                    selectableOptions={props.organisations}
                                    onChange={(selected) => setCreateAccount_selectedOrg2_Id(selected.id)}
                                    defaultValue={props.activeOrgInModal}

                                    filterOptions={(selectableOptions, query) =>
                                        query === '' ?
                                            selectableOptions
                                            :
                                            selectableOptions.filter((option) =>
                                                option.unique_reference_name
                                                    .toLowerCase()
                                                    .replace(/\s+/g, '') // remove all whitespace
                                                    .includes(query.toLowerCase().replace(/\s+/g, ''))
                                            )}

                                    renderMainInput={(selected, query, setQuery) => (
                                        <div className="w-full relative">
                                            <Combobox.Button as="div" className="flex w-full items-center">
                                                <div className="absolute left-2 flex items-center pr-2">
                                                    <BasicImage
                                                        src={selected?.info?.logo_image_url}
                                                        fallbackSrc="/org-logo-placeholder.jpg"
                                                        alt="logo"
                                                        sizeWidthRem="1.8"
                                                        className="rounded-full"
                                                    />
                                                </div>
                                                <Combobox.Input
                                                    className="input pl-12 pr-10 w-full"
                                                    displayValue={(selected) => selected ? selected?.unique_reference_name : ''}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <div className="absolute right-0 flex items-center pr-2">
                                                    <ArrowDownIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </Combobox.Button>
                                        </div>
                                    )}

                                    renderOption={(option) =>
                                        <Combobox.Option
                                            key={option.id}
                                            className='relative'
                                            value={option}
                                        >
                                            {({selected, active}) => (
                                                <div className={classNames(
                                                    'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                                    selected ? 'font-bold' : 'font-normal', // Is the option selected
                                                    active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                                )}>
                                                    {/* SELECTED CHECK */}
                                                    {selected &&
                                                        <div className={classNames(
                                                            'absolute inset-y-0 left-0 flex items-center pl-1',
                                                            active ? 'text-white' : 'text-sky-600'
                                                        )}>
                                                            <CheckIcon className={`h-6 w-6`}/>
                                                        </div>
                                                    }
                                                    {/* ORG LOGO */}
                                                    <BasicImage
                                                        src={option?.info?.logo_image_url}
                                                        fallbackSrc={'/org-logo-placeholder.jpg'}
                                                        alt="logo"
                                                        sizeWidthRem="1.8"
                                                        className="rounded-full"
                                                    />
                                                    {/* ORG NAME */}
                                                    {option?.unique_reference_name}
                                                </div>
                                            )}
                                        </Combobox.Option>
                                    }
                                />

                            </div>

                            <p>Creating account: <b>{accountTypeCreation()}</b></p>

                        </div>

                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showCreateAccountInputs ? 'hidden' : 'btn-outline'}`}
                            onClick={onCreateAccount}>
                            Create single account
                        </button>

                        <Tooltip content='Select accounts from a grid'>
                            <button
                                className={`h-min p-2 mx-0 whitespace-nowrap ${showCreateAccountInputs ? 'hidden' : 'btn-outline'}`}
                                onClick={() => props.setShowAccountCreationModal(!props.showAccountCreationModal)}
                            >
                                Create multiple accounts {props.showAccountCreationModal}
                            </button>
                        </Tooltip>

                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showCreateAccountInputs ? 'btn' : 'hidden'}`}
                            onClick={onCreateAccount}>
                            + Create
                        </button>
                        <button className={`h-min p-2 mx-0 ${showCreateAccountInputs ? 'btn-light' : 'hidden'}`}
                                onClick={() => setShowCreateAccountInputs(false)}>
                            Hide
                        </button>

                    </div>

                </div>

                {/* table */}
                <div className="w-full">
                    <DataTable data={props.orgAccountsTable_data} columns={orgAccountsTable_columns}/>
                </div>
            </div>

        </>
    );
}


function Modal_AccountCreation(props) {

    useEffect(() => {
        if (!props.open)
            props.onClose?.();
    }, [props.open]);

    const [selectedOptions, setSelectedOptions] = useState([
        /*
            {primaryOrg: null, subOrg: null}
        */
    ]);

    const isOptionSelected = (orgOption) => selectedOptions.find(o => o.selectedOrg.id === orgOption.id);
    const getSelectedOption = (orgOption) => selectedOptions.find(o => o.selectedOrg.id === orgOption.id);

    const optionSelected = (selectedOrg) => {
        if(isOptionSelected(selectedOrg)) {
            // Remove the selected option
            setSelectedOptions(selectedOptions.filter(o => o.selectedOrg.id !== selectedOrg.id));
        } else {
            const selection = {
                primaryOrg: props.activeOrgInModal,
                subOrg: selectedOrg,
                mainOrg: props.activeOrgInModal,
                selectedOrg: selectedOrg,
            }
            setSelectedOptions([...selectedOptions, selection]);
        }
    }

    function swapPrimarySub(org) {
        let selectedOption = getSelectedOption(org);
        if(!selectedOption) return;
        const primaryOrg = selectedOption.primaryOrg;
        selectedOption.primaryOrg = selectedOption.subOrg;
        selectedOption.subOrg = primaryOrg;
        setSelectedOptions([...selectedOptions]);
    }

    function isPrimary(org) {
        return getSelectedOption(org)?.primaryOrg.id === org.id || false;
    }

    function orgTypesAllowed(mainOrgType, orgType) {
        /*enum AccountType {
            ADMINISTRATOR_INSURER
            ADMINISTRATOR_SUPPLIER
            INSURER_INSURER
            INSURER_SUPPLIER
        }*/

        if(!mainOrgType || !orgType) return false;

        const accountType = `${mainOrgType}_${orgType}`;
        return ['ADMINISTRATOR_INSURER', 'ADMINISTRATOR_SUPPLIER', 'INSURER_INSURER', 'INSURER_SUPPLIER'].includes(accountType);
    }

    function accountAlreadyExists(selectableOrg) {
        // Does a account already exist between the activeOrg and this selectableOrg organisation?

        let account = props.orgAccountsTable_data.find(account => {
            const org1 = account.account.organisation_1;
            const org2 = account.account.organisation_2;

            if (org1.id === props.activeOrgInModal.id && org2.id === selectableOrg.id)
                return account;
            if (org2.id === props.activeOrgInModal.id && org1.id === selectableOrg.id)
                return account;

        });

        const accountBetweenOrgsExists = !isEmpty(account)

        return accountBetweenOrgsExists;
    }

    // ACCOUNT CREATION
    function onSubmitOrganisations(btnHandler) {

        if (selectedOptions.length === 0)
            return props.showAlertModal('error', 'No organisations selected', 'Please select at least one organisation to create an account with');

        props.showConfirmModal(
            'success',
            `Create accounts`,
            (<div>
                <p><b>Are you sure you want to create {selectedOptions.length} accounts with the below organisations?</b>
                </p>
                <p className='text-red-400'>This is a long running operation, do not interact with page until it has completed.</p>
                <hr className='my-2'/>
                <ul className="list-disc list-inside">
                    {selectedOptions.map((selection, index) => (
                        <li key={index}>{selection.selectedOrg.info.name}</li>
                    ))}
                </ul>
            </div>),
            'Create all',
            () => createAllAccounts(btnHandler),
            () => btnHandler.reset()

        )

    }

    async function createAllAccounts(btnHandler) {
        props.showToastNotificationModal(
            'success',
            'Creating accounts',
            ''
        );

        // Use a for...of loop for sequential execution with async/await
        for (const selection of selectedOptions) {
            try {
                await createAccount(selection.primaryOrg, selection.subOrg);
            } catch (error) {
                // Handle errors for each individual account creation
                console.error("Error creating account:", error);
                // props.showAlertModal('error', 'Account Creation Failed', `Failed to create account for ${selection.subOrg.info.name}.  See console for details.  Other accounts may still be created.`);
                props.onError(error)

                // Important:  Decide whether to continue or stop on error.
                // Current implementation *continues* to the next account.
                // If you want to *stop* on the first error, remove the try/catch
                // and let the error propagate up.  The 'await' will then cause
                // createAllAccounts to halt.
            }
        }

        props.showNotificationModal(
            'success',
            'Accounts created',
            'All accounts have been created successfully'
        )
        btnHandler.reset();
        props.refreshOrganisationModal();

    }

    function createAccount(primaryOrg, subOrg) {

        return new Promise((resolve, reject) => { // <-- Return a promise!

            /** Account Types
             *  ADMINISTRATOR_INSURER
             *  ADMINISTRATOR_SUPPLIER
             *  INSURER_INSURER
             *  INSURER_SUPPLIER
             **/

            console.log('\n\n\n----');
            console.log('Create account primaryOrg:', primaryOrg);
            console.log('Create account subOrg:', subOrg);

            if (!primaryOrg || !subOrg) return props.showAlertModal('error', 'Invalid', 'Two organisations required to create an account');

            const accountType = `${primaryOrg.type}_${subOrg.type}`;
            if(!['ADMINISTRATOR_INSURER', 'ADMINISTRATOR_SUPPLIER', 'INSURER_INSURER', 'INSURER_SUPPLIER'].includes(accountType)){
                return props.showAlertModal(
                    'error',
                    'Invalid Type',
                    <div>
                        <p><b>{accountType}</b> is not a valid account type.</p>
                        <p>Available types: </p>
                        <ul className="list-disc list-inside">
                            <li>ADMINISTRATOR_INSURER</li>
                            <li>ADMINISTRATOR_SUPPLIER</li>
                            <li>INSURER_INSURER</li>
                            <li>INSURER_SUPPLIER</li>
                        </ul>
                    </div>
                );
            }

            let mutationName = `CreateAccount___PRI_${primaryOrg.unique_reference_name}___SUB_${subOrg.unique_reference_name}`;
            mutationName = mutationName.replace(/[^a-zA-Z_]/g, '');

            // Mutation arguments
            // ----------------
            let queryArgs = '';
            if (accountType === "ADMINISTRATOR_INSURER") queryArgs = `administrator:"${primaryOrg.id}" insurer:"${subOrg.id}"`;
            if (accountType === "ADMINISTRATOR_SUPPLIER") queryArgs = `administrator:"${primaryOrg.id}" supplier:"${subOrg.id}"`;
            if (accountType === "INSURER_INSURER") queryArgs = `insurer_1:"${primaryOrg.id}" insurer_2:"${subOrg.id}"`;
            if (accountType === "INSURER_SUPPLIER") queryArgs = `insurer:"${primaryOrg.id}" supplier:"${subOrg.id}"`;

            // Mutation name
            // ----------------
            let mutation = '';
            if (accountType === "ADMINISTRATOR_INSURER") mutation = 'create_administrator_insurer_account';
            if (accountType === "ADMINISTRATOR_SUPPLIER") mutation = 'create_administrator_supplier_account';
            if (accountType === "INSURER_INSURER") mutation = 'create_insurer_insurer_account';
            if (accountType === "INSURER_SUPPLIER") mutation = 'create_insurer_supplier_account';

            let createAccountMutation = `
                mutation ${mutationName}{
                  ${mutation}(${queryArgs}) {
                    error{
                      type
                      message
                    }
                    account{
                      id
                      status
                      organisation_1{
                        id
                      }
                      organisation_2{
                        id
                      }
                      type
                    }
                  }
                }
            `

            console.log('createAccountMutation:', createAccountMutation);
            customGraphRequest(
                createAccountMutation,
                (data) => {
                    // clear all local(cache) storage
                    localStorage.clear();
                    props.showToastNotificationModal(
                        'success',
                        `${accountType} created`,
                        `${primaryOrg.unique_reference_name} <-> ${subOrg.unique_reference_name}`,
                        2000
                    )
                    resolve(data); // Resolve the promise on success
                },
                (error) => {
                    reject(error);  // Reject the promise on error
                }
            );

        });

    }

    function renderOption(org) {
        /* A selectable org option */

        const selectedOption = getSelectedOption(org) || null;
        const isSelected = isOptionSelected(org);

        const validTypes = orgTypesAllowed(selectedOption?.primaryOrg.type, selectedOption?.subOrg.type)

        return (
            <div
                key={org.id}
                className={classNames(
                    'input flex gap-2 w-[19%] items-center p-2 rounded-lg min-h-[3.4rem] h-full hover:shadow-lg',
                    'active:bg-sky-200 ring ring-2 ring-transparent',
                    accountAlreadyExists(org) && 'bg-sky-100 text-gray-500 pointer-events-none',
                    isSelected && 'ring-sky-400',
                    isSelected && !validTypes && 'ring-red-400 ring-4',
                )}
                onClick={() => optionSelected(org)}
            >
                <Tooltip
                    className='max-w-max min-w-[20rem] w-max'
                    content={
                    <div>
                        <p><b>Swap Primary</b></p>
                        <hr/>
                        { isSelected ?
                            <>
                                {!validTypes && <div className='py-3'>
                                    <p className='text-2xl'><b>Account type invalid</b></p>
                                    <p className='text-md'>{selectedOption?.primaryOrg.type}_{selectedOption?.subOrg.type}</p>
                                </div>}
                                <p>The account will be created as:</p>
                                <div className='flex text-left'>
                                    <div className='w-1/2'><b>Primary:</b></div>
                                    <div
                                        className='w-1/2 text-wrap break-words'>{selectedOption?.primaryOrg.info.name}</div>
                                </div>
                                <div className='flex text-left'>
                                    <div className='w-1/2'><b>Sub:</b></div>
                                    <div
                                        className='w-1/2 text-wrap break-words'>{selectedOption?.subOrg.info.name}</div>
                                </div>
                            </>
                            :
                            <i>Organisation not selected</i>
                        }

                    </div>}
                >
                    <input
                        className={classNames(
                            'checkbox',
                            accountAlreadyExists(org) && 'invisible',
                        )}
                        type="checkbox"
                        defaultChecked={isPrimary(org)}
                        checked={isPrimary(org)}
                        onClick={(e) => {
                            e.stopPropagation() // Preventing selecting the option when clicking the checkbox
                            swapPrimarySub(org)
                        }}
                    />
                </Tooltip>

                <p>{org.info.name}</p>

            </div>
        );

    }

    function renderOrganisations(type) {
        /* type: ADMINISTRATOR INSURER SUPPLIER */
        /* Container of all options for a type of organisation */

        // SUPPLIER_ADMINISTRATOR is not allowed, but ADMINISTRATOR_SUPPLIER is allowed, so we check both ways
        const typesAllowed = orgTypesAllowed(props.activeOrgInModal.type, type) || orgTypesAllowed(type, props.activeOrgInModal.type);

        return (
            <div className={classNames(
                'flex flex-wrap gap-4 justify-between items-stretch',
                !typesAllowed && 'opacity-50 pointer-events-none',
            )}>
                {props.organisations.map((org, index) => org.type === type && renderOption(org) )}

                <div className='grow'></div>

            </div>
        );

    }

    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[51] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-center justify-center min-h-screen h-full">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.stopPropagation(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                {/* MODAL */}
                                <div
                                    className="relative w-[90rem] max-h-[50rem] overflow-y-scroll bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-1/2 p-8"
                                    onClick={(e) => {
                                        /**
                                         this is to prevent the parent grabbing the click events inside the modal box
                                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                                         **/
                                        e.stopPropagation();
                                    }}>
                                    <div>

                                        <div>

                                            {/* HEADING */}
                                            <div
                                                className={classNames(
                                                    'flex gap-4 p-2 pb-6 justify-center items-center',
                                                    'border-b-[2px] rounded-lg border-gray-400' // DIVIDER
                                                )}>

                                                <div
                                                    className="flex flex-col gap-6 items-center text-3xl font-light text-gray-900 text-center"
                                                >
                                                    <p><b>Account Creation: </b>{props.activeOrgInModal.info.name}</p>
                                                </div>

                                            </div>

                                            <div className='p-2 text-gray-600 space-y-2'>
                                                <p className='border border-[1px] border-gray-400 p-1 rounded-lg w-fit'>
                                                    Click on a organisation to select it, as a sub organisation
                                                </p>
                                                <div className='flex gap-2 items-center'>
                                                    <CheckIcon className='h-5 w-5 bg-blue-600 rounded-lg text-white'/> Click the checkbox to swap the primary and sub roles
                                                </div>
                                            </div>

                                            {/* BODY */}
                                            <div className='flex flex-col gap-8 py-8'>

                                                <p className='text-lg font-light pt-2'>Admin</p>
                                                {renderOrganisations('ADMINISTRATOR')}

                                                <hr/>

                                                <p className='text-lg font-light pt-2'>Insurers</p>
                                                {renderOrganisations('INSURER')}

                                                <hr/>

                                                <p className='text-lg font-light pt-2'>Suppliers</p>
                                                {renderOrganisations('SUPPLIER')}


                                            </div>

                                        </div>

                                        {/* X CLOSE */}
                                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>


                                        </div>

                                    </div>

                                    {/* FOOTER */}
                                    <div className="mt-2 px-20 flex w-full justify-between">
                                        <button
                                            type="button"
                                            className="btn-light shadow-none"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            Close
                                        </button>

                                        <ApiButton
                                            onClick={onSubmitOrganisations}
                                        >
                                            Create {selectedOptions.length} accounts
                                        </ApiButton>


                                    </div>

                                </div>

                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    );

}


function Modal_CategoryCreation(props) {

    // Query all category data
    const [allCategoryData, setAllCategoryData] = useState(null);
    useEffect(() => {

        if (allCategoryData) {
            populateCategories();
        } else {
            queryCategoriesBasic(
                (data) => {
                    setAllCategoryData(data['categories']);
                },
                (error) => props.onError(error)
            );
        }

    }, [allCategoryData]);

    // Repacked category data to make rendering more readable and maintainable
    const [allCatA, setAllCatA] = useState([
        // {
        //     'name': 'Audio Visual',
        // }
    ]);
    const [allCatB, setAllCatB] = useState([
        // {
        //     'name': 'Speakers',
        //     'belongsTo': 'Audio Visual'
        // }
    ]);
    const [allCatC, setAllCatC] = useState([
        // {
        //     'name': 'Single Speaker',
        //     'belongsTo': 'Speakers'
        // }
    ]);
    const populateCategories = () => {

        // Filter the categories into their respective levels
        let allA = [];
        let allB = [];
        let allC = [];
        allCategoryData.forEach(category => {
            // original category object from backend
            // {
            //     category_a : "Tools & DIY"
            //     category_b : "Power & Cordless Tools"
            //     category_c : "AC/DC Arc Welding Machines"
            //     category_d : "UNKNOWN"
            //     display_name : "AC/DC Arc Welding Machines"
            // }

            if (category.category_a) {
                const alreadyAdded = allA.find(c => c.name === category.category_a);
                if(!alreadyAdded) allA.push({
                    'name': category.category_a
                });
            }

            if (category.category_b) {
                const alreadyAdded = allB.find(c => c.name === category.category_b);
                if(!alreadyAdded) allB.push({
                    'name': category.category_b,
                    'belongsTo': category.category_a
                });
            }

            if (category.category_c) {
                const alreadyAdded = allC.find(c => c.name === category.category_c);
                if(!alreadyAdded) allC.push({
                    'name': category.category_c,
                    'belongsTo': category.category_b
                });
            }

        });

        setAllCatA(allA);
        setAllCatB(allB);
        setAllCatC(allC);

    };

    // Category selection
    const [selectedCategories, setSelectedCategories] = useState([
        // {
        //     'level': '', // CATEGORY_A, CATEGORY_B, CATEGORY_C
        //     'name': null, // e.g "Tool & DIY"
        // }
    ]);
    const selectCategory = (level, name) => {
        setSelectedCategories([...selectedCategories, {level: level, name: name}]);
    }
    const deselectCategory = (level, name) => {
        // INFO: the below filter was not working when implemented with a 1 liner.
        // So a mulit line approach was implemented
        const removed = selectedCategories.filter(c => {
            // This is the category we want to remove, do not keep it
            if(c.level === level && c.name === name) return false;

            // Keep
            return true;
        })
        setSelectedCategories(removed);
    }
    const toggleSelectCategory = (level, name) => {
        const selected = isCategorySelected(level, name);
        if (selected) {
            deselectCategory(level, name);
        } else {
            selectCategory(level, name);
        }
    }
    const isCategorySelected = (level, name) => {
        // Has this category been selected?
        // E.G: CATEGORY_A, "Tools & DIY"
        const exists = selectedCategories.find(c => c.level === level && c.name === name)
        return !isEmpty(exists) ? true : false;
    }
    useEffect(() => {
        populateSelectedWithExistingCategories();
    }, [props.open, allCategoryData]);
    function populateSelectedWithExistingCategories(){
        // Look at the previously added categories inside the activeOrgInModal,
        // and populate selectedCategories with them

        // Exit early if we don't have the required data
        if (!props.orgOptions?.options?.sales_categories || !allCategoryData) return;

        const existingCategories = [];

        // Loop through each sales category in the organization
        props.orgOptions.options.sales_categories.forEach(category => {
            /* Existing category =
            {
                brands_excluded : []
                brands_included : []
                category_level : "CATEGORY_A"
                category_name : "Audio Visual"
                category_rule : "INCLUDE"
                date_created : "2023-04-19 11:57:26"
                date_updated : "2023-04-19 11:57:26"
            }
            * */
            // Skip categories that aren't explicitly included
            if (category.category_rule !== 'INCLUDE') return;

            // Find the corresponding category in our full category dataset
            // We need to match based on category level (A/B/C) and name
            const matchingCategory = allCategoryData.find(c => {
                if (category.category_level === 'CATEGORY_A') return c.category_a === category.category_name;
                if (category.category_level === 'CATEGORY_B') return c.category_b === category.category_name;
                if (category.category_level === 'CATEGORY_C') return c.category_c === category.category_name;
                return false;
            });

            // If we found a match, add it to our selected categories
            // with the proper format expected by the component
            if (matchingCategory) {
                existingCategories.push({
                    level: category.category_level,
                    name: category.category_name,
                });
            }
        });

        // Update the selected categories state with our findings
        setSelectedCategories(existingCategories);
    }

    // Fold everything to A, B, or C level
    const [levelFolding, setLevelFolding] = useState('A');
    // Fold/Unfold a specific level
    const [perLevelFolding, setPerLevelFolding] = useState([]);
    const toggleCategoryFolding = (categoryName) => {
        if(isEmpty(perLevelFolding.find(name => name === categoryName))){
            setPerLevelFolding([...perLevelFolding, categoryName]);
        }
        else {
            setPerLevelFolding(perLevelFolding.filter(name => name !== categoryName));
        }
    }

    // Utility
    function getSubCategoryCount(name, level){
        let count = allCategoryData.filter(c => {
            if (level == 0) {
                if (c.category_a == name && c.category_b) return true;
            }
            if (level == 1) {
                if (c.category_b == name && c.category_c) return true;
            }
            if (level == 2) {
                if (c.category_c == name && c.category_d) return true;
            }
        });
        return count.length;
    }

    // Save
    function reduceToParentCategories(){
        // Return "selectedCategories", but with the below reduction rules applied:
        // If all the level b is selected under a level a, then we just select the level a
        // e.g: if "Spectacles" and "Sunglasses" are selected under "Eyewear", then we just select "Eyewear"
        // Same for level c under b

        // Separate selected categories by level for easy lookup
        const selectedA = selectedCategories.filter(c => c.level === 'CATEGORY_A').map(c => c.name);
        const selectedB = selectedCategories.filter(c => c.level === 'CATEGORY_B').map(c => c.name);
        const selectedC = selectedCategories.filter(c => c.level === 'CATEGORY_C').map(c => c.name);

        const selectedASet = new Set(selectedA);
        const selectedBSet = new Set(selectedB);
        const selectedCSet = new Set(selectedC);

        // We'll build a new selection as we go
        const finalSelection = new Set();

        // Helper: Check if all C's under a given B are selected
        function isAllCUnderBSelected(bName) {
            const cUnderB = allCatC.filter(c => c.belongsTo === bName);
            return cUnderB.length > 0 && cUnderB.every(c => selectedCSet.has(c.name));
        }

        // Helper: Check if all B (and their C children) under A are fully selected
        function isAllBUnderASelected(aName) {
            const bUnderA = allCatB.filter(b => b.belongsTo === aName);
            return bUnderA.length > 0 && bUnderA.every(b => {
                return selectedBSet.has(b.name) || isAllCUnderBSelected(b.name);
            });
        }

        // Process A-level categories
        for (const aCat of allCatA) {
            const aName = aCat.name;

            if (isAllBUnderASelected(aName)) {
                // Include A-level category and exclude its subcategories
                finalSelection.add({ level: 'CATEGORY_A', name: aName });
            } else {
                // If A is not fully selected, check B-level categories
                const bUnderA = allCatB.filter(b => b.belongsTo === aName);

                for (const bCat of bUnderA) {
                    const bName = bCat.name;

                    if (isAllCUnderBSelected(bName)) {
                        // Include B-level category and exclude its C-level children
                        finalSelection.add({ level: 'CATEGORY_B', name: bName });
                    } else {
                        // If not all C under B are selected, include selected C-level categories
                        const cUnderB = allCatC.filter(c => c.belongsTo === bName);
                        for (const cCat of cUnderB) {
                            if (selectedCSet.has(cCat.name)) {
                                finalSelection.add({ level: 'CATEGORY_C', name: cCat.name });
                            }
                        }

                        // Include B-level category if it was explicitly selected
                        if (selectedBSet.has(bName)) {
                            finalSelection.add({ level: 'CATEGORY_B', name: bName });
                        }
                    }
                }

                // Include A-level category if it was explicitly selected
                if (selectedASet.has(aName)) {
                    finalSelection.add({ level: 'CATEGORY_A', name: aName });
                }
            }
        }

        // Convert the set to an array and return it
        return Array.from(finalSelection);
    }

    function saveCategories() {
        console.log('Selected categories:', selectedCategories);

        const reducedSelectedCategories = reduceToParentCategories();

        // Filter out categories that are already in the organisation
        const newlyAddedCategories = reducedSelectedCategories.filter(cat => {
            // Check if the category is already in the organisation
            const exists = props.orgOptions?.options?.sales_categories?.find(c => c.category_name === cat.name && c.category_level === cat.level);
            return !exists;
        });

        if(newlyAddedCategories.length === 0){
            return props.showNotificationModal(
                'info',
                'No new categories',
                'No new categories have been selected to add to the organisation.'
            );
        }

        // Array to store successfully added categories
        const successfulCategories = [];

        // Create an array of promises
        const mutationPromises = newlyAddedCategories.map(cat =>
            onAddCategory(cat.level, cat.name).then(() => {
                // Push successful category to the list
                successfulCategories.push(cat.name);
            })
        );

        // Wait for all mutations to resolve
        Promise.allSettled(mutationPromises).then(() => {
            // Display success notification with list of successful categories
            props.showNotificationModal(
                'success',
                'Added',
                <div className='text-left px-8'>
                    The following categories have been successfully added:
                    <ul className='pt-4'>
                        {successfulCategories.map(cat => (
                            <li className='list-disc' key={cat}>{cat}</li>
                        ))}
                    </ul>
                </div>
            );

            // Clear local storage and refresh modal
            localStorage.clear();
            props.refreshOrganisationModal();
        });
    }

    function updateCategories() {
        // 1 --- Any categories that are in the organisation but not selected should be removed
        // 2 --- Save all newly selected categories
        // 3 --- Display a success message, showing all newly added categories, and all removed categories

        const reducedSelectedCategories = reduceToParentCategories();

        // Current included categories in the org
        const currentCategories = props.orgOptions?.options?.sales_categories?.filter(c => c.category_rule === 'INCLUDE') || [];

        // Determine which categories to remove
        const toRemove = currentCategories.filter(curCat =>
            !reducedSelectedCategories.find(selCat => selCat.name === curCat.category_name && selCat.level === curCat.category_level)
        );

        // Determine which categories to add
        const toAdd = reducedSelectedCategories.filter(selCat =>
            !currentCategories.find(curCat => curCat.category_name === selCat.name && curCat.category_level === selCat.level)
        );

        if (toAdd.length === 0 && toRemove.length === 0) {
            return props.showNotificationModal('info', 'No changes', 'No categories were changed.');
        }

        const addedNames = [];
        const removedNames = [];

        // Create a single array of all operations we need to perform
        // Each element is a function returning a Promise
        const allOperations = [
            ...toAdd.map(cat => () => onAddCategory(cat.level, cat.name).then(() => addedNames.push(cat.name))),
            ...toRemove.map(cat => () => onRemoveCategory(cat.category_level, cat.category_name).then(() => removedNames.push(cat.category_name)))
        ];

        // Execute all operations in sequence, adding a 100ms delay before each call (after the first)
        allOperations.reduce(
            (acc, operation, index) => {
                return acc.then(() =>
                    new Promise(resolve => setTimeout(resolve, index === 0 ? 0 : 100)) // 100ms delay before each subsequent call
                        .then(operation)
                );
            },
            Promise.resolve()
        ).then(() => {
            // Show success message
            props.showNotificationModal(
                'success',
                'Categories Updated',
                <div className='text-left px-8'>
                    {addedNames.length > 0 && (
                        <>
                            <p><b>Added:</b></p>
                            <ul className='pt-2'>
                                {addedNames.map(cat => (
                                    <li className='list-disc' key={`added-${cat}`}>{cat}</li>
                                ))}
                            </ul>
                            <br />
                        </>
                    )}
                    {removedNames.length > 0 && (
                        <>
                            <p><b>Removed:</b></p>
                            <ul className='pt-2'>
                                {removedNames.map(cat => (
                                    <li className='list-disc' key={`removed-${cat}`}>{cat}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            );

            // Clear local storage and refresh
            localStorage.clear();
            props.refreshOrganisationModal();
        });
    }

    const onAddCategory = (level, name) => {

        const addCategoryToOrganisation = `
            mutation {
                supplier_include_sales_category(
                    supplier: "${props.activeOrgInModal.id}"
                    category_name: "${name}" 
                    category_level: ${level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options {
                            sales_categories {
                                category_name
                                category_level
                                category_rule
                            }       
                        }
                    }
                }
            }
        `;

        // Return a Promise from customGraphRequest
        return new Promise((resolve) => {
            customGraphRequest(
                addCategoryToOrganisation,
                () => resolve(), // Resolve on success
                () => resolve() // Resolve on error (ignoring the error)
            );
        });
    };

    const onRemoveCategory = (level, name) => {

        const removeCategoryFromOrganisation = `
            mutation {
                supplier_exclude_sales_category(
                    supplier: "${props.activeOrgInModal.id}"
                    category_name: "${name}" 
                    category_level: ${level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options {
                            sales_categories {
                                category_name
                                category_level
                                category_rule
                            }       
                        }
                    }
                }
            }
        `;

        // Return a Promise from customGraphRequest
        return new Promise((resolve) => {
            customGraphRequest(
                removeCategoryFromOrganisation,
                () => resolve(), // Resolve on success
                () => resolve() // Resolve on error (ignoring the error)
            );
        });
    };

    // Render utilities
    const allCatB_forCatA = (catAName) => allCatB.filter(catB => catB.belongsTo === catAName);
    const allCatC_forCatB = (catBName) => allCatC.filter(catC => catC.belongsTo === catBName);
    const shouldUnfoldB = (catAName) => perLevelFolding.find(name => name === catAName) || levelFolding === 'B' || levelFolding === 'C';
    const shouldUnfoldC = (catBName) => perLevelFolding.find(name => name === catBName) || levelFolding === 'C';
    function foldingArrow(name){
        const style = 'h-6 btn-light shadow-none border-gray-100 text-gray-400 m-0 p-0 px-2';
        return (
            perLevelFolding.find(n => n === name) ?
                <ChevronDownIcon className={style} onClick={()=>toggleCategoryFolding(name)}/>
                :
                <ChevronUpIcon className={style} onClick={()=>toggleCategoryFolding(name)}/>
            );
    }


    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[51] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"/>
                </Transition.Child>

                <style>
                    {`
                        .checkbox {
                            width: 2rem;
                            padding: 0rem;
                            margin: 0rem;
                        }
                    `}
                </style>

                <div className="flex items-center justify-center min-h-screen h-full">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.stopPropagation(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                {/* MODAL */}
                                <div
                                    className="relative w-[90rem] max-h-[50rem] overflow-y-scroll bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-1/2 p-8"
                                    onClick={(e) => {
                                        /**
                                         this is to prevent the parent grabbing the click events inside the modal box
                                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                                         **/
                                        e.stopPropagation();
                                    }}>
                                    <div>

                                        <div>

                                            {/* HEADING */}
                                            <div
                                                className={classNames(
                                                    'flex gap-4 p-2 pb-6 justify-center items-center',
                                                    'border-b-[2px] rounded-lg border-gray-400' // DIVIDER
                                                )}>

                                                <div
                                                    className="flex flex-col gap-6 items-center text-3xl font-light text-gray-900 text-center"
                                                >
                                                    <p><b>Add multiple categories
                                                        for: </b>{props.activeOrgInModal.info.name}</p>
                                                </div>

                                            </div>

                                            {/* BODY */}
                                            <div className='flex flex-col gap-8 py-8'>


                                                <div className='flex items-center w-full justify-between'>

                                                    {/* EXAMPLE */}
                                                    <div
                                                        className='flex gap-4 text-gray-600 opacity-50 pointer-event-none italic'>
                                                        <input
                                                            className='checkbox'
                                                            type='checkbox'
                                                            checked={true}
                                                        />
                                                        <p className='wrap'>
                                                            If the checkbox is already ticket, it means the
                                                            category is already in the list
                                                            of <b>included</b> SalesCategories for this organisation
                                                        </p>
                                                    </div>

                                                    {/* LEVEL FOLDING */}
                                                    <Tooltip content={<div>
                                                        <p>Save the selected categories</p>
                                                        <hr/>
                                                        <i>NB: B and C may take a few seconds to load</i>
                                                    </div>}>
                                                        <div className='flex gap-4'>
                                                            <button className='btn-light'
                                                                    onClick={() => setLevelFolding('A')}>
                                                                Fold A
                                                            </button>
                                                            <button className='btn-light'
                                                                    onClick={() => setLevelFolding('B')}>
                                                                Fold B
                                                            </button>
                                                            <button className='btn-light'
                                                                    onClick={() => setLevelFolding('C')}>
                                                                Fold C
                                                            </button>
                                                        </div>
                                                    </Tooltip>


                                                    {/* SAVE BUTTONS */}
                                                    <div className='flex gap-2'>

                                                        {/* ADD */}
                                                        <Tooltip
                                                            position='left'
                                                            content={<div>
                                                                <p>Only add the additional selected categories</p>
                                                                <hr/>
                                                                <i>Unselected categories will not be removed(excluded)</i>
                                                            </div>}
                                                        >
                                                            <button className='btn'
                                                                    onClick={saveCategories}
                                                            >
                                                                Add
                                                            </button>
                                                        </Tooltip>

                                                        {/* SAVE */}
                                                        <Tooltip
                                                            position='left'
                                                            content={<div>
                                                                <p>Update all categories</p>
                                                                <hr/>
                                                                <div className='text-left' >
                                                                    <p><i>
                                                                        - Unchanged categories won't be modified
                                                                    </i></p>
                                                                    <p><i>
                                                                        - Newly selected categories will be added (included)
                                                                    </i></p>
                                                                    <p><i>
                                                                        - Un-selected categories will be removed (excluded)
                                                                    </i></p>
                                                                </div>
                                                            </div>}
                                                        >
                                                            <button className='btn'
                                                                    onClick={()=>props.showConfirmModal(
                                                                        'info',
                                                                        'Update all categories',
                                                                        <p>Categories will be <b>included</b> or <b>excluded</b> based on the selected categories</p>,
                                                                        'Update all categories',
                                                                        updateCategories
                                                                    )}
                                                            >
                                                                Update
                                                            </button>
                                                        </Tooltip>

                                                    </div>


                                                </div>

                                                <hr/>

                                                <div className='mb-[10rem] flex flex-col '>

                                                    {allCatA.map((catA, index) => (

                                                        // LEVEL A CATEGORY INSTANCE: e.g "Tools & DIY"
                                                        <div className='flex flex-col' key={catA.level + catA.name}>

                                                            <div className='flex items-center gap-2'>
                                                                <input
                                                                    className='checkbox'
                                                                    type='checkbox'
                                                                    checked={isCategorySelected('CATEGORY_A', catA.name)}
                                                                    onChange={() => toggleSelectCategory('CATEGORY_A', catA.name)}
                                                                />
                                                                <p className='flex items-center gap-12 items-center'>
                                                                    {catA.name}
                                                                    <div className='flex gap-2 items-center'>
                                                                        {foldingArrow(catA.name)}
                                                                        <p className='text-gray-400 text-xs'>[{getSubCategoryCount(catA.name, 0)}]</p>
                                                                    </div>
                                                                </p>
                                                            </div>


                                                            {/* LEVEL B CATEGORY INSTANCE: e.g "Power & Cordless Tools"  */}
                                                            <div className='flex flex-col pl-8 gap-4 pt-2'>

                                                                {
                                                                    shouldUnfoldB(catA.name)
                                                                    &&
                                                                    allCatB_forCatA(catA.name).map((catB, index) => (

                                                                        <div key={catB.level + catB.name}>

                                                                            <div className='flex items-center gap-2'>
                                                                                <input
                                                                                    className='checkbox'
                                                                                    type='checkbox'
                                                                                    checked={isCategorySelected('CATEGORY_B', catB.name)}
                                                                                    onChange={() => toggleSelectCategory('CATEGORY_B', catB.name)}
                                                                                />
                                                                                <p className='flex items-center gap-12 items-center'>
                                                                                    {catB.name}

                                                                                    <div
                                                                                        className='flex gap-2 items-center'>
                                                                                        {foldingArrow(catB.name)}
                                                                                        <p className='text-gray-400 text-xs'>[{getSubCategoryCount(catB.name, 1)}]</p>
                                                                                    </div>

                                                                                </p>
                                                                            </div>


                                                                            {/* LEVEL C CATEGORY INSTANCE: e.g "Angle Grinders" */}
                                                                            <div
                                                                                className='flex w-full flex-wrap gap-2 pl-12 pt-2'>

                                                                                {
                                                                                    shouldUnfoldC(catB.name)
                                                                                    &&
                                                                                    allCatC_forCatB(catB.name).map((catC, index) => (

                                                                                        <div
                                                                                            className='flex gap-2 items-center text-xs w-[22%] overflow-x-hide'
                                                                                            key={catC.level + catC.name}
                                                                                        >
                                                                                            <input
                                                                                                className='checkbox'
                                                                                                type='checkbox'
                                                                                                checked={isCategorySelected('CATEGORY_C', catC.name)}
                                                                                                onChange={() => toggleSelectCategory('CATEGORY_C', catC.name)}
                                                                                            />
                                                                                            <p>{catC.name}</p>
                                                                                        </div>

                                                                                        /* ------ LEVEL B INSTANCE END ------ */

                                                                                    ))}
                                                                            </div>


                                                                        </div>

                                                                        /* ------ LEVEL B INSTANCE END ------ */

                                                                    ))}

                                                            </div>

                                                            {/* GAP */}
                                                            {/* Different gaps between level A depending on the folding */}
                                                            <div className={classNames(
                                                                levelFolding === 'A' && 'py-2',
                                                                levelFolding === 'B' && 'py-6',
                                                                levelFolding === 'C' && 'py-14',
                                                            )}></div>


                                                        </div>

                                                        // ------ LEVEL A CATEGORY INSTANCE END ---

                                                    ))}


                                                </div>


                                            </div>

                                        </div>

                                        {/* X CLOSE */}
                                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>


                                        </div>

                                    </div>

                                    {/* FOOTER */}
                                    <div className="mt-2 px-20 flex w-full justify-between">
                                        <Tooltip content='Close modal (changes will be lost)'>
                                            <button
                                                type="button"
                                                className="btn-light shadow-none"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                Close
                                            </button>
                                        </Tooltip>

                                        {/* SAVE BUTTONS */}
                                        <div className='flex gap-2'>

                                            {/* ADD */}
                                            <Tooltip
                                                position='left'
                                                content={<div>
                                                    <p>Only add the additional selected categories</p>
                                                    <hr/>
                                                    <i>Unselected categories will not be removed(excluded)</i>
                                                </div>}
                                            >
                                                <button className='btn'
                                                        onClick={saveCategories}
                                                >
                                                    Add
                                                </button>
                                            </Tooltip>

                                            {/* SAVE */}
                                            <Tooltip
                                                position='left'
                                                content={<div>
                                                    <p>Update all categories</p>
                                                    <hr/>
                                                    <div className='text-left'>
                                                        <p><i>
                                                            - Unchanged categories won't be modified
                                                        </i></p>
                                                        <p><i>
                                                            - Newly selected categories will be added (included)
                                                        </i></p>
                                                        <p><i>
                                                            - Un-selected categories will be removed (excluded)
                                                        </i></p>
                                                    </div>
                                                </div>}
                                            >
                                                <button className='btn'
                                                        onClick={() => props.showConfirmModal(
                                                            'info',
                                                            'Update all categories',
                                                            <p>Categories will
                                                                be <b>included</b> or <b>excluded</b> based on the
                                                                selected categories</p>,
                                                            'Update all categories',
                                                            updateCategories
                                                        )}
                                                >
                                                    Update
                                                </button>
                                            </Tooltip>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    );

}


function Modal_RegionCreation(props) {

    // Query all region data
    const [allRegionData, setAllRegionData] = useState(null);

    useEffect(() => {
        if (allRegionData) {
            populateRegions();
        } else {
            queryRegions(
                null,
                props.activeOrgInModal.info.country,
                (data) => {
                    // data.regions should contain an array of region objects
                    // each with region_0 through region_4 fields
                    const uniqueRegions = data.regions;
                    setAllRegionData(uniqueRegions);
                },
                (error) => props.onError(error)
            );
        }
    }, [allRegionData]);

    // Repacked region data to make rendering more readable and maintainable
    const [allReg0, setAllReg0] = useState([]);
    const [allReg1, setAllReg1] = useState([]);
    const [allReg2, setAllReg2] = useState([]);
    const [allReg3, setAllReg3] = useState([]);
    const [allReg4, setAllReg4] = useState([]);

    const populateRegions = () => {
        // Filter the regions into their respective levels
        let level0 = [];
        let level1 = [];
        let level2 = [];
        let level3 = [];
        let level4 = [];

        allRegionData.forEach(region => {
            // Example region structure:
            // {
            //   "id": "some_id",
            //   "region_0": "Country",
            //   "region_1": "State/Province",
            //   "region_2": "City",
            //   "region_3": "District",
            //   "region_4": "Neighborhood"
            // }

            // Level 0 (region_0)
            if (region.region_0) {
                const alreadyAdded = level0.find(r => r.name === region.region_0);
                if (!alreadyAdded) level0.push({ 'name': region.region_0 });
            }

            // Level 1 (region_1)
            if (region.region_1) {
                const alreadyAdded = level1.find(r => r.name === region.region_1 && r.belongsTo === region.region_0);
                if (!alreadyAdded) level1.push({
                    'name': region.region_1,
                    'belongsTo': region.region_0
                });
            }

            // Level 2 (region_2)
            if (region.region_2) {
                const alreadyAdded = level2.find(r => r.name === region.region_2 && r.belongsTo === region.region_1);
                if (!alreadyAdded) level2.push({
                    'name': region.region_2,
                    'belongsTo': region.region_1
                });
            }

            // Level 3 (region_3)
            if (region.region_3) {
                const alreadyAdded = level3.find(r => r.name === region.region_3 && r.belongsTo === region.region_2);
                if (!alreadyAdded) level3.push({
                    'name': region.region_3,
                    'belongsTo': region.region_2
                });
            }

            // Level 4 (region_4)
            if (region.region_4) {
                const alreadyAdded = level4.find(r => r.name === region.region_4 && r.belongsTo === region.region_3);
                if (!alreadyAdded) level4.push({
                    'name': region.region_4,
                    'belongsTo': region.region_3
                });
            }

        });

        setAllReg0(level0);
        setAllReg1(level1);
        setAllReg2(level2);
        setAllReg3(level3);
        setAllReg4(level4);
    };

    // Region selection
    const [selectedRegions, setSelectedRegions] = useState([]);

    const selectRegion = (level, name) => {
        setSelectedRegions([...selectedRegions, { level: level, name: name }]);
    }

    const deselectRegion = (level, name) => {
        const removed = selectedRegions.filter(r => !(r.level === level && r.name === name));
        setSelectedRegions(removed);
    }

    const toggleSelectRegion = (level, name) => {
        const selected = isRegionSelected(level, name);
        if (selected) {
            deselectRegion(level, name);
        } else {
            selectRegion(level, name);
        }
    }

    const isRegionSelected = (level, name) => {
        const exists = selectedRegions.find(r => r.level === level && r.name === name);
        return !isEmpty(exists);
    }

    useEffect(() => {
        populateSelectedWithExistingRegions();
    }, [props.open, allRegionData]);

    function populateSelectedWithExistingRegions() {
        // Look at the previously added regions inside the activeOrgInModal,
        // and populate selectedRegions with them
        if (!props.orgOptions?.options?.sales_regions || !allRegionData) return;

        const existingRegions = [];

        // Example of how a region might be stored:
        // {
        //   region: "SomeRegionName",
        //   level: "REGION_0" ... "REGION_4",
        //   type: "INCLUDE"
        // }
        props.orgOptions.options.sales_regions.forEach(region => {
            if (region.type !== 'INCLUDE') return;

            // Match in full dataset
            const matchingRegion = allRegionData.find(r => {
                switch (region.level) {
                    case 'REGION_0': return r.region_0 === region.region;
                    case 'REGION_1': return r.region_1 === region.region;
                    case 'REGION_2': return r.region_2 === region.region;
                    case 'REGION_3': return r.region_3 === region.region;
                    case 'REGION_4': return r.region_4 === region.region;
                    default: return false;
                }
            });

            if (matchingRegion) {
                existingRegions.push({
                    level: region.level,
                    name: region.region,
                });
            }
        });

        setSelectedRegions(existingRegions);
    }

    // Folding
    const [levelFolding, setLevelFolding] = useState('1'); // By default, fold to the second level
    // We'll store opened names at each level in perLevelFolding
    const [perLevelFolding, setPerLevelFolding] = useState([]);
    const toggleRegionFolding = (regionName) => {
        if (perLevelFolding.includes(regionName)) {
            setPerLevelFolding(perLevelFolding.filter(name => name !== regionName));
        } else {
            setPerLevelFolding([...perLevelFolding, regionName]);
        }
    }

    // Utility to count sub-level
    function getSubRegionCount(name, level) {
        // level indicates at which level we are counting the next level down
        // level 0: count how many region_1 under region_0
        // level 1: count how many region_2 under region_1
        // etc.
        let arrayFrom, field;
        switch (level) {
            case 0: arrayFrom = allReg1; field = 'belongsTo'; break;
            case 1: arrayFrom = allReg2; field = 'belongsTo'; break;
            case 2: arrayFrom = allReg3; field = 'belongsTo'; break;
            case 3: arrayFrom = allReg4; field = 'belongsTo'; break;
            default: return 0;
        }

        return arrayFrom.filter(r => r[field] === name).length;
    }

    function reduceToParentRegions() {
        // Similar logic to reduceToParentCategories but now for 5 levels.
        // If all children of a region are selected, just select the parent.

        const selected0 = selectedRegions.filter(r => r.level === 'REGION_0').map(r => r.name);
        const selected1 = selectedRegions.filter(r => r.level === 'REGION_1').map(r => r.name);
        const selected2 = selectedRegions.filter(r => r.level === 'REGION_2').map(r => r.name);
        const selected3 = selectedRegions.filter(r => r.level === 'REGION_3').map(r => r.name);
        const selected4 = selectedRegions.filter(r => r.level === 'REGION_4').map(r => r.name);

        const s0 = new Set(selected0);
        const s1 = new Set(selected1);
        const s2 = new Set(selected2);
        const s3 = new Set(selected3);
        const s4 = new Set(selected4);

        // Helpers
        function allSelectedAtLevel(childArray, parentName, setOfChildNames) {
            const children = childArray.filter(c => c.belongsTo === parentName);
            return children.length > 0 && children.every(c => setOfChildNames.has(c.name));
        }

        function isAll4Under3Selected(r3Name) {
            return allSelectedAtLevel(allReg4, r3Name, s4);
        }

        function isAll3Under2Selected(r2Name) {
            // For each r3 under r2
            const r3Children = allReg3.filter(c => c.belongsTo === r2Name);
            return r3Children.length > 0 && r3Children.every(r3Child => {
                return s3.has(r3Child.name) || isAll4Under3Selected(r3Child.name);
            });
        }

        function isAll2Under1Selected(r1Name) {
            const r2Children = allReg2.filter(c => c.belongsTo === r1Name);
            return r2Children.length > 0 && r2Children.every(r2Child => {
                return s2.has(r2Child.name) || isAll3Under2Selected(r2Child.name);
            });
        }

        function isAll1Under0Selected(r0Name) {
            const r1Children = allReg1.filter(c => c.belongsTo === r0Name);
            return r1Children.length > 0 && r1Children.every(r1Child => {
                return s1.has(r1Child.name) || isAll2Under1Selected(r1Child.name);
            });
        }

        const finalSelection = [];

        // Process level 0
        for (const r0 of allReg0) {
            const r0Name = r0.name;

            if (isAll1Under0Selected(r0Name)) {
                // Just select REGION_0
                finalSelection.push({ level: 'REGION_0', name: r0Name });
            } else {
                // Not fully selected, go down to level 1
                const r1Children = allReg1.filter(r1 => r1.belongsTo === r0Name);
                for (const r1Child of r1Children) {
                    const r1Name = r1Child.name;

                    if (isAll2Under1Selected(r1Name)) {
                        finalSelection.push({ level: 'REGION_1', name: r1Name });
                    } else {
                        // Check level 2
                        const r2Children = allReg2.filter(r2 => r2.belongsTo === r1Name);
                        for (const r2Child of r2Children) {
                            const r2Name = r2Child.name;

                            if (isAll3Under2Selected(r2Name)) {
                                finalSelection.push({ level: 'REGION_2', name: r2Name });
                            } else {
                                // Check level 3
                                const r3Children = allReg3.filter(r3 => r3.belongsTo === r2Name);
                                for (const r3Child of r3Children) {
                                    const r3Name = r3Child.name;
                                    if (isAll4Under3Selected(r3Name)) {
                                        finalSelection.push({ level: 'REGION_3', name: r3Name });
                                    } else {
                                        // Check level 4
                                        const r4Children = allReg4.filter(r4 => r4.belongsTo === r3Name);
                                        for (const r4Child of r4Children) {
                                            if (s4.has(r4Child.name)) {
                                                finalSelection.push({ level: 'REGION_4', name: r4Child.name });
                                            }
                                        }

                                        // Include level 3 if explicitly selected
                                        if (s3.has(r3Name)) finalSelection.push({ level: 'REGION_3', name: r3Name });
                                    }
                                }

                                // Include level 2 if explicitly selected
                                if (s2.has(r2Name)) finalSelection.push({ level: 'REGION_2', name: r2Name });
                            }
                        }

                        // Include level 1 if explicitly selected
                        if (s1.has(r1Name)) finalSelection.push({ level: 'REGION_1', name: r1Name });
                    }
                }

                // Include level 0 if explicitly selected
                if (s0.has(r0Name)) finalSelection.push({ level: 'REGION_0', name: r0Name });
            }
        }

        return finalSelection;
    }

    function saveRegions() {
        const reducedSelectedRegions = reduceToParentRegions();

        const newlyAddedRegions = reducedSelectedRegions.filter(reg => {
            // Check if already in the org
            const exists = props.orgOptions?.options?.sales_regions?.find(r => r.region === reg.name && r.level === reg.level);
            return !exists;
        });

        if (newlyAddedRegions.length === 0) {
            return props.showNotificationModal(
                'info',
                'No new regions',
                'No new regions have been selected to add to the organisation.'
            );
        }

        const successfulRegions = [];

        // Start with a resolved promise to chain subsequent operations
        let chain = Promise.resolve();

        newlyAddedRegions.forEach((reg, index) => {
            chain = chain
                .then(() => onAddRegion(reg.level, reg.name))
                .then(() => {
                    successfulRegions.push(reg.name);

                    // Introduce a 100ms delay before the next addition
                    return new Promise(resolve => setTimeout(resolve, 100));
                });
        });

        chain.then(() => {
            // Once all have completed:
            props.showNotificationModal(
                'success',
                'Added',
                <div className='text-left px-8'>
                    The following regions have been successfully added:
                    <ul className='pt-4'>
                        {successfulRegions.map(reg => (
                            <li className='list-disc' key={reg}>{reg}</li>
                        ))}
                    </ul>
                </div>
            );

            localStorage.clear();
            props.refreshOrganisationModal();
        });
    }

    const onAddRegion = (level, name) => {
        const addRegionToOrganisation = `
            mutation {
                supplier_include_sales_region(
                    supplier: "${props.activeOrgInModal.id}"
                    region: "${name}" 
                    level: ${level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options {
                            sales_regions {
                                region
                                level
                                type
                            }       
                        }
                    }
                }
            }
        `;

        return new Promise((resolve) => {
            customGraphRequest(
                addRegionToOrganisation,
                () => resolve(),
                () => resolve()
            );
        });
    };

    const updateRegions = () => {
        // 1 --- Any regions that are in the organisation but not selected should be removed
        // 2 --- Save all newly selected regions
        // 3 --- Display a success message, showing all newly added categories, and all removed regions
        const reducedSelectedRegions = reduceToParentRegions();

        // Current included regions in the org
        const currentRegions = props.orgOptions?.options?.sales_regions?.filter(r => r.type === 'INCLUDE') || [];

        // Determine which regions to remove
        const toRemove = currentRegions.filter(curReg =>
            !reducedSelectedRegions.find(selReg => selReg.name === curReg.region && selReg.level === curReg.level)
        );

        // Determine which regions to add
        const toAdd = reducedSelectedRegions.filter(selReg =>
            !currentRegions.find(curReg => curReg.region === selReg.name && curReg.level === selReg.level)
        );

        // If no changes
        if (toAdd.length === 0 && toRemove.length === 0) {
            return props.showNotificationModal(
                'info',
                'No changes',
                'No regions were changed.'
            );
        }

        const addedNames = [];
        const removedNames = [];

        // Create an array of operations (each a function returning a Promise)
        const allOperations = [
            ...toAdd.map(reg => () => onAddRegion(reg.level, reg.name).then(() => addedNames.push(reg.name))),
            ...toRemove.map(reg => () => onRemoveRegion(reg.level, reg.region).then(() => removedNames.push(reg.region)))
        ];

        // Execute all operations in sequence, adding a 100ms delay before each subsequent call
        allOperations.reduce(
            (acc, operation, index) => {
                return acc.then(() =>
                    new Promise(resolve => setTimeout(resolve, index === 0 ? 0 : 100))
                        .then(operation)
                );
            },
            Promise.resolve()
        ).then(() => {
            // Once all done, show success notification
            props.showNotificationModal(
                'success',
                'Regions Updated',
                <div className='text-left px-8'>
                    {addedNames.length > 0 && (
                        <>
                            <p><b>Added:</b></p>
                            <ul className='pt-2'>
                                {addedNames.map(reg => (
                                    <li className='list-disc' key={`added-${reg}`}>{reg}</li>
                                ))}
                            </ul>
                            <br/>
                        </>
                    )}
                    {removedNames.length > 0 && (
                        <>
                            <p><b>Removed:</b></p>
                            <ul className='pt-2'>
                                {removedNames.map(reg => (
                                    <li className='list-disc' key={`removed-${reg}`}>{reg}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            );

            // Clear local storage and refresh
            localStorage.clear();
            props.refreshOrganisationModal();
        });
    }


    const onRemoveRegion = (level, name) => {
        const removeRegionFromOrganisation = `
        mutation {
            supplier_exclude_sales_region(
                supplier: "${props.activeOrgInModal.id}"
                region: "${name}" 
                level: ${level}
            ){
                error {type, message}
                organisation {
                    id
                    options {
                        sales_regions {
                            region
                            level
                            type
                        }       
                    }
                }
            }
        }
    `;

        return new Promise((resolve, reject) => {
            customGraphRequest(
                removeRegionFromOrganisation,
                () => resolve(),
                (error) => reject(error)
            );
        });
    };

    // Rendering logic

    const allReg1_forReg0 = (reg0Name) => allReg1.filter(r => r.belongsTo === reg0Name);
    const allReg2_forReg1 = (reg1Name) => allReg2.filter(r => r.belongsTo === reg1Name);
    const allReg3_forReg2 = (reg2Name) => allReg3.filter(r => r.belongsTo === reg2Name);
    const allReg4_forReg3 = (reg3Name) => allReg4.filter(r => r.belongsTo === reg3Name);

    const shouldUnfold1 = (reg0Name) => perLevelFolding.includes(reg0Name) || ['1','2','3','4'].includes(levelFolding);
    const shouldUnfold2 = (reg1Name) => perLevelFolding.includes(reg1Name) || ['2','3','4'].includes(levelFolding);
    const shouldUnfold3 = (reg2Name) => perLevelFolding.includes(reg2Name) || ['3','4'].includes(levelFolding);
    const shouldUnfold4 = (reg3Name) => perLevelFolding.includes(reg3Name) || ['4'].includes(levelFolding);

    function foldingArrow(name) {
        const style = 'h-6 btn-light shadow-none border-gray-100 text-gray-400 m-0 p-0 px-2';
        return (
            perLevelFolding.includes(name) ?
                <ChevronDownIcon className={style} onClick={() => toggleRegionFolding(name)}/>
                :
                <ChevronUpIcon className={style} onClick={() => toggleRegionFolding(name)}/>
        );
    }




    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[51] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"/>
                </Transition.Child>

                <style>
                    {`
                        .checkbox {
                            width: 2rem;
                            padding: 0rem;
                            margin: 0rem;
                        }
                    `}
                </style>

                <div className="flex items-center justify-center min-h-screen h-full">
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="flex justify-center items-center w-full h-full">
                            <div className='bg-white rounded-xl'
                                 onClick={(e) => e.stopPropagation()}
                            >
                                <div
                                    className="relative w-[90rem] max-h-[50rem] overflow-y-scroll bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-1/2 p-8">

                                    {/* HEADER */}
                                    <div
                                        className='flex gap-4 p-2 pb-6 justify-center items-center border-b-[2px] rounded-lg border-gray-400'>
                                        <div
                                            className="flex flex-col gap-6 items-center text-3xl font-light text-gray-900 text-center">
                                            <p><b>Add multiple regions for: </b>{props.activeOrgInModal.info.name}</p>
                                        </div>
                                    </div>

                                    {/* BODY */}
                                    <div className='flex flex-col gap-8 py-8'>
                                        <div className='flex items-center w-full justify-between'>
                                            <div
                                                className='flex gap-4 text-gray-600 opacity-50 pointer-event-none italic'>
                                                <input
                                                    className='checkbox'
                                                    type='checkbox'
                                                    checked={true}
                                                    readOnly
                                                />
                                                <p className='wrap'>
                                                    If the checkbox is already ticked, it means the
                                                    region is already in the list
                                                    of <b>included</b> SalesRegions for this organisation.
                                                </p>
                                            </div>

                                            {/* LEVEL FOLDING */}
                                            <Tooltip content={<div>
                                                <p>Change folding level</p>
                                                <hr/>
                                                <i>May take a few seconds to load</i>
                                            </div>}>
                                                <div className='flex gap-4'>
                                                    <button className='btn-light'
                                                            onClick={() => setLevelFolding('0')}>
                                                        Fold 0
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => setLevelFolding('1')}>
                                                        Fold 1
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => setLevelFolding('2')}>
                                                        Fold 2
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => setLevelFolding('3')}>
                                                        Fold 3
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => setLevelFolding('4')}>
                                                        Fold 4
                                                    </button>
                                                </div>
                                            </Tooltip>


                                            {/* SAVE BUTTONS */}
                                            <div className='flex gap-2'>

                                                {/* ADD */}
                                                <Tooltip
                                                    position='left'
                                                    content={<div>
                                                        <p>Only add the additional selected regions</p>
                                                        <hr/>
                                                        <i>Unselected regions will not be removed(excluded)</i>
                                                    </div>}
                                                >
                                                    <button className='btn'
                                                            onClick={saveRegions}
                                                    >
                                                        Add
                                                    </button>
                                                </Tooltip>

                                                {/* SAVE */}
                                                <Tooltip
                                                    position='left'
                                                    content={<div>
                                                        <p>Update all regions</p>
                                                        <hr/>
                                                        <div className='text-left' >
                                                            <p><i>
                                                                - Unchanged regions won't be modified
                                                            </i></p>
                                                            <p><i>
                                                                - Newly selected regions will be added (included)
                                                            </i></p>
                                                            <p><i>
                                                                - Un-selected regions will be removed (excluded)
                                                            </i></p>
                                                        </div>
                                                    </div>}
                                                >
                                                    <button className='btn'
                                                            onClick={()=>props.showConfirmModal(
                                                                'info',
                                                                'Update all regions',
                                                                <p>Categories will be <b>included</b> or <b>excluded</b> based on the selected regions</p>,
                                                                'Update all regions',
                                                                updateRegions
                                                            )}
                                                    >
                                                        Update
                                                    </button>
                                                </Tooltip>

                                            </div>

                                        </div>

                                        <hr/>

                                        <div className='mb-[10rem] flex flex-col '>

                                            {allReg0.map((reg0) => (
                                                <div className='flex flex-col' key={reg0.name}>
                                                    <div className='flex items-center gap-2'>
                                                        <input
                                                            className='checkbox'
                                                            type='checkbox'
                                                            checked={isRegionSelected('REGION_0', reg0.name)}
                                                            onChange={() => toggleSelectRegion('REGION_0', reg0.name)}
                                                        />
                                                        <p className='flex items-center gap-12'>
                                                            {reg0.name}
                                                            <div className='flex gap-2 items-center'>
                                                                {foldingArrow(reg0.name)}
                                                                <p className='text-gray-400 text-xs'>[{getSubRegionCount(reg0.name, 0)}]</p>
                                                            </div>
                                                        </p>
                                                    </div>

                                                    {/* Level 1 */}
                                                    <div className='flex flex-col pl-8 gap-4 pt-2'>
                                                        {
                                                            shouldUnfold1(reg0.name) &&
                                                            allReg1_forReg0(reg0.name).map(reg1 => (
                                                                <div key={reg1.name}>
                                                                    <div className='flex items-center gap-2'>
                                                                        <input
                                                                            className='checkbox'
                                                                            type='checkbox'
                                                                            checked={isRegionSelected('REGION_1', reg1.name)}
                                                                            onChange={() => toggleSelectRegion('REGION_1', reg1.name)}
                                                                        />
                                                                        <p className='flex items-center gap-12'>
                                                                            {reg1.name}
                                                                            <div className='flex gap-2 items-center'>
                                                                                {foldingArrow(reg1.name)}
                                                                                <p className='text-gray-400 text-xs'>[{getSubRegionCount(reg1.name, 1)}]</p>
                                                                            </div>
                                                                        </p>
                                                                    </div>

                                                                    {/* Level 2 */}
                                                                    <div className='flex flex-col pl-8 gap-4 pt-2'>
                                                                        {
                                                                            shouldUnfold2(reg1.name) &&
                                                                            allReg2_forReg1(reg1.name).map(reg2 => (
                                                                                <div key={reg2.name}>
                                                                                    <div
                                                                                        className='flex items-center gap-2'>
                                                                                        <input
                                                                                            className='checkbox'
                                                                                            type='checkbox'
                                                                                            checked={isRegionSelected('REGION_2', reg2.name)}
                                                                                            onChange={() => toggleSelectRegion('REGION_2', reg2.name)}
                                                                                        />
                                                                                        <p className='flex items-center gap-12'>
                                                                                            {reg2.name}
                                                                                            <div
                                                                                                className='flex gap-2 items-center'>
                                                                                                {foldingArrow(reg2.name)}
                                                                                                <p className='text-gray-400 text-xs'>[{getSubRegionCount(reg2.name, 2)}]</p>
                                                                                            </div>
                                                                                        </p>
                                                                                    </div>

                                                                                    {/* Level 3 */}
                                                                                    <div
                                                                                        className='flex flex-col pl-8 gap-4 pt-2'>
                                                                                        {
                                                                                            shouldUnfold3(reg2.name) &&
                                                                                            allReg3_forReg2(reg2.name).map(reg3 => (
                                                                                                <div key={reg3.name}>
                                                                                                    <div
                                                                                                        className='flex items-center gap-2'>
                                                                                                        <input
                                                                                                            className='checkbox'
                                                                                                            type='checkbox'
                                                                                                            checked={isRegionSelected('REGION_3', reg3.name)}
                                                                                                            onChange={() => toggleSelectRegion('REGION_3', reg3.name)}
                                                                                                        />
                                                                                                        <p className='flex items-center gap-12'>
                                                                                                            {reg3.name}
                                                                                                            <div
                                                                                                                className='flex gap-2 items-center'>
                                                                                                                {foldingArrow(reg3.name)}
                                                                                                                <p className='text-gray-400 text-xs'>[{getSubRegionCount(reg3.name, 3)}]</p>
                                                                                                            </div>
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    {/* Level 4 */}
                                                                                                    <div
                                                                                                        className='flex flex-wrap gap-2 pl-8 pt-2'>
                                                                                                        {
                                                                                                            shouldUnfold4(reg3.name) &&
                                                                                                            allReg4_forReg3(reg3.name).map(reg4 => (
                                                                                                                <div
                                                                                                                    className='flex gap-2 items-center text-xs w-[22%] overflow-x-hidden'
                                                                                                                    key={reg4.name}>
                                                                                                                    <input
                                                                                                                        className='checkbox'
                                                                                                                        type='checkbox'
                                                                                                                        checked={isRegionSelected('REGION_4', reg4.name)}
                                                                                                                        onChange={() => toggleSelectRegion('REGION_4', reg4.name)}
                                                                                                                    />
                                                                                                                    <p>{reg4.name}</p>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                    {/* Spacing depending on folding level */}
                                                    <div className={classNames(
                                                        levelFolding === '0' && 'py-2',
                                                        levelFolding === '1' && 'py-6',
                                                        levelFolding === '2' && 'py-10',
                                                        levelFolding === '3' && 'py-14',
                                                        levelFolding === '4' && 'py-20',
                                                    )}></div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                    {/* Close Button */}
                                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                        <button
                                            type="button"
                                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>

                                    {/* FOOTER */}
                                    <div className="mt-2 px-20 flex w-full justify-between">
                                        <Tooltip content='Close modal (changes will be lost)'>
                                            <button
                                                type="button"
                                                className="btn-light shadow-none"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                Close
                                            </button>
                                        </Tooltip>

                                        <Tooltip
                                            content={<div>
                                                <p>Add regions to organisation</p>
                                                <hr/>
                                                <i>Only new regions will be added</i>
                                            </div>}
                                        >
                                            <button className='btn'
                                                    onClick={saveRegions}
                                            >
                                                Save
                                            </button>
                                        </Tooltip>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


function Section_SalesRegions(props) {


    const salesRegionsTable_columns = [
        {Header: 'Level', accessor: 'level'},
        {Header: 'Region', accessor: 'region'},
        {Header: 'Status', accessor: 'type'},
        {
            Header: 'Actions', headerStyle: 'text-right', accessor: 'actions',
            searchable: false, id: 'edit',
            // width: '150%',
            Cell: ({cell}) => (
                <div className="text-sm flex justify-end">

                    {
                        cell.row.original.type === 'INCLUDE' ?

                            <button
                                className="btn-outline-light text-xs py-1.5 px-2 mx-1"
                                onClick={() => onExcludeRegion(cell)}
                            >
                                Exclude
                            </button>

                            :

                            <button
                                className="btn-success text-xs py-1.5 px-2 mx-1"
                                onClick={() => onIncludeRegion(cell)}
                            >
                                Include
                            </button>
                    }

                </div>
            )
        }
    ];
    const [salesRegionsTable_data, setSalesRegionsTable_data] = useState([]);

    const [allRegionData, setAllRegionData] = useState(null);

    useEffect(() => {

        if (allRegionData) {
            populateRegions();
        } else {
            queryRegions(
                null, props.activeOrgInModal.info.country,
                (data) => {

                    let regions = data['regions'];
                    const uniqueRegions = removeDuplicatesByProperties(regions);
                    setAllRegionData(uniqueRegions);

                },
                (error) => props.onError(error)
            );
        }

    }, [allRegionData]);

    function removeDuplicatesByProperties(arr) {
        const seen = new Map();
        return arr.filter((item) => {
            const key = item.region_0 + '|' + item.region_1 + '|' + item.region_2 + '|' + item.region_3;
            if (!seen.has(key)) {
                seen.set(key, true);
                return true;
            }
            return false;
        });
    }

    useEffect(() => {
        if (!props.showModal) setAllRegionData(null);
    }, [props.showModal]);

    const [regions_0, setRegions_0] = useState([]); // all regions dictionaries, with only region_0 data
    const [regions_1, setRegions_1] = useState([]); // all regions dictionaries, with region_0 and region_1 data
    const [regions_2, setRegions_2] = useState([]); // all regions dictionaries, with region_0, region_1 and region_2 data
    const [regions_3, setRegions_3] = useState([]); // all regions dictionaries, with region_0, region_1 and region_2 data
    const [selectedRegion_0, setSelectedRegion_0] = useState({}); // countries
    const [selectedRegion_1, setSelectedRegion_1] = useState({}); // provinces
    const [selectedRegion_2, setSelectedRegion_2] = useState({}); // areas
    const [selectedRegion_3, setSelectedRegion_3] = useState({}); // areas

    const populateRegions = () => {

        let r0 = [];
        let r1 = [];
        let r2 = [];
        let r3 = [];
        allRegionData.forEach(region => {
            if (region.region_0 && !region.region_1) r0.push(region);
            else if (region.region_1 && !region.region_2) r1.push(region);
            else if (region.region_2 && !region.region_3) r2.push(region);
            else if (region.region_3) {
                r3.push(region);
            }

        });
        setRegions_0(r0);
        setRegions_1(r1);
        setRegions_2(r2);
        setRegions_3(r3);


        // for each region 0, get all unique region 1's
        if (!props.orgOptions?.options?.sales_regions) return;
        let tabledata = [];
        props.orgOptions.options.sales_regions.forEach(region => {
            let row = {
                'region': region.region,
                'level': region.level,
                'type': region.type,
                'regionData': region // include the whole region for other functions to use
            };
            tabledata.push(row);
        });
        setSalesRegionsTable_data(tabledata);

    };


    const onExcludeRegion = (rowData) => {

        let region = rowData.row?.original?.regionData;

        let excludeRegionFromSupplier = `
            mutation {
                supplier_exclude_sales_region(
                    supplier: "${props.activeOrgInModal.id}"
                    region: "${region.region}" 
                    level: ${region.level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_regions{
                                level
                                region
                                type
                            }       
                        }
                    }
                
                }
            }
        `;

        customGraphRequest(
            excludeRegionFromSupplier,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Exclude successful', 'The region has been excluded successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );

    };
    const onIncludeRegion = (rowData) => {

        let region = rowData.row?.original?.regionData;

        let addRegionToOrganisation = `
            mutation {
                supplier_include_sales_region(
                    supplier: "${props.activeOrgInModal.id}"
                    region: "${region.region}" 
                    level: ${region.level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_regions{
                                level
                                region
                                type
                            }       
                        }
                    }
                
                }
            }
        `;

        customGraphRequest(
            addRegionToOrganisation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Include successful', 'The region has been included successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );
    };


    const [showAddRegionInputs, setShowAddRegionInputs] = useState(false);
    const onAddRegion = () => {

        // Toggle show the "add region inputs"
        if (!showAddRegionInputs) {
            setShowAddRegionInputs(true);
            let region0 = allRegionData.find(region => region.region_0 === props.activeOrgInModal.info.country_text && !region.region_1 && !region.region_2);
            setSelectedRegion_0(region0);
            return;
        }

        let regionName = '';
        let regionLevel = '';

        if (!isEmpty(selectedRegion_3)) {
            regionName = selectedRegion_3.region_3;
            regionLevel = 'REGION_3';
        } else if (!isEmpty(selectedRegion_2)) {
            regionName = selectedRegion_2.region_2;
            regionLevel = 'REGION_2';
        } else if (!isEmpty(selectedRegion_1)) {
            regionName = selectedRegion_1.region_1;
            regionLevel = 'REGION_1';
        } else if (!isEmpty(selectedRegion_0)) {
            regionName = selectedRegion_0.region_0;
            regionLevel = 'REGION_0';
        }

        let addRegionToOrganisation = `
            mutation {
                supplier_include_sales_region(
                    supplier: "${props.activeOrgInModal.id}"
                    region: "${regionName}" 
                    level: ${regionLevel}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_regions{
                                level
                                region
                                type
                            }       
                        }
                    }
                
                }
            }
        `;

        customGraphRequest(
            addRegionToOrganisation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Activation successful', 'The region has been activated successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );

    };

    const onResetSalesRegions = () => {

        props.showConfirmModal(
            'warning',
            'Reset Sales Regions',
            'Are you sure you want to reset the sales regions for this organisation?',
            'Reset',
            () => {

                let resetSalesRegions = `
                mutation {
                  supplier_set_sales_regions(
                    supplier: "${props.activeOrgInModal.id}"
                    regions:[] 
                  ){
                    error{type, message}
                    organisation{
                      id
                      options{
                      sales_regions{
                          level
                          region
                          type
                        }       
                      }
                    }
                  }
                }`;

                customGraphRequest(
                    resetSalesRegions,
                    (data) => {

                        // clear all local(cache) storage
                        localStorage.clear();

                        props.showNotificationModal('success', 'Sales regions reset', 'The sales regions have been reset successfully');
                        props.refreshOrganisationModal();
                    },
                    (error) => props.onError(error)
                );

            },
            null
        );
    };

    function getSubRegionCount(region, level){
        let count = allRegionData.filter(r => {
            if (level == 0) {
                if (r.region_0 == region.region_0 && r.region_1) return true;
            }
            if (level == 1) {
                if (r.region_1 == region.region_1 && r.region_2) return true;
            }
            if (level == 2) {
                if (r.region_2 == region.region_2 && r.region_3) return true;
            }
        });
        return count.length;
    }

    // List of organisations that can see this section.
    const allowedOrgTypes = ['SUPPLIER'];

    // Check if the active organisation type is in the allowed list.
    const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

    if (!canSeeSection) {
        return <></>;
    }

    return (
        <>
            <div className="w-full py-10">

                {/* title */}
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Sales Regions</h1>

                    <div className="flex gap-5">
                        <div
                            className={`w-fit flex flex-wrap justify-end items-center gap-6 ${showAddRegionInputs ? '' : 'hidden'} `}>

                            <div className="flex flex-wrap items-center">
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm"
                                        value={selectedRegion_0?.id || ''}
                                        onChange={(e) => {
                                            setSelectedRegion_0(allRegionData.find(r => r.id === e.target.value));
                                            setSelectedRegion_1({});
                                            setSelectedRegion_2({});
                                            setSelectedRegion_3({});
                                        }}>

                                    <option value="" className="italic">required</option>

                                    {regions_0.map(region => <option
                                        value={region.id}>{region.region_0} &nbsp;&nbsp; [{getSubRegionCount(region, 0)}]</option>)}

                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm"
                                        value={selectedRegion_1?.id || ''}
                                        onChange={(e) => {
                                            setSelectedRegion_1(allRegionData.find(r => r.id === e.target.value));
                                            setSelectedRegion_2({});
                                            setSelectedRegion_3({});
                                        }}>

                                    <option value="" className="italic">optional</option>

                                    {regions_1.map(region => region.region_0 === selectedRegion_0?.region_0 ? <option
                                        value={region.id}>{region.region_1} &nbsp;&nbsp; [{getSubRegionCount(region, 1)}]</option> : <></>)}

                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm"
                                        value={selectedRegion_2?.id || ''}
                                        onChange={(e) => {
                                            setSelectedRegion_2(allRegionData.find(r => r.id === e.target.value))
                                            setSelectedRegion_3({});
                                        }}>

                                    <option value="" className="italic">optional</option>

                                    {regions_2.map(region => region.region_1 === selectedRegion_1?.region_1 ? <option
                                        value={region.id}>{region.region_2} &nbsp;&nbsp; [{getSubRegionCount(region, 2)}]</option> : <></>)}

                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm"
                                        value={selectedRegion_3?.id || ''}
                                        onChange={(e) => setSelectedRegion_3(allRegionData.find(r => r.id === e.target.value))}>

                                    <option value="" className="italic">optional</option>

                                    {regions_3.map(region => region.region_2 === selectedRegion_2?.region_2 ?
                                        <option value={region.id}>{region.region_3}</option> : <></>)}

                                </select>
                            </div>

                        </div>


                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showAddRegionInputs ? 'hidden' : 'btn-outline-light'}`}
                            onClick={onResetSalesRegions}>
                            Reset
                        </button>
                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showAddRegionInputs ? 'hidden' : 'btn-outline'}`}
                            onClick={onAddRegion}>
                            Add region
                        </button>
                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showAddRegionInputs ? 'hidden' : 'btn-outline'}`}
                            onClick={() => props.setShowRegionCreationModal(true)}>
                            Add multiple regions
                        </button>
                        <button className={`h-min p-2 mx-0 flex-col ${showAddRegionInputs ? 'btn' : 'hidden'}`}
                                onClick={onAddRegion}>
                            + Add

                            {/* The Region that will be added onclick*/}
                            <p className={'italic font-bold'}>
                                {
                                    !isEmpty(selectedRegion_3) ?
                                        selectedRegion_3.region_3
                                        :
                                        !isEmpty(selectedRegion_2) ?
                                            selectedRegion_2.region_2
                                            :
                                            !isEmpty(selectedRegion_1) ?
                                                selectedRegion_1.region_1
                                                :
                                                !isEmpty(selectedRegion_0) ?
                                                    selectedRegion_0.region_0
                                                    :
                                                    '-'
                                }
                            </p>

                        </button>
                        <button className={`h-min p-2 mx-0 ${showAddRegionInputs ? 'btn-light' : 'hidden'}`}
                                onClick={() => setShowAddRegionInputs(false)}>
                            Hide
                        </button>
                    </div>

                </div>

                {/* table */}
                <div className="w-full">
                    <DataTable
                        data={salesRegionsTable_data}
                        columns={salesRegionsTable_columns}
                        pageSize={10}
                    />
                </div>
            </div>

        </>
    );
}


function Section_SalesCategories(props) {

    const salesCategoriesTable_columns = [
        {Header: 'Level', accessor: 'level'},
        {Header: 'Name', accessor: 'name'},
        {Header: 'Status', accessor: 'rule'},
        {
            Header: 'Actions', headerStyle: 'text-right', accessor: 'actions',
            searchable: false, id: 'edit',
            // width: '150%',
            Cell: ({cell}) => (
                <div className="text-sm flex justify-end">

                    {
                        cell.row.original.rule === 'INCLUDE' ?

                            <button
                                className="btn-outline-light text-xs py-1.5 px-2 mx-1"
                                onClick={() => onExcludeCategory(cell)}
                            >
                                Exclude
                            </button>

                            :

                            <button
                                className="btn-success text-xs py-1.5 px-2 mx-1"
                                onClick={() => onIncludeCategory(cell)}
                            >
                                Include
                            </button>
                    }

                    <button
                        className="btn-outline-light text-xs py-1.5 px-2 mx-1"
                        onClick={() => onRemoveCategory(cell)}
                    >
                        Remove
                    </button>

                </div>
            )
        }
    ];
    const [salesCategoriesTable_data, setSalesCategoriesTable_data] = useState([]);

    const [allCategoryData, setAllCategoryData] = useState(null);

    useEffect(() => {

        if (allCategoryData) {
            populateCategories();
        } else {
            queryCategoriesBasic(
                (data) => {
                    setAllCategoryData(data['categories']);
                },
                (error) => props.onError(error)
            );
        }

    }, [allCategoryData]);

    useEffect(() => {
        if (!props.showModal) setAllCategoryData(null);
    }, [props.showModal]);

    const [category_a, setCategory_a] = useState([]); // A list of category dictionaries, unique category_a
    const [category_b, setCategory_b] = useState([]); // A list of category dictionaries, unique category_b
    const [category_c, setCategory_c] = useState([]); // A list of category dictionaries, unique category_c
    const [category_d, setCategory_d] = useState([]); // A list of category dictionaries, unique category_d
    const [selectedCategory_a, setSelectedCategory_a] = useState({});
    const [selectedCategory_b, setSelectedCategory_b] = useState({});
    const [selectedCategory_c, setSelectedCategory_c] = useState({});
    const [selectedCategory_d, setSelectedCategory_d] = useState({});

    const populateCategories = () => {

        // Filter the categories into their respective levels
        let cA = [];
        let cB = [];
        let cC = [];
        let cD = [];
        allCategoryData.forEach(category => {
            if (category.category_a) {
                !cA.find(c => c.category_a === category.category_a) && cA.push(category); // add this category, if it has not already been added to the cA array
            }
            if (category.category_b) {
                !cB.find(c => c.category_b === category.category_b) && cB.push(category); // add this category, if it has not already been added to the cB array
            }
            if (category.category_c) {
                !cC.find(c => c.category_c === category.category_c) && cC.push(category); // add this category, if it has not already been added to the cC array
            }
            if (category.category_d) {
                !cD.find(c => c.category_d === category.category_d) && cD.push(category); // add this category, if it has not already been added to the cD array
            }

        });
        setCategory_a(cA);
        setCategory_b(cB);
        setCategory_c(cC);
        setCategory_d(cD);


        // Populate the SalesRegion table
        if (!props.orgOptions?.options?.sales_categories) return;
        let tabledata = [];
        props.orgOptions.options.sales_categories.forEach(category => {
            let row = {
                'name': category.category_name,
                'level': category.category_level,
                'rule': category.category_rule,
                'categoryData': category // include the whole region for other functions to use
            };
            tabledata.push(row);
        });
        setSalesCategoriesTable_data(tabledata);

    };


    const onRemoveCategory = (rowData) => {

        props.showConfirmModal(
            'warning',
            'Remove Sales Category',
            'Are you sure you want to remove this sales category for this organisation?',
            'Remove',
            () => {

                let category = rowData.row?.original?.categoryData;

                let excludeRegionFromSupplier = `
                    mutation {
                        supplier_remove_sales_category(
                            supplier: "${props.activeOrgInModal.id}"
                            category_name: "${category.category_name}" 
                            category_level: ${category.category_level}
                        ){
                            error {type, message}
                            organisation {
                                id
                                options{
                                    sales_categories{
                                        date_created
                                        date_updated
                                        category_name
                                        category_level
                                        category_rule
                                        brands_included
                                        brands_excluded
                                  }    
                                }
                            }
                        
                        }
                    }
                `;


                customGraphRequest(
                    excludeRegionFromSupplier,
                    (data) => {

                        // clear all local(cache) storage
                        localStorage.clear();

                        props.showNotificationModal('success', 'Removal successful', 'The category has been removed successfully');
                        props.refreshOrganisationModal();
                    },
                    (error) => props.onError(error)
                );

            },
            null
        );

    };

    const onExcludeCategory = (rowData) => {

        let category = rowData.row?.original?.categoryData;

        let excludeRegionFromSupplier = `
            mutation {
                supplier_exclude_sales_category(
                    supplier: "${props.activeOrgInModal.id}"
                    category_name: "${category.category_name}" 
                    category_level: ${category.category_level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_categories{
                                date_created
                                date_updated
                                category_name
                                category_level
                                category_rule
                                brands_included
                                brands_excluded
                          }    
                        }
                    }
                
                }
            }
        `;


        customGraphRequest(
            excludeRegionFromSupplier,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Exclude successful', 'The category has been excluded successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );

    };
    const onIncludeCategory = (rowData) => {

        let category = rowData.row?.original?.categoryData;

        let addRegionToOrganisation = `
            mutation {
                supplier_include_sales_category(
                    supplier: "${props.activeOrgInModal.id}"
                    category_name: "${category.category_name}" 
                    category_level: ${category.category_level}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_categories{
                                date_created
                                date_updated
                                category_name
                                category_level
                                category_rule
                                brands_included
                                brands_excluded
                          }    
                        }
                    }
                
                }
            }
        `;

        customGraphRequest(
            addRegionToOrganisation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Include successful', 'The category has been included successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );
    };


    const [showAddCategoryInputs, setShowAddCategoryInputs] = useState(false);
    const onAddCategory = () => {

        // Toggle show the "add category inputs"
        if (!showAddCategoryInputs) {
            setShowAddCategoryInputs(true);
            return;
        }

        let categoryName = '';
        let categoryLevel = '';

        if (!isEmpty(selectedCategory_d)) {
            categoryName = selectedCategory_d.category_d;
            categoryLevel = 'CATEGORY_D';
        } else if (!isEmpty(selectedCategory_c)) {
            categoryName = selectedCategory_c.category_c;
            categoryLevel = 'CATEGORY_C';
        } else if (!isEmpty(selectedCategory_b)) {
            categoryName = selectedCategory_b.category_b;
            categoryLevel = 'CATEGORY_B';
        } else if (!isEmpty(selectedCategory_a)) {
            categoryName = selectedCategory_a.category_a;
            categoryLevel = 'CATEGORY_A';
        }

        let addRegionToOrganisation = `
            mutation {
                supplier_include_sales_category(
                    supplier: "${props.activeOrgInModal.id}"
                    category_name: "${categoryName}" 
                    category_level: ${categoryLevel}
                ){
                    error {type, message}
                    organisation {
                        id
                        options{
                            sales_regions{
                                level
                                region
                                type
                            }       
                        }
                    }
                
                }
            }
        `;

        customGraphRequest(
            addRegionToOrganisation,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.showNotificationModal('success', 'Addition Successful', 'The category has been added successfully');
                props.refreshOrganisationModal();
            },
            (error) => props.onError(error)
        );

    };

    const onResetSalesCategories = () => {

        props.showConfirmModal(
            'warning',
            'Reset Sales Categories To Default List',
            'Are you sure you want to reset the sales categories for this organisation to the default list of categories?',
            'Reset',
            () => {

                let resetSalesRegions = `
                    mutation {
                        supplier_set_sales_categories(
                            supplier: "${props.activeOrgInModal.id}"
                            categories: [
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Audio Visual" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Computer Equipment" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Consumer Goods" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Cycling, scooters and balance boards" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Drones" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Electronic Equipment" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Eyewear" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Furniture" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Gaming" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Garden" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Generators & Power" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Home Appliances" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Jewellery, Watches & Pens" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Luggage" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Motor Vehicle Accessories" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Other" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Photography" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Smartphones, Tablets & Wearables" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Sport Equipment & Hobbies" },
                                { category_level: CATEGORY_A, category_rule: INCLUDE, category_name: "Tools & DIY" },
                            ]
                        ) {
                            error{type, message}
                            organisation{
                                id
                                options{
                                    sales_categories{
                                        date_created
                                        date_updated
                                        category_name
                                        category_level
                                        category_rule
                                        brands_included
                                        brands_excluded
                                    }
                                }
                            }
                        }
                    }
                `;

                customGraphRequest(
                    resetSalesRegions,
                    (data) => {

                        // clear all local(cache) storage
                        localStorage.clear();

                        props.showNotificationModal('success', 'Sales Categories Reset', 'The sales categories have been reset successfully');
                        props.refreshOrganisationModal();
                    },
                    (error) => props.onError(error)
                );

            },
            null
        );
    };

    function getSubCategoryCount(category, level){
        let count = allCategoryData.filter(c => {
            if (level == 0) {
                if (c.category_a == category.category_a && c.category_b) return true;
            }
            if (level == 1) {
                if (c.category_b == category.category_b && c.category_c) return true;
            }
            if (level == 2) {
                if (c.category_c == category.category_c && c.category_d) return true;
            }
        });
        return count.length;
    }

    // List of organisations that can see this section.
    const allowedOrgTypes = ['SUPPLIER'];

    // Check if the active organisation type is in the allowed list.
    const canSeeSection = allowedOrgTypes.includes(props.activeOrgInModal.type);

    if (!canSeeSection) {
        return <></>;
    }

    return (
        <>
            <div className="w-full py-10">

                {/* title */}
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Sales Categories</h1>

                    <div className="flex gap-5">
                        <div
                            className={`w-fit flex flex-wrap justify-end items-center gap-6 ${showAddCategoryInputs ? '' : 'hidden'} `}>

                            <div className="flex flex-wrap items-center">
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm"
                                        value={selectedCategory_a?.id || ''}
                                        onChange={(e) => {
                                            setSelectedCategory_a(allCategoryData.find(c => c.id === e.target.value) || {});
                                            setSelectedCategory_b({});
                                            setSelectedCategory_c({});
                                            setSelectedCategory_d({});
                                        }}>

                                    <option>-</option>

                                    {category_a.map(category => <option
                                        value={category.id}>{category.category_a} &nbsp;&nbsp; [{getSubCategoryCount(category, 0)}]</option>)}

                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm empty:hidden"
                                        value={selectedCategory_b?.id || ''}
                                        onChange={(e) => {
                                            setSelectedCategory_b(allCategoryData.find(c => c.id === e.target.value) || {});
                                            setSelectedCategory_c({});
                                            setSelectedCategory_d({});
                                        }}>

                                    {selectedCategory_a?.id ?
                                        <option>-</option>
                                        :
                                        <></>
                                    }

                                    {category_b.map(category => category.category_a === selectedCategory_a.category_a ?
                                        <option
                                            value={category.id}>{category.category_b} &nbsp;&nbsp; [{getSubCategoryCount(category, 1)}]</option>
                                        : <></>
                                    )}

                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select className="select w-fit text-sm empty:hidden"
                                        value={selectedCategory_c?.id || ''}
                                        onChange={(e) => {
                                            setSelectedCategory_c(allCategoryData.find(c => c.id === e.target.value) || {});
                                            setSelectedCategory_d({});
                                        }}>

                                    {selectedCategory_b?.id ?
                                        <option>-</option>
                                        :
                                        <></>
                                    }

                                    {category_c.map(category => category.category_b === selectedCategory_b.category_b ?
                                        <option value={category.id}>{category.category_c}</option>
                                        : <></>
                                    )}
                                </select>
                            </div>

                            <div className="flex flex-wrap items-center">
                                <select
                                    className={classNames(
                                        "select w-fit text-sm hover:shadow-sm focus:ring-stone-300 empty:hidden",
                                        isEmpty(selectedCategory_d) ? 'bg-gray-200 hover:bg-gray-200' : ''
                                    )}
                                    value={selectedCategory_d?.id || ''}
                                    onChange={(e) => setSelectedCategory_d(allCategoryData.find(r => r.id === e.target.value))}>


                                    {category_d.map(category => {

                                            let showThisCategoryD = false;
                                            if (!isEmpty(selectedCategory_c)) {
                                                if (selectedCategory_c.category_c === category.category_c) showThisCategoryD = true;
                                            } else if (!isEmpty(selectedCategory_b)) {
                                                if (selectedCategory_b.category_b === category.category_b) showThisCategoryD = true;
                                            } else if (!isEmpty(selectedCategory_a)) {
                                                if (selectedCategory_a.category_a === category.category_a) showThisCategoryD = true;
                                            }

                                            if (showThisCategoryD) return <option
                                                value={category.id}>{category.category_d}</option>
                                            else return <></>

                                        }
                                    )}

                                    <option value=''>-</option>

                                </select>
                            </div>

                        </div>


                        <button
                            className={`tailwind-tooltip-container  p-2 mx-0 whitespace-nowrap ${showAddCategoryInputs ? 'hidden' : 'btn-outline-light'}`}
                            onClick={onResetSalesCategories}>
                            Reset
                            <span className="tailwind-tooltip -left-[4rem] -top-[4rem]">Reset to the default list of categories</span>
                        </button>

                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showAddCategoryInputs ? 'hidden' : 'btn-outline'}`}
                            onClick={onAddCategory}>
                            Add category
                        </button>
                        <button
                            className={`h-min p-2 mx-0 whitespace-nowrap ${showAddCategoryInputs ? 'hidden' : 'btn-outline'}`}
                            onClick={() => props.setShowCategoryCreationModal(true)}>
                            Add multiple categories
                        </button>

                        <div className={classNames(
                            'flex flex-col gap-2',
                            showAddCategoryInputs ? '' : 'hidden'
                        )}>
                            <button className='h-min p-2 mx-0 btn flex-col' onClick={onAddCategory}>
                                <p>+ Add</p>

                                {/* The next category that will be added onclick*/}
                                <p className={'italic font-bold'}>{!isEmpty(selectedCategory_d) ? selectedCategory_d.category_d : !isEmpty(selectedCategory_c) ? selectedCategory_c.category_c : !isEmpty(selectedCategory_b) ? selectedCategory_b.category_b : !isEmpty(selectedCategory_a) ? selectedCategory_a.category_a : ''}</p>
                            </button>
                            <button className='h-min p-2 mx-0 btn-light w-full'
                                    onClick={() => setShowAddCategoryInputs(false)}>
                                Hide
                            </button>
                        </div>
                    </div>

                </div>

                {/* table */}
                <div className="w-full">
                    <DataTable
                        data={salesCategoriesTable_data}
                        columns={salesCategoriesTable_columns}
                        pageSize={10}
                    />
                </div>
            </div>

        </>
    );
}


function EditUserModal(props){

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [allClaimsForUser, setAllClaimsForUser] = useState(null);

    useEffect(() => {
        // on modal open

        if (!props.activeUserInModal)
            return;

        if (!firstname) setFirstname(props.activeUserInModal.info.first_name);
        if (!lastname) setLastname(props.activeUserInModal.info.last_name);

        // get the full user info
        if (!props.activeUserInModal.status) {
            queryUserFull(
                props.activeUserInModal.id,
                (data) => {
                    props.setActiveUserInModal(data['users'][0]);
                },
                (error) => props.onError(error)
            );
        }

        // this is for the "Claims (30 days)" field
        if (!allClaimsForUser && props.activeUserInModal.organisation) {
            calculateClaimsForUser();
        }

    }, [props.activeUserInModal, props.showModal]);

    function calculateClaimsForUser(){
        queryClaimsMinimal(
            props.activeUserInModal.organisation.id,
            (data) => {
                let claims = data['claims'];

                // 30 days ago
                let monthBack = moment().subtract(30, 'days');

                // loop over each claim for this organisation
                let claimsInMonth = claims.map(claim => {

                    // was this claim created by this user
                    if (claim.created_by.id === props.activeUserInModal.id) {

                        // was this claim created within 30 days
                        let claimCreatedDate = moment(claim.date_created);
                        if (claimCreatedDate > monthBack) {
                            return claim;
                        }
                    }
                });
                setAllClaimsForUser(claimsInMonth);

            },
            (error) => props.onError(error)
        );
    }

    useEffect(() => {
        /** Clear all states when the modal is hidden - same as EditOrgProfileModal - (explaination there)*/
        setAllClaimsForUser(null);
        setFirstname(null);
        setLastname(null);
    }, [props.showModal]);

    const onUpdateUser = () => {

        if (!firstname) {
            alert('Please enter a valid first name');
            return;
        } else if (!lastname) {
            alert('Please enter a valid last name');
            return;
        }

        updateUser(
            props.activeUserInModal.id,
            firstname,
            lastname,
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                alert('Successfully updated user');
                props.setShowModal(false);
                window.location.reload();
            },
            (error) => {
                props.onError(error);
                props.setShowModal(false);
            }
        );

    };

    const onDeactivateUser = () => {
        let superAdmin = props.user.roles.filter(role => role.name === 'superadmin');
        if (superAdmin) {
            deactivateUser(
                props.activeUserInModal.id,
                null,
                (data) => {

                    // clear all local(cache) storage
                    localStorage.clear();

                    alert('Successfully deactivated the user');
                    props.setShowModal(false);
                    window.location.reload();
                },
                (error) => props.onError(error)
            );
        }
    };

    if (!props.activeUserInModal)
        return <></>;

    return (

        // == MODAL ==
        <div
            className={('transition-all fixed top-0 left-0 z-50 h-modal w-full h-full bg-zinc-600/75 ') + (props.showModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none')}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white min-w-3/4 self-center rounded-lg shadow-2xl  overflow-y-scroll w-1/2 max-h-5/6"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}
                >

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold text-gray-600">
                            Edit a User
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span onClick={() => props.setShowModal(false)}></span>
                        </button>
                    </div>

                    {/* BODY */}
                    <div className="flex flex-col justify-around space-y-6 p-6 text-gray-700">

                        {/* EXPLANATION */}
                        <p className="text-gray-500 mb-4 text-center">
                            Below allows for the modifications of this user
                        </p>

                        {/* NAME */}
                        <div className="flex justify-between gap-10">

                            <div className="w-1/2">
                                <label>First name</label>
                                <input className="input"
                                       value={firstname} onChange={e => setFirstname(e.target.value)}/>

                            </div>
                            <div className="w-1/2">
                                <label>Last name</label>
                                <input className="input"
                                       value={lastname} onChange={e => setLastname(e.target.value)}/>
                            </div>
                        </div>

                        {/* EMAIL + ROLE */}
                        <div className="flex justify-between gap-10">
                            <div className="w-1/2">
                                <label>Email</label>
                                <p>{(props.activeUserInModal || {}).username}</p>
                            </div>
                            <div className="w-1/2">
                                <label>Role</label>
                                <p>{props.activeUserInModal && props.activeUserInModal.roles && props.activeUserInModal.roles.map(role => role.name + ' ')}</p>
                            </div>
                        </div>

                        {/* STATUS + CLAIMS */}
                        <div className="flex justify-between gap-10">
                            <div className="w-1/2">
                                <label>Status</label>
                                <p>{(props.activeUserInModal || {}).status}</p>
                            </div>
                            <div className="w-1/2">
                                <label>Claims (30 days)</label>
                                <p>{allClaimsForUser && allClaimsForUser.length}</p>
                            </div>

                        </div>

                        {/* FOOTER */}
                        <div className="flex justify-between">
                            <button className="btn btn-danger ml-0"
                                    onClick={() => {
                                        props.setDeleteModalOptions({
                                            title: 'Deactivate User',
                                            message: 'You are about to deactivate the user "' + props.activeUserInModal.info.full_name + '" ',
                                            challenge: 'DEACTIVATE',
                                            successCallback: () => {
                                                onDeactivateUser();
                                            }
                                        });
                                        props.setShowModal(false);
                                        props.setShowConfirmDeleteModal(true);

                                    }}
                            >Deactivate User
                            </button>

                            <div className="flex">
                                <button className="btn btn"
                                        onClick={onUpdateUser}
                                >
                                    Update User
                                </button>
                                <button className="btn btn-light mr-0"
                                        onClick={() => props.setShowModal(false)}>Cancel
                                </button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>


    );

}


function AddUserModal(props){

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [roleId, setRoleId] = useState('admin');

    const [orgRoles, setOrgRoles] = useState(null); // the roles that belong to the organisation that this user is being added to

    useEffect(() => {
        if (!props.showModal) return; // dont query if modal is hidden

        if (!orgRoles) {
            queryRolesForOrganisation(
                props.crossModalCommunication_ActiveOrgInModal.id,
                (data) => {
                    let roles = data['roles'];
                    setOrgRoles(roles);
                    roles[0] && setRoleId(roles[0].id); // set the default org role

                },
                (error) => props.onError(error)
            );
        }
    }, [props.showModal]);

    useEffect(() => {
        //  when the add user modal is opened - requery the organisation roles
        setOrgRoles(null);
    }, [props.showModal]);


    const onCreateUser = () => {

        if (!email) return props.showAlertModal('info', 'Invalid Input', 'Please enter a valid email');

        else if (/[A-Z]/.test(email)) return props.showAlertModal('info', 'Invalid Input', 'Email address must be in lowercase');

        else if (!firstName) return props.showAlertModal('info', 'Invalid Input', 'Please enter a valid first name');

        else if (!lastName) return props.showAlertModal('info', 'Invalid Input', 'Please enter a valid last name');

        else if (!roleId) return props.showAlertModal('info', 'Invalid Input', 'Please select a role');

        createUser({
                organisationId:props.activeOrgInProfileModal.id,
                email:email.trim(),
                first_name:firstName.trim(),
                lastName:lastName.trim(),
                roleId:roleId,
                permissions:[],
            },
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.setShowModal(false);
                props.showNotificationModal('success', 'User created', 'The user has been created successfully');
                window.location.reload();
            },
            (error) => {
                props.onError(error);
                props.setShowModal(false);
            }
        );
    };

    return (

        // == MODAL ==
        <div
            className={('transition-all fixed top-0 left-0 z-50 h-modal w-full h-full bg-zinc-600/75 ') + (props.showModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none')}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white min-w-3/4 self-center rounded-lg shadow-2xl  overflow-y-scroll w-1/2 max-h-5/6"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}
                >

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold text-gray-600">
                            Add a User
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    {/* BODY */}
                    <div className="flex flex-col justify-around space-y-6 p-6 text-gray-700">

                        {/* EXPLANATION */}
                        <p className="text-gray-500 mb-4 text-center">
                            A user may be added to this organisation on this page
                        </p>

                        {/* NAME + ROLE */}
                        <div className="flex gap-10">
                            <div className="w-full">
                                <label>First name</label>
                                <input className="input" type="email" value={firstName}
                                       onChange={e => setFirstName(e.target.value)}/>
                            </div>
                            <div className="w-full">
                                <label>Last name</label>
                                <input className="input" type="email" value={lastName}
                                       onChange={e => setLastName(e.target.value)}/>
                            </div>
                            <div className="w-full">
                                <label>Role</label>
                                {
                                    !orgRoles ?
                                        // Show loading spinner until the org roles are retrieved
                                        <LoadingSpinner size='6' body=' '/>
                                        :
                                        <select className='input m-0' onChange={(e) => setRoleId(e.target.value)} value={roleId}>
                                            {orgRoles && orgRoles.map(role => <option
                                                value={role.id}>{role.name}</option>)}
                                        </select>
                                }

                            </div>
                        </div>

                        {/* ORGANISATION + EMAIL */}
                        <div className="flex gap-10">

                            <div className="w-1/3">
                                <label>Organisation</label>
                                <input className="input" disabled
                                       value={props.activeOrgInProfileModal && props.activeOrgInProfileModal.info.name}/>
                            </div>


                            <div className="w-2/3">
                                <label>Email</label>
                                <input
                                    className={classNames(
                                        "input",
                                        email && email.length > 0 && /[A-Z]/.test(email) && "text-red-500" // Warning styling if the email contains uppercase letters
                                    )}
                                    type="email" value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <p className={classNames(
                                    "text-red-500 pt-1 pl-1 text-sm",
                                    email && email.length > 0 && /[A-Z]/.test(email) && "block" || 'hidden' // Warning styling if the email contains uppercase letters
                                )}>
                                    Ensure the email address is in lowercase before submitting
                                </p>
                            </div>

                            <div className="">
                            </div>


                        </div>

                        {/* FOOTER */}
                        <div className="flex justify-around">
                            <button className="btn btn" onClick={onCreateUser}>+ Create User</button>
                            <button className="btn btn-light mr-0"
                                    onClick={() => props.setShowModal(false)}>Cancel
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>


    );

}


function AddOrganisationModal(props){


    const [name, setName] = useState(null);
    const [referenceName, setReferenceName] = useState(null);
    const [website, setWebsite] = useState(null);
    const [orgType, setOrgType] = useState('ADMINISTRATOR');
    const [country, setCountry] = useState('NL');
    const [currencyCode, setCurrencyCode] = useState('EUR');

    const onCreateOrganisation = () => {

        if (!name) {
            alert('Please enter a name');
            return;
        } else if (!referenceName) {
            alert('Please enter a reference name');
            return;
        } else if (!website) {
            alert('Please enter a website');
            return;
        } else if (!orgType) {
            alert('Please select an organisation type');
            return;
        } else if (!country) {
            alert('Please select a country');
            return;
        } else if (!currencyCode) {
            alert('Please select a currency');
            return;
        }


        createOrganisation({
                name: name,
                orgType: orgType,
                country: country,
                currencyCode: currencyCode,
                website: website,
                uniqueReferenceName: referenceName
            },
            (data) => {

                // clear all local(cache) storage
                localStorage.clear();

                props.setShowModal(false);
                alert('Organisation successfully created');
                window.location.reload();
            },
            (error) => props.onError(error)
        );

    };


    return (

        // == MODAL ==
        <div
            className={('transition-all fixed top-0 left-0 z-50 h-modal w-full h-full bg-zinc-600/75 ') + (props.showModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none')}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white self-center rounded-lg shadow-2xl  overflow-y-scroll w-1/2 max-h-5/6"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}
                >

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold text-gray-600">
                            Create an Organisation
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span onClick={() => props.setShowModal(false)}></span>
                        </button>
                    </div>

                    {/* BODY */}
                    <div className="flex flex-col justify-around space-y-6 p-6 text-gray-700">

                        {/* EXPLANATION */}
                        <p className="text-gray-500 mb-4 text-center">
                            Below allows for the creation of a new organisation
                        </p>

                        {/* NAME */}
                        <div className="">
                            <label>Name</label>
                            <input className="input" type="text" value={name}
                                   onChange={e => setName(e.target.value)}/>
                        </div>

                        {/* REFERENCE NAME */}
                        <div className="">
                            <label>Reference name</label>
                            <input className="input" type="text" value={referenceName}
                                   onChange={e => setReferenceName(e.target.value)}/>
                        </div>


                        {/* WEBSITE */}
                        <div className="">
                            <label>Website</label>
                            <input className="input" type="text" value={website}
                                   onChange={e => setWebsite(e.target.value)}/>
                        </div>


                        {/* TYPE + COUNTRY + CURRENCY */}
                        <div className="flex justify-around gap-14">
                            <div className="w-full">
                                <label>Organisation type</label>
                                <br/>
                                <select onChange={(e) => setOrgType(e.target.value)} value={orgType}>
                                    {props.OrgType && props.OrgType.map(org => <option
                                        value={org.value}>{org.label}</option>)}
                                </select>
                            </div>

                            <div className="w-full">
                                <label>Country</label>
                                <br/>
                                <select onChange={(e) => setCountry(e.target.value)} value={country}>
                                    {props.CountryType && props.CountryType.map(country => <option
                                        value={country.value}>{country.label}</option>)}
                                </select>
                            </div>

                            <div className="w-full">
                                <label>Currency</label>
                                <br/>
                                <select onChange={(e) => setCurrencyCode(e.target.value)} value={currencyCode}>
                                    <option value="EUR">€ EUR</option>
                                    <option value="ZAR">R ZAR</option>
                                    <option value="AUD">$ AUD</option>
                                    <option value="USD">$ USD</option>
                                    <option value="EUR">€ EUR</option>
                                    <option value="GBP">£ GBP</option>

                                </select>
                            </div>
                        </div>


                        {/* FOOTER */}
                        <div className="flex justify-around">
                            <button className="btn"
                                    onClick={onCreateOrganisation}>
                                + Create Organisation
                            </button>
                            <button className="btn btn-light mr-0"
                                    onClick={() => props.setShowModal(false)}>Cancel
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>


    );

}


function DeleteConfirmationModal(props){

    const [challenge, setChallenge] = useState('');

    return (

        // == MODAL ==
        <div
            className={('transition-all fixed top-0 left-0 z-50 h-modal w-full h-full bg-zinc-600/75 ') + (props.showModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none')}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white min-w-3/4 self-center rounded-lg shadow-2xl  overflow-y-scroll w-1/2 max-h-5/6"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}
                >

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold text-gray-600">
                            {props.options.title}
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span onClick={() => props.setShowModal(false)}></span>
                        </button>
                    </div>

                    {/* BODY */}
                    <div className="flex flex-col justify-around space-y-6 p-6 text-gray-700">


                        {/* EXPLANATION */}
                        <p className="text-red-800 text-lg font-bold mb-4 text-center">
                            {props.options.message}
                        </p>

                        <input
                            className="input"
                            value={challenge}
                            onChange={e => setChallenge(e.target.value)}
                            placeholder={('To continue, type "') + props.options.challenge + ('" ')}
                        />


                        {/* FOOTER */}
                        <div className="flex justify-around">
                            <button className="btn btn-danger"
                                    onClick={() => {
                                        if (challenge === props.options.challenge) {
                                            props.setShowModal(false);
                                            props.options.successCallback();
                                        }
                                    }}>
                                Confirm
                            </button>
                            <button className="btn btn-light mr-0"
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}>
                                Cancel
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>


    );

}

