import React, {Fragment, useEffect, useState} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {ArrowDownIcon, CheckIcon} from "@heroicons/react/solid";
import {BasicImage} from "./BasicImage";
import {classNames, isEmpty} from "../../utils/helpers";

const people = [
    {id: 1, name: 'Wade Cooper'},
    {id: 2, name: 'Arlene Mccoy'},
    {id: 3, name: 'Devon Webb'},
    {id: 4, name: 'Tom Cook'},
    {id: 5, name: 'Tanya Fox'},
    {id: 6, name: 'Hellen Schmidt'},
]

export default function FilterSelect(props) {

    const [selected, setSelected] = useState(null)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (props.defaultValue) {
            setSelected(props.defaultValue)
            setQuery(props.defaultQuery || '')
        }
    }, []);

    /* Options = {
        value: content
        ''
    }
    * */

    function renderOptions () {

        const options = props.filterOptions(props.selectableOptions, query)

        if (options.length === 0 && query !== '') {
            return (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    {props.noResults || 'No results found, try adjusting your search.'}
                </div>
            )
        }

        return options.map((option) => props.renderOption(option))
    }

    return (
        <Combobox
            value={selected}
            onChange={(value) => {
                setSelected(value)
                props.onChange(value)
            }}
            className="relative"
            onBlur={(e) => {
                const value = e.target.value
                if(!isEmpty(value)) {
                    setSelected(value)
                    props.onBlur?.(value)
                }
            }}
        >
            <div className="relative mt-1">

                {props.renderMainInput(selected, query, setQuery)}

                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options
                        className="absolute z-[10] w-full mt-1 max-h-[30rem] overflow-auto rounded-md bg-white text-base shadow-lg">

                        {renderOptions()}

                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}

/** EXAMPLE */
// <FilterSelect
//     selectableOptions={[]}
//     onChange={setState()}
//
//     {/* This is called to filter down the options */}
//     filterOptions={(selectableOptions, query) =>
//         query === '' ?
//             selectableOptions
//         :
//             selectableOptions.filter((option) =>
//             option.info.name
//             .toLowerCase()
//             .replace(/\s+/g, '') // remove all whitespace
//             .includes(query.toLowerCase().replace(/\s+/g, ''))
//     )}
//
//     {/* The main input */}
//     renderMainInput={(selected, query, setQuery)=> (
//         <div className="w-full relative">
//             <Combobox.Button as="div" className="flex w-full items-center">
//                 <div className="absolute left-2 flex items-center pr-2">
//                     <BasicImage
//                         src={selected?.info.logo_image_url}
//                         fallbackSrc="/org-logo-placeholder.jpg"
//                         alt="logo"
//                         sizeWidthRem="1.8"
//                         className="rounded-full"
//                     />
//                 </div>
//
//                 <Combobox.Input
//                     className="input pl-12 pr-10 w-full"
//                     displayValue={(selected) => selected ? selected.info.name : '-'}
//                     onChange={(event) => setQuery(event.target.value)}
//                 />
//
//                 <div className="absolute right-0 flex items-center pr-2">
//                     <ArrowDownIcon
//                         className="h-5 w-5 text-gray-400"
//                         aria-hidden="true"
//                     />
//                 </div>
//             </Combobox.Button>
//         </div>
//
//     )}
//
//     {/* The dropdown options */}
//     renderOption={(option) =>
//         <Combobox.Option
//             key={option.id}
//             className='relative'
//             value={option}
//         >
//             {({selected, active}) => (
//                 <div className={classNames(
//                     'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
//                     selected ? 'font-bold' : 'font-normal', // Is the option selected
//                     active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
//                 )}>
//
//                     {/* SELECTED CHECK */}
//                     {selected &&
//                         <div className={classNames(
//                             'absolute inset-y-0 left-0 flex items-center pl-1',
//                             active ? 'text-white' : 'text-sky-600'
//                         )}>
//                             <CheckIcon className={`h-6 w-6`} />
//                         </div>
//                     }
//
//                     {/* ORG LOGO */}
//                     <BasicImage
//                         src={option?.info.logo_image_url}
//                         fallbackSrc={'/org-logo-placeholder.jpg'}
//                         alt="logo"
//                         sizeWidthRem="1.8"
//                         className="rounded-full"
//                     />
//
//                     {/* ORG NAME */}
//                     {option.info.name}
//
//                 </div>
//             )}
//         </Combobox.Option>
//         }
// />