// Library imports
import React, {Fragment, useEffect, useState} from 'react';
import {
    ChevronDownIcon,
    CogIcon,
    DocumentAddIcon,
    DocumentReportIcon,
    ExternalLinkIcon,
    HomeIcon,
    LibraryIcon, MenuIcon
} from '@heroicons/react/solid';
import {DeveloperModal} from '../../containers/modals/DeveloperModal';


// Local imports
import {classNames} from '../../utils/helpers.js';
import {Link} from 'react-router-dom';
import CountrySelection from '../CountrySelection';
import Safe, {safe} from "../Safe";
import {DownloadIcon} from "@heroicons/react/outline";
import Tooltip from "../Tooltip";
import {ActiveURLRequests} from "../ActiveURLRequests";
import LimitedEnvironmentAccess from "../LimitedEnvironmentAccess";
import {Menu, Transition} from "@headlessui/react";
import ProfileModal from "../../containers/modals/ProfileModal";


// NavbarSide component
export default function NavbarMain(props) {

    const [navigationOptions, setNavigationOptions] = useState([
        {
            name: 'Dashboard',
            href: '/',
            icon: HomeIcon,
            classNames: ''
        },
        {
            name: 'Reports',
            href: '/admin/reports',
            icon: DocumentReportIcon,
            classNames: ''
        }
    ]);

    useEffect(() => {
        // Only show HAI for SLVRCLD
        if (!props.mainOrganisation) return; // wait until main organisation has been set
        if (props.mainOrganisation?.type === 'ADMINISTRATOR' || props.mainOrganisation?.type === 'ROOT') {
            if (navigationOptions.find(nav => nav.name === 'HAI')) return; // Do not add the navigation twice

            let copy = [...navigationOptions];
            copy.push({
                name: 'HAI',
                href: '/admin/hai',
                icon: DocumentAddIcon,
                classNames: ''
            });

            copy.push({
                name: 'Organisation Admin',
                href: '/admin/organisations',
                icon: CogIcon,
                classNames: ''
            });
            setNavigationOptions(copy);
        }
    }, [props.mainOrganisation]);


    const [infoModalExists, setInfoModalExists] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {

            // If PageInfoModal exists, set show i button active
            if (document.getElementById('pageInfoModal')) {
                setInfoModalExists(prevState => true);
            }

            // If PageInfoModal does not exist, hide i
            else {
                setInfoModalExists(prevState => false);
            }

        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const [profileOpen, setProfileOpen] = useState(false);

    // if no user is authenticated, do not show the main menu
    if (!props.user) {
        return <></>;
    }

    function renderLinks() {
        return (
            <>
                {navigationOptions.map((item) => (

                    <Tooltip
                        content={item.name}
                        key={item.name}
                        position='bottom'
                        style='max-w-max'
                    >
                        <Link
                            key={item.name}
                            to={item.href ? item.href : '#'}
                            className={`
                                flex flex-col gap-2 items-center justify-center transition-all rounded-md 
                                text-lg text-center font-light text-sky-100 hover:text-white hover:bg-cyan-600 
                                w-fit h-[fit] p-2 m-0
                                shadow-sm hover:shadow-lg hover:bg-sky-500
                                ${item.active ? 'bg-slate-600 !text-white shadow-lg' : ''}
                                ${item.classNames}
                            `}
                            onClick={() => {

                                // de-select all the routes
                                let options = navigationOptions.map(nav => {
                                    nav.active = false;
                                    return nav;
                                });

                                // select the route that was clicked
                                options.find(nav => nav.href === item.href).active = true;
                                setNavigationOptions(options);

                                // if this route has a click handler, call it
                                item.onClick && item.onClick();
                            }}
                        >
                            <item.icon
                                className={classNames(
                                    item.classNames, 'flex-shrink-0 h-7 w-7 '
                                )}
                                aria-hidden="true"
                            />
                        </Link>
                    </Tooltip>

                ))}
            </>
        );
    }

    return (
        <div className="w-full h-fit min-h-[4.5rem] flex justify-between items-center navbar-background scrollbar-hide">

            {/* SLVRCLD LOGO */}
            <Link
                to="/"
                className="px-4 w-[15rem] h-a">

                {props.portalRepresentative === 'THESL' ?
                    <img src="/thesl-logo.png" alt="THESL logo" className='drop-shadow-md bg-cyan-900 rounded-lg px-4 mb-2 object-cover ' />
                    :
                    <img src="/slvrcld_logo.png" alt="SLVRCLD logo" />
                }

            </Link>

            {/*COUNTRY NAV*/}
            <div className="w-[25rem]">
                <Safe><CountrySelection {...props} /></Safe>
            </div>

            {/* GROW */}
            <div className="grow"></div>

            {/* LINKS */}
            <nav className="flex gap-4">
                {safe(renderLinks)}
            </nav>

            {/* USER PROFILE MODAL */}
            <Safe>
                <ProfileModal
                    {...props}
                    open={profileOpen}
                    setOpen={setProfileOpen}
                    user={props.user}
                />
            </Safe>

            {/* USER DROPDOWN */}
            <Safe>
                <UserMenu {...props} setProfileOpen={setProfileOpen}/>
            </Safe>


            {/*<Safe>*/}
            {/*    <LimitedEnvironmentAccess*/}
            {/*        local={true}*/}
            {/*        {...props}*/}
            {/*    >*/}
            {/*        <QueryCount {...props} />*/}
            {/*    </LimitedEnvironmentAccess>*/}
            {/*</Safe>*/}

            {/* Developer Modal */}
            {/*<OpenDeveloperModal {...props} />*/}

            {/*<Safe>*/}
            {/*    <PageHelpInfoModal/>*/}
            {/*</Safe>*/}

            {/*<Safe>*/}
            {/*    <ActiveURLRequests/>*/}
            {/*</Safe>*/}

        </div>
    );
}

function QueryCount(props) {

    const [allRequests, setAllRequests] = useState([]);

    useEffect(() => {
        window.onGraphRequest = (requestPayloadString) => {
            // get time epoch time e.g 1633450000000
            const epochStamp = Date.now();
            const uniquePayload = requestPayloadString + epochStamp;
            setAllRequests((previous) => [...previous, uniquePayload]);
        }
    }, []);

    function getRequestName(requestPayload){
        // let queryName = requestPayload.substring(23, 50);

        let queryName = requestPayload.replace('query {', '').replace('mutation {', '');

        queryName = queryName.substring(0, 50);

        if(queryName.includes('(')) queryName = queryName.substring(0, queryName.indexOf('('));
        if(queryName.includes('{')) queryName = queryName.substring(0, queryName.indexOf('{'));

        queryName = queryName.trim()

        const uniqueRandom = Math.random(); // Get a random number between 0 and 1

        return <p key={requestPayload + uniqueRandom} className='w-full text-xs break-all pb-2 font-light'>{queryName}</p>
    }

    if(!props.mainOrganisation) return (<></>);

    return (
        <div className='flex flex-col items-center text-white max-h-[40rem] overflow-scroll scrollbar-hide p-2 text-sm'>
            <button className='btn-outline bg-transparent p-1 mb-4 text-white opacity-20' onClick={()=> setAllRequests([])}>Clear {allRequests.length}</button>
            {allRequests.map(getRequestName)}
        </div>
    );
}

function OpenDeveloperModal(props) {

    const [developerModalOpen, setDeveloperModalOpen] = useState(false);

    // Only ROOT, or localhost can open the developer modal
    let canViewDeveloperModal = false;
    // if(props.mainOrganisation && props.mainOrganisation?.type === 'ROOT') canViewDeveloperModal = true;
    // if(window.location.host.includes('localhost')) canViewDeveloperModal = true;
    if (window.canViewDeveloperModal >= 5) canViewDeveloperModal = true;
    if (!canViewDeveloperModal) return (<></>);

    return (
        <>

            {/* OPEN BUTTON */}
            <div className="absolute bottom-0 right-3 flex items-center gap-2 p-2 z-10">
                <button className="btn-light text-sm" onClick={() => setDeveloperModalOpen(true)}>Developer modal
                </button>
            </div>

            {/* DEVELOPER MODAL */}
            <DeveloperModal isOpen={developerModalOpen} onClose={setDeveloperModalOpen} {...props} />
        </>

    );
}

function PageHelpInfoModal(props) {
    return (
        <>
            <div
                className={`flex justify-center m-5 mt-0 tailwind-tooltip-container transition-all duration-500 ${props.infoModalExists ? ' opacity-100' : ' opacity-0'}`}
                onClick={() => props.setPageInfoModalOpen(true)}
            >
                <span className="tailwind-tooltip">
                  Click for info
                </span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     className="transition-all h-12 w-12 hover:w-14 hover:h-14 stroke-gray-200 drop-shadow-md ring-0 hover:ring-offset-4 hover:ring-4 "
                     fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </div>
        </>
    );
}

function UserMenu(props) {

    /**
     * Clear local storage, session storage, and cookies.
     */
    function clearCookiesAndStorage() {
        try {
            // Clear local storage and session storage
            localStorage.clear();
            sessionStorage.clear();

            // Clear cookies
            document.cookie.split(";").forEach(cookie => {
                document.cookie = `${cookie.trim().split("=")[0]}=; expires=${new Date(0).toUTCString()}; path=/;`;
            });

            // Show notification
            props.showNotificationModal(
                'success',
                'Cache Cleared',
                'The cache and cookies have been successfully cleared. Please reload the page if necessary.'
            );
        } catch (error) {
            console.error("Error clearing cache:", error);
            props.showNotificationModal(
                'error',
                'Error',
                'An error occurred while clearing the cache. Please try again.'
            );
        }
    }

    /**
     * Calculates the total size of all entries in localStorage and sessionStorage.
     *
     * @returns {string} The total storage size in megabytes (MB) with two decimal places.
     */
    function getTotalStorageSize() {
        try {
            let totalSizeBytes = 0;

            /**
             * Helper function to calculate storage size.
             * @param {Storage} storage - The storage object (localStorage or sessionStorage).
             */
            function calculateStorageSize(storage) {
                for (const key of Object.keys(storage)) {
                    const value = storage.getItem(key);
                    if (value !== null) {
                        totalSizeBytes += value.length * 2; // Each character takes 2 bytes (UTF-16)
                    }
                }
            }

            // Calculate size for both localStorage and sessionStorage
            calculateStorageSize(localStorage);
            calculateStorageSize(sessionStorage);

            const totalSizeMB = totalSizeBytes / (1024 * 1024); // Convert bytes to MB
            return `${totalSizeMB.toFixed(2)} MB`;
        } catch (error) {
            console.error("Error calculating storage size:", error);
            return "Error retrieving storage size";
        }
    }

    return (
        <div className="flex items-center ml-6 mr-4">

            <Menu as="div" className="relative">

                {/* MAIN MENU */}
                <div>
                    <Menu.Button
                        className="btn-raised focus:outline-none bg-transparent rounded-lg flex items-center text-sm">
                        <img
                            className="h-8 w-8 rounded-full"
                            src={props.mainOrganisation?.info?.logo_image_url || "/org-logo-placeholder.jpg"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/org-logo-placeholder.jpg";
                            }}
                            alt="Logo"
                        />
                        <span className="ml-3 text-sky-100 text-sm font-medium">
                        <span className="sr-only">Open user menu for </span>
                                {props.user === null ? 'Loading...' : props.user.info.first_name + ' ' + props.user.info.last_name}
                        </span>
                        <ChevronDownIcon
                            className="ml-1 h-5 w-5 text-gray-400"
                        />
                    </Menu.Button>
                </div>

                {/* DROPDOWN ITEMS */}
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right right-5 absolute z-[100] right-0 mt-2 w-[10rem] rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

                        {/* ORGANISATION */}
                        <Menu.Item>
                            <div>
                                <p
                                    className='px-4 py-1 text-xs text-center text-gray-400 pointer-events-none'
                                    onClick={clearCookiesAndStorage}
                                >
                                    <b>{props.mainOrganisation?.info?.name || ''}</b>
                                </p>
                                <p
                                    className='px-4 pb-1 text-xs text-center text-gray-400 pointer-events-none lowercase'
                                    onClick={clearCookiesAndStorage}
                                >
                                    <>{props.mainOrganisation?.type || ''}</>
                                </p>
                            </div>
                        </Menu.Item>

                        <hr className='w-full my-2 border-gray-100'/>

                        {/* PROFILE */}
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="#"
                                    onClick={() => props.setProfileOpen(true)}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    <Tooltip
                                        content="View your account profile"
                                        position='left'
                                    >
                                        Profile
                                    </Tooltip>
                                </a>
                            )}
                        </Menu.Item>

                        {/* ORGANISATION */}
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="#"
                                    onClick={() => props.setShowEditOrgProfileModal(true)}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    <Tooltip
                                        content="View your organisation's profile"
                                        position='left'
                                    >
                                        Organisation
                                    </Tooltip>
                                </a>
                            )}
                        </Menu.Item>

                        <LimitedEnvironmentAccess
                            local={true}
                            dev={true}
                            prod={false}
                        >

                            {/* SLVRCLD UI Library */}
                            <Menu.Item>
                                {({active}) => (
                                    <a
                                        href="/slvrcld_ui_library"
                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                    >
                                        SLVRCLD UI Library
                                    </a>
                                )}
                            </Menu.Item>

                            {/* TODO refactor out/delete this page as it is replaced by the ui library page*/}
                            {/* Development page */}
                            <Menu.Item>
                                {({active}) => (
                                    <a
                                        href="/dev"
                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                    >
                                        Development Page
                                    </a>
                                )}
                            </Menu.Item>

                        </LimitedEnvironmentAccess>

                        {/* LOGOUT */}
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="/logout"
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    Logout
                                </a>
                            )}
                        </Menu.Item>

                        {/* DIVIDER */}
                        <hr className='w-full my-2 border-gray-100'/>

                        {/* STORAGE INFO */}
                        <Tooltip
                            content="Click to clear all cache and cookies"
                            position="left"
                            containerClassName="w-full hover:cursor-pointer"
                        >
                            <Menu.Item>
                                {({active}) => (

                                    <p
                                        className={classNames(
                                            'px-4 py-2 text-xs text-gray-400',
                                            active && 'bg-gray-100'
                                        )}
                                        onClick={clearCookiesAndStorage}
                                    >
                                        Cache: {localStorage.length + sessionStorage.length} > {getTotalStorageSize()}
                                    </p>
                                )}
                            </Menu.Item>
                        </Tooltip>

                        {/* VERSION */}
                        <Menu.Item>
                            <p
                                className='px-4 py-2 text-xs text-gray-400'
                                onClick={clearCookiesAndStorage}
                            >
                                Version {process.env.REACT_APP_VERSION}
                            </p>
                        </Menu.Item>

                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

