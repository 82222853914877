// Library imports
import {LocationMarkerIcon, OfficeBuildingIcon} from '@heroicons/react/outline';
import LoadingSpinner from "./ui/LoadingSpinner";
import React from "react";
import {BasicImage} from "./base/BasicImage";


export default function ClaimBasicInfoPanel(props) {

    if (!props.claim) {
        return (
            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                <LoadingSpinner/>
            </ul>
        );
    }

    return (
        <div className="flex gap-4 mb-1 items-center py-4">

            {/* LOGO */}
            <BasicImage
                src={props.claim.organisation.info.logo_image_url}
                fallbackSrc={'/org-logo-placeholder.jpg'}
                alt="logo"
                sizeWidthRem="4"
                className="rounded-full"
            />

            {/* CLAIM INFO */}
            <div className='flex flex-col gap-1'>

                {/* NAME */}
                <h2 className="text-gray-600">
                    {props.claim.insurer_claim_number}
                </h2>

                <div className="flex justify-start items-center gap-6 text-sm text-gray-400">

                    {/* LOCATION */}
                    <span className="flex">
                      <LocationMarkerIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                      />
                      <p>{props.claim.location_text}</p>
                    </span>

                    <p>|</p>

                    {/* ORGANISATION */}
                    <span className="flex">
                      <OfficeBuildingIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                      />
                      <p>{props.claim.organisation.info.name}</p>
                    </span>

                </div>

            </div>
        </div>
    );

}
