import {customGraphRequest} from "../utils/coreApi";
import DataTable from "./DataTable";
import Tooltip from "./Tooltip";
import {CursorClickIcon} from "@heroicons/react/solid";
import {HUMAN_READABLE_DATETIME_TIMEZONE, todayTomorrowYesterdayDatetimeFormat} from "../utils/time";
import React from "react";

export function onViewHistoryRecordsClicked(props, params) {
    /* Query the history records for this organisation
       Display the history records in a modal
        params:
            object_type: claims,
            object_id: "${props.claim.id}",
            organisation_id: "${props.mainOrganisation.id}"

    */

    /** Placeholder history records */
    // showHistoryRecordsModal(props, placeholderAuditTrailHistoryRecords['history_records']['history_records']);
    // return;

    let query = `
        query AuditTrail {
          history_records(
            result_limit: 200,
            ${params}
            ){
            history_records{
              id
              date_updated
              user{
                id
                username
                info{
                  first_name
                  last_name
                }
              }
              change_type
              object_type
              object_id
              message
              before
              after
              
            }
          }
        }
    `

    customGraphRequest(
        query,
        (data) => {
            // Display the queried history records
            const recordsArray = data['history_records'] || [];
            showHistoryRecordsModal(props, recordsArray);
        },
        (error) => props.onError(error)
    )
}

function showHistoryRecordsModal(props, records) {

    let content = (

        <div className="relative max-w-[120rem] max-h-[55rem] flex flex-col justify-center items-center px-8">

            <h1 className='p-4 mb-2 mt-4 font-light'>History Records</h1>

            <h4 className='p-2 font-light text-lg text-center'>
                For the organisation: <b>{props.mainOrganisation.info.name}</b>
            </h4>

            <p className='font-light italic'>{records.length} records</p>

            <div className='overflow-y-scroll mt-4'>

                <DataTable
                    columns={[
                        {Header: '#', accessor: 'i'},
                        {Header: 'IDs', accessor: 'ids'},
                        {Header: 'Date Updated', accessor: 'date'},
                        {Header: 'User', accessor: 'user'},
                        {Header: 'Change Type', accessor: 'change_type'},
                        {Header: 'Object Type', accessor: 'object_type'},
                        {Header: 'Object ID', accessor: 'object_id'},
                        {Header: 'Message', accessor: 'message'},
                        {Header: 'Before', accessor: 'before'},
                        {Header: 'After', accessor: 'after'},
                    ]}

                    data={records.map((record, i) => {
                        return {
                            i: i + 1,
                            ids: (
                                <Tooltip
                                    content={(
                                        <div>
                                            <p className='italic'>Click to copy</p>
                                            <hr/>
                                            <p><b>id:</b> {record.id}</p>
                                            <p><b>object_id:</b> {record.object_id}</p>
                                            <p><b>user:</b> {record.user?.id}</p>
                                        </div>
                                    )}
                                >
                                    <CursorClickIcon
                                        className='h-4 w-4'
                                        onClick={() => {
                                            navigator.clipboard.writeText(`record_id: ${record.id}, object_id: ${record.object_id}, user_id: ${record.user?.id}`);
                                            props.showToastNotificationModal('success', 'Copied to clipboard', '', 500);
                                        }}
                                    />
                                </Tooltip>
                            ),
                            date: todayTomorrowYesterdayDatetimeFormat(record.date_updated, HUMAN_READABLE_DATETIME_TIMEZONE),
                            user: record.user.info.first_name + ' ' + record.user.info.last_name,
                            change_type: record.change_type,
                            object_type: record.object_type,
                            object_id: record.object_id,
                            message: record.message,
                            before: <code>{record.before}</code>,
                            after: <code>{record.after}</code>,
                        }
                    })}

                    pageSize={20}
                />

            </div>

            <div className="divider_CRUD"/>

            <div className='absolute bottom-10 right-10'>

                <button
                    className="btn-outline"
                    onClick={() => props.setCustomModalOpen(false)}
                >
                    Close
                </button>
            </div>

        </div>
    );

    props.showCustomModal(content);

}
