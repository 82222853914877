// Library imports
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    BanIcon,
    CashIcon,
    CheckIcon,
    ExternalLinkIcon,
    PencilIcon,
    PlusIcon,
    RefreshIcon,
    TruckIcon
} from '@heroicons/react/outline';
import {
    ArrowCircleLeftIcon,
    CheckCircleIcon,
    ChevronUpIcon,
    ExclamationIcon,
    PencilAltIcon,
    SparklesIcon
} from '@heroicons/react/solid';


// Local imports
import NotificationTopRight from '../../components/NotificationTopRight.jsx';
import AlertModal from '../modals/AlertModal.jsx';
import ConfirmModal from '../modals/ConfirmModal.jsx';
import UpdateManualItemModal from '../modals/UpdateManualItemModal.jsx';
import {
    customGraphRequest,
    fetchClaim,
    fetchUser,
    generateAiProto,
    queryCategories,
    verifyAllManualItems,
    verifyManualItem
} from '../../utils/coreApi.js';
import {alertError, classNames, currencySymbol, escapeDoubleQuotes, isEmpty} from '../../utils/helpers.js';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import MultiProductModal from '../modals/MultiProductModal';
import {useDocumentTitle} from "../../components/PageTitle";
import Tooltip from "../../components/Tooltip";
import {Dialog, Transition} from "@headlessui/react";
import {SimpleSelect} from "../../components/base/SimpleSelect";
import {BasicImage} from "../../components/base/BasicImage";
import ApiButton from "../../components/base/ApiButton";
import {ImageZoom} from "../../components/base/ImageZoom";
import CurrencyInput from "../../components/base/CurrencyInput";
import Safe from "../../components/Safe";

export default function ClaimVerification(props) {

    const setTitle = useDocumentTitle("Verification");

    // React router
    const navigate = useNavigate();

    const {claimId} = useParams();

    const [editManualItemOpen, setUpdateManualItemOpen] = useState(false);

    // TODO replace with base implementation
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '',
        'message': ''
    });

    // TODO replace with base implementation
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({
        'heading': 'Item updated',
        'message': 'Successfully updated item!'
    });

    // TODO replace with base implementation
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions, setConfirmModalOptions] = useState({
        'heading': '',
        'message': '',
        'type': '',
        'buttonText': ''
    });

    const [showDesktopAssessing, setShowDesktopAssessing] = useState(false);
    const [itemsToDesktopAssess, setItemsToDesktopAssess] = useState([]);
    const [desktopAssessment, setDesktopAssessment] = useState([]);

    // Data states
    const [claim, setClaim] = useState(null);
    const [user, setUser] = useState(null);
    const [categories, setCategories] = useState(null);

    // The item to display inside the edit manual item modal
    const [itemInEditManualItemModal, setItemInEditManualItemModal] = useState(null);

    // The Edit and Compare Product modal
    const [productInfoModalOpen, setProductInfoModalOpen] = useState(false);
    const [productInfoModalOptions, setProductInfoModalOptions] = useState({
        'product': null,
        'onProductAdded': null,
        'item': null,
    });

    // The list of items that are currently being AI generated
    const [itemsCurrentlyInAIGeneration, setItemsCurrentlyInAIGeneration] = useState([]);

    // On every page load
    useEffect(() => {

        if (user === null) {
            fetchUser(setUser, navigate);
        } else if (categories === null) {
            queryCategories(categories, setCategories, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        } else if (claim === null) {
            fetchClaim(claimId, setClaim, navigate);
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim});

        if (claim) setTitle(`Verification : ${claim.insurer_claim_number}`)

    }, [user, categories, claim]);

    const desktopAssessmentButtonRef = useRef(null);

    // onClick functions
    const promptVerifyItem = (item, outcome) => {

        let heading = 'Verify item';
        let message = 'Please confirm verification of this item. This action cannot be undone.';
        let type = 'confirm';
        let buttonText = 'Verify';

        if (outcome === 'CANNOT_BE_VERIFIED') {
            heading = 'Mark item as "Cannot be verified"';
            message = 'Please confirm this manual item cannot be verified. This action cannot be undone.';
            type = 'warning';
            buttonText = 'Confirm';
        }

        setConfirmModalOptions({
            'heading': heading,
            'message': message,
            'type': type,
            'buttonText': buttonText,
            'itemId': item['id'],
            'outcome': outcome,
            'onConfirmClicked': onVerifyManualItem
        });

        setConfirmModalOpen(true);
    };

    const promptVerifyAllItems = () => {

        setConfirmModalOptions({
            'heading': 'Verify ALL items',
            'message': 'Please confirm verification of all items on this page. This action cannot be undone.',
            'type': 'default',
            'buttonText': 'Verify All',
            'onConfirmClicked': (options) => {
                onVerifyAllManualItems(options);
            }
        });

        setConfirmModalOpen(true);
    };

    const promptGenerateAllItems = () => {

        setConfirmModalOptions({
            'heading': 'Generate ALL AI Items',
            'message': 'Attempt to generate an AI product for all un-verified items?',
            'type': 'default',
            'buttonText': 'Generate for all',
            'onConfirmClicked': (options) => {
                onGenerateForAllItems();
            }
        });

        setConfirmModalOpen(true);
    };

    const onVerifyAllManualItems = (options) => {

        setConfirmModalOpen(false);

        verifyAllManualItems(
            claimId,
            (data) => {
                setNotificationMessage({
                    'heading': 'Items updated',
                    'message': 'Successfully verified items!'
                });
                setNotificationOpen(true);
                setTimeout(() => {
                    setNotificationOpen(false);
                }, 3000);

                setTimeout(() => fetchClaim(claimId, setClaim, navigate), 1000);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };

    const onVerifyManualItem = (options) => {

        setConfirmModalOpen(false);

        let itemId = options['itemId'];
        let outcome = options['outcome'];

        verifyManualItem(
            itemId, outcome,
            (data) => {
                setNotificationMessage({
                    'heading': 'Item updated',
                    'message': 'Successfully updated item!'
                });

                setNotificationOpen(true);
                setTimeout(() => {
                    setNotificationOpen(false);
                }, 1000);

                setTimeout(() => fetchClaim(claimId, setClaim, navigate), 1000);

            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };

    const updateManualItem = (item) => {

        if (itemInEditManualItemModal === null || itemInEditManualItemModal['id'] !== item['id']) {
            setItemInEditManualItemModal(item);
        }

        setUpdateManualItemOpen(true);
    };

    const onItemUpdatedSuccess = (data) => {

        setUpdateManualItemOpen(false);
        setItemInEditManualItemModal(null);

        setNotificationMessage({
            'heading': 'Item updated',
            'message': 'Successfully updated item!'
        });

        setNotificationOpen(true);
        setTimeout(() => {
            setNotificationOpen(false);
        }, 1000);

        setTimeout(() => fetchClaim(claimId, setClaim, navigate), 1000);
    };

    const checkAllItemsVerified = () => {

        if (claim === null) {
            return false;
        }

        for (const item of claim.items) {
            if (item.is_manual && item.status === 'VERIFICATION') {
                return false;
            }
        }

        return true;
    };

    const onGenerateForAllItems = () => {
        /** Call generate AI proto on each VERIFICATION item */

        let unverifiedItems = claim.items.filter(item => item.status === 'VERIFICATION');

        const currentlyBeingGenerated = (item) => itemsCurrentlyInAIGeneration.find(i => i.id === item.id);

        let itemsNotInGeneration = unverifiedItems.filter(item => !currentlyBeingGenerated(item));

        const delay = 500
        // Call onSubmitItem_GenerateAIProto on each VERIFICATION item
        itemsNotInGeneration.forEach((item, index) => {
            setTimeout(() => {
                submitItemForAIGenerate(item)
            }, index * delay)
        })

    }

    const submitItemForAIGenerate = (item) => {

        let alreadyBeingGenerated = itemsCurrentlyInAIGeneration.find(i => i.id === item.id)
        if (alreadyBeingGenerated) {
            return;
        }

        // Add the item to the list of generating items. Use previous state to prevent multiple set calls in the same update cycle
        setItemsCurrentlyInAIGeneration(prev => [...prev, item]);

        props.showToastNotificationModal('success', 'AI Generation In Progress', `${item.product.common_name} is being generated`, 5000);

        generateAiProto(
            item.product.category,
            item.product.brand,
            item.product.model_number || item.product.model_numbers[0] || null,
            item.id,
            null,
            (data) => {
                console.log('Generate AI Proto response: ', data)

                /** Success */
                if (data['response_success']) {
                    props.showToastNotificationModal(
                        'success',
                        'AI Producted Generated',
                        `${item.product.common_name} Product has successfully been generated and added to this claim`,
                        5000
                    );
                    fetchClaim(claimId, setClaim, navigate);

                    // Remove the item from the list of generating items. Use previous state to prevent multiple set calls in the same update cycle
                    setItemsCurrentlyInAIGeneration(prevState => prevState.filter(i => i.id !== item.id));

                    // Open the new product in the product modal
                    setProductInfoModalOptions({
                        'product': data['new_claim_item']['product'],
                        'onProductAdded': null,
                        'item': data['new_claim_item'],
                        'mode': 'edit'
                    });
                    setProductInfoModalOpen(true);
                }

                /** Failed */
                else {

                    // Remove the item from the list of generating items. Use previous state to prevent multiple set calls in the same update cycle
                    setItemsCurrentlyInAIGeneration(prevState => prevState.filter(i => i.id !== item.id));

                    props.showAlertModal(
                        'error',
                        'AI Generation Request Failed',
                        (
                            <div className='flex flex-col gap-4 w-full'>
                                <p>Failed to generate a catalogue product from this manual item.</p>

                                <div>
                                    <b>Reason:</b>
                                    <p>
                                        {data['response_info'] ?
                                            JSON.stringify(data['response_info']) :
                                            <i>No reason has been provided</i>
                                        }
                                    </p>
                                </div>

                                <div>
                                    <p className='mt-6 text-left'>
                                        This product can still be created manually:
                                    </p>
                                    <button
                                        onClick={() => {
                                            console.log(this)
                                            showProductInfoModal(item)
                                        }}
                                        className="btn ml-0 mb-6">
                                        <PlusIcon className="mr-1 h-5 w-5"/>
                                        Create proto manually
                                    </button>
                                </div>

                            </div>
                        ));
                }

            },
            (error) => {
                // Remove the item from the list of generating items. Use previous state to prevent multiple set calls in the same update cycle
                setItemsCurrentlyInAIGeneration(prevState => prevState.filter(i => i.id !== item.id));

                // do not show the standard error modal for the generate_ai_proto mutation
                props.showAlertModal('error', 'AI Request Failed', 'Failed to generate a catalogue product from this manual item');

                console.error('Generate AI Proto response: ', error);
            }
        );
    };

    const showProductInfoModal = (item) => {

        setProductInfoModalOptions({
            'product': item.product,
            // 'productId': item.product.id,
            'onProductAdded': null,
            'item': item,
            'mode': 'edit'
        });

        setProductInfoModalOpen(true);
    };

    const getItemsToVerify = () => {

        if (!claim) return [];

        const itemsToVerify = claim.items.filter(item => item.type !== 'CATALOGUE');

        return itemsToVerify;
    }

    // HTML render functions

    const renderStandardItems = () => {

        if (claim === null) {
            return (
                <tbody className="bg-white divide-y divide-gray-200">
                </tbody>
            );
        }

        return (
            <tbody className="bg-white divide-y divide-gray-200">
            {getItemsToVerify().map(renderSingleItem)}
            </tbody>
        );
    };

    const renderSingleItem = (item) => {

        const isCompleted = item.status !== 'VERIFICATION';

        let rowBgColor = 'bg-white';
        let statusStyle = 'bg-blue-600 text-white';
        let status = 'verification';

        if (item.status === 'CANNOT_BE_VERIFIED') {
            rowBgColor = 'bg-red-100';
            statusStyle = 'bg-red-600 text-white';
            status = 'cannot be verified';
        } else if (isCompleted) {
            rowBgColor = 'bg-green-100';
            statusStyle = 'bg-green-600 text-white';
            status = 'verified';
        }

        function handleExpand(e) {
            e.currentTarget.classList.remove('max-h-[4.5rem]');
        };

        return (
            <tr key={item.id} className={classNames(isCompleted ? rowBgColor : 'bg-white', '')}>
                <td>
                    <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                         onClick={handleExpand}>
                        {item.product.common_name}
                    </div>
                </td>
                <td>
                    <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                         onClick={handleExpand}>
                        {item.product.description}
                    </div>
                </td>
                <td>
                    <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                         onClick={handleExpand}>
                        {item.category !== null && item.category.display_name}
                    </div>
                </td>
                <td>
                    <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                         onClick={handleExpand}>
                        {item.product.brand}
                    </div>
                </td>
                <td>
                    <Tooltip content='Click to view full'>
                        <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                             onClick={handleExpand}>
                            {item.product.model_numbers !== null && item.product.model_numbers.join(' | ')}
                        </div>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip content='Click to view full'>
                        <div className='px-4 max-h-[4.5rem] max-w-[10rem] overflow-hidden whitespace-normal'
                             onClick={handleExpand}>
                            {item.product.ean_codes !== null && item.product.ean_codes.join(' | ')}
                        </div>
                    </Tooltip>
                </td>
                <td>
                  <span
                      className={classNames(
                          statusStyle,
                          'inline-flex justify-center items-center px-2.5 py-0.5 rounded-full font-medium capitalize'
                      )}
                  >
                    {status}
                  </span>
                </td>
                <td>
                    {item.quantity}
                </td>
                <td className="text-right py-3">
                    <div className='w-full flex items-center justify-end gap-2'>

                        {/* EDIT AND COMPARE PROTO MODAL  */}
                        <Tooltip content="Edit" position="top">
                            <button
                                onClick={() => showProductInfoModal(item)}
                                className={classNames(
                                    'btn-icon-danger',
                                    isCompleted ? 'text-gray-400 ' : 'text-cyan-600 hover:text-cyan-500',
                                    'mr-1 inline-flex items-center text-sm font-medium rounded-md cursor-pointer',
                                    item.status === 'VERIFICATION' ? 'hidden' : ''
                                )}
                            >
                                <PencilIcon className="h-7 w-7 text-red-400" />
                            </button>
                        </Tooltip>

                        {/* EDIT MANUAL ITEM MODAL */}
                        <button
                            disabled={isCompleted ? 'disabled' : undefined}
                            onClick={() => {
                                updateManualItem(item);
                            }}
                            className={classNames(
                                'tooltip-container btn-icon',
                                isCompleted ? 'text-gray-400 pointer-events-none' : 'text-cyan-600 hover:text-cyan-500',
                                item.status === 'VERIFICATION' ? '' : 'hidden'
                            )}
                        >
                            <PencilAltIcon className="h-7 w-7" />
                            <span className="tooltip">Edit</span>
                        </button>

                        {/* CONVERT */}
                        <a
                            disabled={isCompleted ? 'disabled' : undefined}
                            href={`/claim/item-convert/${claimId}/${item.id}`}
                            className={classNames(isCompleted ? 'text-gray-400 pointer-events-none' : 'text-cyan-600 hover:text-cyan-500',
                                'tooltip-container btn-icon')}
                        >
                            <RefreshIcon className="h-7 w-7" />
                            <span className="tooltip">Convert</span>
                        </a>

                        {/* GENERATE AI PROTO */}
                        {
                            itemsCurrentlyInAIGeneration.find(i => i.id === item.id) ?
                                <div className="inline-flex mr-1 tooltip-container">
                                    <LoadingSpinner size="6" text="hidden"/>
                                    <span className="tooltip">AI product generation in progress</span>
                                </div>
                                :
                                <button
                                    onClick={() => {
                                        props.showConfirmModal('success',
                                            'Generate AI product',
                                            'Are you sure you would like to submit this item for AI product generation?',
                                            'Generate AI Product',
                                            () => submitItemForAIGenerate(item),
                                            null);

                                    }}
                                    className={classNames(isCompleted ? 'text-gray-400 pointer-events-none' : 'text-cyan-600 hover:text-cyan-500',
                                        'tooltip-container btn-icon')}
                                >
                                    <SparklesIcon className="h-7 w-7" />
                                    <span className="tooltip">Generate proto</span>
                                </button>
                        }

                        {/* DESKTOP ASSESSING */}
                        <Tooltip content="Desktop assess" styling='min-w-[5rem]'>
                            <input
                                className={classNames(
                                    'checkbox p-2',
                                    isCompleted && 'bg-transparent pointer-events-none'
                                )}
                                type='checkbox'
                                disabled={isCompleted ? 'disabled' : undefined}
                                checked={itemsToDesktopAssess.find(i => i.id === item.id) !== undefined}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        // if this item is not already in the list, add it
                                        if (!itemsToDesktopAssess.find(i => i.id === item.id)) {
                                            setItemsToDesktopAssess([...itemsToDesktopAssess, item]);
                                        }
                                    } else {
                                        // find and remove this item from the list
                                        setItemsToDesktopAssess(itemsToDesktopAssess.filter(i => i.id !== item.id));
                                    }

                                    // If the item selection for desktop assessment has been modified, then reset the desktop assessment data
                                    // This is for the "Desktop Assess" button, to either show "Desktop Assess" or "Continue Assessment"
                                    if (!isEmpty(desktopAssessment)) setDesktopAssessment([])
                                }}
                            />
                        </Tooltip>

                        {/* IGNORE */}
                        <button
                            disabled={isCompleted ? 'disabled' : undefined}
                            onClick={() => {
                                promptVerifyItem(item, 'CANNOT_BE_VERIFIED');
                            }}
                            className={classNames(
                                'tooltip-container btn-icon-danger',
                                isCompleted ? 'text-gray-400 pointer-events-none' : 'text-red-500 hover:text-red-400',
                            )}
                        >
                            <ExclamationIcon className="h-7 w-7" />
                            <span className="tooltip">Cannot verify</span>
                        </button>

                        {/* VERIFY */}
                        <button
                            disabled={isCompleted ? 'disabled' : undefined}
                            onClick={() => {
                                promptVerifyItem(item, 'VERIFIED');
                            }}
                            className={classNames(
                                'tooltip-container btn-icon',
                                isCompleted ? 'text-gray-400 pointer-events-none' : 'text-green-600 hover:text-green-500',
                            )}
                        >
                            <CheckCircleIcon className="h-7 w-7" />
                            <span className="tooltip">Verify</span>
                        </button>


                    </div>
                </td>

            </tr>
        );
    };

    const renderStandardPagination = () => {
        if (claim === null) {
            return (
                <nav
                    className="bg-white px-3 py-3 flex items-center justify-between border-t border-gray-200 sm:px-3"
                    aria-label="Pagination"
                >
                    <div className="w-full">
                        <svg role="status"
                             className="align-middle inline-block mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"></path>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"></path>
                        </svg>
                        <span>Loading items...</span>
                    </div>
                </nav>
            );
        }

        if (isEmpty(claim.items)) {
            return (
                <nav
                    className="bg-white px-3 py-3 flex items-center justify-between border-t border-gray-200 sm:px-3"
                    aria-label="Pagination"
                >
                    <div>No items require verification.</div>
                </nav>
            );
        }

        let itemCount = 0;

        for (const item of claim.items) {
            if (item.is_manual) {
                itemCount += 1;
            }
        }

        return (
            <nav
                className="bg-white px-3 py-3 flex items-center justify-between border-t border-gray-200 sm:px-3"
                aria-label="Pagination"
            >
                <div className="hidden sm:block">
                    <p className="text-sm text-gray-500">
                        Showing <span className="font-medium">{itemCount}</span> items for verification
                    </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                </div>
            </nav>
        );
    };

    const renderVerifyAllButton = () => {

        if (claim === null || checkAllItemsVerified()) {
            return (<></>);
        }

        return (
            <button
                type="button"
                onClick={promptVerifyAllItems}
                className="btn-success"
            >
                <CheckCircleIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                Verify All
            </button>
        );
    };

    function onInitiateDesktopAssessing(buttonHandler) {

        // If the desktop assessment has already been done, then re-open the modal
        // If the desktop assessment data has been cleared, then re-run the desktop assessment
        if (!isEmpty(desktopAssessment)) {
            buttonHandler.reset();
            setShowDesktopAssessing(true);
            return
        }


        if (itemsToDesktopAssess.length === 0) {
            props.showAlertModal('error', 'No items selected', 'Please select items to desktop assess');
            buttonHandler.reset();
            return;
        }

        let query = `
        query DesktopAssessmentRequest{
          request_desktop_assessment(
            claim_id:"${claimId}" 
            item_ids:[${itemsToDesktopAssess.map(i => `"${i.id}"`).join(',')}]
          ){
            error{
              message
              type
            }
            items{
              id
              product{
                brand
                model_numbers
                description
              }
              claimant_quote
              desktop_assessment{
                entry{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                mid{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                high{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                product_list {
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  thumbnail
                  supplier_name
                  brand
                  model_number
                  description
                  price_class
                }
              }
            }
          }
        }
        `

        customGraphRequest(
            query,
            (data) => {
                // Select the entry quote by default for each item
                let assessment_items = data.items.map(item => {

                    const entry = item.desktop_assessment.entry;
                    if (entry.price &&
                        entry.source_url &&
                        entry.brand &&
                        entry.description) {
                        item.selected_quote = entry;
                    } else {
                        item.selected_quote = {};
                    }

                    return item;
                })

                setDesktopAssessment(assessment_items)
                setShowDesktopAssessing(true);
                setItemsToDesktopAssess([])

                // Show the "Continue Assessment" text on the desktop assessing button
                buttonHandler.reset();
            },
            (error) => {
                props.onError(error);
                buttonHandler.onError();
            }
        )

    }

    const allProps = {
        ...props,
        claimId,
        claim, setClaim,
        user, setUser,
        categories, setCategories,
        alertOpen, setAlertOpen,
        alertOptions, setAlertOptions,
        notificationOpen, setNotificationOpen,
        notificationMessage, setNotificationMessage,
        confirmModalOpen, setConfirmModalOpen,
        confirmModalOptions, setConfirmModalOptions,
        showDesktopAssessing, setShowDesktopAssessing,
        itemsToDesktopAssess, setItemsToDesktopAssess,
        desktopAssessment, setDesktopAssessment,
        editManualItemOpen, setUpdateManualItemOpen,
        itemInEditManualItemModal, setItemInEditManualItemModal,
        productInfoModalOpen, setProductInfoModalOpen,
        productInfoModalOptions, setProductInfoModalOptions,
        itemsCurrentlyInAIGeneration, setItemsCurrentlyInAIGeneration,
    }

    const renderGenerateAllAI_Button = () => {

        if (claim === null || checkAllItemsVerified()) {
            return (<></>);
        }

        return (
            <button
                type="button"
                onClick={promptGenerateAllItems}
                className="btn-outline hidden"
            >
                <SparklesIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                Generate All AI
            </button>
        );
    };

    return (
        <>
            <div className="">

                <NotificationTopRight
                    open={notificationOpen}
                    setOpen={() => {
                    }}
                    message={notificationMessage}
                />

                <MultiProductModal
                    {...props}
                    open={productInfoModalOpen}
                    setOpen={setProductInfoModalOpen}
                    options={productInfoModalOptions}
                    setOptions={setProductInfoModalOptions}
                />

                <AlertModal open={alertOpen} setOpen={setAlertOpen} options={alertOptions}/>

                <UpdateManualItemModal
                    open={editManualItemOpen}
                    setOpen={setUpdateManualItemOpen}
                    categories={categories}
                    item={itemInEditManualItemModal}
                    onSuccess={onItemUpdatedSuccess}
                    onError={(error) => {
                        alertError(setAlertOpen, setAlertOptions, error);
                    }}
                />

                <DesktopAssessingModal
                    open={showDesktopAssessing}
                    setOpen={setShowDesktopAssessing}
                    {...allProps}
                />


                <div className="flex flex-col flex-1">

                    {/* TODO : change usage to base modal */}
                    <ConfirmModal
                        open={confirmModalOpen}
                        setOpen={setConfirmModalOpen}
                        options={confirmModalOptions}
                    />

                    <main className="flex-1 pb-8">

                        {/* HEADER */}
                        <div className="page-header-bar">
                            <h3 className="page-header-title">Item Verification</h3>

                            <div className='flex gap-4 mr-4'>

                                {/* CLAIM PROFILE */}
                                <a
                                    type="button"
                                    href={`/profile/${claimId}`}
                                    className="tooltip-container btn py-2"
                                >
                                                <span className="">
                                                  <ArrowCircleLeftIcon className="mr-2 inline h-5 w-5 align-center"
                                                                       aria-hidden="true"/>
                                                  Claim Profile
                                                </span>
                                    <span style={{marginTop: '75px', marginLeft: '-15px', width: '200px'}}
                                          className="tooltip">Return to the claim quantification review</span>
                                </a>

                                {/* TENDER */}
                                {claim !== null && (claim.status_reason === 'Tendering' || checkAllItemsVerified()) && (
                                    <a
                                        type="button"
                                        onClick={() => navigate(`/tender/${claimId}`)}
                                        className="tooltip-container btn py-2"
                                    >
                                                <span className="ml-auto mr-auto">
                                                  <PencilAltIcon className="mr-1 inline h-5 w-5 align-top"
                                                                 aria-hidden="true"/>
                                                  Tender
                                                </span>
                                        <span style={{marginTop: '75px', marginLeft: '-32px', width: '140px'}}
                                              className="tooltip">Go to tender page</span>
                                    </a>
                                )}

                            </div>

                        </div>


                        <div className="mt-8">

                            <div className="widget p-0 mx-8">

                                <table className="">
                                    <thead className="">
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Category
                                        </th>
                                        <th>
                                            Brand
                                        </th>
                                        <th>
                                            Model
                                        </th>
                                        <th>
                                            Bar code (EAN)
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                        <th>
                                            Quantity
                                        </th>
                                        <th className='text-end'>
                                            <div className='flex gap-5 justify-end'>
                                                <Tooltip content='Mark all items for Desktop Assessing'
                                                         styling='min-w-[8rem]'>
                                                    <input
                                                        className='checkbox p-2'
                                                        type='checkbox'
                                                        disabled={!claim?.items.find(i => i.status === 'VERIFICATION')}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                // Set all items
                                                                setItemsToDesktopAssess(claim.items.filter(i => i.is_manual && i.status === 'VERIFICATION'));
                                                            } else {
                                                                // clear desktopAssessingItems
                                                                setItemsToDesktopAssess([]);
                                                            }

                                                            // If the item selection for desktop assessment has been modified, then reset the desktop assessment data
                                                            // This is for the "Desktop Assess" button, to either show "Desktop Assess" or "Continue Assessment"
                                                            if (!isEmpty(desktopAssessment)) setDesktopAssessment([])
                                                        }}
                                                    />
                                                </Tooltip>
                                                Actions
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    {renderStandardItems()}
                                </table>

                                {/* Pagination */}
                                {renderStandardPagination()}

                            </div>

                            <div className="mt-4 w-full flex justify-end gap-4">

                                {/* DESKTOP ASSESSING */}
                                <ApiButton
                                    className='btn'
                                    onClick={onInitiateDesktopAssessing}
                                    buttonRef={desktopAssessmentButtonRef}
                                    content={(
                                        isEmpty(desktopAssessment) ?
                                            <>
                                                <CheckCircleIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                                                Desktop Assess
                                            </>
                                            :
                                            <>
                                                <ChevronUpIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                                                Continue Assessment
                                            </>
                                    )}
                                    loadingContent={(<>
                                        <LoadingSpinner color='darkcyan' size='6' body=''/>
                                    </>)}
                                    errorContent='Failed to complete desktop assessment'
                                />
                                {renderGenerateAllAI_Button()}
                                {renderVerifyAllButton()}

                            </div>

                        </div>
                    </main>
                </div>

            </div>
        </>
    );
}

function DesktopAssessingModal(props) {

    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [supplierOrganisations, setSupplierOrganisations] = useState(null);

    const [includeDeliveryFee, setIncludeDeliveryFee] = useState(false);

    useEffect(() => {
        if (props.open)
            fetchSuppliers();
    }, [props.open]);

    function fetchSuppliers() {

        if (!isEmpty(supplierOrganisations)) return supplierOrganisations;

        let query = `
            query Accounts_Suppliers{
              accounts( 
                organisation: "${props.claim.organisation.id}",
                types: [ADMINISTRATOR_SUPPLIER, INSURER_SUPPLIER]
              ) {
                error {
                  type
                  message
                }
                
                accounts {
                  type
                  organisation_2 {
                    unique_reference_name
                    id
                    type
                    info {
                      name
                      logo_image_url
                      country
                      country_text
                    }
                  }
                  
                }
              }
            }
        `

        customGraphRequest(
            query,
            (data) => {
                const organisations = data.accounts.map(account => account.organisation_2);
                setSupplierOrganisations(organisations);
            },
            props.onError
        )
    }

    function onSubmitAssessment(buttonHandler) {
        /* If any selected_quotes are missing required fields,
        warn the user that these will be ignored */

        buttonHandler.reset()

        let invalidQuotes = false;

        for (let item of props.desktopAssessment) {
            if (!item.selected_quote ||
                !item.selected_quote.brand ||
                !item.selected_quote.model_number ||
                !item.selected_quote.description ||
                !item.selected_quote.price ||
                !item.selected_quote.source_url
            ) {
                invalidQuotes = true;
            }
        }

        if (invalidQuotes) {
            props.showConfirmModal(
                'info',
                'Invalid quotes',
                (<div>
                    <p>Some selected quotes are missing required fields</p>
                    <p>These quotes will be ignored</p>
                    <br/>
                    <b>Required fields:</b>
                    <p>Brand, Model number, Price, Description, Source url</p>
                </div>),
                'Ignore and submit',
                ()=> submitDesktopAssessment(buttonHandler),
                ()=>buttonHandler.reset()
            )
        }
        else {
            submitDesktopAssessment(buttonHandler)
        }

    }

    function submitDesktopAssessment(buttonHandler) {

        buttonHandler.onLoading()

        let payloads = assessmentPayloads();

        if (isEmpty(payloads)) {
            props.showAlertModal(
                'info',
                'No items/levels selected',
                'No levels for any items have been selected for desktop assessment'
            )
            buttonHandler.onError("No items selected for desktop assessment");
            return;
        }

        if (isEmpty(selectedSupplier)) {
            props.showAlertModal(
                'info',
                'No supplier selected',
                'Please select a supplier to submit the desktop assessment to'
            )
            buttonHandler.onError("No supplier selected");
            return;
        }

        let mutation = `
            mutation ResolveAssessment {
              resolve_desktop_assessment(
                claim_id: "${props.claimId}"
                supplier_id: "${selectedSupplier.id}"
                assessment_payloads: [${payloads}]
              ){
                error{type, message}
                items{
                  id
                }
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showNotificationModal(
                    'success',
                    'Resolved Successfully',
                    'The desktop assessment has been submitted successfully'
                )
                props.setOpen(false);
                buttonHandler.onSuccess();
                fetchClaim(props.claimId, props.setClaim, props.navigate);
            },
            (error) => {
                props.onError(error)
                buttonHandler.onError();
            }
        )

    }

    function assessmentPayloads() {
        /* Create the assessment payload
            assessment_payloads:[
                  {
                    item_id: ""

                    // original product
                    brand: ""
                    description: ""
                    model_number: ""

                    // selected quote
                    desktop_replacements: [
                      {
                        brand: "Chaka"
                        description: "Bag of charcoal"
                        model_number: "ch1x"
                        price: 60
                        source_url: "https://www.example.com/source1"
                      }
                    ]

                  }
                ]
       */

        let payloads = []

        props.desktopAssessment.forEach(item => {

            let payload = ` 
                {
                    item_id: "${item.id}",
                    
                    brand: "${escapeDoubleQuotes(item.product.brand)}",
                    description: "${escapeDoubleQuotes(item.product.description)}",
                    model_number: "${escapeDoubleQuotes(item.product.model_numbers[0])}",
                    
                    desktop_replacements: [
                        {
                            brand: "${escapeDoubleQuotes(item.selected_quote.brand)}"
                            description: "${escapeDoubleQuotes(item.selected_quote.description)}"
                            model_number: "${escapeDoubleQuotes(item.selected_quote.model_number)}"
                            price: ${getQuotePrice(item.selected_quote)}
                            source_url: "${escapeDoubleQuotes(item.selected_quote.source_url)}"
                        }
                    ]
                }
            `

            // Only add the payload if the selected quote has all the required fields
            if (item.selected_quote &&
                item.selected_quote.brand &&
                item.selected_quote.model_number &&
                item.selected_quote.description &&
                item.selected_quote.price &&
                item.selected_quote.source_url
            ) {
                payloads.push(payload)
            }

        })

        return payloads.join(',\n');

    }

    function getQuotePrice(quote) {
        /* A desktop assessment object as a input
        * Return the quote price, optionally including the delivery fee */

        if (!quote || !quote.price) return '-';

        const price = parseFloat(quote.price) || 0;
        const deliveryCost = parseFloat(quote.delivery_cost_extract) || 0;
        const freeDelivery = quote.free_delivery || false;

        let totalPrice = includeDeliveryFee ? price + deliveryCost : price;
        if (freeDelivery) totalPrice = price;

        // Round down to two decimal points
        totalPrice = Math.floor(totalPrice * 100) / 100;

        return totalPrice;
    }

    function renderItem(item) {

        function getBrandRecommendations() {
            /* Return a list of terms for the brand dropdown */
            if (!item.selected_quote || !item.selected_quote?.description) return [];
            return cumulativeSplit(item.selected_quote.description);
        }

        function getModelRecommendations() {
            /* Return a list of terms for the model number dropdown */
            if (!item.selected_quote || !item.selected_quote?.description) return [];

            // Remove the brand from the beginning of the description
            let description = item.selected_quote.description;
            let brandWords = item.selected_quote.brand?.split(' ') || [];
            for (let word of brandWords) {
                // replace the first occurrence of the word
                description = description.replace(word, '').trim();
            }

            return cumulativeSplit(description);
        }

        function cumulativeSplit(text) {
            /**
             * Returns cumulative phrases from the input string
             *
             * @example
             * text = "this is a test string"
             * // Output: ["this", "this is", "this is a", "this is a test", "this is a test string"]
             */

            let words = text.split(' ');
            // Remove the first word if it is not a letter or number
            if (!/[a-zA-Z0-9]/.test(words[0])) words.shift();

            let result = [];
            for (let i = 1; i <= words.length; i++) {

                // If the last word (next additional word) does not contain a letter or a number
                // then ignore that phrase
                // E.g: "this is a test -" should be ignored, since only a "-" has been added
                let phraseWords = words.slice(0, i);
                const lastWord = phraseWords[i - 1];

                if (/[a-zA-Z0-9]/.test(lastWord)) {
                    const phrase = phraseWords.join(' ');
                    result.push(phrase);
                }
            }
            return result;
        }

        function onSelectQuote(product) {

            // Update the selected quote for the item
            let _item = props.desktopAssessment[props.desktopAssessment.indexOf(item)];

            // -- Delivery fee in the description
            let description = product.description;
            if(includeDeliveryFee){
                if (product.delivery_cost_extract) {
                    description += ` (Delivery: ${currencySymbol(props.claim)}${product.delivery_cost_extract || '-'})`
                }
                if(product.free_delivery){
                    description += ` (Free delivery)`
                }
            }

            // -- Auto populate brand and model number
            let brand = product.brand;
            let model_number = product.model_number || product.model_numbers?.[0];
            try {
                // replace all none letter or number characters with a space
                const descriptionWords = product?.description?.replaceAll(/[^a-zA-Z0-9]/g, ' ').split(' ') || ['-'];
                brand = descriptionWords[0];

                // model number is the second, to 5th word in the description
                model_number = descriptionWords.slice(1, 5).join(' ');
            }
            catch (e) {
                console.error('Error creating brand and model from description: ', e)
            }

            _item.selected_quote = {
                ...product,
                model_number: model_number,
                brand: brand,
                original_quote: {
                    ...product,
                    model_number: product.model_number || product.model_numbers?.[0],
                },
                description: description
            };

            props.setDesktopAssessment([...props.desktopAssessment]);
        }


        return (
            <tr>

                {/* ITEMS */}
                <td className='text-start w-[10rem] pl-2'>
                    <b>{item.product.brand}</b>
                    <p>{item.product.model_numbers[0]}</p>
                </td>


                {/* QUOTES */}
                <td className='w-[10rem] pr-4'>
                    <SimpleSelect
                        width='w-full bg-white'
                        selectOptionClassName='!max-h-[inherit] h-[40rem] w-[60rem]'
                        selectedState={null}
                        selectableOptions={item.desktop_assessment?.product_list || []}
                        onChange={(selectedProductQuote) => onSelectQuote(selectedProductQuote)}

                        renderOption={(product) =>
                            <div className='flex w-full gap-[2rem] h-[5rem] py-2'>

                                <div className='w-[5rem] shrink-0 max-h-[5rem]'>
                                    <BasicImage
                                        src={product?.thumbnail}
                                        fallbackSrc={''}
                                        alt="product"
                                        sizeWidthRem="5"
                                        className=""
                                    />
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='w-[10rem] shrink-0 flex flex-col justify-around items-start'>
                                    <p className='text-2xl font-bold'>{currencySymbol(props.claim)} &nbsp; {getQuotePrice(product)}</p>

                                    <div className='flex gap-2'>
                                        <CashIcon className='h-4 w-4 mr-1'/>
                                        <p className='text-sm flex'>{product?.price || '-'}</p>
                                    </div>

                                    <div className='flex gap-2'>
                                        <TruckIcon className='h-4 w-4 mr-1'/>
                                        <p className='text-sm flex'>
                                            {product?.delivery_cost_extract || '-'}
                                            <span className='text-xs'>&nbsp; {product?.free_delivery && ' free delivery'}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='w-[8rem] flex flex-col justify-around shrink-0 font-bold text-md'>
                                    <p>{product?.brand || '-'}</p>
                                    <p>{product?.model_number || '-'}</p>
                                    <i className='text-xs font-normal'>{product?.price_class || '-'}</i>
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='flex flex-col w-full justify-between items-center text-center'>
                                    <p className='overflow-hidden text-ellipsis whitespace-normal'>{product?.description || '-'}</p>

                                    <a href={product.source_url} target='_blank'
                                       className='text-blue-700 flex gap-1'>
                                        <i>{product?.supplier_name}</i>
                                        <ExternalLinkIcon className='h-3 w-3 mt-[4px]'/>
                                    </a>
                                </div>

                            </div>
                        }

                        renderSelected={(org) =>
                            <p>{item.desktop_assessment?.product_list?.length} Quotes</p>
                        }
                    />
                </td>

                {/* PRODUCT PREVIEW */}
                <td className='w-[10rem]'>
                    <ImageZoom
                        imageSrc={item.selected_quote?.thumbnail || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        altText="Product Preview"
                        className="w-full max-w-[8rem] max-h-[6rem] object-contain px-2"
                        zoomClassName={classNames(
                            'border-[1px] border-stone-400 rounded-md',
                            // Don't show the preview for the placeholder product image
                            item.selected_quote?.thumbnail ? 'scale-[300%]' : 'scale-[100%]'
                        )}
                    />
                </td>

                {/* QUOTE */}
                <td>

                    <div className='my-2 flex gap-2 mb-2'>

                        <div className='flex flex-col gap-2  w-full'>

                            <div className='flex gap-2'>

                                {/* BRAND*/}
                                <div className='relative group'>
                                    <input
                                        placeholder='brand'
                                        value={item.selected_quote?.brand || ''}
                                        onChange={(e) => {
                                            let newProducts = props.desktopAssessment;
                                            newProducts[props.desktopAssessment.indexOf(item)].selected_quote.brand = e.target.value;
                                            props.setDesktopAssessment([...newProducts]);
                                        }}
                                    />
                                    <div className='invisible hover:visible group-hover:visible '>
                                        <ul className="absolute top-[2rem] w-[30rem] bg-white border border-gray-300 rounded mt-0 max-h-[20rem] overflow-y-auto z-10">
                                            {getBrandRecommendations().map((phrase, index) =>
                                                <option
                                                    className='hover:bg-gray-200 text-start pl-2 py-1'
                                                    key={index}
                                                    onClick={() => {
                                                        let newProducts = props.desktopAssessment;
                                                        newProducts[props.desktopAssessment.indexOf(item)].selected_quote.brand = phrase;
                                                        props.setDesktopAssessment([...newProducts]);
                                                    }}
                                                >
                                                    {phrase}
                                                </option>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                {/* MODEL */}
                                <div className='relative group'>
                                    <input
                                        placeholder='model number'
                                        value={item.selected_quote?.model_number || item.selected_quote?.model_numbers?.[0] || ''}
                                        onChange={(e) => {
                                            let newProducts = props.desktopAssessment;
                                            newProducts[props.desktopAssessment.indexOf(item)].selected_quote.model_number = e.target.value;
                                            props.setDesktopAssessment([...newProducts]);
                                        }}
                                    />

                                    <div className='invisible hover:visible group-hover:visible '>
                                        <ul className="absolute top-[2rem] w-[30rem] bg-white border border-gray-300 rounded mt-0 max-h-[20rem] overflow-y-auto z-10">
                                            {getModelRecommendations().map((phrase, index) =>
                                                <option
                                                    className='hover:bg-gray-200 text-start pl-2 py-1'
                                                    key={index}
                                                    onClick={() => {
                                                        let newProducts = props.desktopAssessment;
                                                        newProducts[props.desktopAssessment.indexOf(item)].selected_quote.model_number = phrase;
                                                        props.setDesktopAssessment([...newProducts]);
                                                    }}
                                                >
                                                    {phrase}
                                                </option>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className='flex gap-2'>
                                {/* URL */}
                                <div className='flex w-full'>
                                    <Tooltip content='Click to open this URL in a new tab' styling='!max-w-[300%]'>
                                        <a
                                            className='btn-light m-0 p-2'
                                            href={item.selected_quote?.source_url}
                                            target='_blank'
                                        >
                                            <ExternalLinkIcon className='h-5 w-5'/>
                                        </a>
                                    </Tooltip>
                                    <input
                                        className='input text-xs text-blue-700'
                                        type='text'
                                        value={item.selected_quote?.source_url || ''}
                                        onChange={(e) => {
                                            let _item = props.desktopAssessment[props.desktopAssessment.indexOf(item)];
                                            _item.selected_quote.source_url = e.target.value;
                                            props.setDesktopAssessment([...props.desktopAssessment]);
                                        }}
                                    />
                                </div>

                                {/* PRICE */}
                                <Tooltip
                                    className='text-start'
                                    content={
                                        <div>
                                            <p>
                                                <b>Quote:</b> {currencySymbol(props.claim)} {item.selected_quote?.original_quote?.price}
                                            </p>
                                            <p>
                                                <b>Delivery:</b> {currencySymbol(props.claim)} {item.selected_quote?.original_quote?.delivery_cost_extract || 0}
                                            </p>
                                            <p>
                                                <b>Delivery Free: </b>
                                                {
                                                    (item.selected_quote?.original_quote?.free_delivery === true && 'Yes') ||
                                                    (item.selected_quote?.original_quote?.free_delivery === false && 'No')
                                                }
                                            </p>
                                            {item.claimant_quote &&
                                                <p>
                                                    <b>Claimant
                                                        quote*:</b> {currencySymbol(props.claim)} {item.claimant_quote}
                                                </p>
                                            }
                                        </div>
                                    }
                                >
                                    <CurrencyInput
                                        currencySymbol={item.claimant_quote ? currencySymbol(props.claim) + '*' : currencySymbol(props.claim)}

                                        value={getQuotePrice(item.selected_quote)}
                                        onChange={(e) => {
                                            let newProducts = props.desktopAssessment;
                                            let _item = newProducts[props.desktopAssessment.indexOf(item)];
                                            _item.selected_quote.price = e.target.value;
                                            // If the user is inputting a price, then ignore the delivery cost
                                            _item.selected_quote.delivery_cost_extract = 0;
                                            props.setDesktopAssessment([...newProducts]);
                                        }}
                                        className={classNames(
                                            'input pl-8 w-[10rem]',

                                            // If there is a claimant quote, then color the prices
                                            item.claimant_quote && parseInt(item.claimant_quote) === parseInt(item.selected_quote?.price) && 'text-yellow-600',
                                            item.claimant_quote && item.claimant_quote < item.selected_quote?.price && 'text-red-500',
                                            item.claimant_quote && item.claimant_quote > item.selected_quote?.price && 'text-green-600',
                                        )}
                                    />
                                </Tooltip>

                            </div>


                        </div>

                        {/* DESCRIPTION */}
                        <textarea
                            placeholder='description'
                            className='input w-full'
                            value={item.selected_quote?.description || ''}
                            onChange={(e) => {
                                let newProducts = props.desktopAssessment;
                                newProducts[props.desktopAssessment.indexOf(item)].selected_quote.description = e.target.value;
                                props.setDesktopAssessment([...newProducts]);
                            }}
                        />

                    </div>

                </td>

                {/* ACTIONS */}
                <td>
                    <div className='flex flex-col'>

                        {/* CLEAR QUOTE */}
                        <Tooltip content='Clear' className='max-w-[400%]'>
                            <button className='btn-light hover:btn-outline !p-2 !m-0'
                                    onClick={() => {
                                        let _item = props.desktopAssessment[props.desktopAssessment.indexOf(item)];
                                        _item.selected_quote = {};
                                        props.setDesktopAssessment([...props.desktopAssessment]);
                                    }}
                            >
                                <RefreshIcon className='h-5 w-5'/>
                            </button>
                        </Tooltip>

                        {/* REMOVE ITEM */}
                        <Tooltip content='Remove this item' className='max-w-[400%]'>
                            <button className='btn-light hover:btn-outline-danger !p-2 !m-0'
                                    onClick={() => {
                                        let _items = props.desktopAssessment;
                                        _items.splice(props.desktopAssessment.indexOf(item), 1);
                                        props.setDesktopAssessment([..._items]);
                                    }}
                            >
                                <BanIcon className='h-5 w-5'/>
                            </button>
                        </Tooltip>

                    </div>
                </td>

            </tr>
        );

    }

    function downloadAttachment(attachment) {

        if (!attachment || !attachment.id || !attachment.object_id || !attachment.object_type) {
            props.showAlertModal('error', 'Invalid attachment', 'Attachment is missing required fields');
            return;
        }

        // Create download url
        let mutation = `
            query GenerateFileDownloadURL{
               generate_file_download_url(
                file_id: "${attachment.id}"
                object_id: "${attachment.object_id}"
                object_type: ${attachment.object_type}
              ){
                error{type, message}
                url
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal('success', 'URL Generated', 'The download will start shortly');

                // Create a new anchor element
                const anchor = document.createElement('a');

                // Set the href attribute to the download URL
                anchor.href = data.url;

                // Trigger a click event on the anchor element
                anchor.click();

                // Cleanup: remove the anchor element from the document
                anchor.remove();
            },
            props.onError
        )

    }

    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[10] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-start mt-[4rem] mb-[12rem] justify-center min-h-screen">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white w-[100rem] rounded-xl'
                                 onClick={(e) => {
                                     e.stopPropagation(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                <div className="flex flex-col justify-center items-center p-8">

                                    <h1 className='p-2 mb-2 font-light text-3xl '>Desktop Assessing</h1>

                                    <h4 className='p-4 mb-4 font-light text-lg text-center'>
                                        Assessment results for the selected items are shown below
                                    </h4>

                                    <div className="divider_CRUD"/>

                                    <div className='flex w-full justify-between gap-4 items-center'>
                                        <SimpleSelect
                                            width='w-[20rem]'
                                            selectedState={selectedSupplier}
                                            selectableOptions={supplierOrganisations}
                                            onChange={(supplier) => setSelectedSupplier(supplier)}

                                            renderOption={(org) =>
                                                <div className='flex gap-2'>
                                                    <BasicImage
                                                        src={org?.info.logo_image_url}
                                                        fallbackSrc={'/org-logo-placeholder.jpg'}
                                                        alt="logo"
                                                        sizeWidthRem="1.2"
                                                        className="rounded-full"
                                                    />
                                                    <span>{org?.info.name || ''}</span>
                                                </div>
                                            }

                                            /* TODO REMOVE THIS use {active}*/
                                            renderSelected={(org) =>
                                                org ?
                                                    <div className='flex gap-2'>
                                                        <BasicImage
                                                            src={org.info.logo_image_url}
                                                            fallbackSrc={'/org-logo-placeholder.jpg'}
                                                            alt="logo"
                                                            sizeWidthRem="1.2"
                                                            className="rounded-full"
                                                        />
                                                        <span>{org.info.name || ''}</span>
                                                    </div> : 'Select a supplier'
                                            }
                                        />

                                        {/* ATTACHMENTS */}
                                        {
                                            props.claim?.attachments?.length <= 0 ?
                                                <Tooltip content='There are no attachments in this claim'>
                                                    <p className='text-gray-500'>Attachments</p>
                                                </Tooltip>
                                                :
                                                <Tooltip content='Selecting an attachment will automatically download it'>
                                                    <SimpleSelect
                                                        width='w-[20rem]'
                                                        selectedState={null}
                                                        selectableOptions={props.claim?.attachments || []}

                                                        onChange={(attachment) => {
                                                            if (!attachment || !attachment.id || !attachment.object_id || !attachment.object_type) {
                                                                props.showAlertModal('error', 'Invalid attachment', 'Attachment is missing required fields');
                                                                return;
                                                            }

                                                            // Create download url
                                                            let mutation = `
                                                                query GenerateFileDownloadURL{
                                                                   generate_file_download_url(
                                                                    file_id: "${attachment.id}"
                                                                    object_id: "${attachment.object_id}"
                                                                    object_type: ${attachment.object_type}
                                                                  ){
                                                                    error{type, message}
                                                                    url
                                                                  }
                                                                }
                                                            `
                                                            props.showConfirmModal(
                                                                'info',
                                                                'Disclaimer',
                                                                'By downloading this file, you acknowledge and agree that the content is provided \'as is\' without any guarantees.',
                                                                'Download',
                                                                () => { // On confirm
                                                                    customGraphRequest(
                                                                        mutation,
                                                                        (data) => {
                                                                            props.showToastNotificationModal('success', 'URL Generated', 'The download will start shortly');

                                                                            // Create a new anchor element
                                                                            const anchor = document.createElement('a');

                                                                            // Set the href attribute to the download URL
                                                                            anchor.href = data.url;

                                                                            // Trigger a click event on the anchor element
                                                                            anchor.click();

                                                                            // Cleanup: remove the anchor element from the document
                                                                            anchor.remove();
                                                                        },
                                                                        props.onError
                                                                    )
                                                                }
                                                            )

                                                        }}

                                                        renderOption={(attachment) =>
                                                            <div className='flex gap-2'>
                                                                <p>{attachment.file_name}</p>
                                                            </div>
                                                        }

                                                        /* TODO REMOVE THIS use {active}*/
                                                        renderSelected={(attachment) =>
                                                            attachment ?
                                                                <div className='flex gap-2'>
                                                                    <p>{attachment.file_name}</p>
                                                                </div> : 'Attachments'
                                                        }
                                                    />
                                                </Tooltip>
                                        }


                                        <div className='flex'>

                                            {/* DELIVERY FEE CHECKBOX */}
                                            <div className='flex gap-4'>
                                                <Tooltip content='Include the delivery fee in all prices, in all items'>
                                                    <button
                                                        className={classNames(
                                                            includeDeliveryFee ? 'btn rounded-none shadow-none shadow-inner' : 'btn-light',
                                                        )}
                                                        onClick={() => setIncludeDeliveryFee(!includeDeliveryFee)}
                                                    >
                                                        {includeDeliveryFee ?
                                                            <div className='flex'>
                                                                <CheckIcon className='h-5 w-5 mr-2'/>
                                                                Delivery included
                                                            </div>
                                                            :
                                                            <div className='flex'>
                                                                <TruckIcon className='h-5 w-5 mr-2'/>
                                                                Delivery excluded
                                                            </div>
                                                        }
                                                    </button>
                                                </Tooltip>
                                            </div>

                                            {/* PRICE CLASS */}
                                            <Tooltip content='Select the level for all items'>
                                                <div className='flex'>
                                                    <button className='btn-light'
                                                            onClick={() => {
                                                                // Set the selected_quote to entry for all items
                                                                let newItems = props.desktopAssessment.map(item => {
                                                                    item.selected_quote = item.desktop_assessment.entry;
                                                                    return item;
                                                                })
                                                                props.setDesktopAssessment([...newItems]);
                                                            }}
                                                    >
                                                        Entry
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => {
                                                                // Set the selected_quote to mid for all items
                                                                let newItems = props.desktopAssessment.map(item => {
                                                                    item.selected_quote = item.desktop_assessment.mid;
                                                                    return item;
                                                                })
                                                                props.setDesktopAssessment([...newItems]);
                                                            }}
                                                    >
                                                        Mid
                                                    </button>
                                                    <button className='btn-light'
                                                            onClick={() => {
                                                                // Set the selected_quote to high for all items
                                                                let newItems = props.desktopAssessment.map(item => {
                                                                    item.selected_quote = item.desktop_assessment.high;
                                                                    return item;
                                                                })
                                                                props.setDesktopAssessment([...newItems]);
                                                            }}
                                                    >
                                                        High
                                                    </button>
                                                </div>
                                            </Tooltip>

                                        </div>

                                    </div>

                                    <div className="divider_CRUD"/>

                                    <table>

                                        <thead>
                                        <tr>
                                            <th>
                                                Item
                                            </th>
                                            <th>
                                                Quotes
                                            </th>
                                            <th>
                                                Preview
                                            </th>
                                            <th>
                                                Assessed
                                            </th>
                                            <th></th>
                                        </tr>
                                        </thead>

                                        {props.desktopAssessment.map(item => <Safe>{renderItem(item)}</Safe>)}

                                    </table>

                                    <div className="divider_CRUD"/>

                                    {/* FOOTER */}
                                    <div className='flex gap-4 mt-8 mb-4 w-full justify-end'>

                                        <Tooltip content={<>
                                            <p>Temporarily hide this desktop assessment</p>
                                            <p>You may re-open it via the <b>Desktop Assess</b> button</p>
                                        </>}>
                                            <button
                                                className="btn-outline-light"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                Minimize
                                            </button>
                                        </Tooltip>

                                        <ApiButton
                                            onClick={(buttonHandler) => props.showConfirmModal(
                                                'success',
                                                'Submit Desktop Assessment',
                                                'Are you sure you would like to submit the desktop assessment?',
                                                'Submit',
                                                () => onSubmitAssessment(buttonHandler),
                                                () => buttonHandler.onError('Cancelled')
                                            )}
                                            className={'btn'}
                                            content='Assess'
                                            loadingContent={<LoadingSpinner color='darkcyan' size='6' body=''/>}
                                            successContent='Credentials accepted'
                                            errorContent='Incorrect username or password'
                                        />

                                    </div>

                                </div>
                            </div>

                        </div>


                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )

}

