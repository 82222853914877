/** Any page that is related to Claims, should fall under here, to get the benefit of the NavbarTop */
import {Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import ClaimItemSearch from './containers/claim/ClaimItemSearch';
import ClaimIssueAward from './containers/claim/ClaimIssueAward';
import ClaimIssueVoucher from './containers/claim/ClaimIssueVoucher';
import ClaimVoucherReturn from './containers/claim/ClaimVoucherReturn';
import ClaimCartReview from './containers/claim/ClaimCartReview';
import ClaimProfile from './containers/claim/ClaimProfile';
import ClaimTender from './containers/claim/ClaimTender';
import ClaimPostTenderInspection from './containers/claim/ClaimPostTenderInspection';
import ClaimVerification from './containers/claim/ClaimVerification';
import ClaimsHome from './containers/claim/ClaimsHome';
import Safe from "./components/Safe";


export default function ClaimPages(props){

    return (

        <div className="">

            <Safe> <ClaimPagesRoutes {...props} /> </Safe>

        </div>

    );

}

const ClaimPagesRoutes = (props) => {

    function RedirectToNewPath({ newPath }) {
        const params = useParams();
        const location = useLocation();

        // Extract dynamic segments and reconstruct the new URL
        let newUrl = newPath;
        Object.entries(params).forEach(([key, value]) => {
            newUrl = newUrl.replace(`:${key}`, value);
        });

        // Preserve search params if any (e.g., ?query=xyz)
        if (location.search) {
            newUrl += location.search;
        }

        return <Navigate to={newUrl} replace/>;
    }

    const access = props.access;

    return (
        <Routes>

            <Route path="/blank" element={access(<BlankPage {...props} />, 'ADMIN_UP')}/>

            <Route path="/" element={access(<ClaimsHome {...props} />, '')} exact/>
            <Route path="/home" element={access(<ClaimsHome {...props} />, '')} exact/>

            {/* ITEM SEARCH */}
            {/* TODO test this works well */}
            <Route path="/search/:claimId" element={access(<ClaimItemSearch {...props} mode="search"/>, 'INSURER_UP')}/>
            <Route path="/claim/item-search/:claimId" element={<RedirectToNewPath newPath="/search/:claimId" />} /> {/* Old route, redirect */}

            {/* ITEM SEARCH - SPECIAL FUNCTIONALITY */}
            <Route path="/claim/item-convert/:claimId/:itemId" element={access(<ClaimItemSearch {...props} mode="convert"/>, 'ADMIN_UP')}/>
            <Route path="/claim/item-add-replacement/:claimId/:itemId" element={access(<ClaimItemSearch {...props} mode="addReplacement"/>, 'SUPPLIER_UP')}/>
            <Route path="/claim/item-add-replacement/:claimId/:itemId/:supplierId" element={access(<ClaimItemSearch {...props} mode="addReplacement"/>, 'SUPPLIER_UP')}/>
            <Route path="/claim/item-link-to-task/:claimId" element={access(<ClaimItemSearch {...props} mode="linkToHaiTask"/>, 'ADMIN_UP')}/>

            {/* CLAIM CART REVIEW */}
            <Route path="/cart/:claimId" element={access(<ClaimCartReview {...props} />, 'INSURER_UP')}/>
            <Route path="/claim/cart-review/:claimId" element={<RedirectToNewPath newPath="/cart/:claimId" />} /> {/* Old route, redirect */}

            {/* TENDER */}
            <Route path="/tender/:claimId" element={access(<ClaimTender {...props} />, 'SUPPLIER_UP')}/>
            <Route path="/claim/tender/:claimId" element={<RedirectToNewPath newPath="/tender/:claimId" />} /> {/* Old route, redirect */}

            {/* POST TENDER */}
            <Route path="/post/:claimId" element={access(<ClaimPostTenderInspection {...props} />, 'INSURER_UP')}/>
            <Route path="/claim/post-tender/:claimId" element={<RedirectToNewPath newPath="/post/:claimId" />} /> {/* Old route, redirect */}

            {/* AWARD */}
            <Route path="/award/:claimId" element={access(<ClaimIssueAward {...props} />, 'SUPPLIER_UP')}/>
            <Route path="/claim/issue-award/:claimId" element={<RedirectToNewPath newPath="/award/:claimId" />} /> {/* Old route, redirect */}

            {/* VOUCHER */}
            <Route path="/voucher/:claimId" element={access(<ClaimIssueVoucher {...props} />, 'INSURER_UP')}/>
            <Route path="/claim/issue-voucher/:claimId" element={<RedirectToNewPath newPath="/voucher/:claimId" />} /> {/* Old route, redirect */}

            {/* SPECIAL VOUCHER RETURN PAGE */}
            <Route path="/special/suppliervoucherkeyreturn" element={access(<ClaimVoucherReturn {...props} />, '')}/>

            {/* CLAIM PROFILE */}
            <Route path="/profile/:claimId" element={access(<ClaimProfile {...props} />, 'SUPPLIER_UP')} />
            <Route path="/claim/quantification-review/:claimId" element={<RedirectToNewPath newPath="/profile/:claimId" />} />

            {/* VERIFICATION */}
            {/* TODO remove this page */}
            <Route path="/claim/verification/:claimId" element={access(<ClaimVerification {...props} />, 'INSURER_UP')}/>

        </Routes>
    );

};

const BlankPage = () => {
    return (
        <></>
    );
};