import React, {useEffect, useRef, useState} from "react";
import {classNames} from "../../utils/helpers";
import LoadingSpinner from "../ui/LoadingSpinner";
import Tooltip from "../Tooltip";

const ApiButton = (props) => {

    const content = props.content || props.children || ' ';

    const [successContent, setSuccessContent] = useState(props.successContent || 'Success');
    const [errorContent, setErrorContent] = useState(props.errorContent || 'Error');

    // 'idle', 'loading', 'success', 'error'
    const [status, setStatus] = useState('idle');

    useEffect(() => {
        if(props.defaultStatus) setStatus(props.defaultStatus);
    }, [props.defaultStatus]);

    const buttonHandler = {
        'onSuccess': (_content) => {
            if(_content) setSuccessContent(_content);
            setStatus('success');
        },
        'onError': (_content) => {
            if(_content) setErrorContent(_content);
            setStatus('error');
        },
        'reset': () => {
            setStatus('idle');
        },
        'onLoading': () => {
            setStatus('loading');
        }
    }

    function handleClick() {

        // Reset the button, if it's status is success or error
        if(status === 'success' || status === 'error' || status === 'loading') {
            setStatus('idle');
            return;
        }

        setStatus('loading');
        props.onClick(buttonHandler)
    }

    function renderContent(){

        if(status === 'loading') {
            if(props.loadingContent) return props.loadingContent;
            return (
                <Tooltip message="Request in progress...">
                    <LoadingSpinner color='darkcyan' size='5' body='' />
                </Tooltip>
            );
        }

        if(status === 'success' && props.successContent) {
            return (
                <span>{successContent}</span>
            );
        }

        if(status === 'error' && props.errorContent) {
            return (
                <span>{errorContent}</span>
            );
        }

        return <>{content}</>

    }

    const timeoutRef = useRef(null);
    function onHover() {
        /** When the button is hovered, reset the status
         * We use a short timeout for improved UX */

        if (status === 'success' || status === 'error') {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setStatus('idle');
                timeoutRef.current = null;
            }, 500);
        }
    }

    return (
        <button
            onClick={handleClick}
            ref={props.buttonRef}
            className={classNames(
                status === 'idle' && 'btn',
                status === 'success' && 'btn-outline-success',
                status === 'error' && 'btn-outline-danger',
                props.className || ''
            )}
            onMouseEnter={onHover}
        >
            {renderContent()}
        </button>
    );
};

export default ApiButton;
