// Library imports

import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Combobox, Dialog, Transition} from '@headlessui/react';

import {CheckIcon, ClipboardCopyIcon, XIcon} from '@heroicons/react/outline';

import {
    ArrowCircleLeftIcon,
    ArrowDownIcon,
    CheckCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/solid';


// Local imports
import {customGraphRequest} from '../../utils/coreApi.js';
import {classNames, currencySymbol, escapeDoubleQuotes, isEmpty} from '../../utils/helpers.js';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import Safe from "../../components/Safe";
import {MobileNumberInput} from "../../components/MobileNumberInput";
import {useDocumentTitle} from "../../components/PageTitle";
import FilterSelect from "../../components/base/FilterSelect";
import {BasicImage} from "../../components/base/BasicImage";
import ApiButton from "../../components/base/ApiButton";

export default function ClaimIssueVoucher(props) {

    const setPageTitle = useDocumentTitle("Post Tender");

    const navigate = useNavigate();
    const {claimId} = useParams();

    const [awardModalOpen, setAwardModalOpen] = useState(false);
    const [voucherItem, setVoucherItem] = useState(false);

    const [claim, setClaim] = useState(null);
    // const [currencySymbol, setCurrencySymbol] = useState(null);

    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [awardedValue, setAwardedValue] = useState(0);
    const [excess, setExcess] = useState(0);

    // On every page load
    useEffect(() => {

        if (claim === null) {
            props.updateGlobalClaim(claimId, setClaim);
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim})

        if (claim) setPageTitle(`Voucher : ${claim.insurer_claim_number}`)

    }, [claim]);

    useEffect(() => {
        fetchSuppliers();
    }, []);

    function fetchSuppliers() {
        /* Fetch a list of supplier organisations, for the voucher's supplier selection */

        if (!isEmpty(suppliers)) return;

        let query = `
            query Voucher_ClaimSuppliers{
              claim_suppliers(
                claim_id:"${claimId}"
                excluded_voucher_request_types: [PURCHASE_ORDER]
              ){
                error{type, message}
                organisations{
                  id
                  unique_reference_name
                  info{
                    name
                    country
                    currency_symbol
                    logo_image_url
                  }
                }
              }
            }
        `

        customGraphRequest(
            query,
            (data) => {
                setSuppliers(data.organisations);
            },
            props.onError
        )

    }

    // onClick functions
    const issueVoucher = () => {

        let mutation = `
            mutation Voucher_CreateVoucherItem{
              create_voucher_item(
                claim_id: "${claimId}"
                supplier_id: "${selectedSupplier.id}"
                description: ""

                awarded_value: ${awardedValue}
                excess_value: ${excess}

              ){
                error{type, message}
                voucher_item{
                  id
                  awarded_value
                  voucher_value
                  excess_value
                  claim{
                    id
                  }
                }
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                console.log('voucher item created', data)
                props.showToastNotificationModal(
                    'success',
                    'Voucher Created',
                    ''
                )

                setVoucherItem(data.voucher_item)
                setAwardModalOpen(true)
            },
            props.onError
        )

    };

    const onIssueVoucherClicked = (event) => {

        // Check that the supplier is selected
        if (isEmpty(selectedSupplier)) {
            props.showAlertModal(
                'info',
                'Invalid Supplier',
                'A supplier must be selected'
            )
            return;
        }

        // Check that the total value is positive
        let totalValue = voucherValue();

        if (totalValue === '0.00' || parseFloat(totalValue) < 0) {
            props.showAlertModal(
                'info',
                'Invalid Total Value',
                'The total value must be greater than zero'
            )
            return;
        }

        issueVoucher();

    };

    const supplierField = () => {

        if (claim === null) {
            return (<LoadingSpinner/>);
        }

        return (

            <div className="flex justify-between items-center">
                <label className="font-medium">
                    Supplier
                </label>
                <div className="relative">
                    <div
                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-700 sm:text-sm">{currencySymbol(claim)}</span>
                    </div>

                    <FilterSelect
                        selectableOptions={suppliers}
                        onChange={(selected) => setSelectedSupplier(selected)}

                        // Filter by the organisation name
                        filterOptions={(selectableOptions, query) =>
                            query === '' ?
                                selectableOptions
                                :
                                selectableOptions.filter((option) =>
                                    option.info.name
                                        .toLowerCase()
                                        .replace(/\s+/g, '') // remove all whitespace
                                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                                )}
                        renderMainInput={(selected, query, setQuery) => (
                            <div className="w-full relative">
                                <Combobox.Button as="div" className="flex w-full items-center">
                                    <div className="absolute left-2 flex items-center pr-2">
                                        {selected?.info?.logo_image_url &&
                                            <img
                                                src={selected?.info?.logo_image_url}
                                                className="h-8 w-8 rounded-full"
                                                alt="logo"
                                                onError={(e) => {
                                                    if (!e.target.dataset.retry) {
                                                        e.target.src = "/org-logo-placeholder.jpg";
                                                        e.target.dataset.retry = "true";
                                                    }
                                                }}
                                            />
                                        }
                                    </div>

                                    <Combobox.Input
                                        className="input pl-12 pr-10 w-full"
                                        displayValue={(selected) => selected ? selected.info?.name : '-'}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />

                                    <div className="absolute right-0 flex items-center pr-2">
                                        <ArrowDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Combobox.Button>
                            </div>

                        )}
                        renderOption={(option) =>
                            <Combobox.Option
                                key={option.id}
                                className='relative'
                                value={option}
                            >
                                {({selected, active}) => (
                                    <div className={classNames(
                                        'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                        selected ? 'font-bold' : 'font-normal', // Is the option selected
                                        active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                    )}>

                                        {/* SELECTED CHECK */}
                                        {selected &&
                                            <div className={classNames(
                                                'absolute inset-y-0 left-0 flex items-center pl-1',
                                                active ? 'text-white' : 'text-sky-600'
                                            )}>
                                                <CheckIcon className={`h-6 w-6`}/>
                                            </div>
                                        }

                                        {/* ORG LOGO */}
                                        <BasicImage
                                            src={option?.info?.logo_image_url}
                                            fallbackSrc={'/org-logo-placeholder.jpg'}
                                            alt="logo"
                                            sizeWidthRem="1.8"
                                            className="rounded-full"
                                        />

                                        {/* ORG NAME */}
                                        {option?.info.name}

                                    </div>
                                )}
                            </Combobox.Option>
                        }
                    />

                </div>
            </div>

        );

    };

    const renderInstructions = () => {

        return (
            <div className="page-header-bar widget mt-0 mx-10 w-fit rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div>
                            <p className="text-sm font-medium text-blue-700">
                                Complete the fields below to issue a voucher.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    function voucherValue() {
        let totalValue = parseFloat(awardedValue) - parseFloat(excess);
        return totalValue.toFixed(2);
    }

    const renderVoucherForm = () => {

        if (!claim) {
            return (
                <div className="flex flex-col justify-center p-12 w-1/2">
                    <LoadingSpinner/>
                </div>
            );
        }

        return (
            <div>
                <div className="flex flex justify-center w-full">
                    <div className="widget shadow-xl p-5 m-10 rounded-md">
                        <div className="space-y-8 p-5">

                            {supplierField()}

                            {/* VALUE */}
                            <div className="flex gap-[8rem] justify-between items-center">
                                <label className="font-medium">
                                    Claim value
                                </label>
                                <div className="relative">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-700">{currencySymbol(claim)}</span>
                                    </div>
                                    <input
                                        className="input pl-7"
                                        placeholder="0.00"
                                        disabled={!isEmpty(voucherItem)}
                                        value={awardedValue}
                                        onChange={(e) => {
                                            // Remove any leading zeros
                                            let value = e.target.value;
                                            value = value.replace(/^0+/, ''); // Removes leading zeros

                                            // Handle case where input is just "0"
                                            if (value === '') {
                                              value = '0';
                                            }

                                            setAwardedValue(value)
                                        }}
                                    />
                                </div>
                            </div>

                            {/* EXCESS */}
                            <div className="flex gap-[8rem] justify-between items-center">
                                <label className="font-medium">
                                    Less excess
                                </label>
                                <div className="relative">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-700">{currencySymbol(claim)}</span>
                                    </div>
                                    <input
                                        className="input pl-7"
                                        placeholder="0.00"
                                        disabled={!isEmpty(voucherItem)}
                                        value={excess}
                                        onChange={(e) => {
                                            // Remove any leading zeros
                                            let value = e.target.value;
                                            value = value.replace(/^0+/, ''); // Removes leading zeros

                                            // Handle case where input is just "0"
                                            if (value === '') {
                                              value = '0';
                                            }

                                            setExcess(value)
                                        }}
                                    />
                                </div>
                            </div>

                            {/* TOTAL */}
                            <div className="flex gap-[8rem] justify-start items-center">
                                <label className="font-medium">
                                    Voucher value
                                </label>
                                <div className="relative">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-700">{currencySymbol(claim)}</span>
                                    </div>
                                    <div id="totalValue" className="w-full pl-7 pr-12 sm:text-sm border-none">
                                        {voucherValue()}
                                    </div>
                                </div>
                            </div>

                            {/* BUTTONS */}
                            <div className="flex gap-12 pt-8">
                                <button
                                    type="button"
                                    id="backButton"
                                    onClick={() => navigate(`/profile/${claimId || ''}`)}
                                    className="btn w-full mx-0"
                                >
                                  <span className="ml-auto mr-auto">
                                    <ArrowCircleLeftIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                    Claim profile
                                  </span>
                                </button>

                                {/* Create voucher */}
                                {isEmpty(voucherItem) &&
                                    <button
                                        type="button"
                                        onClick={onIssueVoucherClicked}
                                        className="btn w-full mx-0"
                                    >
                                  <span className="ml-auto mr-auto">
                                    <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                    Issue Voucher
                                  </span>
                                    </button>
                                }

                                {/* Re-open the Award modal if a voucher has been created */}
                                {!isEmpty(voucherItem) &&
                                    <button
                                        type="button"
                                        onClick={() => setAwardModalOpen(true)}
                                        className="btn w-full mx-0"
                                    >
                                  <span className="ml-auto mr-auto">
                                    <ClipboardCopyIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                    Re-open Award Modal
                                  </span>
                                    </button>
                                }


                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };

    const allProps = {
        ...props,
        navigate,
        claimId,
        claim, setClaim,
        awardModalOpen, setAwardModalOpen,
        selectedSupplier, setSelectedSupplier,
        excess, setExcess,
        awardedValue, setAwardedValue,
        voucherItem, setVoucherItem,
    }

    return (
        <>
            <div className="">

                <AwardModal
                    open={awardModalOpen}
                    setOpen={setAwardModalOpen}
                    {...allProps}
                />

                <div className="flex flex-col flex-1">
                    <main className="flex-1 pb-8">

                        {/* HEADER */}
                        <div className="page-header-bar">
                            <h3 className="page-header-title">Issue Voucher</h3>
                            <div
                                onClick={() => navigate(`/profile/${claimId}`)}
                                className="tooltip-container btn py-2"
                            >
                                    <span className="">
                                      <ArrowCircleLeftIcon className="mr-2 inline h-5 w-5 align-center"
                                                           aria-hidden="true"/>
                                      Claim Profile
                                    </span>
                                <span style={{marginTop: '75px', marginLeft: '-15px', width: '200px'}}
                                      className="tooltip">Return to the claim quantification review</span>
                            </div>

                        </div>

                        {renderInstructions()}

                        {renderVoucherForm()}

                    </main>
                </div>

            </div>
        </>
    );
}

function AwardModal(props) {

    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobileCountryCode, setMobileCountryCode] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');

    function onIssueAward(buttonHandler) {

        if (!title || !lastname || !mobileCountryCode || !mobileNumber || !email) {
            props.showAlertModal(
                'info',
                'Incomplete Details',
                'Please complete all fields'
            )
            buttonHandler.onError('Incomplete Details')
            return
        }
        if (!email.includes('@') || !email.includes('.')) {
            props.showAlertModal(
                'info',
                'Invalid Email Address',
                'Please enter a valid email address'
            )
            buttonHandler.onError('Invalid Email Address')
            return
        }

        const mobile = mobileCountryCode + mobileNumber;

        let mutation = `
            mutation Voucher_Settlement{
              create_settlement(
                claim: "${props.claimId}"
                method: VOUCHER
                supplier: "${props.selectedSupplier.id}"
                note: "${escapeDoubleQuotes(description)}"
                settle: true
                
                title: "${title}"
                last_name: "${lastname}"
                phone_number: "${mobile}"
                email: "${email}"
                
                value: ${props.voucherItem.voucher_value}
                excess: ${props.voucherItem.excess_value}
                
                voucher_item_ids: ["${props.voucherItem.id}"]
              ) {
                error {
                  type
                  message
                }
                settlement {
                  id
                  country
                  status
                  settlement_engine_status
                  method
                  currency
                  value
                  excess
                }
              }
            }

        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showNotificationModal(
                    'success',
                    'Awarded',
                    'The award has successfully been awarded'
                )
                buttonHandler.onSuccess('Successfully issued voucher')

                setTimeout(() => {
                    props.navigate(`/profile/${props.claimId}`)
                }, 2000);

            },
            (error) => {
                props.onError(error)
                buttonHandler.onError('Failed to issue voucher')
            }
        )

    }

    const renderVoucher = () => {

        return (
            <tr key={props.voucherItem.id}>
                <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">
                    Claim value
                </td>
                <td className="px-3 py-4 text-right text-sm sm:table-cell">1</td>
                <td className="px-3 py-4 text-right text-sm sm:table-cell">{currencySymbol(props.claim)} {props.voucherItem.awarded_value}</td>
            </tr>
        );
    };

    if (!props.voucherItem) return (
        <></>
    );

    if (!props.claim) return (<></>);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay
                        className={classNames(
                            "transition absolute inset-0 ",
                            props.open ? "bg-gray-400/30" : "bg-gray-100/0"
                        )}
                        onClick={() => props.setOpen(false)}
                    />

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="">
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 modal-header sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    Award Voucher
                                                </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="btn-outline bg-transparent text-white p-1"
                                                        onClick={() => props.setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-cyan-100">
                                                    Create settlement for the voucher
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">

                                                <main className="flex-1 ">

                                                    {/* ITEMS */}
                                                    <div>

                                                        <div className="">
                                                            <div className="sm:flex sm:items-center">
                                                                <div className="sm:flex-auto">
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="my-5 shadow ">
                                                                <table
                                                                    className="">

                                                                    {/* HEADER */}
                                                                    <thead className="bg-gray-50">
                                                                    <tr>
                                                                        <th scope="col"
                                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                            Item
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                                            Qty
                                                                        </th>
                                                                        <th scope="col"
                                                                            className="px-3 pr-5 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                                            Total
                                                                        </th>
                                                                    </tr>
                                                                    </thead>

                                                                    {/* BODY */}
                                                                    <tbody
                                                                        className="divide-y divide-gray-200 bg-white">

                                                                    {/* VOUCHER ROW */}
                                                                    {renderVoucher()}

                                                                    {/* EXCESS ROW */}
                                                                    <tr>
                                                                        <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">
                                                                            Less excess
                                                                        </td>
                                                                        <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                        <td className="px-3 py-4 text-right text-sm sm:table-cell">
                                                                            {props.claim && props.claim.organisation.info.currency_symbol}
                                                                            {props.voucherItem.excess_value}</td>
                                                                    </tr>

                                                                    {/* INVOICE TOTAL ROW */}
                                                                    <tr>
                                                                        <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">
                                                                            Voucher value
                                                                        </td>
                                                                        <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                        <td className="px-3 py-4 text-right text-sm sm:table-cell">
                                                                            {props.claim && props.claim.organisation.info.currency_symbol} {props.voucherItem.awarded_value - props.voucherItem.excess_value}
                                                                        </td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>


                                                        <div
                                                            className="flex flex-col justify-center py-2 sm:px-6 lg:px-8">
                                                            <div className="divide-y divide-gray-200 ">

                                                                <div className="space-y-6 pt-6 pb-5">

                                                                    <h4 className="font-medium leading-tight text-2xl mt-0 mb-2 text-gray-500 ">
                                                                        Additional Information
                                                                    </h4>

                                                                    {/* PURCHASE ORDER */}
                                                                    <div
                                                                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                        <label htmlFor="firstName"
                                                                               className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                            Purchase order (optional)
                                                                        </label>
                                                                        <div
                                                                            className="mt-1 relative rounded-md shadow-sm">
                                                                            <input
                                                                                className="input"
                                                                                onChange={(e) => setDescription(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    {/* CLAIMANT DETAILS */}
                                                                    <h4 className="font-medium leading-tight text-2xl mt-0 mb-2 text-gray-500 pt-10 ">
                                                                        Claimant Details
                                                                    </h4>

                                                                    {/* FIRST NAME */}
                                                                    <div
                                                                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                        <label htmlFor="firstName"
                                                                               className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                            Title
                                                                        </label>
                                                                        <div
                                                                            className="mt-1 relative rounded-md shadow-sm">
                                                                            <input
                                                                                className="input"
                                                                                onChange={(e) => setTitle(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* LAST NAME */}
                                                                    <div
                                                                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                        <label htmlFor="lastName"
                                                                               className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                            Surname
                                                                        </label>
                                                                        <div
                                                                            className="mt-1 relative rounded-md shadow-sm">
                                                                            <input
                                                                                className="input"
                                                                                onChange={(e) => setLastname(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* MOBILE */}
                                                                    <div
                                                                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                        <label htmlFor="mobile"
                                                                               className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                            Mobile number
                                                                        </label>

                                                                        <div
                                                                            className="mt-1 relative rounded-md shadow-sm flex justify-between">

                                                                            <Safe>
                                                                                <MobileNumberInput
                                                                                    className='input w-[10rem] p-1 pr-4 m-0 mr-4'
                                                                                    claim={props.claim}
                                                                                    onChange={(e) => setMobileCountryCode(e.target.value)}
                                                                                />
                                                                            </Safe>

                                                                            <input
                                                                                className="input w-[10rem] m-0"
                                                                                value={mobileNumber}
                                                                                onChange={(e) => {
                                                                                    // remove any leading zeros
                                                                                    let mobile = e.target.value
                                                                                    if (mobile[0] === '0') {
                                                                                        mobile = mobile.substring(1);
                                                                                        props.showToastNotificationModal(
                                                                                            'info',
                                                                                            'Please do not start mobile numbers with a 0',
                                                                                            '',
                                                                                            5000
                                                                                        )
                                                                                    }
                                                                                    setMobileNumber(mobile)
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    </div>


                                                                    {/* EMAIL */}
                                                                    <div
                                                                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                        <label htmlFor="mobile"
                                                                               className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                            Email
                                                                        </label>
                                                                        <div
                                                                            className="mt-1 relative rounded-md shadow-sm">
                                                                            <input
                                                                                className="input"
                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">

                                                                        <ApiButton
                                                                            onClick={onIssueAward}
                                                                            className={'btn w-full mt-6 py-4'}
                                                                            loadingContent={<LoadingSpinner
                                                                                color='darkcyan' size='6'/>}
                                                                            successContent='Voucher Issued'
                                                                            errorContent='Failed to issue voucher'
                                                                        >
                                                                            <span
                                                                                className="ml-auto mr-auto">
                                                                                  <CheckCircleIcon
                                                                                      className="mr-1 inline h-5 w-5 align-top"
                                                                                      aria-hidden="true"/>
                                                                                  Issue Award
                                                                                </span>
                                                                        </ApiButton>

                                                                    </div>
                                                                    <div className="flex justify-end">

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </main>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 flex justify-start">
                                        <button
                                            type="button"
                                            className="btn-light"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <XIcon className="h-5 w-5 mr-2"/>
                                            Close
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

