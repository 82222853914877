import React from 'react';
import {ClipboardCopyIcon} from "@heroicons/react/outline";
import Tooltip from "../components/Tooltip";

const SLVRCLD_UI_Library = () => {

    // ================== HELPER FUNCTIONS ==================
    function listTailwindColorPalette(role, colorName){

        const range = [100, 200, 300, 400, 500, 600, 700, 800, 900];
        const palette = 'h-12 w-fit flex items-center p-4';

        // Copy to clipboard
        const click = (e) => {
            navigator.clipboard.writeText(`bg-${colorName}-${e.target.getAttribute('colorValue')}`);
            window.alert(`Copied bg-${colorName}-${e.target.getAttribute('colorValue')} to clipboard`);
        };

        return (
            <div className="flex flex-col justify-center text-center">
                <p className={palette}>{role}</p>
                {range.map((value) =>
                    <div className={`bg-${colorName}-${value} ` + palette} onClick={click} colorValue={value}>{colorName}-{value}</div>
                )}
            </div>
        );
    }

    // Square box used for displaying shadow and rounded
    const squareBox = 'bg-white-100 border border-[1px] border-gray-200 flex items-center justify-center rounded-lg w-[8rem] h-[8rem] ';

    return (
        <div className="p-8 space-y-12 text-gray-800">

            <header className="mb-[4rem] w-full text-center">
                <p className="text-[4rem] font-light text-gray-700">SLVRCLD UI Library</p>
                <p className="text-sm mt-12 text-gray-500">A overview and reference of the default UI for PORTAL</p>
                <p className="text-sm mt-2 text-gray-500"><i>CSS in SLVRCLD_UI_Library.css</i></p>
            </header>


            <PageHeaderBar
                pageTitle="Components"
                pageDescription="Reusable UI react components. e.g: <Tooltip>..."
            />

            <Card title="Card">
                <CardBody>
                    <p>This is a base card that all interactive elements should be wrapped around</p>
                </CardBody>
            </Card>

            <Card title="Pages">
                <CardBody>
                    <div className='flex justify-around'>

                        <a className={squareBox + 'rounded-xl'} href='/blank/' target={'_blank'} rel="noreferrer">
                            blank
                        </a>
                        <a className={squareBox + 'rounded-xl'} href='/dev/' target={'_blank'} rel="noreferrer">
                            dev
                        </a>
                        <a className={squareBox + 'rounded-xl'} href='/ratelimited/' target={'_blank'} rel="noreferrer">
                            ratelimited
                        </a>



                    </div>
                </CardBody>
            </Card>


            <Card title="Tooltips">
                <CardBody>

                    <div className='flex gap-4 p-10'>

                        <Tooltip position="left" message="Tooltip message">
                            <button className="btn">Tooltip left</button>
                        </Tooltip>

                        <Tooltip position="top" message="Tooltip message">
                            <button className="btn">Tooltip top</button>
                        </Tooltip>

                        <Tooltip position="bottom" message="Tooltip message">
                            <button className="btn">Tooltip bottom</button>
                        </Tooltip>

                        <Tooltip position="right" message="Tooltip message">
                            <button className="btn">Tooltip right</button>
                        </Tooltip>

                        <div className='w-10'></div>

                        <Tooltip message="Word">
                            <button className="btn">1 word</button>
                        </Tooltip>

                        <Tooltip message="3 tooltip words">
                            <button className="btn">3 words</button>
                        </Tooltip>

                    </div>

                    <div className='flex gap-4 p-10'>
                        <Tooltip position="left"
                                 message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                            <button className="btn">Tooltip left long</button>
                        </Tooltip>

                        <Tooltip position="top"
                                 message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                            <button className="btn">Tooltip top (long) long</button>
                        </Tooltip>

                        <Tooltip position="bottom"
                                 message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                            <button className="btn">Tooltip bottom long</button>
                        </Tooltip>

                        <Tooltip position="right"
                                 message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                            <button className="btn">Tooltip right long</button>
                        </Tooltip>

                    </div>

                    <div className='flex gap-4 p-10'>

                        <Tooltip position="left"
                                 message={
                            <div>
                                <p>Tooltip JSX</p>
                                <b>Tooltip JSX</b>
                                <i>Tooltip JSX</i>
                                <br/>
                                Tooltip JSX
                            </div>
                        }>
                            <button className="btn">Tooltip JSX</button>
                        </Tooltip>

                        <Tooltip position="top"
                                 message={
                            <div>
                                <p>Tooltip JSX</p>
                                <b>Tooltip JSX</b>
                                <i>Tooltip JSX</i>
                                <br/>
                                Tooltip JSX
                            </div>
                        }>
                            <button className="btn">Tooltip JSX</button>
                        </Tooltip>

                    </div>

                </CardBody>
            </Card>

            <Card title="Loading Spinners">
                <CardBody>

                </CardBody>
            </Card>

            <Card title="Modals">
                <CardBody>

                </CardBody>
            </Card>


            {/* ============================================================ */}

            {/* Gap */}
            <div className='py-8 w-full'>
                <hr/>
            </div>

            <PageHeaderBar
                pageTitle="Classes"
                pageDescription={
                    <span>A showcase of all classes e.g: <i>"shadow-lg"</i> and elements e.g: {'<p></p>'} tag</span>}
            />

            <Card title="Color palette">
                <CardBody>
                    <div className='relative flex justify-around'>
                    <div className='absolute top-1 left-1 text-gray-400'>
                            <p><i>Click to copy</i></p>
                        </div>
                        {listTailwindColorPalette('Primary', 'sky')}
                        {listTailwindColorPalette('Secondary', 'purple')}
                        {listTailwindColorPalette('Success', 'green')}
                        {listTailwindColorPalette('Danger', 'red')}
                        {listTailwindColorPalette('Warning', 'yellow')}
                        {listTailwindColorPalette('Light', 'stone')}
                        {listTailwindColorPalette('Dark', 'slate')}
                    </div>
                </CardBody>
            </Card>

            <Card title="Buttons">
                <CardBody>

                    {/* BASIC */}
                    <h3 className='pt-6 pb-2'>Basic</h3>
                    <div className='flex gap-4 p-10'>
                        <button className='btn-default'>Default</button>
                        <button className='btn-primary btn'>Primary</button>
                        <button className='btn-light'>Light</button>
                        <button className='btn-success'>Success</button>
                        <button className='btn-danger'>Danger</button>
                        <button className='btn-disabled' disabled={true}>Disabled</button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the foreground</i>
                        </p>
                    </div>

                    <div className='flex gap-4 p-10 body-background'>
                        <button className='btn-default'>Default</button>
                        <button className='btn-primary btn'>Primary</button>
                        <button className='btn-light'>Light</button>
                        <button className='btn-success'>Success</button>
                        <button className='btn-danger'>Danger</button>
                        <button className='btn-disabled' disabled={true}>Disabled</button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the background</i>
                        </p>
                    </div>

                    <div className='flex gap-4 p-10'>
                        <Tooltip content='Unset/clear all styles applied by any of the button styles (it should be nothing but plain text)'>
                            <button className='btn-primary btn-outline btn-raised btn-icon !btn-unset'>btn-unset</button>
                        </Tooltip>
                    </div>

                    {/* OUTLINE */}
                    <h3 className='pt-6 pb-2'>Outline</h3>
                    <div className='flex gap-4 p-10'>
                        <button className='btn-outline-default'>Default</button>
                        <button className='btn-outline-primary btn-outline'>Primary</button>
                        <button className='btn-outline-light'>Light</button>
                        <button className='btn-outline-success'>Success</button>
                        <button className='btn-outline-danger'>Danger</button>
                        <button className='btn-outline' disabled={true}>Disabled</button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the foreground</i>
                        </p>
                    </div>
                    <div className='flex gap-4 p-10 body-background'>
                        <button className='btn-outline-default'>Default</button>
                        <button className='btn-outline-primary btn-outline'>Primary</button>
                        <button className='btn-outline-light'>Light</button>
                        <button className='btn-outline-success'>Success</button>
                        <button className='btn-outline-danger'>Danger</button>
                        <button className='btn-outline' disabled={true}>Disabled</button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the background</i>
                        </p>
                    </div>

                    {/* RAISED */}
                    <h3 className='pt-6 pb-2'>Raised</h3>
                    <div className='flex gap-4 p-10 body-background'>
                        <button className='btn-raised-default'>Default</button>
                        <button className='btn-raised-primary btn-raised'><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <button className='btn-raised-light'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-success'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-danger'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the foreground</i>
                        </p>
                    </div>

                    <div className='flex gap-4 p-10'>
                        <button className='btn-raised-default'>Default</button>
                        <button className='btn-raised-primary btn-raised'><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <button className='btn-raised-light'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-success'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-danger'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-raised-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the background</i>
                        </p>
                    </div>


                    {/* ICON */}
                    <h3 className='pt-6 pb-2'>Icon</h3>
                    <div className='flex gap-4 p-10 body-background'>
                        <button className='btn-icon-default'>Default</button>
                        <button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the foreground</i>
                        </p>
                    </div>

                    <div className='flex gap-4 p-10'>
                        <button className='btn-icon-default'>Default</button>
                        <button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button>
                        <button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/>
                        </button>
                        <p className='text-sm text-gray-300 flex items-center px-4'>
                            <i>Buttons against the background</i>
                        </p>
                    </div>

                    <div className='pl-10'>

                        <table className='table w-1/2'>
                            <thead>
                            <tr>
                                <th>Default</th>
                                <th>Primary</th>
                                <th>Light</th>
                                <th>Success</th>
                                <th>Danger</th>
                                <th>Disabled</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><button className='btn-icon-default'>Default</button></td>
                                <td><button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                            </tr>
                            <tr className='bg-gray-100'>
                                <td><button className='btn-icon-default'>Default</button></td>
                                <td><button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                            </tr>
                            <tr className='bg-green-100'>
                                <td><button className='btn-icon-default'>Default</button></td>
                                <td><button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                            </tr>
                            <tr className='bg-red-100'>
                                <td><button className='btn-icon-default'>Default</button></td>
                                <td><button className='btn-icon-primary btn-icon'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-light'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-success'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-danger'><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                                <td><button className='btn-icon-disabled' disabled={true}><ClipboardCopyIcon className='w-4 h-4'/></button></td>
                            </tr>

                            </tbody>
                        </table>

                    </div>


                </CardBody>
            </Card>

            <Card title="Inputs">
                <CardBody>
                    <input className='input'/>
                </CardBody>
            </Card>

            <Card title="Checkbox">
                <CardBody>
                    <input className='checkbox' type='checkbox'/>
                </CardBody>
            </Card>

            <Card title="Select">
                <CardBody>
                    <select className='select'>
                        <option className='option'>Option 1</option>
                        <option className='option'>Option 2</option>
                        <option className='option'>Option 3</option>
                    </select>
                </CardBody>
            </Card>

            <Card title="Textarea">
                <CardBody>
                    <textarea className='textarea'/>
                </CardBody>
            </Card>

            <Card title="Table">
                <CardBody>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Header 1</th>
                                <th>Header 2</th>
                                <th>Header 3</th>
                                <th>Header 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Row 1</td>
                                <td>Row 2</td>
                                <td>Row 3</td>
                                <td>Row 4</td>
                            </tr>
                            <tr>
                                <td>Row 1</td>
                                <td>Row 2</td>
                                <td>Row 3</td>
                                <td>Row 4</td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            <Card title="Typography">
                <CardBody>
                    <div className='flex justify-around'>
                        <div>
                            <h1 className='text-4xl'>Tags</h1>
                            <h1 className='text-4xl'>h1 - Heading 1</h1>
                            <h2 className='text-3xl'>h2 - Heading 2</h2>
                            <h3 className='text-2xl'>h3 - Heading 3</h3>
                            <h4 className='text-xl'>h4 - Heading 4</h4>
                            <h5 className='text-lg'>h5 - Heading 5</h5>
                            <h6 className='text-base'>h6 - Heading 6</h6>
                            <p className='text-base'>p - Paragraph</p>
                            <b className='text-base'>b - Bold</b>
                            <br/>
                            <i className='text-base'>i - Italic</i>
                        </div>

                        <div>
                            <h1 className='text-4xl'>Tailwind</h1>
                            <div className='text-4xl'>text-4xl</div>
                            <div className='text-3xl'>text-3xl</div>
                            <div className='text-2xl'>text-2xl</div>
                            <div className='text-xl'>text-xl</div>
                            <div className='text-lg'>text-lg</div>
                            <div className='text-base'>text-base</div>
                            <div className='text-sm'>text-sm</div>
                            <div className='text-xs'>text-xs</div>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card title="Shadows">
                <CardBody>
                    <div className='flex justify-around'>
                        <div className={squareBox + 'shadow-xl'}>shadow-xl</div>
                        <div className={squareBox + 'shadow-lg'}>shadow-lg</div>
                        <div className={squareBox + 'shadow-md'}>shadow-md</div>
                        <div className={squareBox + 'shadow-sm'}>shadow-sm</div>
                        <div className={squareBox + 'shadow-none'}>shadow-none</div>
                    </div>
                </CardBody>
            </Card>

            <Card title="Shadow Styles">
                <CardBody className='flex flex-col gap-8'>
                    <div className='flex justify-around text-center'>
                        <div className={squareBox + 'shadow-xl-primary'}>shadow-xl-primary</div>
                        <div className={squareBox + 'shadow-lg-primary'}>shadow-lg-primary</div>
                        <div className={squareBox + 'shadow-md-primary'}>shadow-md-primary</div>
                        <div className={squareBox + 'shadow-sm-primary'}>shadow-sm-primary</div>
                        <div className={squareBox + 'shadow-none-primary'}>shadow-none-primary</div>
                    </div>
                    <div className='flex justify-around text-center'>
                        <div className={squareBox + 'shadow-xl-success'}>shadow-xl-success</div>
                        <div className={squareBox + 'shadow-lg-success'}>shadow-lg-success</div>
                        <div className={squareBox + 'shadow-md-success'}>shadow-md-success</div>
                        <div className={squareBox + 'shadow-sm-success'}>shadow-sm-success</div>
                        <div className={squareBox + 'shadow-none-success'}>shadow-none-success</div>
                    </div>
                    <div className='flex justify-around text-center'>
                        <div className={squareBox + 'shadow-xl-danger'}>shadow-xl-danger</div>
                        <div className={squareBox + 'shadow-lg-danger'}>shadow-lg-danger</div>
                        <div className={squareBox + 'shadow-md-danger'}>shadow-md-danger</div>
                        <div className={squareBox + 'shadow-sm-danger'}>shadow-sm-danger</div>
                        <div className={squareBox + 'shadow-none-danger'}>shadow-none-danger</div>
                    </div>
                </CardBody>
            </Card>

            <Card title="Rounded">
                <CardBody>
                    <div className='flex justify-around'>

                        <div className={squareBox + 'rounded-l-3xl'}>rounded-l-3xl</div>
                        <div className={squareBox + 'rounded-xl'}>rounded-xl</div>
                        <div className={squareBox + 'rounded-lg'}>rounded-lg</div>
                        <div className={squareBox + 'rounded-md'}>rounded-md</div>
                        <div className={squareBox + 'rounded-sm'}>rounded-sm</div>
                        <div className={squareBox + 'rounded-none'}>rounded-none</div>

                    </div>
                </CardBody>
            </Card>


            {/*    Alerts/banners */}
            {/*

            Look at Flowbite for many great examples
            https://flowbite.com/docs/components/

            Creative buttons.
            Consider using the [button] -> [-->] button for navigation buttons e.g "Claim Profile" "Tender" buttons
            https://devdojo.com/tailwindcss/buttons#_

            DONE
            Bootstrap stack -> primary, secondary, success, danger, warning
            , info, light
            buttons
                bootstrap stack
            icon buttons
                bootstrap stack
            h1 - h6
            p

            dividers
            api button
            input - text, password, textarea, number, email, dropdown
            country selector
            mobile number input with country code
            Currency selector function from claim
            radios & checkboxes
            checkbox button
            cards

            tables
            * every reusable component should be used here
            forms, label + input
            later - validation form
            Every type of Modal - base modals, global modal, side modal
            simple dropdown
            filter dropdown
            multi select dropdown
            date picker


            MISCELLANEOUS
            grid examples
                3 column grid
            flex examples
                flex row, with bases, with grow, with shrink, with grow and shrink
                flex column




         */}

        </div>
    );
};


// ================== EXPORT COMPONENTS ==================

export function PageHeaderBar({pageTitle, pageDescription}) {
    return (
        <header className="mt-2 mb-8 ml-8 space-y-4">
            <h1 className="text-4xl font-light text-gray-700">{pageTitle}</h1>
            <p className="text-sm text-gray-500">{pageDescription}</p>
        </header>
    );
}

export function Card({title, children}){
    return (
        <section className='flex flex-col gap-4'>
            <h2 className="font-light text-3xl">{title}</h2>
            {children}
        </section>
    );
}

export function CardBody({className, children}) {
    return (
        <div className={`bg-white rounded-lg shadow-md p-4 m-2 ${className}`}>
            {children}
        </div>
    );
}

export default SLVRCLD_UI_Library;
