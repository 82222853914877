// Library imports
import {
    BanIcon,
    CheckIcon,
    MenuAlt2Icon,
    NewspaperIcon,
    PencilAltIcon,
    PlusIcon,
    SearchIcon,
    TrashIcon,
    MailIcon,
    RefreshIcon,
    DownloadIcon,
    CashIcon,
    TruckIcon,
    ExternalLinkIcon,
    ClipboardListIcon,
    ClipboardCheckIcon,
    XIcon,
    DocumentSearchIcon,
    ExclamationIcon,
    ClipboardCopyIcon,
    CollectionIcon,
} from '@heroicons/react/outline';
import {
    ChevronDownIcon,
    ChevronRightIcon,
    SparklesIcon,
} from '@heroicons/react/solid';
import moment from 'moment-timezone';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Safe from '../../components/Safe';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import {
    customGraphRequest,
    generateAiProto,
    generateReportDownloadUrl,
    queryClaimComment,
    querySettlements,
    snoozeClaim,
    updateItemClaimantQuote,
    uploadFile,
    verifyAllManualItems,
    verifyManualItem,
} from '../../utils/coreApi.js';
import {
    alertError,
    calculateProperties,
    classNames,
    currencySymbol,
    escapeDoubleQuotes,
    formatAsTitle,
    isEmpty,
    sortClaimItems,
} from '../../utils/helpers.js';
import useInterval from '../../utils/useInterval';


// Local imports
import AlertModal from '../modals/AlertModal.jsx';
import MultiProductModal from '../modals/MultiProductModal';
import * as coreApi from '../../utils/coreApi';
import {useDocumentTitle} from '../../components/PageTitle';
import {BasicImage} from '../../components/base/BasicImage';
import {Countdown_ClaimSLA} from './ClaimsHome';
import Tooltip from '../../components/Tooltip';
import {
    utcMomentInstanceFromLocal,
    localMomentInstanceFromUTC,
    HUMAN_READABLE_DATETIME_TIMEZONE,
    todayTomorrowYesterdayDatetimeFormat,
} from '../../utils/time';
import {Menu} from '@headlessui/react';
import {DropdownMenu} from '../../components/base/DropdownMenu';
import LimitedOrganisationAccess, {OrganisationType} from '../../components/LimitedOrganisationAccess';
import {ClaimAttachmentsButton} from '../modals/ClaimAttachments';
import {ImageZoom} from '../../components/base/ImageZoom';
import CurrencyInput from '../../components/base/CurrencyInput';
import {SimpleSelect} from '../../components/base/SimpleSelect';
import ApiButton from '../../components/base/ApiButton';
import UpdateManualItemModal from '../modals/UpdateManualItemModal';
import {onViewHistoryRecordsClicked} from '../../components/AuditTrail';

// Constants

const displayStatuses = {

    // Transition states

    'CALCULATION_IN_PROGRESS': 'Calculating',
    'VERIFICATION': 'Verification',
    'TENDERING': 'Tendering',
    'POST_TENDER_INSPECTION': 'Post tender inspection',

    // End states

    'CANNOT_BE_VERIFIED': 'Cannot be verified',
    'DELETED': 'Deleted',
    'EXPIRED': 'Expired',
    'QUANTIFIED': 'Quantified',
    'UNABLE_TO_TENDER': 'Unable to tender',

};

const statusSteps = {

    // 0
    'Calculating': 0,

    // 1
    'Tendering': 1,
    'Verification': 1,

    // 2
    'Post tender inspection': 2,

    // 3
    'Cannot be verified': 3,
    'Deleted': 3,
    'Expired': 3,
    'Quantified': 3,
    'Unable to tender': 3,

};


export default function ClaimProfile(props) {

    const setTitle = useDocumentTitle('Claim Profile');

    // React router
    const navigate = useNavigate();

    const {claimId} = useParams();

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '', 'message': '',
    });

    // UI states
    const [refreshInterval, setRefreshInterval] = useState(null);

    // Edit manual item modal states
    const [itemInEditManualItemModal, setItemInEditManualItemModal] = useState(null);
    const [showEditManualItem, setShowEditManualItem] = useState(false);

    // Data states
    const user = props.user;
    const [claim, setClaim] = useState(null);
    const [settlements, setSettlements] = useState(null);

    const [claimNote, setClaimNote] = useState(null);

    const [verifiedByUsers, setVerifiedByUsers] = useState([]);

    // Compare and Edit proto modal
    const [productInfoModalOpen, setProductInfoModalOpen] = useState(false);
    const [productInfoModalOptions, setProductInfoModalOptions] = useState({
        'product': null,
        'onProductAdded': null,
    });

    // On every page load
    useEffect(() => {

        if (claim === null) {
            // Immediately set the claim to the global claim if it is available
            if (props.globalClaim && props.globalClaim.id === claimId) {
                setClaim(props.globalClaim);
            }
            // Get the latest version of the claim
            getFullClaim();
        }

        if (settlements === null) {
            querySettlements(claimId, (data) => {
                setSettlements(data.settlements);
            }, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        }

        return () => {
            if (refreshInterval !== null) {
                clearInterval(refreshInterval);
                setRefreshInterval(null);
            }
        };


    }, []);

    useEffect(() => {

        if (claim && !claimNote) {
            queryClaimComment(claim.id, (data) => {
                if (data.comments[0]) {
                    const commentMessage = data.comments[0].message;
                    setClaimNote(commentMessage);
                } else {
                    setClaimNote('No notes found');
                }
            }, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        }

        if (claim) setTitle(`Claim : ${claim.insurer_claim_number}`);

    }, [claim]);

    useEffect(() => {
        /** ATTACHMENT MODAL
         * Set/Update the top navbar content when the claim is fetched/updated */

        if (!claim) return;

        props.setNavbarTopPageContent({
            'claim': claim,
            'content':
                <Safe>
                    <div className='flex w-full justify-end'>
                        <ClaimAttachmentsButton {...props} />
                    </div>
                </Safe>,
        });
    }, [claim, props.claimAttachmentsOptions]);
    useEffect(() => {
        /** ATTACHMENT MODAL
         * Set the Attachments modal options.
         * Due to state issues, the options needs to be set separately from updating the ClaimAttachmentsButton */

        if (!claim) return;
        const options = {
            'claim': claim,
        };
        props.setClaimAttachmentsOptions(options);
    }, [claim]);

    const [claimPollDelay, setClaimPollDelay] = useState(3000); // Initial delay set to 3000ms

    // null implies that the claim polling has not started yet. False means it has stopped
    const shouldPollForClaim = useRef(null);
    useInterval(() => {
        /** Polling for claim data every 3 seconds */

            // check if any items status is CALCULATION_IN_PROGRESS
        const calculationInProgress = claim?.items.some(item => item.status === 'CALCULATION_IN_PROGRESS');
        if (!calculationInProgress) setClaimPollDelay(5000);

        // If shouldPollForClaims is null, it means the interval has not started
        if (shouldPollForClaim.current === null) {

            shouldPollForClaim.current = true;

            // stop polling after 20 minutes
            setTimeout(() => shouldPollForClaim.current = false, (1000 * 60 * 20));
        }

        if (shouldPollForClaim) getClaim();
    }, claimPollDelay);

    useEffect(() => {
        /** Additional data to retrieve once the claim has been retrieved */

        if (!claim) return;

        getVerifiedByUsers();

    }, [claim]);

    // Functions
    function getVerifiedByUsers() {
        /** Fetch the users who have verified this claim */

        // Do not query if the verifiedByUsers has already been set
        if (verifiedByUsers.length) return;

        const userIdsToRetrieve = [];

        // for each item.product.metadata.verified_by, add it to the list of users to retrieve
        claim?.items.forEach(item => {
            if (item.product?.metadata?.verified_by) {
                userIdsToRetrieve.push(item.product.metadata.verified_by);
            }
        });

        if (!userIdsToRetrieve.length) return;

        // query the users details for each verified_by user
        userIdsToRetrieve.forEach(userId => {
            const query = `
                query VerifiedByUsers {
                  users(
                    id: "${userId}"
                    ){
                    users {
                      id
                      organisation {
                        id
                      }
                      info {
                        first_name
                        last_name
                        full_name
                      }
                    }
                  }
                }
            `;

            customGraphRequest(
                query,
                (data) => {
                    const user = data['users'][0];
                    if (user && user.info)
                        setVerifiedByUsers(state => {
                            // add the user to the list of verified by users
                            return [...state, user];
                        });
                },
                props.onError,
            );
        });
    }

    function getClaim() {
        /** Make a minimal query for the claim object,
         * if certain statuses have changed, the full claim will be queried */

        if (!shouldPollForClaim.current) return;

        if (claim?.status === 'QUANTIFIED') shouldPollForClaim.current = false;

        let minimalClaimsQuery = `
            query minClaims_pollStatus{
                claims(|placeholder|){
                error {
                  type
                  message
                }
            
                claims {
                  status
                  items{
                    id
                    status
                    desktop_assessment{
                        product_list{
                            price
                        }
                    }
                  }
                }
              }
            }
        `;
        const queryArgs = `id:"${claimId}"`;
        minimalClaimsQuery = minimalClaimsQuery.replace('|placeholder|', queryArgs);

        // get claim data
        customGraphRequest(
            minimalClaimsQuery,
            (data) => {

                const _polledClaim = data['claims'][0];

                if (claimStatusHasChanged(claim, _polledClaim)) {
                    console.log('claim status has changed, retrieving full claim');
                    getFullClaim();
                }

            },
            props.onError,
        );
    }

    function claimStatusHasChanged(oldClaim, newClaim) {
        /** Check if certain statuses have changed, if so, re-query the full claim object */

        if (!oldClaim || !newClaim) return false;

        let statusHasChanged = false;

        // Compare the status of the claim
        if (oldClaim.status !== newClaim.status) statusHasChanged = true;

        // Compare the status of the items
        oldClaim.items.forEach(item => {

            const newItem = newClaim.items.find(i => i.id === item.id);

            // Check status
            if (newItem && newItem.status !== item.status) statusHasChanged = true;

            // Check desktop assessment
            if (isEmpty(item.desktop_assessment)) { // item has no desktop assessment
                if (!isEmpty(newItem?.desktop_assessment)) statusHasChanged = true; // polled item has desktop assessment
            }

        });

        return statusHasChanged;
    }

    function getFullClaim() {
        /** Query the full Claim object (not the minimal claim, used in polling) */

        const query = `
            query {
              claims(
                id: "${claimId}"
              ) {
                error {
                  type
                  message
                }
                claims {
                  cc_recipients{
                    id,
                    email,
                    full_name,
                    first_name
                    last_name,
                  }
                  attachments{
                    id
                    object_type
                    content_type
                    date_created
                    date_updated
                    file_name
                    object_id
                    user{
                      id
                      username
                    }
                    url
                  }
                  total_cost
                  tat_minutes
                  cost_vat
                  claimant_report_s3_key
                  comments{
                    message
                  }
                  cost_subtotal
                  created_by {
                    id
                    info {
                      first_name
                      last_name
                      profile_image_url
                    }
                    status
                    status_reason
                    username
                  }
                  delivery_costs {
                    supplier_id
                    supplier_name
                    delivery_cost
                    is_winning_tender
                    is_adhoc
                  }
                  date_created
                  date_quantified
                  date_quantification_requested
                  date_sla_expiry
                  date_tender_expiry
                  location_text
                  id
                  insurer_claim_number
                  insurer_policy_number
                  organisation {
                    id
                    info {
                      name
                      country
                      currency
                      currency_symbol
                      logo_image_url
                    }
                    unique_reference_name
                  }
                  owned_by {
                    id
                    status
                    username
                    info {
                      first_name
                      last_name
                      profile_image_url
                    }
                  }
                  quantification_requested_by {
                    id
                    info {
                      first_name
                      last_name
                    }
                  }
                  postal_code
                  status
                  status_reason
                  
                  
                  items {
                    id
                    is_tender_item
                    claimant_quote
                    is_manual
                    status
                    quantity
                    status_reason
                    type
                    metadata{
                      claimed_amount
                      comment
                      date_purchased
                      incident_location
                      purchase_price
                      purchase_source
                      replacement_quote
                      attachments{
                        id
                        object_type
                        content_type
                        date_created
                        date_updated
                        file_name
                        object_id
                        user{
                          id
                          username
                        }
                        url
                      }
                    }
                    category {
                      id
                      category_a
                      display_name
                    }
                    
                    supplier_panel {
                      id
                      name
                      unique_reference_name
                      status
                      supplier {
                        id
                        info {
                          name
                          country
                          currency
                          currency_symbol
                          logo_image_url
                        }
                      }
                      recommended_replacement {
                        product {
                          id
            
                          brand
                          category
                          common_name
                          description
            
                          status
                          type
            
                          metadata
                          model_number
                          model_numbers
            
                          images {
                            main
                            thumbnail_web
                            thumbnail_print
                          }
                          
                        }
                        quote {
                          id
                          status
                          country
                          currency
                          currency_symbol
                          is_manual
                          price
                          brand
                          description
                          supplier {
                            id
                            unique_reference_name
                            display_name
                          }
                        }
                      }
                    }
                    
                    desktop_assessment{
                      selected_by
                      selected_supplier
                      
                      selected_product{
                        delivery_cost_extract
                        free_delivery
                        price
                        rating
                        reviews
                        source_url
                        thumbnail
                        supplier_name
                        brand
                        model_number
                        description
                        price_class
                      }
                      
                      product_list{
                        delivery_cost_extract
                        free_delivery
                        price
                        rating
                        reviews
                        source_url
                        thumbnail
                        supplier_name
                        brand
                        model_number
                        description
                        price_class
                      }
            
                      entry{
                        delivery_cost_extract
                        free_delivery
                        price
                        source_url
                        supplier_name
                        url_valid
                      }
                      mid{
                        delivery_cost_extract
                        free_delivery
                        price
                        source_url
                        supplier_name
                        url_valid
                      }
                      high{
                        delivery_cost_extract
                        free_delivery
                        price
                        source_url
                        supplier_name
                        url_valid
                      }
                      
                      product_list {
                        price
                      }
                    }
                    
                    possible_replacements {
                      quotes {
                        id
                        supplier {
                          unique_reference_name
                        }
                      }
                    }
                    product {
                      id
            
                      brand
                      category
                      common_name
                      description
            
                      status
                      type
            
                      metadata
                      model_number
                      model_numbers
                      
                      ai_generated
            
                      images {
                        main
                        thumbnail_web
                        thumbnail_print
                      }
                      
                    }
                    replacement {
                      product {
                        id
            
                        brand
                        category
                        common_name
                        description
            
                        status
                        type
            
                        metadata
                        model_number
                        model_numbers
            
                        images {
                          main
                          thumbnail_web
                          thumbnail_print
                        }
                        
                      }
                      quote {
                        id
                        status
                        country
                        currency
                        currency_symbol
                        price
                        brand
                        date_expiry
                        is_manual
                        description
                        match_accuracy
                        supplier {
                          id
                          unique_reference_name
                          display_name
                        }
                      }
                      note
                      date_selected
                      selected_by
                      is_system_selected
                    }
                  }
                }
              }
            }
        `;

        customGraphRequest(
            query,
            (data) => {
                let newClaim = data['claims'][0];
                newClaim = sortClaimItems(newClaim);

                if (isEmpty(data['claims'])) return props.showAlertModal('error', 'Failed to fetch claim', 'No claim data was returned');

                // Copy over any selected_quotes from the old claim
                newClaim.items = newClaim.items.map((item) => {
                    const oldItem = claim?.items.find(old => old.id === item.id);
                    return {
                        ...item,
                        selected_quote: oldItem?.selected_quote || item.selected_quote,
                    };
                });

                setClaim(newClaim);

                redirectIfDeleted(newClaim);
            },
            props.onError,
        );

    }

    function redirectIfDeleted(newClaim) {
        if (newClaim.status === 'DELETED') {
            props.showAlertModal(
                'error',
                'Claim deleted',
                'This claim has been deleted',
            );
        }
    }

    function currencyChange(cur = null) {
        if (cur === 0) {
            return 0;
        }
        if (cur) {
            return cur.toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else {
            return ' ';
        }
    }

    function showProductInfoModal(item) {


        const product1 = item.product;
        let product2 = item.replacement?.product || null;
        // let product2 = item.product;

        if (props.mainOrganisation?.type === 'SUPPLIER') {
            // the replacement is only shown if the item is quantified/post tender
            // if the claim is quantified, the replacement is shown
            // The supplier does not see the item.replacement
            // instead the supplier sees their own quoted replacement in item.supplier_panel[at the supplier].recommended_replacement
            // IF THEY HAVE - SHOW THAT QUOTE AS THE REPLACEMENT
            // IF THEY HAVE NOT - SHOW "declined to tender"
            const supplier = item.supplier_panel?.find(supplier => supplier.id === props.mainOrganisation.id) || null;
            const replacement = supplier?.recommended_replacement || null;
            if (replacement && replacement.product) {
                product2 = replacement.product;
            }
        }

        setProductInfoModalOptions({
            'product': product1,
            'product2': product2,
            // 'productId': product1.id,
            // 'productId2': product2?.id || null,
            'onProductAdded': null,
            'mode': 'view',
        });

        setProductInfoModalOpen(true);
    }

    const allProps = {
        ...props,
        navigate,
        claimId,
        alertOpen, setAlertOpen,
        alertOptions, setAlertOptions,
        refreshInterval, setRefreshInterval,
        user,
        getFullClaim,
        claim, setClaim,
        settlements, setSettlements,
        claimNote, setClaimNote,
        itemInEditManualItemModal, setItemInEditManualItemModal,
        showEditManualItem, setShowEditManualItem,
        verifiedByUsers, setVerifiedByUsers,
        shouldPollForClaim,
        productInfoModalOpen, setProductInfoModalOpen,
        productInfoModalOptions, setProductInfoModalOptions,
        showProductInfoModal,
        currencyChange,
    };

    // Page content
    return (<div className="">

        {/* TODO : change usage to base modal */}
        <AlertModal
            open={alertOpen}
            setOpen={setAlertOpen}
            options={alertOptions}
        />

        <div className="">

            <main className="max-w-full pb-24 px-8">

                <Safe> <Header {...allProps} /> </Safe>

                <Safe> <ClaimInfo {...allProps} /> </Safe>

                <Safe> <ClaimNote {...allProps} /> </Safe>

                <Safe> <Settlements {...allProps} /> </Safe>

                <Safe>
                    <UpdateManualItemModal
                        open={showEditManualItem}
                        setOpen={setShowEditManualItem}
                        item={itemInEditManualItemModal}
                        onSuccess={() => {
                            getFullClaim();
                            setShowEditManualItem(false);
                        }}
                        onError={props.onError}
                    />
                </Safe>

                <Safe>
                    <LimitedOrganisationAccess
                        types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR, OrganisationType.INSURER]}
                        mainOrganisation={props.mainOrganisation}
                    >
                        <ButtonSection {...allProps} />
                    </LimitedOrganisationAccess>
                </Safe>

                <Safe> <Items {...allProps} /> </Safe>

                <Safe>
                    <MultiProductModal
                        {...props}
                        open={productInfoModalOpen}
                        setOpen={setProductInfoModalOpen}
                        options={productInfoModalOptions}
                        setOptions={setProductInfoModalOptions}
                    />
                </Safe>

            </main>

        </div>

    </div>);
}

function Header(props) {

    const claim = props.claim;

    const generateAndDownloadReport = (event, type) => {

        // Prevent the default action of the event
        event.preventDefault();

        generateReportDownloadUrl(props.claimId, type, (data) => {
            // Create a new anchor element
            const anchor = document.createElement('a');

            // Set the href attribute to the download URL
            anchor.href = data.url;

            // Trigger a click event on the anchor element
            anchor.click();

            // Cleanup: remove the anchor element from the document
            anchor.remove();
        }, (error) => props.onError(error));
    };

    function resendQuantificationReport(recalculate = false) {

        const mutation = `
            mutation Regenerate_Resend_QuantificationReport{
              regenerate_quantification_report(
                claim_id: "${props.claim.id}"
                recalculate: ${recalculate}
                resend_report: true
              ){
                error {type, message}
                claim{
                  id
                }
              }
            }
        `;

        customGraphRequest(
            mutation,
            () => {
                props.showNotificationModal(
                    'success',
                    'Successfully requested',
                    '');
            },
            props.onError,
        );


    }

    if (!claim) return (
        <div className="page-header-bar flex items-baseline justify-between">
        </div>
    );

    return (
        <div className="page-header-bar flex items-baseline justify-between">

            {/* HEADER */}
            <h1 className="page-header-title">
                Claim Profile
            </h1>

            <div className='flex gap-4'>

                {/* VIEW HISTORY RECORDS */}
                <button
                    className='btn'
                    onClick={() => onViewHistoryRecordsClicked(props, `object_id: "${claim.id}", object_type: claims`)}
                >
                    <CollectionIcon className="h-5 w-5 mr-1"/>
                    Audit Trail
                </button>

                {/* DOWNLOAD REPORT */}
                <div className={'ROOT ADMINISTRATOR INSURER'.includes(props.mainOrganisation?.type) ? '' : 'hidden'}>

                    <DropdownMenu
                        optionGroups={[
                            [
                                <button className='btn-raised-light w-full justify-start'
                                        onClick={(e) => generateAndDownloadReport(e, 'QUANTIFICATION')}
                                >
                                    <DownloadIcon className="mr-1 h-5 w-5"/>
                                    Quantification Report
                                </button>,
                                <button className={classNames(
                                    'btn-raised-light w-full justify-start',
                                    !claim.claimant_report_s3_key ? 'btn-disabled !justify-start !w-full' : '',
                                )}
                                        disabled={!claim.claimant_report_s3_key}
                                        onClick={(e) => generateAndDownloadReport(e, 'CLAIMANT')}
                                >
                                    <DownloadIcon className="mr-1 h-5 w-5"/>
                                    Claimant Report
                                </button>,
                            ],

                            [
                                <LimitedOrganisationAccess
                                    types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                    mainOrganisation={props.mainOrganisation}
                                >
                                    <button className='btn-raised-light w-full justify-start'
                                            onClick={() => resendQuantificationReport(false)}
                                    >
                                        <MailIcon className="mr-1 h-5 w-5"/>
                                        Resend all reports
                                    </button>
                                </LimitedOrganisationAccess>,

                                <LimitedOrganisationAccess
                                    types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                    mainOrganisation={props.mainOrganisation}
                                >
                                    <Tooltip content={<>
                                        <p className='pb-2'>Recalculate all reports, and resend</p>
                                        <p className='italic text-xs'>This is a resources intensive process</p>
                                        <p className='italic text-xs'>only use when recalculation is required</p>
                                    </>}>
                                        <button className='btn-raised-light w-full justify-start'
                                                onClick={() => resendQuantificationReport(true)}
                                        >
                                            <RefreshIcon className="mr-1 h-5 w-5"/>
                                            Recalculate and resend
                                        </button>
                                    </Tooltip>
                                </LimitedOrganisationAccess>,
                            ],

                        ]}
                        mainButton={
                            <Menu.Button
                                className="btn">
                                <NewspaperIcon className="mr-1 h-5 w-5"/>
                                Download report
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                                />
                            </Menu.Button>
                        }

                        renderOption={(option) => option}
                    />
                </div>

            </div>


        </div>

    );
}

function ClaimInfo(props) {

    const [showSnoozeButtons, setShowSnoozeButtons] = useState(false);

    const claim = props.claim;

    const onSnoozeClaim = (snoozeAmount) => {

        let snoozeMinutes = '';
        if (snoozeAmount === '5m')
            snoozeMinutes = 'SNOOZE_5_MINUTES';
        else if (snoozeAmount === '30m')
            snoozeMinutes = 'SNOOZE_30_MINUTES';
        else if (snoozeAmount === '60m')
            snoozeMinutes = 'SNOOZE_60_MINUTES';
        else if (snoozeAmount === '4h')
            snoozeMinutes = 'SNOOZE_240_MINUTES';
        else
            snoozeMinutes = 'SNOOZE_5_MINUTES';

        snoozeClaim(
            claim.id,
            snoozeMinutes,
            (data) => {
                alert('Successfully snoozed claim');
                window.location.reload();
            }, (error) => {
                console.error('failed to snooze claim');
                console.error(error);
            },
        );
    };

    const allItemsQuantified = (inClaim = null) => {

        let claimToCheck = props.claim;

        if (inClaim !== null) {
            claimToCheck = inClaim;
        }

        if (claimToCheck === null) {
            return false;
        }

        for (const item of claimToCheck.items) {
            if (item.status !== 'QUANTIFIED') {
                return false;
            }
        }

        return true;
    };

    const claimAddress = () => {

        const addressParts = props.claim.location_text.split(',');

        return (<dd>
            {addressParts.map((part) => (
                <span key={part} className="block">{part}</span>
            ))}
        </dd>);
    };

    function reOpenClaim() {

        props.showConfirmModal(
            'success',
            'Reopen Claim',
            'Are you sure you want to reopen this claim? This will allow you to add additional items to the claim.',
            'Reopen',
            () => {

                const mutation = `
                    mutation ReOpenClaim {
                      reopen_claim(claim_id: "${props.claim.id}"){
                        error {type, message}
                        claim {
                          id
                        }
                      }
                    }
                `;

                customGraphRequest(
                    mutation,
                    (data) => {
                        props.showNotificationModal('success', 'Claim Reopened', '');

                        // Redirect to the item search page
                        props.navigate(`/search/${props.claimId}`);
                    },
                    props.onError,
                );

            },
            () => {
            },
        );


    }

    function CountdownClaimProfile({date_sla_expiry}) {
        const [time, setTime] = useState({text: '', minutes: 0});
        const [flicker, setFlicker] = useState(false);

        // Toggle flicker every second
        useEffect(() => {
            const timer = setInterval(() => {
                setFlicker(prev => !prev);
            }, 1000);
            return () => clearInterval(timer);
        }, []);

        // Recalculate time whenever flicker or date_sla_expiry changes
        useEffect(() => {
            const [display, totalMinutes] = calculateRemainingSLATime(date_sla_expiry);
            setTime({
                text: flicker ? display.replaceAll(':', ' ') : display,
                minutes: totalMinutes
            });
        }, [date_sla_expiry, flicker]);

        function calculateRemainingSLATime(date_sla_expiry) {
            const now = moment().tz(moment.tz.guess());
            const sla = localMomentInstanceFromUTC(date_sla_expiry);
            if (now.isAfter(sla)) return ['expired', 0];

            const diff = moment.duration(sla.diff(now));
            const days = Math.floor(diff.asDays());
            const hours = Math.floor(diff.asHours() % 24);
            const minutes = Math.floor(diff.asMinutes() % 60);
            const totalMinutes = Math.floor(diff.asMinutes());

            const display = `${days ? days + 'D ' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            return [display, totalMinutes];
        }

        return (
            <p className={time.minutes < 15 && time.minutes > 0 ? 'text-red-600' : ''}>
                {time.text}
            </p>
        );
    };


    const claimInfo = () => {

        if (!props.claim) {
            return (
                <div className='p-4 m-2'>
                    <LoadingSpinner/>
                </div>
            );
        }

        let requestedBy = null;

        if (props.claim.quantification_requested_by !== null) {
            const info = props.claim.quantification_requested_by.info;
            requestedBy = info.first_name + ' ' + info.last_name;
        }

        let tatDisplay = '-';

        if (props.claim.tat_minutes !== null) {
            const quotient = Math.floor(props.claim.tat_minutes / 60);
            const remainder = props.claim.tat_minutes % 60;
            tatDisplay = quotient.toString()
                .padStart(2, '0') + ':' + remainder.toString()
                .padStart(2, '0');
        }

        function ccEmails() {
            /** Render CC emails */

            // If there are no cc recipients or the user's org is not an  root/admin/insurer, return placeholder '-'
            if (!'ROOT ADMINISTRATOR INSURER'.includes(props.mainOrganisation?.type)) return <p>-</p>;

            const recipients = props.claim.cc_recipients;

            if (!recipients || recipients.length === 0) return <p>-</p>;

            return recipients.map((recipient) =>

                <div key={recipient.id}>
                    <div key={recipient.email}>
                        <p>{recipient.first_name} {recipient.last_name} </p>
                        <p>{recipient.email}</p>
                        <div className="my-2"></div>
                    </div>
                </div>,
            );
        }

        function showAddCCEmailModal() {

            function addCCEmail() {

                const ccFirstName = document.getElementById('ccFirstName').value;
                const ccLastName = document.getElementById('ccLastName').value;
                const ccEmail = document.getElementById('ccEmail').value;

                const mutation = `
                    mutation ClaimProfile_AddCCRecipient {
                      add_cc_recipients_to_claim(
                        claim_id: "${props.claim.id}",
                        cc_recipients: [
                          {
                            email: "${ccEmail}",
                            first_name: "${ccFirstName}",
                            last_name: "${ccLastName}",
                          }
                        ]
                      ){
                        error{type, message}
                        claim{
                          id
                        }
                      }
                    }
                `;

                customGraphRequest(
                    mutation,
                    (data) => {
                        props.showToastNotificationModal('success', 'CC email added', 'Reloading claim data...');
                        props.getFullClaim();
                        props.setCustomModalOpen(false);
                    },
                    props.onError,
                );

            }

            const content = (
                <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                    <h1 className='p-4 mb-4 font-light text-3xl'>Add CC Email Address</h1>

                    <div className='w-full border-b-2 border-gray-200 my-4'></div>

                    <div className="mb-4">
                        <div className="flex space-x-2 mb-2">
                            <input
                                type="text"
                                id='ccFirstName'
                                placeholder="First Name"
                                className="input flex-1"
                            />
                            <input
                                type="text"
                                id='ccLastName'
                                placeholder="Last Name"
                                className="input flex-1"
                            />
                        </div>
                        <input
                            type="email"
                            id='ccEmail'
                            placeholder="Email"
                            className="input w-full"
                        />
                    </div>

                    <div className="mt-5 mt-4 flex w-full justify-between">

                        <button
                            type="button"
                            className="btn-outline"
                            onClick={() => props.setCustomModalOpen(false)}
                        >
                            Close
                        </button>


                        <button
                            type="button"
                            className="btn"
                            onClick={addCCEmail}
                        >
                            <PlusIcon className="h-5 w-5 mr-2"/>
                            Add
                        </button>

                    </div>

                </div>
            );

            props.showCustomModal(content);

        }

        function showAddClaimCommentModal() {

            function addClaimComment() {

                const claimComment = document.getElementById('claimComment').value;

                const mutation = `
                    mutation create_comment {
                      create_claim_comment (
                        claim:"${props.claim.id}" 
                        message:"${escapeDoubleQuotes(claimComment)}"
                      ) {
                        comment {
                          message
                        }
                      }
                    }
                `;

                customGraphRequest(
                    mutation,
                    (data) => {
                        props.showToastNotificationModal('success', 'Claim Comment Added', 'Successfully added claim comment');
                        props.getFullClaim();
                        props.setCustomModalOpen(false);
                    },
                    props.onError,
                );

            }

            const content = (
                <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                    <h1 className='p-4 mb-4 font-light text-3xl'>Add Claim Comment</h1>

                    <div className='w-full border-b-2 border-gray-200 my-4'></div>

                    <textarea
                        id='claimComment'
                        placeholder="Claim comment"
                        className="input w-full my-6"
                    />

                    <div className="mt-5 mt-4 flex w-full justify-between">

                        <button
                            type="button"
                            className="btn-outline"
                            onClick={() => props.setCustomModalOpen(false)}
                        >
                            Close
                        </button>


                        <button
                            type="button"
                            className="btn"
                            onClick={addClaimComment}
                        >
                            <PlusIcon className="h-5 w-5 mr-2"/>
                            Add claim comment
                        </button>

                    </div>

                </div>
            );

            props.showCustomModal(content);

        }


        return (
            <section className="widget flex-col">
                <h2 id="summary-heading" className="font-medium ml-2 text-2xl">Claim Details</h2>
                <div className="rounded-lg flex justify-between px-[2.5rem] gap-[15rem]">
                    {/* Table 1 */}
                    <table className="w-1/3">
                        <tbody className="bg-white">
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900 w-1/2">Claim
                                number
                            </td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500 w-1/2">{props.claim.insurer_claim_number}</td>
                        </tr>
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Organisation</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">{props.claim.organisation.info.name}</td>
                        </tr>
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            {/* LOCATION */}
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Area</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">{claimAddress()}</td>
                        </tr>
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            {/* REQUESTED ON DATE AND TIME */}
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Requested</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">{props.claim.date_quantification_requested !== null ? localMomentInstanceFromUTC(props.claim.date_quantification_requested).format(HUMAN_READABLE_DATETIME_TIMEZONE) : '-'}</td>
                        </tr>

                        {claim.status !== 'QUANTIFIED' ?
                            // Status
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                <td className="whitespace-nowrap py-1 border-gray-50 text-sm font-bold text-gray-900">
                                    Status
                                </td>
                                <td className="whitespace-nowrap py-1 text-sm text-gray-500">
                                    {formatAsTitle(claim.status_reason)}
                                </td>
                            </tr>
                            :
                            // Closed
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Closed</td>
                                <td className="whitespace-nowrap py-1 text-sm text-gray-500">{props.claim.date_quantified !== null ? localMomentInstanceFromUTC(props.claim.date_quantified).format(HUMAN_READABLE_DATETIME_TIMEZONE) : '-'}</td>
                            </tr>
                        }

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            {/* COMMENTS */}
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900"
                                onClick={showAddClaimCommentModal}
                            >
                                <Tooltip
                                    content={'Click to add additional claim comments'}
                                >
                                    Comments +
                                </Tooltip>
                            </td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">
                                {claim.comments && claim.comments.length > 0 ? (
                                    claim.comments.map((comment, index) => (
                                        <div key={index}>
                                            <p>{comment.message}</p>
                                        </div>
                                    ))
                                ) : (
                                    <span>-</span>
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {/* Table 2 */}
                    <table className="w-1/3">
                        <tbody className="bg-white">
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900 w-1/2">Policy
                                number
                            </td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500 w-1/2">{props.claim.insurer_policy_number !== null ? props.claim.insurer_policy_number : '-'}</td>
                        </tr>
                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">User</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">{props.claim.owned_by?.info?.first_name || '-'} {props.claim.owned_by?.info?.last_name || ''}</td>
                        </tr>
                        <tr className='h-[3rem] align-bottom divide-transparent'>

                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Requested
                                by
                            </td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">{requestedBy !== null ? requestedBy : '-'}</td>
                        </tr>
                        <LimitedOrganisationAccess
                            types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR, OrganisationType.INSURER]}
                            mainOrganisation={props.mainOrganisation}
                        >
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                {/* ITEMS */}
                                <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900"
                                    onClick={reOpenClaim}
                                >
                                    <Tooltip content={<div>
                                        <p>The number of items in this claim</p>
                                        <hr/>
                                        <p>Click to re-open this claim and add additional items</p>
                                    </div>} className='max-w-[10rem]'>
                                        Items +
                                    </Tooltip>
                                </td>
                                <td className="whitespace-nowrap py-1 text-sm text-gray-500">
                                    {props.claim?.items?.length}
                                </td>
                            </tr>
                        </LimitedOrganisationAccess>

                        {claim.status !== 'QUANTIFIED' ?
                            // SLA
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                <td className="whitespace-nowrap py-1 border-gray-50 text-sm font-bold text-gray-900">
                                    SLA
                                </td>
                                <td className="whitespace-nowrap py-1 text-sm text-gray-500 flex items-end gap-4">

                                    {/* REMAINING TIME */}
                                    <Tooltip content='The remaining time of this claim'>
                                        <div className='min-w-[5rem]'>
                                            <CountdownClaimProfile date_sla_expiry={claim.date_sla_expiry}/>
                                        </div>
                                    </Tooltip>

                                    {/* SNOOZE */}
                                    <button className="btn-light px-2 flex gap-1 m-0 h-fit py-1"
                                            onClick={() => setShowSnoozeButtons(!showSnoozeButtons)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                        Snooze
                                    </button>

                                    {/* SNOOZE AMOUNTS */}
                                    {showSnoozeButtons &&
                                        <div>
                                            <button className="btn-light p-2 flex gap-1"
                                                    onClick={() => onSnoozeClaim('30m')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                                30 minutes
                                            </button>
                                            <button className="btn-light p-2 flex gap-1"
                                                    onClick={() => onSnoozeClaim('60m')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                                1 hour
                                            </button>
                                            <button className="btn-light p-2 flex gap-1"
                                                    onClick={() => onSnoozeClaim('4h')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                                4 hours
                                            </button>
                                        </div>
                                    }

                                </td>
                            </tr>
                            :

                            // TAT
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">
                                    Turnaround time (TAT)
                                </td>
                                <td className="whitespace-nowrap py-1 text-sm text-gray-500">{tatDisplay}</td>
                            </tr>
                        }

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900"
                                onClick={showAddCCEmailModal}
                            >
                                <Tooltip
                                    content={'Click to add additional CC email'}
                                >
                                    CC emails +
                                </Tooltip>
                            </td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-500">
                                {ccEmails()}
                            </td>
                        </tr>

                        </tbody>
                    </table>

                    {/* Table 3 */}
                    <table className="w-1/3">
                        <tbody className="bg-white">

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900 w-1/2">Subtotal</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-900 w-1/2">
                                {props.claim !== null && props.claim.cost_subtotal !== 0 ? (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.cost_subtotal) : '-'}
                            </td>
                        </tr>

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Shipping</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-900"></td>
                        </tr>

                        {props.claim.delivery_costs?.filter(dC => dC.is_winning_tender).map((deliveryCost, i) => (
                            <tr className='h-[3rem] align-bottom divide-transparent'>
                                <td className="whitespace-nowrap py-1 pl-10 pr-10 border-gray-50 text-sm text-gray-900">
                                    <span className="">{deliveryCost.supplier_name}</span></td>

                                <td className="whitespace-nowrap py-1 text-sm text-gray-900">
                                    <span>{currencySymbol(props.claim)}{props.currencyChange(deliveryCost.delivery_cost)}</span>
                                </td>
                            </tr>
                        ))}


                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Tax</td>
                            <td className="whitespace-nowrap py-1 text-sm text-gray-900">
                                {props.claim !== null && props.claim.cost_vat !== 0 ? (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.cost_vat) : '-'}
                            </td>
                        </tr>

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 pr-10 border-gray-50 text-sm font-bold text-gray-900">Total</td>
                            <td className="whitespace-nowrap py-1 text-sm text-purple-600">
                                {props.claim !== null && props.claim.total_cost !== 0 ?
                                    (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.total_cost)
                                    :
                                    '-'}
                            </td>
                        </tr>

                        <tr className='h-[3rem] align-bottom divide-transparent'>
                            <td className="whitespace-nowrap py-1 text-sm text-yellow-600" colSpan="2">
                                {props.claim !== null && !allItemsQuantified() && (<>
                                    <span className="font-medium text-yellow-700">* Please note: </span>
                                    <span>Not all replacements have been calculated yet.</span>
                                    <br/>
                                </>)}
                                <span className="text-yellow-700">*</span>
                                <span> Does not include excess.</span>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </section>
        );


    };

    return claimInfo();
}

function ClaimNote(props) {
    const renderClaimNote = () => {
        if (!props.claim || !props.claim.id || !props.claimNote || !props.claimNote.comments) {
            return;
        }

        if (props.claimNote.comments[0]) {
            const _cNote = props.claimNote.comments[0].message;
            // let cNote = _cNote.replace("</br>", "\n" );
            props.setClaimNote(_cNote);
        } else {
            props.setClaimNote('No notes found');
        }

        return (<section className="mt-10">
            <h2 id="summary-heading" className="font-medium ml-3 mb-3 text-2xl">Comments</h2>

            <div className="bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg ">
                {(props.claimNote || ' ')}
            </div>
        </section>);

    };

    return renderClaimNote();
}

function Settlements(props) {

    /** DEVELOPMENT PLACEHOLDER DATA */
        // useEffect(() => {if(props.claim) props.setSettlements(placeholderSettlementData['data']['settlements']['settlements']);}, [props.claim]);

    const onVerifySettlement = (settlementId, method) => {
            props.showConfirmModal('success', 'Verify settlement', 'Are you sure you want to verify this settlement?', 'Verify', () => {
                verifySettlement(settlementId, method);
            });
        };

    const verifySettlement = (settlementId, method) => {

        coreApi.verifySettlementVoucher(
            {
                'settlement': settlementId,
                'claim_number': props.claim.insurer_claim_number,
                'method': method,
                'beneficiary_id': settlementId,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Settlement verified', 'The settlement has been verified');
                querySettlements(
                    props.claim.id,
                    (data) => {
                        props.setSettlements(data.settlements);
                    },
                    props.onError,
                );
            },
            props.onError,
        );
    };

    const onDeleteSettlement = (settlementId) => {
        props.showConfirmModal(
            'warning',
            'Delete settlement',
            'Are you sure you want to delete this settlement?',
            'Delete',
            (options) => {
                cancelSettlementVoucher(settlementId, options.inputValue);
            },
            () => {
            },
            {
                'showInput': true,
                'inputPlaceholder': 'Reason for canceling settlement',
            },
        );
    };

    const cancelSettlementVoucher = (settlementId, reason) => {

        coreApi.cancelSettlementVoucher(
            {
                'settlement': settlementId,
                'claim_number': props.claim.insurer_claim_number,
                'reason': reason,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Settlement canceled', 'The settlement has been canceled');
                querySettlements(
                    props.claim.id,
                    (data) => {
                        props.setSettlements(data.settlements);
                    },
                    props.onError,
                );
            },
            props.onError,
        );
    };

    const onViewBeneficiaryDetails = (settlementId) => {

        /** DEVELOPMENT PLACEHOLDER DATA */
            // showBeneficiaryDetails(placeholderSettlementBeneficiary['data']['settlement_beneficiary']); return;

        const query = `
            query {
                settlement_beneficiary(
                    settlement: "${settlementId}"
                    beneficiary: "${settlementId}"
                ){
                    error {type, message}
                    voucher{
                        status
                    }
                    beneficiary{
                        title
                        first_name
                        last_name
                        msisdn
                        email
                        verification_method
                    }
                }
            }
            `;

        customGraphRequest(
            query,
            (data) => {
                showBeneficiaryDetails(data);
            },
            props.onError,
        );
    };

    const showBeneficiaryDetails = (settlementBeneficiary) => {

        const content = (
            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl'>Settlement Beneficiary Details</h1>

                <div className='w-full border-b-2 border-gray-200 my-4'></div>

                <table>

                    <tbody>

                    <tr>
                        <td className='font-bold'>Email</td>
                        <td>{settlementBeneficiary.beneficiary.email}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Title</td>
                        <td>{settlementBeneficiary.beneficiary.title}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Last name</td>
                        <td>{settlementBeneficiary.beneficiary.last_name}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>MSISDN</td>
                        <td>{settlementBeneficiary.beneficiary.msisdn}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Verification method</td>
                        <td>{settlementBeneficiary.beneficiary.verification_method}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Voucher status</td>
                        <td>{settlementBeneficiary.voucher.status}</td>
                    </tr>

                    </tbody>


                </table>

                <div className="mt-5 mt-4 flex w-full justify-end">

                    <button
                        type="button"
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Close
                    </button>

                </div>

            </div>
        );

        props.showCustomModal(content);
    };

    const renderAwards = () => {

        if (props.settlements.filter(settlement => settlement.method === 'AWARD').length <= 0) return <></>;

        const currencySymbol = props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' ';

        function awardedValue(settlement) {

            const excess = settlement.excess || 0;
            const value = settlement.value || 0;

            try {
                const val = value - excess;
                return props.currencyChange(val);
            } catch (e) {
                return '-';
            }
        }

        function settlementDatetimeHumanReadable(settlement) {

            const localDateCreatedMoment = localMomentInstanceFromUTC(settlement.date_created);

            return (
                <div className='pl-4'>
                    <p>{moment(localDateCreatedMoment).format('YYYY-MM-DD')}</p>
                    <p>{moment(localDateCreatedMoment).format('HH:mm:00 z')}</p>
                </div>
            );
        }

        return (
            <div className='px-8'>

                <h1 className="ml-5 font-light text-gray-500 mt-10">
                    Awards
                </h1>

                <table className="p-8">

                    <thead>
                    <tr>
                        <th>Date and Time</th>
                        <th>Supplier</th>
                        <th>Items</th>
                        <th>Value</th>
                        <th className='w-[20rem]'>Status</th>
                        <th className='w-[30rem]'>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {props.settlements.filter(i => i.method === 'AWARD')
                        .map((settlement) => {

                            if (settlement.items) {
                                settlement.items.forEach(_itemID => {
                                    _itemID.details = props.claim.items.find(item => item.id === _itemID.id);
                                });
                            }

                            return (
                                <tr key={settlement.id}>

                                    {/* DATE AND TIME */}
                                    <td>
                                        <Tooltip
                                            content={(<div>
                                                <h4>Created on:</h4>
                                                <i>{utcMomentInstanceFromLocal(settlement.date_created).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</i>
                                                <p>{localMomentInstanceFromUTC(settlement.date_created).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                                                <hr className='my-4'/>
                                                <h4>Updated on:</h4>
                                                <i>{utcMomentInstanceFromLocal(settlement.date_updated).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</i>
                                                <p>{localMomentInstanceFromUTC(settlement.date_updated).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                                            </div>)}
                                        >
                                                <span
                                                    className='tooltip_underline p-[1rem]'>{todayTomorrowYesterdayDatetimeFormat(settlement.date_created, HUMAN_READABLE_DATETIME_TIMEZONE)}</span>
                                        </Tooltip>
                                    </td>

                                    {/* SUPPLIER */}
                                    <td>
                                        {settlement.organisation_supplier.info.name}
                                    </td>

                                    {/* ITEMS */}
                                    <td>
                                        {settlement.items?.map((item, index) => (
                                                item && item.details ?
                                                    <span key={settlement.id + item.id}>
                                                        {index + 1}. {item.details.category.display_name} - {item.details.product.common_name}
                                                    </span>
                                                    :
                                                    ' '
                                            ),
                                        )}
                                    </td>

                                    {/* VALUE */}
                                    <td>
                                        <div>
                                            <span>Total value: </span>{currencySymbol} {props.currencyChange(settlement.value)}
                                        </div>
                                        <div>
                                            <span>Excess: </span>{currencySymbol} {props.currencyChange(settlement.excess)}
                                        </div>
                                        <div>
                                            <b>Awarded value: </b>{currencySymbol} {awardedValue(settlement)}
                                        </div>
                                    </td>

                                    {/* STATUS */}
                                    <td>
                                            <span
                                                className="bg-blue-800 text-white inline-flex justify-center items-center px-2.5 py-0.5 rounded-full font-medium capitalize">
                                                {settlement.status.replaceAll('_', ' ').toLowerCase()}
                                            </span>
                                    </td>

                                    {/* ACTIONS */}
                                    <td>

                                        <LimitedOrganisationAccess
                                            types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                            mainOrganisation={props.mainOrganisation}
                                        >
                                            <div className='flex gap-4'>
                                                <button className='btn-raised-danger px-3'
                                                        onClick={() => onDeleteSettlement(settlement.id)}>
                                                    <TrashIcon className="h-5 w-5 mr-2"/>
                                                    Delete
                                                </button>
                                                <button className='btn-raised px-3'
                                                        onClick={() => onVerifySettlement(settlement.id, 'AWARD')}>
                                                    <CheckIcon className="h-5 w-5 mr-2"/>
                                                    Verify
                                                </button>
                                                <button className='btn-raised px-3'
                                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                                    View
                                                </button>
                                            </div>
                                        </LimitedOrganisationAccess>

                                        <LimitedOrganisationAccess
                                            types={[OrganisationType.SUPPLIER]}
                                            mainOrganisation={props.mainOrganisation}
                                        >
                                            {/* Suppliers can view settlements that were awarded to them */}
                                            {settlement.organisation_supplier.id === props.mainOrganisation.id &&
                                                <button className='btn-raised px-3'
                                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                                    View
                                                </button>
                                            }
                                        </LimitedOrganisationAccess>


                                    </td>

                                </tr>
                            );
                        })}
                    </tbody>

                </table>

            </div>
        );
    };

    const renderVouchers = () => {

        if (props.settlements.filter(settlement => settlement.method === 'VOUCHER').length <= 0) return <></>;


        const renderSettlementTable = (settlement) => {
            return (
                <tr key={settlement.id}>

                    {/* DATE AND TIME */}
                    <td>
                        <Tooltip
                            content={(<div>
                                <h4>Created on:</h4>
                                <i>{utcMomentInstanceFromLocal(settlement.date_created).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</i>
                                <p>{localMomentInstanceFromUTC(settlement.date_created).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                                <hr className='my-4'/>
                                <h4>Updated on:</h4>
                                <i>{utcMomentInstanceFromLocal(settlement.date_updated).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</i>
                                <p>{localMomentInstanceFromUTC(settlement.date_updated).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                            </div>)}
                        >
                            <span
                                className='tooltip_underline p-[1rem]'>{todayTomorrowYesterdayDatetimeFormat(settlement.date_created, HUMAN_READABLE_DATETIME_TIMEZONE)}</span>
                        </Tooltip>
                    </td>

                    {/* SUPPLIER */}
                    <td>
                        {settlement.organisation_supplier.info.name}
                    </td>

                    {/* VALUE */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {props.currencyChange(settlement.value)}
                    </td>

                    {/* EXCESS */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {props.currencyChange(settlement.excess)}
                    </td>

                    {/* TOTAL VALUE */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {(parseFloat(settlement.value) - parseFloat(settlement.excess)).toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </td>

                    {/* STATUS */}
                    <td>
                        <span
                            className="bg-blue-800 text-white inline-flex justify-center items-center px-2.5 py-0.5 rounded-full font-medium capitalize"
                        >
                            {settlement.status.replaceAll('_', ' ').toLowerCase()}
                        </span>
                    </td>

                    {/* ACTIONS */}
                    <td>

                        <LimitedOrganisationAccess
                            types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                            mainOrganisation={props.mainOrganisation}
                        >
                            <div className='flex gap-4'>
                                <button className='btn-raised-danger px-3'
                                        onClick={() => onDeleteSettlement(settlement.id)}>
                                    <TrashIcon className="h-5 w-5 mr-2"/>
                                    Delete
                                </button>
                                <button className='btn-raised px-3'
                                        onClick={() => onVerifySettlement(settlement.id, 'VOUCHER')}>
                                    <CheckIcon className="h-5 w-5 mr-2"/>
                                    Verify
                                </button>
                                <button className='btn-raised px-3'
                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                    View
                                </button>
                            </div>
                        </LimitedOrganisationAccess>

                        <LimitedOrganisationAccess
                            types={[OrganisationType.SUPPLIER]}
                            mainOrganisation={props.mainOrganisation}
                        >
                            {/* Suppliers can view settlements that were awarded to them */}
                            {settlement.organisation_supplier.id === props.mainOrganisation.id &&
                                <button className='btn-raised px-3'
                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                    View
                                </button>
                            }
                        </LimitedOrganisationAccess>

                    </td>
                </tr>
            );
        };

        return (
            <div className='px-8'>

                <h1 className="ml-5 mt-10 font-light text-gray-500">
                    Vouchers
                </h1>

                <table>

                    <thead>
                    <tr>
                        <th>Dates</th>
                        <th>Supplier</th>
                        <th>Value</th>
                        <th>Excess</th>
                        <th>Total Value</th>
                        <th className='w-[20rem]'>Status</th>
                        <th className='w-[30rem]'>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {props.settlements.filter(i => i.method === 'VOUCHER')
                        .map((settlement) => renderSettlementTable(settlement))
                    }
                    </tbody>

                </table>

            </div>
        );
    };

    const renderSettlements = () => {

        if (!props.claim || !props.settlements || !props.mainOrganisation) return <></>;

        if (isEmpty(props.settlements)) {
            return (
                <section className="mt-14 widget opacity-50">

                    <h2 id="summary-heading" className="font-medium ml-3 mb-3 text-2xl">
                        Settlements
                    </h2>

                </section>
            );
        }


        return (
            <section className="mt-10 widget">
                <h2 className="ml-3 mb-3 text-2xl">Settlements</h2>

                {renderAwards()}

                {renderVouchers()}

            </section>
        );
    };

    return renderSettlements();

}

function ButtonSection(props) {

    if (!props.mainOrganisation) return <></>;

    return (
        <section className="mt-14 flex justify-end gap-6">

            {/* VOUCHER */}
            <LimitedOrganisationAccess
                types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                mainOrganisation={props.mainOrganisation}
            >
                <button
                    onClick={() => props.navigate(`/voucher/${props.claimId}`)}
                    className={classNames(
                        'btn w-1/4 py-4 justify-center font-medium',
                    )}
                >
                    Voucher
                </button>
            </LimitedOrganisationAccess>

            {/* AWARD */}
            <button
                onClick={() => props.navigate(`/award/${props.claimId}`)}
                className="btn w-1/4 py-4 justify-center font-medium"
            >
                Award
            </button>

        </section>
    );
}

function Items(props) {

    // Desktop assessment
    const [selectedSupplier_DA, setSelectedSupplier_DA] = useState(null);
    const [includeDeliveryFee_DA, setIncludeDeliveryFee_DA] = useState(false);

    // A state to trigger desktop assessment on all children items
    const [desktopAssessAllItems, setDesktopAssessAllItems] = useState(false);
    const [submitAllDesktopAssessments, setSubmitAllDesktopAssessments] = useState(false);

    // The supplier organisations that are associated with this claim
    const [supplierOrganisations, setSupplierOrganisations] = useState(null);
    useEffect(() => {
        fetchSuppliers();
    }, [props.claim]);

    function fetchSuppliers() {

        if (!props.claim) return;

        if (!isEmpty(supplierOrganisations)) return supplierOrganisations;

        const query = `
            query Accounts_Suppliers{
              accounts( 
                organisation: "${props.claim.organisation.id}",
                types: [ADMINISTRATOR_SUPPLIER, INSURER_SUPPLIER]
              ) {
                error {
                  type
                  message
                }
                
                accounts {
                  type
                  organisation_2 {
                    unique_reference_name
                    id
                    type
                    info {
                      name
                      logo_image_url
                      country
                      country_text
                    }
                  }
                  
                }
              }
            }
        `;

        customGraphRequest(
            query,
            (data) => {
                const organisations = data.accounts.map(account => account.organisation_2);
                setSupplierOrganisations(organisations);
            },
            props.onError,
        );
    }

    const onVerifyAllManualItems = (options) => {

        verifyAllManualItems(
            props.claimId,
            (data) => {
                props.showToastNotificationModal(
                    'success',
                    'Verified',
                    'All manual items have been verified',
                    3500,
                );

                setTimeout(() => props.getFullClaim(), 300);
            },
            props.onError,
        );
    };

    const onVerifyManualItem = (item, verifyOrIgnore) => {
        /** verify or ignore a manual item in VERIFICATION
         * item: ClaimItem object
         * verifyOrIgnore: VERIFIED | CANNOT_BE_VERIFIED */

        verifyManualItem(
            item.id,
            verifyOrIgnore,
            (data) => {
                props.showToastNotificationModal(
                    'success',
                    'Verified',
                    item?.product?.common_name || '',
                );
                props.getFullClaim();

            },
            props.onError,
        );
    };

    function onSubmitAllDesktopAssessments() {

        props.showConfirmModal(
            'success',
            'Submit all desktop assessments',
            'Are you sure you want to submit all below selected desktop assessments?',
            'Submit',
            () => {
                setSubmitAllDesktopAssessments(true);

                // Reset to avoid multiple triggers
                setTimeout(() => setSubmitAllDesktopAssessments(false), 300);

                setTimeout(() => props.getFullClaim(), 2000);
                setTimeout(() => props.getFullClaim(), 4000);
                setTimeout(() => props.getFullClaim(), 10000);
            },
        );

    }

    const allProps = {
        ...props,
        selectedSupplier_DA, setSelectedSupplier_DA,
        includeDeliveryFee_DA, setIncludeDeliveryFee_DA,
        desktopAssessAllItems, setDesktopAssessAllItems,
        submitAllDesktopAssessments, setSubmitAllDesktopAssessments,
        supplierOrganisations, setSupplierOrganisations,
        onVerifyManualItem,
    };

    const itemCount = props.claim?.items?.length || 0;

    const renderItemRows = () => {

        if (!props.claim) {
            return <LoadingSpinner/>;
        }

        return (
            <div className="space-y-[2rem]">

                {props.claim.items.map((item) => <ItemRow item={item} {...allProps} />)}

                {/* TEST CODE TO FORCE DIFFERENT ITEM STATES */}
                {/* {(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'CALCULATION_IN_PROGRESS';*/}
                {/*    clone.status = 'VERIFICATION';*/}
                {/*    clone.status = 'CANNOT_BE_VERIFIED';*/}
                {/*    clone.status = 'NEW';*/}
                {/*    clone.status = 'TENDERING';*/}
                {/*    clone.status = 'DELETED';*/}
                {/*    clone.status = 'EXPIRED';*/}
                {/*    clone.status = 'UNABLE_TO_TENDER';*/}
                {/*    clone.status = 'POST_TENDER_INSPECTION';*/}
                {/*    clone.status = 'QUANTIFIED';*/}
                {/*    return renderItemRows(clone);*/}
                {/* })()}*/}

            </div>
        );
    };

    function renderButtons() {

        const showDesktopAssessAll = props.claim?.items?.find(item => isEmpty(item.desktop_assessment)) || false;

        const claimHasItemsInVerification = props.claim?.items.find(item => item.status === 'VERIFICATION');

        const disabledStyle = !claimHasItemsInVerification ? 'opacity-40 hover:opacity-100 transition' : '';

        const marginPadding = 'm-0 p-2';

        return (
            <div className={classNames(
                'flex gap-4 w-1/2 justify-end ',
                disabledStyle,
            )}>

                {/* FETCH QUOTES ON ALL ITEMS */}
                {showDesktopAssessAll &&
                    <Tooltip content='Fetch quotes for manual items'>
                        <button className={classNames(
                            'btn-icon',
                            marginPadding,
                        )}
                                onClick={() => {
                                    setDesktopAssessAllItems(true);

                                    // Reset to avoid multiple triggers
                                    setTimeout(() => setDesktopAssessAllItems(false), 300);
                                }}
                        >
                            <SparklesIcon className="h-5 w-5"/>
                        </button>
                    </Tooltip>
                }

                {/* SUBMIT ALL DESKTOP ASSESSMENTS */}
                <Tooltip content='Submit all ready desktop assessments below'>
                    <button className={classNames(
                        'btn-icon',
                        marginPadding,
                    )}
                            onClick={onSubmitAllDesktopAssessments}
                    >
                        <ClipboardCopyIcon className="h-5 w-5"/>
                    </button>
                </Tooltip>

                {/* SELECT SUPPLIER */}
                <div className='h-a'>
                    <SimpleSelect
                        width='w-[20rem]'
                        selectedState={selectedSupplier_DA}
                        selectableOptions={supplierOrganisations}
                        onChange={(supplier) => setSelectedSupplier_DA(supplier)}

                        renderOption={(org) =>
                            <div className='flex gap-2'>
                                <BasicImage
                                    src={org?.info.logo_image_url}
                                    fallbackSrc={'/org-logo-placeholder.jpg'}
                                    alt="logo"
                                    sizeWidthRem="1.2"
                                    className="rounded-full"
                                />
                                <span>{org?.info.name || ''}</span>
                            </div>
                        }

                        /* TODO REMOVE THIS use {active}*/
                        renderSelected={(org) =>
                            org ?
                                <div className='flex gap-2'>
                                    <BasicImage
                                        src={org.info.logo_image_url}
                                        fallbackSrc={'/org-logo-placeholder.jpg'}
                                        alt="logo"
                                        sizeWidthRem="1.2"
                                        className="rounded-full"
                                    />
                                    <span>{org.info.name || ''}</span>
                                </div> : 'Select a supplier'
                        }
                    />
                </div>

                {/* INCLUDE DELIVERY */}
                <Tooltip content={<div>
                    <p>Desktop assessment price</p>
                    <b>{includeDeliveryFee_DA ? 'includes delivery' : 'excludes delivery'}</b>
                    <hr/>
                    <i>Toggle the inclusion of the delivery fee</i>
                </div>}>
                    <button
                        className={classNames(
                            'btn-icon',
                            marginPadding,
                            includeDeliveryFee_DA ? 'bg-sky-200' : '',
                        )}
                        onClick={() => setIncludeDeliveryFee_DA(!includeDeliveryFee_DA)}
                    >
                        {includeDeliveryFee_DA ?
                            <div className='flex'>
                                <CheckIcon className='h-5 w-5'/>
                                {/* Delivery included*/}
                            </div>
                            :
                            <div className='flex'>
                                <TruckIcon className='h-5 w-5'/>
                                {/* Delivery excluded*/}
                            </div>
                        }
                    </button>
                </Tooltip>

                {/* VERIFY ALL */}
                <Tooltip content='Verify all manual items and continue to tender' position='left'>
                    <button className={classNames(
                        'btn-icon',
                        marginPadding,
                    )}
                            onClick={onVerifyAllManualItems}
                    >
                        <ClipboardCheckIcon className="h-5 w-5"/>
                    </button>
                </Tooltip>

            </div>
        );
    }

    function renderHeader() {

        return (
            <div className="widget flex items-center justify-between mb-4 shadow-sm">

                {/* HEADING */}
                <p className="font-medium text-2xl">Products</p>

                {/* BUTTONS */}
                {renderButtons()}

            </div>
        );
    }

    function renderFootRow() {

        return (
            /* ROW*/
            <div className='pr-4'>
                <div className='widget w-full flex justify-end p-10 mt-5 shadow-sm'>

                    {/* BUTTONS */}
                    {renderButtons()}

                </div>
            </div>
        );
    }

    return (
        <section className="mt-10">

            {/* Only show the header AND footer if the claim is large */}
            {itemCount > 4 &&
                renderHeader()
            }

            {renderItemRows()}

            {renderFootRow()}

        </section>
    );
}

function ItemRow(props) {

    const [showDesktopAssessment, setShowDesktopAssessment] = useState(false);

    const allProps = {
        ...props,
        showDesktopAssessment, setShowDesktopAssessment,
    };

    return (
        <div
            key={props.item.id + props.supplierOrganisations?.length}
            className='widget shadow-sm py-6 px-2'
        >

            <div className="flex pb-4">

                {/* STATEMENT OF LOSS */}
                <LimitedOrganisationAccess
                    types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR, OrganisationType.INSURER]}
                    mainOrganisation={props.mainOrganisation}
                >
                    <StatementOfLoss item={props.item} {...allProps} />
                </LimitedOrganisationAccess>

                {/* REQUESTED PRODUCT */}
                <div className="flex justify-start items-center w-full pl-[4rem]">
                    <Safe>
                        <Item_RequestedProduct
                            {...allProps}
                            item={props.item}
                            verifiedByUsers={props.verifiedByUsers}
                        />
                    </Safe>
                </div>

                {/* > */}
                <div className="flex justify-center items-center w-1/4 lg:w-fit">

                    <button className="btn-raised-light p-1 tailwind-tooltip-container flex items-center justify-center"
                            onClick={() => {
                                props.showProductInfoModal(props.item);
                            }}
                    >
                        {/* If replacement is the same as product, show equals icon */}
                        {props.item.replacement && props.item.replacement.product.id === props.item.product.id ? (
                            /* Makeshift = equals icon */
                            <div className='h-14 w-14 py-3'>
                                <p className='text-[5rem] text-gray-300 text-center'>=</p>
                            </div>
                        ) : (
                            <ChevronRightIcon
                                className="h-14 w-14 text-gray-300 group-hover:text-gray-500"
                            />
                        )}
                        <span className="tailwind-tooltip -top-[5rem]">
                            Click to view the <b>requested</b> product, or compare to the <b>replacement</b> product
                        </span>

                    </button>

                </div>

                {/* REPLACEMENT PRODUCT/QUOTE*/}
                <div className="flex justify-center items-center w-full">
                    <Safe>
                        <Item_ReplacementCard
                            {...allProps}
                            item={props.item}
                        />
                    </Safe>
                </div>

            </div>

            {/* PROGRES BAR */}
            <Safe> <ItemStatusFlow item={props.item}/> </Safe>

        </div>
    );
}

function StatementOfLoss(props) {

    const metadata = props.item.metadata || {};

    const claimed_amount = metadata.claimed_amount || null;
    const comment = metadata.comment || '-';
    const date_purchased = metadata.date_purchased || null;
    const incident_location = metadata.incident_location || null;
    const purchase_price = metadata.purchase_price || null;
    // const purchase_source = metadata.purchase_source || null
    // const replacement_quote = metadata.replacement_quote || null

    // The input element for uploading attachments
    const itemAttachmentUploadInputRef = useRef(null);
    const itemToUploadAttachmentTo = useRef(null);

    function onUploadDocument(file) {
        /* Upload the given attachment */

        let sizeInMB = file.size / 1024 / 1024;
        sizeInMB = sizeInMB.toFixed(2);

        if (file.size > 7 * 1024 * 1024) { // 7 MB limit
            props.showAlertModal(
                'info',
                'File Too Large',
                <>
                    <p>The chosen file is {sizeInMB}MB, which exceeds the 7MB limit</p>
                    <p>Please compress or upload another smaller file</p>
                </>,
            );
            return;
        }

        uploadFile(
            {
                objectId: props.claim.id,
                objectType: 'claims',
                fileName: file.name,
                file: file,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Attachment uploaded', '');

                // Manually add the attachment to the claim
                props.setClaim({...props.claim, attachments: [...props.claim.attachments, data.file]});

                // Update claim.attachments
                props.updateGlobalClaim(props.claimId, props.setClaim);

                linkAttachmentToItem(data.file);

            },
            props.onError,
        );
    }

    function linkAttachmentToItem(attachment) {
        /* Link an attachment to an item */

        if (!itemToUploadAttachmentTo.current) {
            props.showAlertModal('info', 'No item object', 'Failed to link attachment to item');
            return;
        }

        customGraphRequest(
            `mutation UpdateItemMetadata{
                update_item_metadata(
                    item_id: "${itemToUploadAttachmentTo.current.id}"
                    attachment_ids: ["${attachment.id}"]
                ){
                    error{ message, type }
                    item{id}
                }
            }`,
            (data) => {
                props.showToastNotificationModal(
                    'success', 'Attachment linked', '',
                );
                props.updateGlobalClaim(props.claimId, props.setClaim);
            },
            (error) => props.onError(error),
        );
    }

    function updateMetadata(itemId, metadata) {

        function isNullUndefined(value) {
            return value === null || value === undefined;
        }

        const mutation = `
        mutation UpdateItemMetadata{
            update_item_metadata(
                item_id: "${itemId}"
                ${isNullUndefined(metadata.clients_quote) ? '' : `replacement_quote: ${metadata.clients_quote}`}
                ${isNullUndefined(metadata.incident_location) ? '' : `incident_location: "${metadata.incident_location}"`}
                ${isNullUndefined(metadata.comment) ? '' : `comment: "${metadata.comment}"`}
                ${isNullUndefined(metadata.claimed_amount) ? '' : `claimed_amount: ${metadata.claimed_amount}`}
                ${isNullUndefined(metadata.date_purchased) ? '' : `date_purchased: "${metadata.date_purchased}"`}
                ${isNullUndefined(metadata.purchase_price) ? '' : `purchase_price: ${metadata.purchase_price}`}
            ){
                error{ message, type }
                item{id}
            }
        }
        `;

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal(
                    'success', 'Updated', '',
                );
                props.updateGlobalClaim(props.claimId, props.setClaim);
            },
            (error) => props.onError(error),
        );

    }

    function updateClientQuote(itemID, price) {

        if (!price) return props.showAlertModal('info', 'Invalid price', 'The price must be a positive number');

        updateItemClaimantQuote(
            itemID,
            price,
            (data) => {
                props.showToastNotificationModal('success', 'Claimant quote update');
            },
            (error) => props.onError(error),
        );
    }

    const clearStyle = 'bg-transparent border-none outline-none focus:outline-none shadow-none p-0 m-1';
    // Statement of loss styles
    const rowContainer = 'flex gap-2 w-full pt-2 h-[2rem] ';
    const rowTitle = 'w-full text-gray-400 group-hover:text-gray-800 transition font-light group-hover:font-bold text-xs ';
    const rowValue = `w-full ${clearStyle} text-right self-end `;

    return (
        <div className='w-[50%]'>

            {/* Hidden file input, for uploading attachments */}
            <input
                type="file"
                style={{display: 'none'}}
                ref={itemAttachmentUploadInputRef}
                onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                        onUploadDocument(file);
                    }
                }}
            />

            <Tooltip
                content={<div>
                    <b>Statement of loss details</b>
                    <hr/>
                    <p>Click to edit values</p>
                </div>}
            >

                <div className={classNames(
                    'flex flex-col gap-0 justify-around items-start',
                    'max-h-[14rem] h-fit',
                    'group transition-all',
                    'text-sm text-gray-500 rounded-lg p-2',
                    'divide-y divide-gray-200',
                )}>

                    {/* CLIENT */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Client</p>
                        <CurrencyInput
                            currencySymbol={currencySymbol(props.claim)}
                            defaultValue={props.item.claimant_quote}
                            onBlur={(e) => {
                                // Replace the value with 0 if it's an empty input
                                const value = e.target.value ? e.target.value : 0;
                                updateMetadata(props.item.id, {clients_quote: value});

                                // TODO the above "replacement_quote" is not updating the item's claimant_quote
                                // So additionally use the old method for now
                                updateClientQuote(props.item.id, value);
                            }}
                            className={rowValue}
                            containerClassName={'w-full'}
                        />
                    </div>

                    {/* CLAIMED */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Claimed</p>
                        <CurrencyInput
                            currencySymbol={currencySymbol(props.claim)}
                            defaultValue={claimed_amount}
                            onBlur={(e) => {
                                // Don't trigger if no change
                                if (e.target.value === claimed_amount) return;
                                // Replace the value with 0 if it's an empty input
                                const value = e.target.value ? e.target.value : 0;
                                updateMetadata(props.item.id, {claimed_amount: value});
                            }}
                            className={rowValue}
                            containerClassName={'w-full'}
                        />
                    </div>

                    {/* PURCHASED FOR */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Purchased for</p>
                        <CurrencyInput
                            currencySymbol={currencySymbol(props.claim)}
                            defaultValue={purchase_price}
                            onBlur={(e) => {
                                // Replace the value with 0 if it's an empty input
                                const value = e.target.value ? e.target.value : 0;
                                updateMetadata(props.item.id, {purchase_price: value});
                            }}
                            className={rowValue}
                            containerClassName={'w-full'}
                        />
                    </div>

                    {/* PURCHASED ON */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Purchased on</p>
                        <input
                            defaultValue={
                                date_purchased
                                    ? new Date(date_purchased).toISOString().split('T')[0] // Format the date to YYYY-MM-DD
                                    : ''
                            }
                            type={'date'}
                            placeholder='Space'
                            onBlur={(e) => {
                                if (!e.target.value) return;
                                updateMetadata(props.item.id, {date_purchased: e.target.value});
                            }}
                            className={classNames(
                                rowValue,
                                'text-gray-400',
                                props.item.metadata?.date_purchased ? 'text-gray-800' : 'text-gray-400',
                            )}
                        />
                    </div>

                    {/* SPACE */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Space</p>
                        <input
                            defaultValue={incident_location}
                            onBlur={(e) => {
                                // Replace the value with 0 if it's an empty input
                                const value = e.target.value ? e.target.value : 0;
                                updateMetadata(props.item.id, {incident_location: value});
                            }}
                            className={rowValue + ' pr-4 m-0'}
                        />
                    </div>

                    {/* COMMENT */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Comments</p>

                        <div className='flex w-full'>
                            <Tooltip
                                content={<div>
                                    <b>Full comment</b>
                                    <hr/>
                                    <p>{comment}</p>
                                </div>}
                                position='right'
                                className='max-w-[20rem] m-0 p-0'
                                containerClassName='w-full m-0 p-0'
                                hidden={comment.length < 20}
                            >
                                <input
                                    defaultValue={comment.length > 20 ? comment.substring(0, 10) + '...' : comment}
                                    onBlur={(e) => {
                                        // Replace the value with 0 if it's an empty input
                                        const value = e.target.value ? e.target.value : 0;
                                        updateMetadata(props.item.id, {comment: value});
                                    }}
                                    className={rowValue + 'm-0 pr-4'}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    {/* ATTACHMENTS */}
                    <div className={rowContainer}>
                        <p className={rowTitle}>Attachments</p>

                        <div
                            className='w-full flex justify-end self-end items-center gap-4 pr-4'
                            onClick={() => {
                                itemToUploadAttachmentTo.current = props.item;
                                itemAttachmentUploadInputRef.current.click();
                            }}
                        >
                            {props.item.metadata?.attachments?.length > 0 ?
                                (
                                    <>
                                        <PlusIcon
                                            className='hidden group-hover:block h-4 w-4 text-gray-300 group-hover:text-gray-800 transition'
                                        />
                                        <p
                                            className='group-hover:hidden text-gray-300 group-hover:text-gray-800'
                                        >{props.item.metadata?.attachments?.length}</p>
                                    </>
                                )
                                :
                                <PlusIcon className='h-4 w-4 text-gray-300 group-hover:text-gray-800 transition'/>
                            }
                        </div>
                    </div>

                </div>
            </Tooltip>
        </div>
    );

}

function ItemStatusFlow({item}) {

    const display_status = displayStatuses[item['status']];
    const status_step = statusSteps[display_status];

    let secondStatus = '...';
    let progress = '20%';
    let gridCols = 'grid-cols-3';

    if (status_step > 0) {

        if (item.is_tender_item) {
            secondStatus = 'Tendering';
            gridCols = 'grid-cols-4';

            if (item.status === 'TENDERING') {
                progress = '38%';
            } else if (item.status === 'POST_TENDER_INSPECTION') {
                progress = '63%';
            }

        } else if (item.is_manual) {
            secondStatus = 'Desktop assessment';
            progress = '50%';
        } else {
            secondStatus = '';
        }
    }

    if (status_step === 3) {
        progress = '100%';
    }

    let statusTextColor = 'text-gray-400';
    let statusBarColor = 'bg-sky-600';

    if (item.status === 'CANNOT_BE_VERIFIED' || item.status === 'EXPIRED' || item.status === 'DELETED' || item.status === 'UNABLE_TO_TENDER') {
        statusTextColor = 'text-rose-500';
        statusBarColor = 'bg-rose-400';
    }

    return (
        <div className="mt-6 mb-2 px-4">

            {/* PROGRESS BAR */}
            <div className="bg-gray-100 rounded-full overflow-hidden">
                <div
                    className={classNames(statusBarColor, 'h-[4px] rounded-full')}
                    style={{width: progress}}
                />
            </div>

            {/* STATUS TEXT */}
            <div className={classNames(gridCols, 'font-light hidden sm:grid text-sm font-medium text-gray-400 mt-6')}>

                {/* CALCULATING */}
                <div className={statusTextColor}>
                    Calculating
                </div>

                {/* STATE 2 */}
                <div className={classNames(status_step > 0 ? statusTextColor : '', 'text-center')}>
                    {secondStatus}
                </div>

                {/* STATE 3 */}
                {status_step > 0 && item.is_tender_item && (
                    <div className={classNames(status_step > 1 ? statusTextColor : '', 'text-center')}>
                        Post tender inspection
                    </div>)}

                {/* STATE 4 */}
                <div className={classNames(status_step > 2 ? statusTextColor : '', 'text-right')}>
                    {status_step > 2 ? display_status : 'Quantified'}
                </div>

            </div>

        </div>
    );
}

function Item_RequestedProduct(props) {

    const navigate = useNavigate();

    function getVerifiedBy() {

        const userId = props.item.product?.metadata?.verified_by;

        if (!userId) return <p></p>;

        const userData = props.verifiedByUsers.find(user => user.id === userId);

        if (userData) {
            return <p className="text-xs text-gray-500 mt-2">Verified by: {userData.info.full_name}</p>;
        } else {
            return <p className="text-xs text-gray-500 mt-2">Verified by: {userId}</p>;
        }

    }

    const item = props.item;

    function itemDetails() {
        return (
            <div className="">

                <div className="flex justify-between">
                    <h3 className="font-medium text-lg hover:text-gray-700">
                        {item.is_manual ? item.product.brand || item.product.model_numbers[0] : item.product.common_name}
                    </h3>
                </div>

                <div className="mt-1 flex text-sm">
                    <p className="text-md text-gray-800">
                        {item.is_manual ? `${item.product.brand} | ${item.product.description}` : calculateProperties(item.product)}
                    </p>
                </div>
                <div className="mt-1 text-sm font-medium text-gray-900">
                    {item.product.model_number !== null ? (
                        <p className="text-sm text-gray-500">{item.product.model_number}</p>
                    ) : (
                        <p className="text-sm text-gray-500">
                            {item.product.model_numbers?.slice(0, 3).join(' | ') || ''}
                        </p>
                    )}
                    {item.is_manual ? `${formatAsTitle(item.category.category_a)} >  ${formatAsTitle(item.product.category)}` : ''}

                    {getVerifiedBy()}
                </div>

                <p className="mt-2 text-sm font-medium text-gray-900">{item.product.type === 'MANUAL' ? item.product.description : calculateProperties(item.product)}</p>

                {/* MANUAL TAG */}
                {item.is_manual &&
                    <div className="mt-2 flex text-sm">
                        <span
                            className="manual-item-tag">Manual item</span>
                    </div>
                }
            </div>
        );
    }

    const generateAIProtoStates = {
        IDLE: 'IDLE',
        IN_PROGRESS: 'IN_PROGRESS',
        SUCCESS: 'SUCCESS',
    };
    const [generateAIProtoState, setGenerateAIProtoState] = useState('IDLE');

    function generateAIProto() {

        setGenerateAIProtoState(generateAIProtoStates.IN_PROGRESS);

        props.showToastNotificationModal('success', 'AI Generation In Progress', `${item.product.common_name} is being generated`, 5000);

        generateAiProto(
            item.product.category,
            item.product.brand,
            item.product.model_number || item.product.model_numbers[0] || null,
            item.id,
            null,
            (data) => {
                console.log('Generate AI Proto response: ', data);

                setGenerateAIProtoState(generateAIProtoStates.SUCCESS);

                /** Success */
                if (data['response_success']) {
                    props.showToastNotificationModal(
                        'success',
                        'AI Producted Generated',
                        `${item.product.common_name} Product has successfully been generated and added to this claim`,
                        5000,
                    );
                    props.getFullClaim();

                    // Open the new product in the product modal
                    props.setProductInfoModalOptions({
                        'product': data['new_claim_item']['product'],
                        'onProductAdded': null,
                        'item': data['new_claim_item'],
                        'mode': 'edit',
                    });
                    props.setProductInfoModalOpen(true);
                }

                /** Failed */
                else {

                    setGenerateAIProtoState(generateAIProtoStates.IDLE);

                    props.showAlertModal(
                        'error',
                        'AI Generation Request Failed',
                        (
                            <div className='flex flex-col gap-4 w-full'>
                                <p>Failed to generate a catalogue product from this manual item.</p>

                                <div>
                                    <b>Reason:</b>
                                    <p>
                                        {data['response_info'] ?
                                            JSON.stringify(data['response_info']) :
                                            <i>No reason has been provided</i>
                                        }
                                    </p>
                                </div>

                                <div>
                                    <p className='mt-6 text-left'>
                                        This product can still be created manually:
                                    </p>
                                    <button
                                        onClick={() => {
                                            showProductInfoModal(item);
                                        }}
                                        className="btn ml-0 mb-6">
                                        <PlusIcon className="mr-1 h-5 w-5"/>
                                        Create proto manually
                                    </button>
                                </div>

                            </div>
                        ));
                }

            },
            (error) => {

                setGenerateAIProtoState(generateAIProtoStates.IDLE);

                // do not show the standard error modal for the generate_ai_proto mutation
                props.showAlertModal('error', 'AI Request Failed', 'Failed to generate a catalogue product from this manual item');

                console.error('Generate AI Proto response: ', error);
            },
        );

    }

    const showProductInfoModal = (item) => {

        props.setProductInfoModalOptions({
            'product': item.product,
            'onProductAdded': null,
            'item': item,
            'mode': 'edit',
        });

        props.setProductInfoModalOpen(true);
    };


    function itemButtons() {

        const claimHasItemsInVerification = props.claim?.items.find(item => item.status === 'VERIFICATION');

        const btnStyle = 'text-sky-600 hover:text-sky-500 cursor-pointer btn-icon';
        const ignoreStyle = 'point-events-none text-gray-300 hover:text-gray-300';
        const disabledStyle = !claimHasItemsInVerification ? 'opacity-30 pointer-events-none' : '';


        return (
            <div className={classNames(
                'w-fit flex items-end justify-center gap-4',
            )}>

                {/* EDIT MANUAL ITEM MODAL */}
                <Tooltip content='Edit manual item'>
                    <button
                        className={classNames(
                            btnStyle,
                            disabledStyle,
                            item.status === 'VERIFICATION' ? '' : ignoreStyle,
                            item.is_manual ? '' : 'hidden',
                        )}
                        onClick={() => {
                            props.setItemInEditManualItemModal(item);
                            props.setShowEditManualItem(true);
                        }}
                    >
                        <PencilAltIcon className="h-7 w-7"/>
                    </button>
                </Tooltip>

                {/* GENERATE PROTO */}
                <Tooltip content='AI generate a catalog product'>

                    {/* LOADING SPINNER */}
                    {generateAIProtoState === 'IN_PROGRESS' &&
                        <LoadingSpinner size="8" color='lightblue' body=''/>
                    }

                    {/* BUTTON */}
                    {(generateAIProtoState === 'SUCCESS' || generateAIProtoState === 'IDLE') &&
                        <button
                            className={classNames(
                                btnStyle,
                                disabledStyle,
                            )}
                            onClick={generateAIProto}
                        >
                            <SparklesIcon className="h-7 w-7"/>
                        </button>
                    }

                </Tooltip>

                {/* CONVERT */}
                <Tooltip content='Change this manual item with a catalog product'>
                    <button
                        onClick={() => navigate(`/claim/item-convert/${props.claimId}/${item.id}`)}
                        className={classNames(
                            btnStyle,
                            disabledStyle,
                        )}
                    >
                        <DocumentSearchIcon className="h-7 w-7 m-0 p-0"/>
                    </button>
                </Tooltip>

                {/* IGNORE */}
                <Tooltip content='Ignore this item'>
                    <button
                        className={classNames(
                            btnStyle,
                            disabledStyle,
                            item.status === 'VERIFICATION' ? '' : ignoreStyle,
                        )}
                        disabled={item.status !== 'VERIFICATION'}
                        onClick={() => {
                            props.showConfirmModal(
                                'info',
                                'Ignore item',
                                (<p>Are you sure you want to ignore this item? It will be marked as <i>can not be
                                    verified</i></p>),
                                'Ignore',
                                () => props.onVerifyManualItem(item, 'CANNOT_BE_VERIFIED'),
                            );
                        }}
                    >
                        <ExclamationIcon className="h-7 w-7"/>
                    </button>
                </Tooltip>

                {/* DESKTOP ASSESS */}
                <Tooltip content='Toggle desktop assessment' style='max-w-max'>
                    <button
                        className={classNames(
                            btnStyle,
                            props.showDesktopAssessment ? 'text-sky-500 bg-sky-100 rounded-lg' : '',
                        )}
                        onClick={() => props.setShowDesktopAssessment(!props.showDesktopAssessment)}
                    >
                        <ClipboardListIcon className="h-7 w-7"/>
                    </button>
                </Tooltip>

            </div>
        );
    }

    return (
        <div className="flex justify-start gap-16">

            {/* PRODUCT IMAGE */}
            <div className="flex-shrink-0 w-60 h-45 rounded-lg overflow-hidden">
                <BasicImage
                    src={item?.product.images?.thumbnail_web}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeWidthRem="16"
                    sizeHeightRem={16 * 0.75} // 4:3 aspect ratio
                    className="rounded-md"
                />
            </div>

            <div className='space-y-4'>
                {itemDetails()}

                {itemButtons()}
            </div>

        </div>
    );
}

function Item_ReplacementCard(props) {

    if (!props.claim) return <></>;

    const hasItemsInVerification = () => {

        if (!props.claim) {
            return false;
        }

        for (const item of props.claim.items) {
            if (item.status === 'VERIFICATION') {
                return true;
            }
        }

        return false;
    };

    const item = props.item;

    // Use a separate variable so that it can be modified
    let itemStatus = item.status;

    let replacement = item.replacement;
    if (props.mainOrganisation?.type === 'SUPPLIER') {
        // the replacement is only shown if the item is quantified/post tender
        // if the claim is quantified, the replacement is shown
        // The supplier does not see the item.replacement
        // instead the supplier sees their own quoted replacement in item.supplier_panel[at the supplier].recommended_replacement
        // IF THEY HAVE - SHOW THAT QUOTE AS THE REPLACEMENT
        // IF THEY HAVE NOT - SHOW "declined to tender"
        const supplier = item.supplier_panel?.find(supplier => supplier.id === props.mainOrganisation.id) || null;
        replacement = supplier?.recommended_replacement || null;
        if (supplier?.status === 'DECLINED_TENDER') {
            itemStatus = 'UNABLE_TO_TENDER';
        }
        if (supplier?.status === 'UNABLE_TO_TENDER') {
            itemStatus = 'UNABLE_TO_TENDER';
        }
        if (supplier?.status === 'TENDER_DELETED') {
            itemStatus = 'DELETED';
        }


    }

    const replacementProduct = replacement?.product || null;
    const replacementQuote = replacement?.quote || null;

    // Find the winning supplier's full object in the supplier panel
    const winningSupplierInPanel = item.supplier_panel?.find(supplier => supplier.id === replacement?.quote?.supplier.id) || null;

    const claimHasItemsInVerification = hasItemsInVerification();

    const replacementProductCard_calculationInProgress = () => {
        // calculation in progress
        // - CALCULATION_IN_PROGRESS
        return (
            <div className="flex justify-center items-center w-full">
                <LoadingSpinner size="14" text={'ml-20 text-2xl '} color={'gray'} body={'Calculating'}/>
            </div>
        );
    };

    const replacementProductCard_CannotBeVerified = () => {
        return (
            <div className="w-full flex justify-center items-center gap-16 mx-8">

                {/* IMAGE */}
                {/* <img src='' />*/}
                <BanIcon className="h-24 w-24 text-red-400 "/>
                {/* TEXT */}
                <div className="space-y-2 w-1/2">
                    <h1 className="text-lg font-bold">Verification</h1>
                    <p>This item could not be verified</p>

                </div>

            </div>
        );
    };

    const replacementProductCard_tender = () => {

        // 1. Ready to tender (other items in verification)
        // 2. Tender (no other items in verification)
        // - NEW
        // - TENDERING

        // READY TO TENDER - AWAITING VERIFICATION
        // if(claimHasItemsInVerification) {
        if (claimHasItemsInVerification || itemStatus === 'NEW') { // TOOD remove this
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    {/* <img src='' />*/}
                    <SearchIcon className="h-24 w-24 text-gray-200"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-lg font-bold">Ready to tender</h1>
                        <p>Awaiting the verification of all items</p>

                    </div>

                </div>
            );
        }


        // TENDER
        return (
            <div className="flex flex-col p-4 gap-10">
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    {/* <img src='' />*/}
                    <MenuAlt2Icon className="h-24 w-24 text-gray-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-lg font-bold">Tendering</h1>
                        <p>This item is in tender</p>
                        <p>Potential replacements: {item.possible_replacements?.length || '-'}</p>

                        <Link className={classNames('btn mx-0 px-6 py-2',
                            'ROOT ADMINISTRATOR SUPPLIER'.includes(props.mainOrganisation?.type) ? '' : 'hidden', // Only show this button if the user/org is an admin or supplier
                        )}
                              to={`/tender/${props.claim.id}`}
                        >
                            <PencilAltIcon className="h-5 w-5 mr-2"/>
                            Tender
                        </Link>

                    </div>


                </div>

                {/* If this supplier/organisation user has quoted on this item, show their quote/product */}
                {replacementProduct && replacementProductCard_recommendedReplacement()}

            </div>

        );
    };

    const replacementProductCard_unableToTender = () => {
        // unable to tender
        // declined to tender
        // - UNABLE_TO_TENDER
        // - DELETED
        // - EXPIRED

        if (itemStatus === 'UNABLE_TO_TENDER') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item was marked as <i>"Unable to tender"</i></p>
                        <p>No further action will be taken for this item</p>
                        <p><b>Reason: </b>{item.status_reason}</p>
                    </div>

                </div>
            );
        }


        if (itemStatus === 'EXPIRED') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item has expired</p>
                        <p>No further action will be taken for this item</p>
                    </div>

                </div>
            );
        }

        if (itemStatus === 'DELETED') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item has been deleted</p>
                        <p>No further action will be taken for this item</p>
                    </div>

                </div>
            );
        }

    };

    const replacementProductCard_postTender = () => {
        // - POST_TENDER_INSPECTION

        return (
            <div className="overflow-scroll scrollbar-hide">
                {replacementProductCard_recommendedReplacement()}

                {/* POST TENDER BUTTON */}
                <div className={classNames('flex justify-end',
                    'ROOT ADMINISTRATOR'.includes(props.mainOrganisation?.type) ? '' : 'hidden', // Only show this button if the user/org is an admin
                )}
                >
                    <div className="w-[55%]">
                        <Link className="btn ml-4 py-2"
                              to={`/post/${props.claim.id}`}
                        >
                            <SearchIcon className="h-5 w-5 mr-2"/>
                            Post tender inspection
                        </Link>
                    </div>

                </div>


            </div>

        );
    };

    const replacementProductCard_recommendedReplacement = () => {

        if (!replacementProduct) return <i>No catalog pricing available</i>;

        let supplierImageUrl = null;
        if (replacementQuote !== null) {
            const name = replacementQuote.supplier.unique_reference_name.replaceAll('.', '-')
                .replaceAll('_', '-');
            supplierImageUrl = `https://slvrcld-images.s3.eu-west-1.amazonaws.com/organisations/${name}.png`;
        }

        const assessedBy = item?.desktop_assessment?.selected_by || '';
        const DaQuote = item?.desktop_assessment?.selected_product || {};

        return (
            <div className="w-full flex justify-start items-center gap-16 scrollbar-hide">

                {/* IMAGE */}
                <BasicImage
                    src={replacement?.product?.images?.thumbnail_web}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeWidthRem="16"
                    sizeHeightRem={16 * 0.75} // 4:3 aspect ratio
                    className="rounded-md"
                />

                {/* TEXT */}
                <div className="space-y-2">

                    {/* RECOMMENDED REPLACEMENT */}
                    <h1 className="text-lg font-bold">{replacementProduct.common_name}</h1>

                    {/* CATEGORY */}
                    <p className="text-sm text-gray-600">{item.category.display_name}</p>

                    {/* MODAL NUMBER */}
                    {replacementProduct.type === 'CATALOGUE' && replacementProduct.model_number !== null && (
                        <p className="text-sm text-gray-500">{replacementProduct.model_number}</p>)}
                    {replacementProduct.type === 'CATALOGUE' && replacementProduct.model_number === null && (
                        <p className="text-sm text-gray-500">{replacementProduct.model_numbers.slice(0, 3).join(' | ')}</p>)}


                    {/* DESCRIPTION */}
                    <p className="text-sm font-medium text-gray-900">
                        {replacementProduct.type === 'MANUAL' ? replacementProduct.description : calculateProperties(replacementProduct)}
                    </p>

                    {/* ASSESSED BY */}
                    {assessedBy &&
                        <p className="text-sm font-medium text-gray-900">
                            <b>Assessed by:</b> {assessedBy}
                        </p>
                    }

                    <div className='flex gap-4'>

                        {/* MANUAL TAG */}
                        {replacementProduct.type === 'MANUAL' &&
                            (<span
                                className="inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-blue-500 text-white">
                                Manual item
                            </span>)
                        }

                        {/* DESKTOP ASSESSMENT QUOTE */}
                        {!isEmpty(DaQuote) &&
                            <Tooltip content={<div className='flex flex-col items-start gap-1 text-left'>
                                <p><b>Original desktop assessment quote</b></p>
                                <p><i>Click to open</i></p>
                                <hr/>
                                <p><b>Brand:</b> {DaQuote.brand}</p>
                                <p><b>Model number:</b> {DaQuote.model_number}</p>
                                <p><b>Price:</b> {DaQuote.price}</p>
                                <p><b>Delivery:</b> {DaQuote.delivery_cost_extract || '-'}</p>
                                <p><b>Description:</b> {DaQuote.description}</p>
                                <p><b>Source:</b> {DaQuote.source_url?.substring(0, 30) || ''}...</p>
                            </div>}
                                     style='max-w-[20rem]'
                                     position='left'
                            >
                                <a
                                    href={DaQuote.source_url}
                                    target='_blank' rel="noreferrer"
                                >
                                    <ClipboardListIcon className="h-6 w-6 text-blue-500"/>
                                </a>
                            </Tooltip>
                        }

                    </div>

                    {/* QUOTE PRICE */}
                    {replacement.quote !== null &&

                        <div className="flex items-center pt-2 gap-6">

                            {/* PRICE */}
                            <p className="text-lg font-bold text-gray-900">
                                {currencySymbol(props.claim) + ' ' + parseFloat(replacement.quote.price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </p>

                            <div className="flex items-center">

                                {/* SUPPLIER IMAGE */}
                                <BasicImage
                                    src={supplierImageUrl}
                                    fallbackSrc={'/org-logo-placeholder.jpg'}
                                    alt="Supplier logo"
                                    sizeWidthRem="2"
                                    sizeHeightRem="2"
                                    className="flex-shrink-0 rounded-full"
                                />

                                {/* SUPPLIER NAME*/}
                                <span
                                    className="ml-2">{winningSupplierInPanel?.name || replacementQuote.supplier.display_name}</span>
                            </div>

                        </div>

                    }

                </div>

            </div>
        );

    };

    if (props.showDesktopAssessment) return <ReplacementProductCard_DesktopAssess {...props} item={item}/>;

    // Return a different card depending on the status of the item
    if (itemStatus === 'CALCULATION_IN_PROGRESS') return replacementProductCard_calculationInProgress();
    if (itemStatus === 'VERIFICATION') return <ReplacementProductCard_DesktopAssess {...props} item={item}/>;
    if (itemStatus === 'CANNOT_BE_VERIFIED') return replacementProductCard_CannotBeVerified();
    if (itemStatus === 'NEW') return replacementProductCard_tender();
    if (itemStatus === 'TENDERING') return replacementProductCard_tender();
    if (itemStatus === 'UNABLE_TO_TENDER' || itemStatus === 'DELETED' || itemStatus === 'EXPIRED') return replacementProductCard_unableToTender();
    if (itemStatus === 'POST_TENDER_INSPECTION') return replacementProductCard_postTender();
    if (itemStatus === 'QUANTIFIED') return replacementProductCard_recommendedReplacement();

}

function ReplacementProductCard_DesktopAssess(props) {

    // IDLE, PENDING, COMPLETE, FAILED
    const [submittedDesktopAssessment, setSubmittedDesktopAssessment] = useState('IDLE');

    // IDLE, NONE, EXISTS, QUERYING
    const [desktopAssessmentState, setDesktopAssessmentState] = useState('IDLE');
    useEffect(() => {
        if (!isEmpty(props.item.desktop_assessment)) {
            setDesktopAssessmentState('EXISTS');
        } else {
            setDesktopAssessmentState('NONE');
        }
    }, [props.item]);

    useEffect(() => {
        // Do not trigger on page load
        if (!props.desktopAssessAllItems) return;

        const itemIsUnverified = props.item.status === 'VERIFICATION';

        // Fetch desktop assessment if the item is unverified and has no desktop assessment data
        if (itemIsUnverified && isEmpty(props.item.desktop_assessment)) {
            onInitiateDesktopAssessing();
        }
    }, [props.desktopAssessAllItems]);

    useEffect(() => {
        // Do not trigger on page load
        if (!props.submitAllDesktopAssessments) return;

        // Submit this item's desktop assessment
        onSubmitAssessment();
    }, [props.submitAllDesktopAssessments]);

    useEffect(() => {
        // Update certain fields affected by the delivery fee
        addDeliveryFeeToDescription();
    }, [props.includeDeliveryFee_DA]);

    function addDeliveryFeeToDescription() {
        /** If includeDeliveryFee is true, the delivery fee is added to the quotes description */

        // If no quote is selected, return
        if (isEmpty(props.item.selected_quote)) return;

        let description = props.item.selected_quote.description;
        const deliveryFee = props.item.selected_quote.delivery_cost_extract || '';

        // Remove a previous delivery fee from the description
        if (description.includes(' (Delivery: ')) description = description.split(' (Delivery: ')[0];
        if (description.includes(' (Free delivery)')) description = description.split(' (Free delivery)')[0];

        if (props.includeDeliveryFee_DA) {
            if (deliveryFee) {
                description += ` (Delivery: ${currencySymbol(props.claim)}${deliveryFee || '-'})`;
            }
            if (props.item.selected_quote.free_delivery) {
                description += ' (Free delivery)';
            }
        }

        props.item.selected_quote.description = description;

        updateSelectedQuote(props.item.selected_quote);
    }

    function onInitiateDesktopAssessing(buttonHandler) {

        const query = `
        query DesktopAssessmentRequest{
          request_desktop_assessment(
            claim_id:"${props.claimId}" 
            item_ids:["${props.item.id}"]
          ){
            error{
              message
              type
            }
            items{
              id
              product{
                brand
                model_numbers
                description
              }
              claimant_quote
              desktop_assessment{
                entry{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                mid{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                high{
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  supplier_name
                  url_valid
                }
                product_list {
                  delivery_cost_extract
                  free_delivery
                  price
                  source_url
                  thumbnail
                  supplier_name
                  brand
                  model_number
                  description
                  price_class
                }
              }
            }
          }
        }
        `;

        setDesktopAssessmentState('QUERYING');

        customGraphRequest(
            query,
            (data) => {
                // Select the entry quote by default for each item

                // Merge the component's item and the desktop assessment item
                const desktopAssessmentItem = data.items?.[0] || {};
                const mergedItem = {...props.item};
                mergedItem.desktop_assessment = desktopAssessmentItem.desktop_assessment;

                // Update the item in the claim
                props.setClaim((prev) => {
                    const claimCopy = {...prev};
                    const _item = claimCopy.items.find(i => i.id === mergedItem.id);
                    _item.desktop_assessment = mergedItem.desktop_assessment;
                    return claimCopy;
                });

                setDesktopAssessmentState('EXISTS');

                // Show the "Continue Assessment" text on the desktop assessing button
                buttonHandler?.reset();
            },
            (error) => {
                setDesktopAssessmentState('NONE');
                props.onError(error);
                buttonHandler?.onError();
            },
        );

    }

    function updateSelectedQuote(quoteAndProduct) {
        // Update the selected quote for the item
        props.setClaim((prev => {
            const claimCopy = {...prev};
            const _item = claimCopy.items.find(i => i.id === props.item.id);
            _item.selected_quote = quoteAndProduct;
            return claimCopy;
        }));
    }

    function getBrandRecommendations() {
        /* Return a list of terms for the brand dropdown */
        if (!props.item.selected_quote || !props.item.selected_quote?.description) return [];
        return cumulativeSplit(props.item.selected_quote.description);
    }

    function getModelRecommendations() {
        /* Return a list of terms for the model number dropdown */
        if (!props.item.selected_quote || !props.item.selected_quote?.description) return [];

        // Remove the brand from the beginning of the description
        let description = props.item.selected_quote.description;
        const brandWords = props.item.selected_quote.brand?.split(' ') || [];
        for (const word of brandWords) {
            // replace the first occurrence of the word
            description = description.replace(word, '').trim();
        }

        return cumulativeSplit(description);
    }

    function cumulativeSplit(text) {
        /**
         * Returns cumulative phrases from the input string
         *
         * @example
         * text = "this is a test string"
         * // Output: ["this", "this is", "this is a", "this is a test", "this is a test string"]
         */

        const words = text.split(' ');
        // Remove the first word if it is not a letter or number
        if (!/[a-zA-Z0-9]/.test(words[0])) words.shift();

        const result = [];
        for (let i = 1; i <= words.length; i++) {

            // If the last word (next additional word) does not contain a letter or a number
            // then ignore that phrase
            // E.g: "this is a test -" should be ignored, since only a "-" has been added
            const phraseWords = words.slice(0, i);
            const lastWord = phraseWords[i - 1];

            if (/[a-zA-Z0-9]/.test(lastWord)) {
                const phrase = phraseWords.join(' ');
                result.push(phrase);
            }
        }
        return result;
    }

    function onSelectQuote(product) {
        // Update the selected quote for the item

        // -- Delivery fee in the description
        let description = product.description;

        // Remove a previous delivery fee from the description
        if (description.includes(' (Delivery: ')) description = description.split(' (Delivery: ')[0];

        if (props.includeDeliveryFee_DA) {
            if (product.delivery_cost_extract) {
                description += ` (Delivery: ${currencySymbol(props.claim)}${product.delivery_cost_extract || '-'})`;
            }
            if (product.free_delivery) {
                description += ' (Free delivery)';
            }
        }

        // -- Auto populate brand and model number
        let brand = product.brand;
        let model_number = product.model_number || product.model_numbers?.[0];
        try {
            // replace all none letter or number characters with a space
            const descriptionWords = product?.description?.replaceAll(/[^a-zA-Z0-9]/g, ' ').split(' ') || ['-'];
            brand = descriptionWords[0];

            // model number is the second, to 5th word in the description
            model_number = descriptionWords.slice(1, 5).join(' ');
        } catch (e) {
            console.error('Error creating brand and model from description: ', e);
        }

        const selected_quote = {
            ...product,
            model_number: model_number,
            brand: brand,
            original_quote: {
                ...product,
                model_number: product.model_number || product.model_numbers?.[0],
            },
            description: description,
        };

        updateSelectedQuote(selected_quote);
    }

    function getQuotePrice(quote) {
        /* A desktop assessment object as a input
        * Return the quote price, optionally including the delivery fee */

        if (!quote || !quote.price) return '-';

        const price = parseFloat(quote.price) || 0;
        const deliveryCost = parseFloat(quote.delivery_cost_extract) || 0;
        const freeDelivery = quote.free_delivery || false;

        let totalPrice = props.includeDeliveryFee_DA ? price + deliveryCost : price;
        if (freeDelivery) totalPrice = price;

        // Round down to two decimal points
        totalPrice = Math.floor(totalPrice * 100) / 100;

        return totalPrice;
    }

    function onSubmitAssessment(buttonHandler) {
        /* If any selected_quotes are missing required fields,
        warn the user that these will be ignored */

        let invalidQuote = false;

        const item = props.item;

        if (isEmpty(item.selected_quote)) {
            // If no quote is selected, just return
            return;
        }

        if (!item.selected_quote ||
            !item.selected_quote.brand ||
            !item.selected_quote.model_number ||
            !item.selected_quote.description ||
            !item.selected_quote.price ||
            !item.selected_quote.source_url
        ) {
            invalidQuote = true;
        }

        if (invalidQuote) {

            buttonHandler?.reset();

            props.showAlertModal(
                'info',
                'Missing fields',
                (<div>
                    <p>Item: <i>{item.product.common_name}</i></p>
                    <hr className='my-2'/>
                    <b>Please ensure all the fields are filled:</b>
                    <p>Brand, Model number, Price, Description, Source url</p>
                </div>),
            );
        } else {

            // Prevent multiple submissions at once, by adding a random delay
            const randomDelay = Math.floor(Math.random() * 1000); // Random delay between 0 and 1 second

            setTimeout(submitDesktopAssessment, randomDelay);
        }

    }

    function submitDesktopAssessment(buttonHandler) {

        buttonHandler?.onLoading();

        const payloads = assessmentPayload();

        if (isEmpty(payloads)) {
            props.showAlertModal(
                'info',
                'No items/levels selected',
                'No levels for any items have been selected for desktop assessment',
            );
            buttonHandler?.onError('No items selected for desktop assessment');
            return;
        }

        if (isEmpty(props.selectedSupplier_DA)) {
            props.showAlertModal(
                'info',
                'Select a supplier',
                'No supplier has been selected',
            );
            buttonHandler?.onError();
            return;
        }

        const quote = props.item.selected_quote;

        if (!quote) {
            buttonHandler?.reset();
            props.showAlertModal(
                'info',
                'Missing fields',
                'No quote selected',
            );
        }
        if (!quote.brand ||
            !quote.model_number ||
            !quote.description ||
            !quote.source_url ||
            !quote.thumbnail
        ) {
            props.showAlertModal(
                'info',
                'Missing fields',
                <div>
                    <p>Please ensure all the fields are filled:</p>
                    <p><b>Brand:</b> <i>quote.brand</i></p>
                    <p><b>Model number:</b> <i>quote.model_number</i></p>
                    <p><b>Description:</b> <i>quote.description</i></p>
                    <p><b>Source url:</b> <i>quote.source_url</i></p>
                    <p><b>Thumbnail:</b> <i>quote.thumbnail</i></p>
                </div>,
            );
            return;
        }

        const mutation = `
            mutation ResolveAssessment {
              resolve_desktop_assessment_for_item(
                item_id: "${props.item.id}"
                supplier_id: "${props.selectedSupplier_DA.id}"
                brand: "${escapeDoubleQuotes(quote.brand)}"
                model_number: "${escapeDoubleQuotes(quote.model_number)}"
                description: "${escapeDoubleQuotes(quote.description)}"
                price: ${getQuotePrice(quote)}
                source_url: "${quote.source_url}"
                thumbnail: "${quote.thumbnail}" 
              ){
                error{type, message}
                item {
                  id
                  is_tender_item
                  claimant_quote
                  is_manual
                  status
                  quantity
                  status_reason
                  type
                  metadata{
                    claimed_amount
                    comment
                    date_purchased
                    incident_location
                    purchase_price
                    purchase_source
                    replacement_quote
                    attachments{
                      id
                      object_type
                      content_type
                      date_created
                      date_updated
                      file_name
                      object_id
                      user{
                        id
                        username
                      }
                      url
                    }
                  }
                  category {
                    id
                    category_a
                    display_name
                  }
                  
                  supplier_panel {
                    id
                    name
                    unique_reference_name
                    status
                    supplier {
                      id
                      info {
                        name
                        country
                        currency
                        currency_symbol
                        logo_image_url
                      }
                    }
                    recommended_replacement {
                      product {
                        id
            
                        brand
                        category
                        common_name
                        description
            
                        status
                        type
            
                        metadata
                        model_number
                        model_numbers
            
                        images {
                          main
                          thumbnail_web
                          thumbnail_print
                        }
                        
                      }
                      quote {
                        id
                        status
                        country
                        currency
                        currency_symbol
                        is_manual
                        price
                        brand
                        description
                        supplier {
                          id
                          unique_reference_name
                          display_name
                        }
                      }
                    }
                  }
                  
                  desktop_assessment{
                    selected_by
                    selected_supplier
                    
                    selected_product{
                      delivery_cost_extract
                      free_delivery
                      price
                      rating
                      reviews
                      source_url
                      thumbnail
                      supplier_name
                      brand
                      model_number
                      description
                      price_class
                    }
                    
                    product_list{
                      delivery_cost_extract
                      free_delivery
                      price
                      rating
                      reviews
                      source_url
                      thumbnail
                      supplier_name
                      brand
                      model_number
                      description
                      price_class
                    }
            
                    entry{
                      delivery_cost_extract
                      free_delivery
                      price
                      source_url
                      supplier_name
                      url_valid
                    }
                    mid{
                      delivery_cost_extract
                      free_delivery
                      price
                      source_url
                      supplier_name
                      url_valid
                    }
                    high{
                      delivery_cost_extract
                      free_delivery
                      price
                      source_url
                      supplier_name
                      url_valid
                    }
                    
                    product_list {
                      price
                    }
                  }
                  
                  possible_replacements {
                    quotes {
                      id
                      supplier {
                        unique_reference_name
                      }
                    }
                  }
                  product {
                    id
            
                    brand
                    category
                    common_name
                    description
            
                    status
                    type
            
                    metadata
                    model_number
                    model_numbers
                    
                    ai_generated
            
                    images {
                      main
                      thumbnail_web
                      thumbnail_print
                    }
                    
                  }
                  replacement {
                    product {
                      id
            
                      brand
                      category
                      common_name
                      description
            
                      status
                      type
            
                      metadata
                      model_number
                      model_numbers
            
                      images {
                        main
                        thumbnail_web
                        thumbnail_print
                      }
                      
                    }
                    quote {
                      id
                      status
                      country
                      currency
                      currency_symbol
                      price
                      brand
                      date_expiry
                      is_manual
                      description
                      match_accuracy
                      supplier {
                        id
                        unique_reference_name
                        display_name
                      }
                    }
                    note
                    date_selected
                    selected_by
                    is_system_selected
                  }
                }
              }
            }
        `;

        setSubmittedDesktopAssessment('PENDING');

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal(
                    'success',
                    'Resolved Successfully',
                    'The desktop assessment has been submitted successfully',
                );
                buttonHandler?.onSuccess();
                props.setShowDesktopAssessment(false);
                setSubmittedDesktopAssessment('SUCCESS');

                // Update the item in the claim
                props.setClaim((prev) => {
                    const claimCopy = {...prev};
                    let _item = claimCopy.items.find(i => i.id === data.item.id);
                    _item = {..._item, ...data.item};
                    return claimCopy;
                });

            },
            (error) => {
                setSubmittedDesktopAssessment('FAILURE');
                props.onError(error);
                buttonHandler?.onError();
            },
        );

    }

    // TODO - Remove this function
    function submitDesktopAssessment_old(buttonHandler) {

        buttonHandler.onLoading();

        const payloads = assessmentPayload();

        if (isEmpty(payloads)) {
            props.showAlertModal(
                'info',
                'No items/levels selected',
                'No levels for any items have been selected for desktop assessment',
            );
            buttonHandler.onError('No items selected for desktop assessment');
            return;
        }

        if (isEmpty(props.selectedSupplier_DA)) {
            props.showAlertModal(
                'info',
                'Select a supplier',
                'No supplier has been selected',
            );
            buttonHandler.onError();
            return;
        }

        const mutation = `
            mutation ResolveAssessment {
              resolve_desktop_assessment(
                claim_id: "${props.claimId}"
                supplier_id: "${props.selectedSupplier_DA.id}"
                assessment_payloads: [${payloads}]
              ){
                error{type, message}
                items{
                  id
                }
              }
            }
        `;

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal(
                    'success',
                    'Resolved Successfully',
                    'The desktop assessment has been submitted successfully',
                );
                buttonHandler.onSuccess();
                props.getFullClaim();
                props.setShowDesktopAssessment(false);
            },
            (error) => {
                props.onError(error);
                buttonHandler.onError();
            },
        );

    }

    function assessmentPayload() {
        /* Create the assessment payload
            assessment_payloads:[
                  {
                    item_id: ""

                    // original product
                    brand: ""
                    description: ""
                    model_number: ""

                    // selected quote
                    desktop_replacements: [
                      {
                        brand: "Chaka"
                        description: "Bag of charcoal"
                        model_number: "ch1x"
                        price: 60
                        source_url: "https://www.example.com/source1"
                      }
                    ]

                  }
                ]
       */

        const item = props.item;

        const payload = ` 
            {
                item_id: "${item.id}",
                
                brand: "${escapeDoubleQuotes(item.product.brand)}",
                description: "${escapeDoubleQuotes(item.product.description)}",
                model_number: "${escapeDoubleQuotes(item.product.model_numbers[0])}",
                
                desktop_replacements: [
                    {
                        brand: "${escapeDoubleQuotes(item.selected_quote.brand)}"
                        description: "${escapeDoubleQuotes(item.selected_quote.description)}"
                        model_number: "${escapeDoubleQuotes(item.selected_quote.model_number)}"
                        price: ${getQuotePrice(item.selected_quote)}
                        source_url: "${escapeDoubleQuotes(item.selected_quote.source_url)}"
                    }
                ]
            }
        `;

        return payload;

    }

    // Render
    function renderDesktopAssessmentCard() {

        const item = props.item;

        return (
            <div className='flex justify-around gap-2 h-full'>

                <div className='flex flex-col items-center justify-between gap-2 w-[35%]'>

                    {/* QUOTES */}
                    <SimpleSelect
                        width='w-full bg-white'
                        selectOptionClassName='!max-h-[inherit] h-[50rem] w-[60rem] right-0 top-[-20rem] shadow-xl'
                        selectedState={null}
                        selectableOptions={item.desktop_assessment?.product_list || []}
                        onChange={(selectedProductQuote) => onSelectQuote(selectedProductQuote)}

                        renderOption={(product) =>
                            <div className='flex w-full gap-[2rem] h-[5rem] py-2'>

                                <div className='w-[5rem] shrink-0 max-h-[5rem]'>
                                    <BasicImage
                                        src={product?.thumbnail}
                                        fallbackSrc={''}
                                        alt="product"
                                        sizeWidthRem="5"
                                        className=""
                                    />
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='w-[10rem] shrink-0 flex flex-col justify-around items-start'>
                                    <p className='text-2xl font-bold'>{currencySymbol(props.claim)} &nbsp; {getQuotePrice(product)}</p>

                                    <div className='flex gap-2'>
                                        <CashIcon className='h-4 w-4 mr-1'/>
                                        <p className='text-sm flex'>{product?.price || '-'}</p>
                                    </div>

                                    <div className='flex gap-2'>
                                        <TruckIcon className='h-4 w-4 mr-1'/>
                                        <p className='text-sm flex'>
                                            {product?.delivery_cost_extract || '-'}
                                            <span
                                                className='text-xs'>&nbsp; {product?.free_delivery && ' free delivery'}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='w-[8rem] flex flex-col justify-around shrink-0 font-bold text-md'>
                                    <p>{product?.brand || '-'}</p>
                                    <p>{product?.model_number || '-'}</p>
                                    <i className='text-xs font-normal'>{product?.price_class || '-'}</i>
                                </div>

                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                <div className='flex flex-col w-full justify-between items-center text-center'>
                                    <p className='overflow-hidden text-ellipsis whitespace-normal'>{product?.description || '-'}</p>

                                    <a href={product.source_url} target='_blank'
                                       className='text-blue-700 flex gap-1' rel="noreferrer">
                                        <i>{product?.supplier_name}</i>
                                        <ExternalLinkIcon className='h-3 w-3 mt-[4px]'/>
                                    </a>
                                </div>

                            </div>
                        }

                        renderSelected={(org) =>
                            <p>{item.desktop_assessment?.product_list?.length} Quotes</p>
                        }
                    />

                    {/* PRODUCT PREVIEW */}
                    <ImageZoom
                        imageSrc={item.selected_quote?.thumbnail || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        altText="Product Preview"
                        className="w-full max-w-[8rem] max-h-[6rem] object-contain px-2"
                        zoomClassName={classNames(
                            'border-[1px] border-stone-400 rounded-md',
                            // Don't show the preview for the placeholder product image
                            item.selected_quote?.thumbnail ? 'scale-[300%]' : 'scale-[100%]',
                        )}
                    />

                    {/* BUTTONS */}
                    <div className='w-full flex justify-center gap-4'>

                        <div className='flex gap-2'>
                            {/* VERIFY */}
                            <Tooltip
                                content={<div>
                                    <p>Verify manual item</p>
                                    <hr/>
                                    <p>Send to tender</p>
                                </div>}
                                className='max-w-[400%]'>
                                <button className='btn-icon !p-2 !m-0'
                                        onClick={() => {
                                            props.onVerifyManualItem(item, 'VERIFIED');
                                        }}
                                >
                                    <ClipboardCheckIcon className='h-5 w-5'/>
                                </button>
                            </Tooltip>

                            {/* CLEAR QUOTE */}
                            <Tooltip content='Clear' className='max-w-[400%]'>
                                <button className='btn-icon !p-2 !m-0'
                                        onClick={() => {
                                            const updateProduct = {};
                                            updateSelectedQuote(updateProduct);
                                        }}
                                >
                                    <XIcon className='h-5 w-5'/>
                                </button>
                            </Tooltip>
                        </div>

                        {/* SUBMIT */}
                        <Tooltip content={<div>
                            <p>Submit product and quote</p>
                            {item.replacement &&
                                <>
                                    <hr/>
                                    <p>This will overwrite the existing replacement</p>
                                </>
                            }
                        </div>}
                                 className='max-w-[400%]'
                        >
                            <ApiButton
                                onClick={onSubmitAssessment}
                                className={'btn-icon !bg-transparent !p-2 !m-0'}
                                content={<ClipboardCopyIcon className='h-5 w-5'/>}
                                loadingContent={<LoadingSpinner color='darkcyan' size='5' body=''/>}
                            />
                        </Tooltip>

                    </div>

                </div>

                <div className='flex flex-col  justify-start gap-2'>

                    <div className='flex gap-2'>

                        {/* BRAND*/}
                        <div className='relative group'>
                            <input
                                placeholder='brand'
                                value={item.selected_quote?.brand || ''}
                                onChange={(e) => {
                                    const updateProduct = item.selected_quote;
                                    updateProduct.brand = e.target.value;
                                    updateSelectedQuote(updateProduct);
                                }}
                            />
                            <div className='invisible hover:visible group-hover:visible '>
                                <ul className="absolute top-[2rem] w-[30rem] bg-white border border-gray-300 rounded mt-0 max-h-[20rem] overflow-y-auto z-10">
                                    {getBrandRecommendations().map((phrase, index) =>
                                        <option
                                            className='hover:bg-gray-200 text-start pl-2 py-1'
                                            key={index}
                                            onClick={() => {
                                                const updateProduct = item.selected_quote;
                                                updateProduct.brand = phrase;
                                                updateSelectedQuote(updateProduct);
                                            }}
                                        >
                                            {phrase}
                                        </option>,
                                    )}
                                </ul>
                            </div>
                        </div>

                        {/* MODEL */}
                        <div className='relative group'>
                            <input
                                placeholder='model number'
                                value={item.selected_quote?.model_number || item.selected_quote?.model_numbers?.[0] || ''}
                                onChange={(e) => {
                                    const updateProduct = item.selected_quote;
                                    updateProduct.model_number = e.target.value;
                                    updateSelectedQuote(updateProduct);
                                }}
                            />

                            <div className='invisible hover:visible group-hover:visible '>
                                <ul className="absolute top-[2rem] w-[15rem] bg-white border border-gray-300 rounded mt-0 max-h-[20rem] overflow-y-auto z-10">
                                    {getModelRecommendations().map((phrase, index) =>
                                        <option
                                            className='hover:bg-gray-200 text-start pl-2 py-1'
                                            key={index}
                                            onClick={() => {
                                                const updateProduct = item.selected_quote;
                                                updateProduct.model_number = phrase;
                                                updateSelectedQuote(updateProduct);
                                            }}
                                        >
                                            {phrase}
                                        </option>,
                                    )}
                                </ul>
                            </div>
                        </div>

                    </div>

                    {/* URL */}
                    <div className='relative w-full'>
                        <input
                            className='input text-xs text-blue-700'
                            type='text'
                            placeholder={'URL'}
                            value={item.selected_quote?.source_url || ''}
                            onChange={(e) => {
                                const updateProduct = item.selected_quote;
                                updateProduct.source_url = e.target.value;
                                updateSelectedQuote(updateProduct);
                            }}
                        />
                        <a
                            className='absolute top-1 right-1 h-6 btn-transparent bg-white/70 m-0 p-1 text-gray-400 hover:text-gray-600 pointer-cursor'
                            href={item.selected_quote?.source_url}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <Tooltip content='Click to open this URL in a new tab' className='max-w-max'
                                     position='left'>
                                <ExternalLinkIcon className='h-5 w-5 '/>
                            </Tooltip>
                        </a>

                    </div>

                    <div className='flex gap-2'>

                        {/* PRICE */}
                        <Tooltip
                            className='text-start'
                            containerClassName='w-full'
                            content={
                                <div>
                                    <p>
                                        <b>Quote:</b> {currencySymbol(props.claim)} {item.selected_quote?.original_quote?.price}
                                    </p>
                                    <p>
                                        <b>Delivery:</b> {currencySymbol(props.claim)} {item.selected_quote?.original_quote?.delivery_cost_extract || 0}
                                    </p>
                                    <p>
                                        <b>Delivery Free: </b>
                                        {
                                            (item.selected_quote?.original_quote?.free_delivery === true && 'Yes') ||
                                            (item.selected_quote?.original_quote?.free_delivery === false && 'No')
                                        }
                                    </p>
                                    {item.claimant_quote &&
                                        <p>
                                            <b>Claimant
                                                quote*:</b> {currencySymbol(props.claim)} {item.claimant_quote}
                                        </p>
                                    }
                                </div>
                            }
                        >
                            <CurrencyInput
                                currencySymbol={item.claimant_quote ? currencySymbol(props.claim) + '*' : currencySymbol(props.claim)}

                                value={getQuotePrice(item.selected_quote)}
                                onChange={(e) => {
                                    const updateProduct = item.selected_quote;
                                    updateProduct.price = e.target.value;
                                    // // If the user is inputting a price, then ignore the delivery cost
                                    updateProduct.delivery_cost_extract = 0;
                                    updateSelectedQuote(updateProduct);

                                }}
                                className={classNames(
                                    'input pl-8 w-full',

                                    // If there is a claimant quote, then color the prices
                                    item.claimant_quote && parseInt(item.claimant_quote) === parseInt(item.selected_quote?.price) && 'text-yellow-600',
                                    item.claimant_quote && item.claimant_quote < item.selected_quote?.price && 'text-red-500',
                                    item.claimant_quote && item.claimant_quote > item.selected_quote?.price && 'text-green-600',
                                )}
                            />
                        </Tooltip>

                        {/* TODO */}
                        {/* ASSESSED BY */}
                        {/* <select className='m-0 py-2 w-full'> */}
                        {/*    <option>User 1</option> */}
                        {/*    <option>George Global</option> */}
                        {/* </select> */}

                    </div>


                    {/* DESCRIPTION */}
                    <div>
                        <textarea
                            placeholder='description'
                            className='input w-full'
                            value={item.selected_quote?.description || ''}
                            onChange={(e) => {
                                const updateProduct = item.selected_quote;
                                updateProduct.description = e.target.value;
                                updateSelectedQuote(updateProduct);
                            }}
                        />
                    </div>

                </div>

            </div>
        );
    }

    // Loading spinner
    if (desktopAssessmentState === 'QUERYING' || submittedDesktopAssessment === 'PENDING') {
        return (
            <LoadingSpinner/>
        );
    }

    // No desktop assessment data
    if (isEmpty(props.item.desktop_assessment)) {
        return (
            <div className="w-full h-full">
                <div className="flex justify-center items-center w-full h-full">
                    <ApiButton
                        onClick={onInitiateDesktopAssessing}
                        className={'btn-outline'}
                        content={<>
                            <SparklesIcon className='h-5 w-5 mr-2'/>
                            Fetch quotes
                        </>}
                    />
                </div>
            </div>
        );

    }

    return (
        <div className="w-full h-full">
            {renderDesktopAssessmentCard()}
        </div>
    );
}
