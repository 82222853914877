import {useState, useEffect} from 'react';
import {customGraphRequest} from "../utils/coreApi";


// =======   QUERY STATUS   =======

export const QueryStatus = {
    NOT_QUERIED: 'NOT_QUERIED', // No query has been executed
    QUERYING: 'QUERYING',       // Query is currently executing
    QUERIED: 'QUERIED',         // Query has been executed successfully
    ERROR: 'ERROR',             // Query has been executed with an error
};


// =======   CACHE QUERY HOOK   =======

export function useQuery(args) {
    /**
     * Custom React hook for managing state with additional features for querying a GraphQL endpoint.
     * This hook extends the basic useState functionality by integrating GraphQL query execution.
     *
     * @param {Object} args - The arguments object.
     * @param {any} [args.initialValue=null] - The default state value.
     * @param {Function} [args.queryStringFunction=null] - Function expected to return a GraphQL query string.
     * @param {Function} [args.onLoadQueryDelaySeconds=null] - The delay in seconds before executing the query on load.
     *
     * @param {boolean} [args.useExistingCache=false] - Whether to automatically query on load.
     * @param {boolean} [args.cacheResponse=false] - Indicates if the query response should be cached. If true, a queryStringFunction or cacheKey is required.
     * @param {boolean} [args.skipQueryIfCache=false] - Whether to skip querying on load if cache is available.
     * @param {boolean} [args.cacheExpirationMin=60] - The cache expiration time in minutes.
     * @param {boolean} [args.cacheKey=null] - The key to use for the localStorage key.
     *
     * @param {Function|null} [args.onSuccess=null] - Callback function for successful query execution.
     * @param {Function|null} [args.onError=null] - Callback function for query execution error.
     * @returns {Object} The hook returns an object with the state value and functions to manipulate it.
     */

    const _defaultArgs = {
        initialValue: null,
        queryStringFunction: null,
        dependsOn: [],
        onLoadQueryDelaySeconds: null,

        // Cache options
        useExistingCache: false,
        cacheResponse: false,
        skipQueryIfCache: false,
        cacheExpirationMin: 60,
        cacheKey: null,

        onSuccess: null,
        onError: null
    };

    args = {..._defaultArgs, ...args};

    const [state, setState] = useState(args.initialValue);

    const [queryStatus, setQueryStatus] = useState({
        status: QueryStatus.NOT_QUERIED,
        lastQuery: null
    });


    // =======   LOADING   =======

    let loading = queryStatus.status === QueryStatus.QUERYING;
    let loaded = queryStatus.status === QueryStatus.QUERIED;
    let error = queryStatus.status === QueryStatus.ERROR;

    function query() {

        let queryString = args.queryStringFunction?.() || null;
        if (!queryString) return;

        setQueryStatus({
            status: QueryStatus.QUERYING,
            lastQuery: queryString
        });

        customGraphRequest(queryString,
            (data) => {

                // Cache the query response
                // Caching immediately here avoids using a "state" approach, as the  onSuccess call might modified the data before caching
                _setCache(data)

                setState(data);
                setQueryStatus({...queryStatus, status: QueryStatus.QUERIED});
                if (args.onSuccess) args.onSuccess(data);

            },
            (error) => {
                setQueryStatus({...queryStatus, status: QueryStatus.ERROR});
                if (args.onError) args.onError(error);
            }
        );
    }

    const requery = () => query();


    // =======   CACHE   =======

    let cacheKey = () => args.queryStringFunction ? args.queryStringFunction() : args.cacheKey;

    useEffect(() => {

        // only query if all of the dependencies have been retrieved (not null)
        if (args.dependsOn.some(dep => !dep)) return;

        if (args.skipQueryIfCache && _hasCache()) {
            // Cache is available, so set state from cache, do not query on load
        } else if (args.queryStringFunction) {
            // Cache is not available, so query on load
            if (args.onLoadQueryDelaySeconds)
                setTimeout(() => query(), args.onLoadQueryDelaySeconds * 1000);
            else
                query();
        }

        // on load, instantly return existing cache if available
        if (args.useExistingCache && _hasCache()) {
            /* Because onSuccess has post-query modifications e.g data['accounts'][0],
            this post modification must take place when 'restoring' cache (cache is the raw query).
            Therefor do not directly set the state to the cache.
            Presumably, the onSuccess will set the state from the returned cache */
            if (args.onSuccess) args.onSuccess(_getCache().data);
        }

        // Run on load, or re-query if dependencies change
    }, args.dependsOn);

    const _hasCache = () => hasCache(cacheKey());
    const _getCache = () => getCache(cacheKey());
    const _setCache = (cache) => setCache(cacheKey(), cache, args.cacheExpirationMin);
    const _cacheExpired = () => cacheExpired(cacheKey());
    const _cacheVersionInvalid = () => cacheVersionInvalid(cacheKey());
    const _removeCache = () => removeCache(cacheKey());

    return {
        args,
        state, setState,
        query, requery,
        queryStatus, setQueryStatus,
        loading, loaded, error,

        hasCache: _hasCache,
        getCache: _getCache,
        setCache: _setCache,
        removeCache: _removeCache,
        cacheExpired: _cacheExpired,
        cacheVersionInvalid: _cacheVersionInvalid,
    };
}


// =======   CACHE FUNCTIONS   =======

export function hasCache(cacheKey) {
    /** Get cache in localStorage and return whether cache is valid */

    const cacheAvailable = localStorage.getItem(cacheKey) !== null;

    if (!cacheAvailable) return false;

    if (cacheExpired(cacheKey)) {
        removeCache();
        return false;
    }

    if (cacheVersionInvalid(cacheKey)) {
        removeCache();
        return false;
    }

    // Cache is available AND not expired
    return cacheAvailable;
}

export function cacheExpired(cacheKey) {
    /** Get cache in localStorage and return whether cache has expired */

    const cache = getCache(cacheKey);
    return new Date().getTime() - cache.timestamp > cache.cacheExpirationMin * 60 * 1000;
}

export function cacheVersionInvalid(cacheKey) {
    /** Get cache in localStorage and return whether cache version is different (older or newer) and therefor invalid */

    const cache = getCache(cacheKey);
    return cache.portalVersion !== getPortalVersion();
}


export function getCache(cacheKey) {
    /** Get cache in localStorage */

    return JSON.parse(localStorage.getItem(cacheKey));
}

export function getPortalVersion() {
    /**  */
    let version = process.env.REACT_APP_VERSION;
    if (!version) version = '0.0.0';
    return version;
}

export function setCache(cacheKey, data, expirationMin = 60) {
    /** Set cache in localStorage */

    if (!cacheKey || !data) return;

    const json = JSON.stringify({
        data: data,
        timestamp: new Date().getTime(),
        cacheExpirationMin: expirationMin,
        portalVersion: getPortalVersion(),
    });

    try {
        localStorage.setItem(cacheKey, json);
    } catch (error) {
        clearAllCache();
        throw error;
    }
}

export function removeCache(cacheKey) {
    localStorage.removeItem(cacheKey);
}

export function clearAllCache() {
    console.log('useQuery.jsx: Clearing all cache')
    localStorage.clear();
}

// =======   EXAMPLE   =======

/**
 function Examples() {

 // useQuery as a replacement for useState
 const {state: accounts, setState: setAccounts} = useQuery({initialValue: null});

 // ----

 // useQuery with a hook object
 const accountsHook = useQuery({initialValue: null});
 accounts.state, accounts.setState, accounts.hasCache(), accounts.getCache()...

 // ----

 // useQuery - handle querying
 const mainOrganisationHook = queryState({
     queryStringFunction: () => {
        let queryArgs = ` unique_reference_name:"${user.organisation.unique_reference_name}"`;
        let query = ORGANISATIONS.replace('|placeholder|', queryArgs);
        return query.replace('query {', `query user_organisation {`);
     },
     onSuccess: (data) => mainOrganisationHook.setState(data['organisations'][0]),
     onError: props.onError,
     cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
 });

 // drop-in replacement backwards compatibility
 const { state: mainOrganisation, setState: setMainOrganisation } = mainOrganisationHook
 mainOrganisation.id

 // ----

 // useQuery - full example
 const mainOrganisationHookFullExample = queryState({
     dependsOn: [user],  // dependencies, re-query if any of these change, AND do not query until these are all not null
     onLoadQueryDelaySeconds: null, // delay in seconds before executing the query on load, allows for convent preloading data without blocking the immediate page load
     initialValue: null, // default state value
     queryStringFunction: () => {
         let queryArgs = `organisation:"${props.mainOrganisation.id}"`;
         let query = CLAIMS_MINIMAL.replace('|placeholder|', queryArgs);
    },
     onSuccess: (data) => {}, // called on query success, can be used to re-set state e.g data['claims'][0]
     onError: props.onError,
     cacheKey: null, // if cache is used, either cacheKey or queryStringFunction is required
     cacheResponse: true, // cache new responses
     useExistingCache: true, // instantly use existing cache if available
     skipQueryIfCache: true, // skip query if cache is available
     cacheExpirationMin: 60, // cache expiration time in minutes
 });
 const { state: mainOrganisation, setState: setMainOrganisation } = mainOrganisationHook

 */
