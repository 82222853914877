// Library imports
import React, {Fragment, useLayoutEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {useLocation} from 'react-router-dom';


// Local imports
import {classNames} from '../../utils/helpers.js';
import ProfileModal from '../../containers/modals/ProfileModal';
import ClaimBasicInfoPanel from '../ClaimBasicInfoPanel';
import Safe from "../Safe";
import {BasicImage} from "../base/BasicImage";
import LimitedEnvironmentAccess from "../LimitedEnvironmentAccess";


// NavbarTop component
export default function PageBar(props) {

    const location = useLocation();
    useLayoutEffect(() => {
        /** useLayoutEffect is a version of useEffect that fires before the browser repaints the screen.
         * Clear the navbar content when navigating to a new page */
        props.setNavbarTopPageContent({});
    }, [location.pathname]);

    return (
        <div className="flex w-full px-4 flex justify-between">

            {/* CLAIM HEADER AND DETAILS */}
            <Safe> <ClaimBasicInfoPanel claim={props.navbarTopPageContent?.claim}/> </Safe>

            {/* CUSTOM CONTENT PER PAGE */}
            <div className="flex grow h-full items-center">
                {props.navbarTopPageContent?.content}
            </div>

        </div>
    );
}















