// Library imports

import React from 'react';
import {classNames} from "../utils/helpers";
import {ExternalLinkIcon} from "@heroicons/react/solid";


// Footer component
export default function Footer(props) {
    return (
        <footer className={classNames(
            "relative flex justify-center items-center",
            "border-t border-gray-200 min-h-[6rem] text-sm text-gray-400 hover:text-gray-700",
            window.location.pathname === '/login' && 'bg-white' // If we are on the login page, make the footer white
        )}>

            {/* ALL RIGHT RESERVED */}
            <p className="absolute left-1/2 transform -translate-x-1/2">
                <span>&copy; {new Date().getFullYear()}</span>
                <a href="https://www.slvrcld.com"> <b>SLVR</b>CLD BV</a>
                <span> | </span>
                <span>All rights reserved</span>
            </p>

            {/* PRIVACY POLICY AND TERMS & CONDITIONS */}
            <div className={classNames(
                'absolute left-1/2 -translate-x-1/2 bottom-[18%]',
                'pl-3',
            )}>
                <div className='flex gap-4 justify-center text-gray-500 hover:text-gray-700'>
                    {/* PRIVACY POLICY */}
                    <a href="https://www.slvrcld.com/legal/privacy-policy" target="_blank"
                       className="opacity-50 text-center text-[12px] flex justify-center items-center text-[12px]"
                       rel="noreferrer">
                        <ExternalLinkIcon className="h-4 w-4 mr-1"/>
                        Privacy Policy
                    </a>

                    {/* TERMS AND CONDITIONS */}
                    <a href="https://www.slvrcld.com/legal/terms-and-conditions" target="_blank"
                       className="opacity-50 text-center text-[12px] flex justify-center items-center text-[12px]"
                       rel="noreferrer">
                        <ExternalLinkIcon className="h-4 w-4 mr-1"/>
                        Terms & Conditions
                    </a>
                </div>
            </div>

            {/* SLVRCLD LOGO */}
            {/* Check if props.portalRepresentative is 'THESL' and conditionally render logo */}
            {props.portalRepresentative === 'THESL' &&
                <div className="flex items-center justify-end w-full gap-4 pr-12">
                    <a href="https://www.slvrcld.com/" target="_blank" rel="noopener noreferrer">
                        Powered by <b>SLVR</b>CLD
                    </a>
                    <a href="https://www.slvrcld.com/" target="_blank" rel="noopener noreferrer">
                        <img src="/logo192.png" alt="SLVRCLD logo" className="h-14"/>
                    </a>
                </div>
            }

        </footer>
    );
};
