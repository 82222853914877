import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, InformationCircleIcon } from '@heroicons/react/outline'

export default function PageInfoModal(props) {


  return (
      <div id="pageInfoModal">
      <Transition.Root show={props.pageInfoModalOpen} as={Fragment} >
      <Dialog as="div" className="relative z-10" onClose={props.setPageInfoModalOpen} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto" >
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              
              {/* MODAL */}
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 h-1/2 p-4">
                <div>
                  
                  {/* HEADING AND BODY */}
                  <div>
                    <div className="flex gap-4 p-2 justify-center items-center border-b border-gray-200">
                      <InformationCircleIcon className="h-8 w-8 stroke-cyan-800 opacity-70" aria-hidden="true" />
                      <Dialog.Title as="h3" className="text-3xl leading-6 font-medium text-gray-900 capitalize text-center">
                        {props.heading}
                      </Dialog.Title>
                    </div>
                    
                    
                    <p className="text-md text-gray-800 p-6">
                      {props.body}
                    </p>
                    
                  </div>
                  
                  {/* X CLOSE */}
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 "
                      onClick={()=>props.setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  
                  
                </div>
                
                {/* FOOTER */}
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 hover:bg-cyan-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={()=>props.setPageInfoModalOpen(false)}
                  >
                    OK
                  </button>
                </div>
                
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      
      </div>
  )
}
