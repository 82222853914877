// Library imports

import React, {useEffect, useState} from 'react'
import {Combobox} from '@headlessui/react'

import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'

import {classNames} from '../utils/helpers.js';
import {BasicImage} from "./base/BasicImage";
import LoadingSpinner from "./ui/LoadingSpinner";

export default function OrganisationBox(props) {

    const [query, setQuery] = useState('');

    const [selectableOrgs, setSelectableOrgs] = useState([]);

    useEffect(() => {

        // when the list of organizations has been updated (due to a selected country change),
        // then, from the new list of organizations(of that country), we must update this components list of selectable organizations
        updateSelectableOrgs();

    }, [props.primaryOrganisations, query])

    const updateSelectableOrgs = () => {
        if (!props.primaryOrganisations) {
            return;
        }

        let filteredOrgs = props.primaryOrganisations;

        // if there are no selected organizations, inform the user
        if (!filteredOrgs || !filteredOrgs.length) {
            return;
        }

        if (query === '') {

        } else {
            filteredOrgs = props.primaryOrganisations.filter((org) => {
                return org.unique_reference_name.toLowerCase().includes(query.toLowerCase());
            });
        }

        setSelectableOrgs(filteredOrgs)

        // now that we have filtered the organizations that are selectable, we need to set the initial selection
        // due to many stateful updates, we add a small delay before updating this state
        setTimeout(() => props.setSelectedOption(filteredOrgs[0]), 200)
    }

    if (!props.organisations) {
        return <LoadingSpinner color='darkblue' size='6' text='text-md' />
    }

    return (
        <Combobox as="div" value={props.selectedOption !== null ? props.selectedOption : ""}
                  onChange={props.setSelectedOption}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="input"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(org) => org !== null && org.info !== undefined ? org.info.name : ""}
                />
                <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </Combobox.Button>

                {selectableOrgs.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                        {selectableOrgs.map((organisation) => (
                            <Combobox.Option
                                key={organisation.id}
                                value={organisation}
                                className={({active}) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({active, selected}) => (
                                    <>
                                        <div className="flex items-center">
                                            <BasicImage
                                                src={organisation.info.logo_image_url}
                                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                                alt="logo"
                                                sizeWidthRem="1.7"
                                                className="rounded-full"
                                            />
                                            <span
                                                className={classNames('ml-3 truncate', selected && 'font-semibold')}>{organisation.info.name}</span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}

                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );

}
