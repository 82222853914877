const ACCOUNTS = `
query {
  accounts(|placeholder|) {
    error {
      type
      message
    }
    
    accounts {
      id
      status
      type
      organisation_1 {
        unique_reference_name
        id
        type
        users {
          id
        }
        info {
          name
          country
          country_text
          website
          currency
          logo_image_url
        }
      }
      organisation_2 {
        unique_reference_name
        id
        type
        users {
          id
        }
        info {
          name
          country
          country_text
          website
          currency
          logo_image_url
        }
      }
      
      
      sub_accounts{
        id
        type
        status
        organisation_1 {
          unique_reference_name
          id
          type
          info {
              name
              country
              country_text
              website
              currency
              logo_image_url
          }
        }
        organisation_2 {
          unique_reference_name
          id
          type
          info {
              name
              country
              country_text
              website
              currency
              logo_image_url
          }
        }
      }
      
      
    }
    
  }
}

`

const ADD_CATALOGUE_PRODUCT_TO_CLAIM = `
mutation {
  add_catalogue_product_to_claim(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const ADD_MANUAL_PRODUCT_TO_CLAIM = `
mutation {
  add_manual_product_to_claim(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
      claim

      category {
        id
        category_a
        category_b
        category_c
        category_d
        display_name
      }

      date_created
      date_updated

      is_manual
      quantity

      product {
        id
        date_created
        date_updated

        date_verified
        date_released
        dates_released

        brand
        category
        common_name
        description

        status
        type

        ean_codes
        metadata
        model_number
        model_numbers

        images {
          main
          thumbnail_web
          thumbnail_print
        }
        properties {
          name
          display_name
          value
        }
      }
    }
  }
}
`

const ADD_REPLACEMENT = `
mutation {
  add_replacement(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const APPROVE_POST_TENDER_ITEM = `
mutation {
  approve_post_tender_item(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const CATEGORIES = `
query {
  categories(sort_by:[{ field:"display_name" order:ASCENDING }]) {
    error {
      type
      message
    }
    categories {
      id
      category_a
      category_b
      category_c
      category_d
      display_name
    }
  }
}
`

const CLAIMS_MINIMAL = `
query{
    claims(|placeholder|){
        error{
          type
          message
        }
        claims{
            id
            date_created
            created_by{
                id
                username
            }
            owned_by{
                id
                username
            }
        }
    }
}
` // minimal fields for all claims for organisation with user included

const CLAIMS_SEARCH = `
query {
  claims(|placeholder|) {
    error {
      type
      message
    }
    statuses
    count
    claims {
      country
      created_by {
        info {
          first_name
          last_name
          profile_image_url
        }
      }
      date_sla_expiry
      date_tender_expiry
      date_updated
      id
      insurer_claim_number
      item_count
      organisation {
        id
        info {
          name
          country
          logo_image_url
        }
        unique_reference_name
      }
      owned_by {
        username
        info {
          first_name
          last_name
          profile_image_url
        }
      }
      status
      status_reason
    }
  }
}
`


const CLAIMS_WITH_ITEMS = `
query {
  claims(|placeholder|) {
    error {
      type
      message
    }
    
    count
    
    claims {
      cc_recipients{
        id,
        email,
        full_name,
        first_name
        last_name,
      }
      attachments{
        id
        object_type
        content_type
        date_created
        date_updated
        file_name
        object_id
        user{
          id
          username
        }
        url
      }
      country
      metadata
      location_text
      total_cost
      tat_minutes
      cost_vat
      claimant_report_s3_key
      comments{
        message
      }
      cost_subtotal
      created_by {
        id
        info {
          first_name
          last_name
          profile_image_url
        }
        status
        status_reason
        username
      }
      delivery_costs {
        supplier_id
        supplier_name
        delivery_cost
        is_winning_tender
        is_adhoc
      }
      date_created
      date_quantified
      date_quantification_requested
      date_sla_expiry
      date_tender_expiry
      date_updated
      id
      insurer_claim_number
      insurer_policy_number
      item_count
      item_count_unique
      locality
      organisation {
        id
        info {
          name
          country
          currency
          currency_symbol
          logo_image_url
        }
        unique_reference_name
      }
      suppliers {
        id
        display_name
        unique_reference_name
        logo_image_url
      }
      owned_by {
        id
        status
        username
        info {
          first_name
          last_name
          profile_image_url
        }
      }
      quantification_requested_by {
        id
        info {
          first_name
          last_name
        }
      }
      postal_code
      locality
      status
      status_reason
      
      possible_items{
        brand
        category{
          category_a
          category_b
          category_c
          category_d
          display_name
          id
        }
        claimant_quote
        description
        model_number
        proto_products{
          id
          brand
          common_name
          model_number
          description
          category          
          images{
            thumbnail_web
          }
        }
        quantity
      }
      
      items {
        claimant_quote
        id
        date_created
        date_updated
        is_tender_item
        is_manual
        status
        status_reason
        type
        metadata{
          claimed_amount
          comment
          date_purchased
          incident_location
          purchase_price
          purchase_source
          replacement_quote
          attachments{
            id
            object_type
            content_type
            date_created
            date_updated
            file_name
            object_id
            user{
              id
              username
            }
            url
          }
        }
        category {
          id
          category_a
          category_b
          category_c
          category_d
          display_name
        }
        quantity
        supplier_panel {
          id
          name
          unique_reference_name
          status
          ranking
          time_to_quote_minutes
          supplier {
            is_adhoc
            id
            info {
              name
              country
              currency
              currency_symbol
              logo_image_url
            }
          }
          recommended_replacement {
            product {
              id
              date_created
              date_updated

              date_verified
              date_released
              dates_released

              brand
              category
              common_name
              description

              status
              type

              ean_codes
              metadata
              model_number
              model_numbers

              images {
                main
                thumbnail_web
                thumbnail_print
              }
              properties {
                name
                display_name
                value
              }
            }
            quote {
              id
              status
              country
              currency
              currency_symbol
              is_manual
              price
              brand
              description
              supplier {
                id
                unique_reference_name
                display_name
              }
            }
          }
        }
        possible_replacements {
          product {
            id
            date_created
            date_updated

            date_verified
            date_released
            dates_released

            brand
            category
            common_name
            description

            status
            type

            ean_codes
            metadata
            model_number
            model_numbers

            images {
              main
              thumbnail_web
              thumbnail_print
            }
            properties {
              name
              display_name
              value
            }
          }
          quotes {
            id
            status
            country
            currency
            is_manual
            currency_symbol
            description
            price
            brand
            supplier {
              id
              unique_reference_name
            }
          }
        }
        product {
          id
          date_created
          date_updated

          date_verified
          date_released
          dates_released

          brand
          category
          common_name
          description

          status
          type

          ean_codes
          metadata
          model_number
          model_numbers
          
          ai_generated

          images {
            main
            thumbnail_web
            thumbnail_print
          }
          properties {
            name
            display_name
            value
          }
        }
        replacement {
          product {
            id
            date_created
            date_updated

            date_verified
            date_released
            dates_released

            brand
            category
            common_name
            description

            status
            type

            ean_codes
            metadata
            model_number
            model_numbers

            images {
              main
              thumbnail_web
              thumbnail_print
            }
            properties {
              name
              display_name
              value
            }
          }
          quote {
            id
            status
            country
            currency
            currency_symbol
            price
            brand
            date_expiry
            is_manual
            description
            match_accuracy
            supplier {
              id
              unique_reference_name
              display_name
            }
          }
          note
          date_selected
          selected_by
          is_system_selected
        }
      }
    }
  }
}
`

const CONVERT_MANUAL_ITEM_TO_CATALOGUE_ITEM = `
mutation {
  convert_manual_item_to_catalogue_item(|placeholder|) {
    error {
      message
      type
    }
    item {
      id
      status
    }
  }
}
`

const CREATE_ORGANISATION = `
mutation {
  create_organisation(|placeholder|) {
    error {
      type
      message
    }
    organisation {
      id
      unique_reference_name
      info{
        name
      }
    }
  }
}
`

const CREATE_USER = `
mutation {
  create_user(|placeholder|) {
    error {
      type
      message
    }
    user{
      id
    }
  }
}
`

const CREATE_CLAIM = `
mutation {
  create_claim(|placeholder|) {
    error {
      type
      message
    }
    claim {
      id
      status
    }
  }
}
`

const CREATE_MANUAL_QUOTE = `
mutation {
  create_manual_quote(|placeholder|) {
    error {
      type
      message
    }
    quote {
      id
      status
    }
  }
}
`

const SUPPLIER_SET_PREFERRED_REPLACEMENT = `
mutation {
  supplier_set_preferred_replacement(|placeholder|) {
    error {
      type
      message
    }
    item{
      id
      claim
    }
  }
}
`

const CREATE_SETTLEMENT = `
mutation {
  create_settlement(|placeholder|) {
    error {
      type
      message
    }
    settlement {
      id
      country
      status
      settlement_engine_status
      method
      currency
      value
      excess
    }
  }
}
`

const CREATE_MANUAL_REPLACEMENT = `
mutation {
  create_manual_replacement(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
      claim

      category {
        id
        category_a
        category_b
        category_c
        category_d
        display_name
      }

      date_created
      date_updated

      is_manual
      quantity

      product {
        id
        date_created
        date_updated

        date_verified
        date_released
        dates_released

        brand
        category
        common_name
        description

        status
        type

        ean_codes
        metadata
        model_number
        model_numbers

        images {
          main
          thumbnail_web
          thumbnail_print
        }
        properties {
          name
          display_name
          value
        }
      }
    }
  }
}
`

const CURRENT_USER = `
query {
  current_user(|placeholder|) {
    error {
      type
      message
    }
    user {
      id
      date_created
      date_updated
      info {
        first_name
        last_name
        profile_image_url
        mfa_enabled
      }
      roles {
      name
      }
      organisation {
        id
        info {
          name
          country
          country_text
          currency
          currency_symbol
        }
        type
        unique_reference_name
      }
      status
      status_reason
      username
    }
  }
}
`

const USER_FULL = `
query {
  users(|placeholder|){
    error {
      type
      message
    }
    users{
      id
      organisation{
        id
        type
        unique_reference_name
      }
      date_created  
      date_updated
      status
      status_reason
      username
      info{
        first_name
        last_name
        full_name
        profile_image_url
      }
      roles{
        id
        date_created
        date_updated
        organisation
        name
        status
        # permissions{
        #   account
        #   actions
        # }
      }
      permissions{
        account
        actions
      }
    }
  }
}
`

const ROLES = `
query {
  roles(|placeholder|){
    roles{
      name
      id
      permissions{
        account
        actions
      }
    }
  }
}
`

const DEACTIVATE_USER = `
mutation {
  deactivate_user(|placeholder|) {
    error{
      type
      message
    }
    user{
      id
      status
    }
  }
}
`

const DEACTIVATE_ORGANISATION = `
mutation {
  deactivate_organisation(|placeholder|) {
    error{
      type
      message
    }
    organisation{
      id
      status
    }
  }
}
`

const ACTIVATE_ORGANISATION = `
mutation {
  activate_organisation(|placeholder|) {
    error{
      type
      message
    }
    organisation{
      id
      status
    }
  }
}
`

const ACTIVATE_ACCOUNT = `
mutation {
  activate_account(|placeholder|) {
    error{
      type
      message
    }
    account{
      id
      status
    }
  }
}
`

const DEACTIVATE_ACCOUNT = `
mutation {
  deactivate_account(|placeholder|) {
    error{
      type
      message
    }
    account{
      id
      status
    }
  }
}
`


const DELETE_CLAIM = `
mutation {
  delete_claim(|placeholder|) {
    error {
      message
      type
    }
    claim {
      id
      date_created
      date_updated
      status
    }
  }
}
`

const DELETE_ITEM = `
mutation {
  delete_item(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
      quantity
    }
  }
}
`

const DELETE_MANUAL_QUOTE = `
mutation { 
  delete_manual_quote(|placeholder|) {
    error {
      type
      message
    }
    quote {
      id
      status
    }
  }
}
`

const DELETE_TENDER = `
mutation { 
  delete_tender(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const DISMISS_ALL_PENDING_TENDER = `
mutation {
  dismiss_all_pending_tender(|placeholder|) {
    error {
      message
      type
    }
    claim {
      id
      date_created
      date_updated
      status
    }
  }
}
`

const DISMISS_TENDER = `
mutation {
  dismiss_tender(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const FILTER_SEARCH = `
query {
  filter_search(|placeholder|) {
    error {
      type
      message
    }
    brands{
      brand
      is_supported
      families{
        family
        categories
      }
      categories{
        families
        category
      }
    }
    auto_suggest
    count
    ai_generated
    ai_data_lacking
    found_exact_matches
    result_reason
    filters {
      name
      values {
        value
        count
      }
      display_name
    }
    products {
      id
      date_updated
      date_released
      dates_released

      brand
      category
      common_name
      description

      ean_codes
      model_number
      model_numbers
      
      ai_generated
      type

      images {
        main
        thumbnail_web
        thumbnail_print
      }
      properties {
        display_name
        name
        value
      }
    }
  }
}
`

export const PROTO_PRODUCT_PROPERTIES = `
query {
  proto_product_properties(|placeholder|) {
    error {
      type
      message
    }
    product {
      id
      date_updated
      date_released
      dates_released

      brand
      category
      common_name
      description

      ean_codes
      model_number
      model_numbers
      
      ai_generated
      type

      images {
        main
        thumbnail_web
        thumbnail_print
      }
      properties {
        display_name
        name
        value
      }
    }
  }
}`

export const PRODUCT_PROPERTIES = `
query {
  product_properties(|placeholder|) {
    error {
      type
      message
    }
    product {
      id
      date_updated
      date_released
      dates_released

      brand
      category
      common_name
      description

      ean_codes
      model_number
      model_numbers
      
      ai_generated
      type

      images {
        main
        thumbnail_web
        thumbnail_print
      }
      properties {
        display_name
        name
        value
      }
    }
  }
}`


const GENERATE_REPORT_DOWNLOAD_URL = `
mutation {
  generate_report_download_url(|placeholder|) {
    error {
      type
      message
    }
    url
  }
}
`



const UPLOAD_FILE = `
mutation upload_file($file: Upload!) {  
  upload_file(|placeholder|) {
    error {type, message}
    file{
      id
      date_created
      date_updated
      file_name
      url
      user{
        id
      }
      object_id
      object_type
      content_type
    }
  }
}
`


const _UPLOAD_FILE = `
mutation upload_file($file: Upload!) {  
  upload_file(|placeholder|) {
    error {
      type
      message
    }
    id
    url
    file_name
    organisation{
      id
      unique_reference_name
    }
    user{
      id
      username
    }
    date_created
    date_updated
  }
}
`


const ORGANISATIONS = `
query {
  organisations(|placeholder|) {
    error {
      type
      message
    }
    organisations {
      id
      date_created
      date_updated
      status
      unique_reference_name
      type
      account_type
      info {
        name
        country
        country_text
        website
        currency
        logo_image_url
        snappy_info{
          status_text
          thank_you_text
          landing_text
          snappy_logo_url
        }
      }
      users {
        id
        username
        info {
          first_name
          last_name
          full_name
          profile_image_url
        }
      }
    }
  }
}
`

const ORGANISATION_OPTIONS = `
query OrganisationOptions {
  organisations(|placeholder|) {
    error {
      type
      message
    }
    organisations {
      id
      unique_reference_name
      type
      options {
        email_notification_option
        email_notification_address_claims
        email_notification_address_billing
        reduced_tender_threshold_percentage
        reduced_tender_minimum_suppliers
        enforce_unique_insurer_claim_number
        replacement_pattern
        sales_regions{
          level
          region
          type
        }
        sales_categories{
          date_created
          date_updated
          category_name
          category_level
          category_rule
          brands_included
          brands_excluded
        }
        delivery_costs{
          category_level
          category_name
          region_0{
            region
            cost
          }
          region_1{
            region
            cost
          }
          region_2{
            region
            cost
          }
        }
        allow_manual_quotes
        voucher{
          default_name
          validity_period_months
          supported_request_type
        }
        invoice{
          generation_type
          xero_id
          credit_facility_amount
          payment_term_days
          discount_percentage
        }
      }
      
    }
  }
}
`

const SETTLE = `
mutation {
  settle(|placeholder|) {
    error {
      type
      message
    }
    settlement {
      id
      country
      status
      settlement_engine_status
      method
      currency
      value
      excess
    }
  }
}
`

const SETTLEMENTS = `
query {
  settlements(|placeholder|) {
    error {
      type
      message
    }
    settlements {
      id
      country
      created_by {
        id
      }
      organisation_insurer {
        id
      }
      organisation_supplier {
        id
        info {
          name
          logo_image_url
        }
      }
      date_created
      date_updated
      claim {
        id
      }
      items {
        id
      }
      item_count
      note
      metadata
      status
      settlement_engine_status
      change_records{
        date_updated
        user {
          id
          username
        }
        change_type
        message
        before
        after
      }
      method
      currency
      value
      excess
    }
  }
}
`

const UPDATE_ITEM_QUANTITY = `
mutation {
  update_item_quantity(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const UPDATE_MANUAL_ITEM = `
mutation {
  update_manual_item(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      date_created
      date_updated
      status
      is_manual
      claim
      product {
        id
        date_created
        date_updated

        date_verified
        date_released
        dates_released

        brand
        category
        common_name
        description

        status
        type

        ean_codes
        metadata
        model_number
        model_numbers

        images {
          main
          thumbnail_web
          thumbnail_print
        }
        properties {
          name
          display_name
          value
        }
      }   
    }
  }
}
`

const UPDATE_QUOTE_PRICE = `
mutation { 
  update_quote_price(|placeholder|) {
    error {
      type
      message
    }
    quote {
      id
      status
    }
  }
}
`

const UPDATE_ORGANISATION = `
mutation { 
  update_organisation(|placeholder|) {
    error {
      type
      message
    }
    organisation {
      id
    }
  }
}
`

const UPDATE_USER = `
mutation {
  update_user(|placeholder|){
      error {
        type
        message
      }
      user {
        id
        username
        info {
          full_name
        }
      }
  
  }
}
`

const UPLOAD_SNAPPY_LOGO = `
mutation { 
  upload_snappy_logo(|placeholder|) {
    error {
      type
      message
    }
    organisation {
      id
    }
  }
}
`

const UPDATE_SETTLEMENT_VOUCHER_CODE = `
mutation {
  update_settlement_voucher_code(|placeholder|) {
    error {
      type
      message
    }
    settlement {
      id
      status
    }
  }
}
`

const VERIFY_ALL_MANUAL_ITEMS = `
mutation {
  verify_all_manual_items(|placeholder|) {
    error {
      type
      message
    }
    claim {
      id
      status
    }
  }
}
`

const VERIFY_MANUAL_ITEM = `
mutation {
  verify_manual_item(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      status
    }
  }
}
`

const QUANTIFY_CLAIM = `
mutation {
  quantify_claim(|placeholder|) {
    error {
      type
      message
    }
    claim {
      id
      status
    }
  }
}
`

const CHANGE_PASSWORD = `
mutation {
  set_password(|placeholder|) {
    error {
      type
      message
    }
    message
  }
}
`

const CLAIM_COMMENT = `
query {
  claim_comments(|placeholder|){
    error{
      type
      message
    }
    comments{
      message
      id
    }
  }
}
`

const CREATE_CLAIM_COMMENT = `
mutation {
  create_claim_comment (|placeholder|) {
    comment {
      message
    }
  }
}
`

const SNOOZE_CLAIM = `
mutation {
  snooze_claim(|placeholder|){
    error{
      type
      message
    }
    claim{
      id
    }
  }
}
`

const SUPPLIER_DELIVERY_COST = `
query {
  delivery_cost (|placeholder|){
    cost
    error {
      type
      message
      }
  }
}
`


const UPDATE_ITEM_CLAIMANT_QUOTE = `
mutation {
  update_item_claimant_quote(|placeholder|) {
    error {
      type
      message
    }
    item {
      id
      claimant_quote
    }
  }
}
`

const SEARCH_LOCALITIES = `
query {
  search_localities(|placeholder|) {
    error {
      type
      message
    }
    localities
  }
}
`

const REGIONS = `
query {
  regions (|placeholder|) {
    error {
      message
      type
    }
    regions {
      id
      region_0
      region_1
      region_2
      region_3
    }
  }
}
`

// HAI
const HAI_TASK_SEARCH = `
query {
   hai_tasks(|placeholder|) {
      error {
          message
          type
      }
      tasks {
          description
          id
          date_created
          date_resolved
          date_updated
          date_deleted
          resolution_data
          data
          task_type
          status
          assignees {
              user
          }
      }
   }
}
`

const HAI_CHANGE_TASK = `
mutation {
resolve_hai_task(|placeholder|) {
    error {
        message
        type
    }
    task {
        assignees {
            user
            type
        }
        status
        change_records
    }
  }
}
`;

const DELETE_HAI_TASK = `
mutation {
  delete_hai_task(|placeholder|){
    task{
      id
    }
  }
}
`

const HAI_ASSIGN_TASK = `
mutation {
assign_hai_task(|placeholder|) {
        error {
            message
            type
        }
        task {
            assignees {
                user
                type
            }
        }
    }
}
`

const ALL_SUPPLIERS = `
query {
 claims {
    claims {
      suppliers {
        display_name
        id
        unique_reference_name
      }
    }
  }
}
`

const GENERATE_SUPPLIER_DELIVERY_COSTS_SHEETS = `
mutation {
    generate_supplier_delivery_costs_sheets(|placeholder|) {
    error{
      type
      message
    }
    urls
  }
}
`

const GENERATE_AI_PROTO = `
mutation {
  generate_ai_proto(|placeholder|){
    proto_product {
      id
      date_created
      date_updated
      date_released
      dates_released

      date_verified
      brand
      category
      common_name
      description
      status
      type

      ean_codes
      metadata
      model_number
      model_numbers

      images {
        main
        thumbnail_web
        thumbnail_print
      }
      properties {
        display_name
        name
        value
      }

    }
    response_info
    response_success
    new_claim_item {
      id
      date_created
      date_updated
      is_tender_item
      is_manual
      status
      category {
        id
        category_a
        category_b
        category_c
        category_d
        display_name
      }
      quantity
      supplier_panel {
        id
        name
        unique_reference_name
        status
        ranking
        recommended_replacement {
          product {
            id
            date_created
            date_updated

            date_verified
            date_released
            dates_released

            brand
            category
            common_name
            description

            status
            type

            ean_codes
            metadata
            model_number
            model_numbers

            images {
              main
              thumbnail_web
              thumbnail_print
            }
            properties {
              name
              display_name
              value
            }
          }
          quote {
            id
            status
            country
            currency
            currency_symbol
            price
            brand
          }
        }
      }
      possible_replacements {
        product {
          id
          date_created
          date_updated

          date_verified
          date_released
          dates_released

          brand
          category
          common_name
          description

          status
          type

          ean_codes
          metadata
          model_number
          model_numbers

          images {
            main
            thumbnail_web
            thumbnail_print
          }
          properties {
            name
            display_name
            value
          }
        }
        quotes {
          id
          status
          country
          currency
          currency_symbol
          price
          brand
          supplier {
            id
          }
        }
      }
      product {
        id
        date_created
        date_updated

        date_verified
        date_released
        dates_released

        brand
        category
        common_name
        description

        status
        type

        ean_codes
        metadata
        model_number
        model_numbers

        images {
          main
          thumbnail_web
          thumbnail_print
        }
        properties {
          name
          display_name
          value
        }
      }
      replacement {
        product {
          id
          date_created
          date_updated

          date_verified
          date_released
          dates_released

          brand
          category
          common_name
          description

          status
          type

          ean_codes
          metadata
          model_number
          model_numbers

          images {
            main
            thumbnail_web
            thumbnail_print
          }
          properties {
            name
            display_name
            value
          }
        }
        quote {
          id
          status
          country
          currency
          currency_symbol
          price
          brand
          supplier {
            id
          }
        }
        note
        date_selected
        selected_by
        is_system_selected
      }
    }
  }
}


`

const GENERATE_SUPPLIER_QUOTES_CATALOGUES = `
mutation {
  generate_supplier_quotes_catalogues(|placeholder|) {
    error {
      type
      message
    }
    urls
  }
}
`

const LOAD_SUPPLIER_DELIVERY_COST_SHEET = `
mutation {
  load_supplier_delivery_cost_sheet(|placeholder|) {
    error {
      type
      message
    }
    success
  }
}
`

const LOAD_SUPPLIER_QUOTES_CATALOGUE_SHEET = `
mutation {
  load_supplier_quotes_catalogues_sheet(|placeholder|) {
    error {
      type
      message
    }
    success
  }
}
`

const IMPORT_SUPPLIER_QUOTES_CATALOGUE_WORKBOOK = `
mutation {
  import_supplier_quotes_catalogue_workbook(|placeholder|) {
    error {
      type
      message
    }
    success
  }
}
`

const IMPORT_SUPPLIER_DELIVERY_COST_WORKBOOK = `
mutation {
  import_supplier_delivery_cost_workbook(|placeholder|) {
    error {
      type
      message
    }
    success
  }
}
`

const GENERATE_CLAIMS_REPORTS = `
mutation {
    generate_claims_report(|placeholder|) {
    error{
      type
      message
    }
    url_csv
    url_xlsx
    output_data
  }
}
`

const GENERATE_SUPPLIER_CLAIMS_REPORT = `
mutation {
    generate_supplier_claims_report(|placeholder|) {
    error{
      type
      message
    }
    url_csv
    url_xlsx
    output_data
  }
}
`

const UPDATE_PROTO_PRODUCT_MANUALLY = `
mutation {
  update_proto_product_manually(|placeholder|){
    error{ type, message }
    update_result{
      ore_id
      ingot_id
      new_proto_product{
        id
      }
    \terror{ type, message }
    }
  }
}
`

export const DELETE_SETTLEMENT = `
mutation {
  delete_settlement(|placeholder|){
    error { type, message }
    settlement{
      id
    }
  }
}
`

export const VERIFY_SETTLEMENT_VOUCHER = `
mutation verifySettelement {
  verify_settlement_voucher(|placeholder|){
    error{type, message}
    settlement{
      id
    }
  }
}
`

export const CANCEL_SETTLEMENT_VOUCHER = `
mutation verifySettelement {
  cancel_settlement_voucher(|placeholder|){
    error{type, message}
    settlement{
      id
    }
  }
}
`

export const PRODUCT_CREATION_SPECS = `
query {
  product_creation_specs(|placeholder|){
    error{type, message}
    product_specs_by_brand{
      brand
      specs_by_importance{
        importance
        product_specs{
          id
          spec_field
          spec_values
        }
      }
    }
  }
}
`




export {
    ACCOUNTS,
    ADD_CATALOGUE_PRODUCT_TO_CLAIM,
    ADD_MANUAL_PRODUCT_TO_CLAIM,
    ADD_REPLACEMENT,
    ALL_SUPPLIERS,
    APPROVE_POST_TENDER_ITEM,
    CONVERT_MANUAL_ITEM_TO_CATALOGUE_ITEM,
    CATEGORIES,
    CLAIM_COMMENT,
    CLAIMS_MINIMAL,
    CLAIMS_SEARCH,
    CLAIMS_WITH_ITEMS,
    CREATE_ORGANISATION,
    CREATE_USER,
    CREATE_CLAIM,
    SNOOZE_CLAIM,
    CREATE_MANUAL_QUOTE,
    CREATE_MANUAL_REPLACEMENT,
    SUPPLIER_SET_PREFERRED_REPLACEMENT,
    CREATE_SETTLEMENT,
    CREATE_CLAIM_COMMENT,
    CURRENT_USER,
    USER_FULL,
    CHANGE_PASSWORD,
    ROLES,
    DEACTIVATE_USER,
    DEACTIVATE_ORGANISATION,
    ACTIVATE_ORGANISATION,
    DEACTIVATE_ACCOUNT,
    ACTIVATE_ACCOUNT,
    DELETE_CLAIM,
    DELETE_ITEM,
    DELETE_MANUAL_QUOTE,
    DELETE_TENDER,
    DISMISS_ALL_PENDING_TENDER,
    DISMISS_TENDER,
    FILTER_SEARCH,
    GENERATE_REPORT_DOWNLOAD_URL,
    HAI_TASK_SEARCH,
    HAI_CHANGE_TASK,
    DELETE_HAI_TASK,
    HAI_ASSIGN_TASK,
    ORGANISATIONS,
    ORGANISATION_OPTIONS,
    QUANTIFY_CLAIM,
    SEARCH_LOCALITIES,
    REGIONS,
    SETTLE,
    SETTLEMENTS,
    SUPPLIER_DELIVERY_COST,
    UPDATE_ITEM_CLAIMANT_QUOTE,
    UPDATE_ITEM_QUANTITY,
    UPDATE_MANUAL_ITEM,
    UPDATE_QUOTE_PRICE,
    UPDATE_SETTLEMENT_VOUCHER_CODE,
    UPDATE_ORGANISATION,
    UPDATE_USER,
    UPLOAD_SNAPPY_LOGO,
    VERIFY_ALL_MANUAL_ITEMS,
    VERIFY_MANUAL_ITEM,
    GENERATE_AI_PROTO,
    GENERATE_SUPPLIER_DELIVERY_COSTS_SHEETS,
    GENERATE_SUPPLIER_QUOTES_CATALOGUES,
    LOAD_SUPPLIER_DELIVERY_COST_SHEET,
    LOAD_SUPPLIER_QUOTES_CATALOGUE_SHEET,
    IMPORT_SUPPLIER_QUOTES_CATALOGUE_WORKBOOK,
    IMPORT_SUPPLIER_DELIVERY_COST_WORKBOOK,
    UPLOAD_FILE,
    GENERATE_CLAIMS_REPORTS,
    GENERATE_SUPPLIER_CLAIMS_REPORT,
    UPDATE_PROTO_PRODUCT_MANUALLY,
};

