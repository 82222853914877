import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {CheckIcon, ExclamationIcon, InformationCircleIcon, XIcon} from '@heroicons/react/outline';

import { classNames } from  '../../utils/helpers.js';


export default function AlertModal(props) {

  let options = props.options;

  const renderIcon = () => {

    let bgColor = '';

    if (options.iconType === "success") {
      bgColor = "bg-green-100"
    }
    if (options.iconType === "error") {
      bgColor = "bg-red-500"
    }
    if (options.iconType === "info" || options.iconType === "warning") {
      bgColor = "bg-white"
    }

    return (
      <div className={classNames(bgColor, "mx-auto flex items-center justify-center h-12 w-12 rounded-full")}>
        {options.iconType === "success" && <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
        {options.iconType === "error" && <ExclamationIcon className="h-10 w-10 text-red-200" aria-hidden="true" />}
        {options.iconType === "info" && <InformationCircleIcon className="h-10 w-10 text-yellow-500" aria-hidden="true" />}
      </div>
    );
  }

  const onClose = () => {

    props.setOpen(false);

    if (options.onClose !== undefined && options.onClose !== null) {
      options.onClose();
    }
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-[100] inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 "
                      onClick={()=>{if(onClose) onClose()}}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 mx-0 rounded-full bg-red-100">
                      {renderIcon()}
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 capitalize">
                        {options.heading}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm text-gray-800">
                          {options.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn w-full flex justify-center"
                    onClick={()=>{if(onClose) onClose()}}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
