import React from 'react';
import {currencySymbol as currencySymbolFromClaim} from "../../utils/helpers";

const CurrencyInput = ({ currencySymbol, ...props }) => {
    /* Seperate the currencySymbol prop, so that it doesn't get passed to the input element
    *   (React will throw a warning)
    *   - currencySymbol: Custom currency symbol
    *  */

    return (
        <div className={`relative ${props.containerClassName}`}>

            <span className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 ${props.symbolClassName}`}>
                {currencySymbol || currencySymbolFromClaim(props.claim)}
            </span>

            <input
                type="number"
                {...props}
                className={'pl-6 ' + props.className}
            />

        </div>
    );
};

export default CurrencyInput;
