import React from 'react';

const Tooltip = (props) => {
    /** Tooltip component
     * If the width is too thin, it can be modified via className prop.
     *  E.g className="max-w-[7rem]" */


    const children = props.children;
    const content = props.message || props.text || props.content || '-';
    const position = props.position || 'top';
    const styling = props.styling || props.style || props.classNames || props.className || '';
    const hidden = props.hidden || false;

    // Function to dynamically calculate tooltip offset
    const calculateOffset = (position) => {
        switch (position) {
            case 'top':
            case 'bottom':
                return 'left-1/2 transform -translate-x-1/2';
            case 'left':
            case 'right':
                return 'top-1/2 transform -translate-y-1/2';
            default:
                return '';
        }
    };

    const tooltipSpacingMargin = 2;
    const positionClasses = {
        // Add a small margin for spacing
        top: `bottom-full mb-${tooltipSpacingMargin} ${calculateOffset(position)}`,
        right: `left-full ml-${tooltipSpacingMargin} ${calculateOffset(position)}`,
        bottom: `top-full mt-${tooltipSpacingMargin} ${calculateOffset(position)}`,
        left: `right-full mr-${tooltipSpacingMargin} ${calculateOffset(position)}`,
    };

    const stylingTooltipClasses = [
        "absolute z-[100] overflow-visible",
        "invisible opacity-0 pointer-events-none",
        "group-hover:visible group-hover:opacity-100",
        "transition-all ease-in-out duration-500",
        "text-sm text-white text-center", // text
        "w-max _max-w-[200%] min-w-[4rem] px-3 py-1", // sizing
        "bg-gray-700 rounded-md border-2 border-gray-300", // styling
        "hover:border-sky-300", // hover
    ];

    // Helper function to calculate max width based on content length
    const getMaxWidth = (text) => {

        // Override the dynamic width calculation if a max-width class is provided
        if(styling?.includes('max-w-')) {
            return '';
        }

        const baseWidth = 4; // Minimum width in rem
        const widthPerCharacter = 1 / 3; // Width added per character in rem
        return `${Math.max(baseWidth, text.length * widthPerCharacter)}rem`;
    };

    const stylingTooltip = stylingTooltipClasses.join(' ');

    return (
        <div className={`relative inline-block group ${props.containerClassName || ''}`}>

            {/* CONTENT */}
            {children}

            {/* TOOLTIP */}
            <div
                className={`absolute z-[100] ${positionClasses[position]} ${stylingTooltip} ${styling} ${hidden ? 'hidden' : ''}`}
                style={{ maxWidth: getMaxWidth(content) }}
            >
                {content}
            </div>
        </div>
    );

};

export default Tooltip;
