// Library imports

import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {
    AdjustmentsIcon,
    ArrowRightIcon,
    CloudUploadIcon,
    DocumentDuplicateIcon,
    DocumentSearchIcon,
    DocumentTextIcon,
    ExternalLinkIcon,
    PencilAltIcon,
    PhotographIcon,
    PlusIcon,
    SearchIcon,
    SparklesIcon,
    XIcon
} from '@heroicons/react/outline';
import moment from 'moment';

// Local imports
import {classNames, escapeDoubleQuotes, formatAsTitle, isEmpty} from '../../utils/helpers.js';
import ClaimItemSearch from '../claim/ClaimItemSearch';
import {ArrowLeftIcon, LockClosedIcon} from '@heroicons/react/solid';
import {
    customGraphRequest,
    filterSearch,
    productCreationSpecs, productProperties,
    updateProtoProductManually
} from '../../utils/coreApi';
import {useParams} from "react-router-dom";
import {BasicImage} from "../../components/base/BasicImage";
import {getCache, hasCache, setCache, useQuery} from "../../components/useQuery";
import Tooltip from "../../components/Tooltip";
import LimitedOrganisationAccess, {OrganisationType} from "../../components/LimitedOrganisationAccess";
import CountrySelection from "../../components/CountrySelection";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {ImageZoom} from "../../components/base/ImageZoom";
import ApiButton from "../../components/base/ApiButton";
import {PRODUCT_CREATION_SPECS} from "../../utils/graphqlTemplates";


export const standardTransition = {
    'enter': 'ease-out duration-300',
    'enterFrom': 'opacity-0 translate-y-4 md:translate-y-0 md:scale-95',
    'enterTo': 'opacity-100 translate-y-0 md:scale-100',
    'leave': 'ease-in duration-200',
    'leaveFrom': 'opacity-100 translate-y-0 md:scale-100',
    'leaveTo': 'opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
};

export default function MultiProductModal(props) {

    /** Queries and mutations
     *
     * Q product_properties
     *  get the full array of properties for a product (claim object only returns required properties)
     * Q categories
     *  get a list of all selectable the categories
     * Q product_creation_specs
     *  each product category has a list of specs (required, optional, general, additional) that can be added to a product
     *      general - required specs that are common to all products
     *      required - required specs that must be included in a product of this category
     *      optional - non-required specs that can be included in a product of this category
     *      additional - non-required specs that are general to multiple categories
     * Q filter_search
     *  retrieve a product's full data with only the product's ID
     *
     * M create_proto_product_manually
     *  create a new Proto product (not added to claim)
     * M update_proto_manually
     *  Update the specs of an existing Proto product
     * */

    /** Property and Spec/specification
     *   both are the same thing, used interchangeably
     *   a dictionary containing a name, a value, and (optionally) a display_name */

    const {claimId} = useParams();

    const [showModalTwo, setShowModalTwo] = useState(false);

    // A list of each product in all the ProductModals
    const [allProducts, setAllProducts] = useState([]);

    // A list of lists of all properties, found in all the products
    const [allProductsAllProperties, setAllProductsAllProperties] = useState([
        // { product 1
        //    id: '...',
        //    properties: [
        //        {name: '...', value: '...'},
        //        {name: '...', value: '...'},
        //    ]
        // },
        //
        // {
        //    { product 2
        //        id: '...',
        //        properties: [
        //            {name: '...', value: '...'},
        //            {name: '...', value: '...'},
        //        ]
        //    }
        // }
    ]);

    // The name of the property that currently has focus, from the user clicking on it to edit
    const [focusedProductProperty, setFocusedProductProperty] = useState(null);

    // This state is used to copy property values from one product to another - e.g the "copy across" functionality
    const [copyProperty, setCopyProperty] = useState(null);

    // The current mode of the ProductModals ('view' products or 'edit' products)
    const [mode, setMode] = useState('view');

    // All supported categories
    const categoriesHook = useQuery({
        queryStringFunction: () => {
            return `
                query ProductModal_categories{
                  categories(sort_by:[{ field:"display_name" order:ASCENDING }]) {
                    error {
                      type
                      message
                    }
                    categories {
                      id
                      category_a
                      category_b
                      category_c
                      category_d
                      display_name
                    }
                  }
                }
            `
        },
        onSuccess: (data) => {
            categoriesHook.setState(data["categories"]);
        },
        cacheExpirationMin: 60 * 6,
        onError: props.onError,
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: categories, setState: setCategories} = categoriesHook;

    useEffect(() => {
        /** Set any options passed from Parent **/

        // Mode: view | edit
        setMode(props.options.mode);
    }, [props.options]);

    useEffect(() => {
        /** If a second product is passed, the second modal must be opened */

        if (props.options.product2) setShowModalTwo(true);
    }, [props.options]);

    const allProps = {
        ...props,
        claimId,
        showModalTwo, setShowModalTwo,
        allProducts, setAllProducts,
        allProductsAllProperties, setAllProductsAllProperties,
        focusedProductProperty, setFocusedProductProperty,
        mode, setMode,
        copyProperty, setCopyProperty,
        categories, setCategories,
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
                <div
                    className="flex w-full items-stretch justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                    {/* DARK BACKGROUND */}
                    <Transition.Child as={Fragment} {...standardTransition} >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* MODAL 1 */}
                    {<ProductModal {...allProps} product={props.options.product} modalCount={0}/>}

                    {/* MODAL 2 */}
                    {showModalTwo && <ProductModal {...allProps} product={props.options.product2} modalCount={1}/>}

                </div>
            </Dialog>
        </Transition.Root>
    );
}

function ProductModal(props) {


    // ==== PRODUCT ====

    // The product that is being displayed and modified in this modal
    const [product, setProduct] = useState(null);
    /** {
     //      id: '...',
     //      ai_generated: null,
     //      ...
     //      properties: [...]
     //  }
     **/

        // A deep copy of the original product, unmodified, used for reference (what properties have been modified, etc.)
    const [originalProduct, setOriginalProduct] = useState(null);

    // TODO make this a function call
    // Flag to indicate if any of the specs of this product have been modified
    const [productHasBeenModified, setProductHasBeenModified] = useState(false);

    const [showLinkedReplacements, setShowLinkedReplacements] = useState(false);
    const [linkedReplacementsData, setLinkedReplacementsData] = useState(null);

    // ==== PROPERTIES ====

    // Required specs on all products
    const [brand, setBrand] = useState('');
    const [modelNumbers, setModelNumbers] = useState('');
    const [prpModelNumbers, setPrpModelNumbers] = useState('');
    const [eanCodes, setEanCodes] = useState('');
    const [releaseDate, setReleaseDate] = useState('');
    const [newImageUrl, setNewImageUrl] = useState('');

    const [allProperties, setAllProperties] = useState([
        /** {
         //      name: 'internal_memory',
         //      value: '12GB RAM'
         //  } **/
    ]);

    const importanceOrder = {
        "GENERAL": 0,
        "REQUIRED": 1,
        "OPTIONAL": 2,
        "ADDITIONAL": 3
    };

    useEffect(() => {
        if (!props.open) clearStates();
    }, [props.open]);

    function clearStates() {
        setSpecList_originalQuery([]);
        setSpecList_array([]);
        setAllProperties([]);
        setOriginalProduct(null);
        setProductHasBeenModified(false);

        props.setAllProductsAllProperties([]);
        props.setAllProducts([]);
    }


    useEffect(() => {
        /** if a product was passed down as a prop, use it (skip displaying ClaimItemSearch) **/

        if (!props.product) return;

        // State fix: do not reset the product (again) if it has already been set
        if (product) return;

        onSelectProduct(props.product);

    }, [props.product]);

    const getFullProduct = (_product) => {
        /** Get the full product
         * function accepts a product object, this is to circumvent a 'useEffect' setup,
         * and allow this function to be called directly **/

        productProperties(
            {
                productId: _product.id,
                productCategory: _product.category,
            },
            (data) => {
                let fullProduct = data.product || null;

                if (!fullProduct) return props.showAlertModal('error', 'Failed to retrieve all specs', 'Failed to retrieve the full product data');

                props.showToastNotificationModal('success', 'Full product retrieved', '');
                onSelectProduct(fullProduct, true)
            },
            props.onError
        )

    }

    const onSelectProduct = (newProduct, newProductIncludesAllProperties = false) => {
        /** Set the product for this card/modal
         * Called locally in the ProductModal, and called inside the ClaimItemSearch **/

        clearStates();

        // A product's "properties" field can be null (in the case of manual items)
        // make sure the properties field is not null
        if (!newProduct.properties) newProduct.properties = [];

        // TODO temp fix for duplicated brand property
        // is the brand property present twice?
        let brandProperties = newProduct.properties.filter(property => property.name === 'brand');
        if (brandProperties.length > 1) {
            // Remove the first brand property
            newProduct.properties = newProduct.properties.filter(property => property.name !== 'brand');
        }

        // Flag to indicate if the full properties have been retrieved via the proto_product_properties query
        newProduct.hasRetrievedFullProperties = newProductIncludesAllProperties;
        if (!newProductIncludesAllProperties) getFullProduct(newProduct);

        setProduct(newProduct);
        // Create a deep copy of the product, that can be modified without affecting the original product
        setOriginalProduct(structuredClone(newProduct));

        createProperties(newProduct)

        /** When any ProductModal has selected a new product, clear the list of 'allProducts' , so each ProductModal will re-add it's product **/
        props.setAllProducts([]);

        // Get the list of specs for this product's category
        getSpecListForProductCategory(newProduct);

    };

    function createProperties(newProduct) {
        /** Set the allProperties state **/

        setBrand(newProduct.brand)
        setReleaseDate(newProduct.date_released)

        if (newProduct.model_numbers) {
            setModelNumbers(newProduct.model_numbers.join(', '))
        } else {
            console.log('no model numbers')
            setModelNumbers('')
        }

        if (newProduct.ean_codes) {
            setEanCodes(newProduct.ean_codes.join(', '))
        } else {
            setEanCodes('')
        }

        // Remove any of the ignore specs (these are GENERAL specs and handled separately)
        let ignoreSpecs = ['brand', 'model_numbers', 'ean_codes', 'date_released'];
        let allSpecs = newProduct.properties.filter(spec => !ignoreSpecs.includes(spec.name));

        // create a deep copy of the product's properties
        setAllProperties(structuredClone(allSpecs));
    }


    useEffect(() => {
        /** Whenever the list of properties have been modified, update the allProductsAllProperties state */
        updatePropertiesToAllProductsAllProperties();
    }, [allProperties]);
    useEffect(() => {
        /** If allProductsAllProperties has been modified, update this products properties to the list **/
        addPropertiesToAllProductsAllProperties();
    }, [props.allProductsAllProperties]);

    function updatePropertiesToAllProductsAllProperties() {
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if (!product) return;

        // Check if this product has already been added
        const added = props.allProductsAllProperties.find(_product => _product.id === product.id);
        if (!added) {
            // Add this product to the list
            addPropertiesToAllProductsAllProperties();
            return;
        }

        // Update the properties of this product
        props.allProductsAllProperties.find(_product => _product.id === product.id).properties = allProperties;
        props.setAllProductsAllProperties([...props.allProductsAllProperties])
    }

    function addPropertiesToAllProductsAllProperties() {
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if (!product) return;

        // Check if this product has already been added
        const added = props.allProductsAllProperties.find(_product => _product.id === product.id);
        if (added) return;

        let productAndProperties = {id: product.id, properties: allProperties};
        props.setAllProductsAllProperties([...props.allProductsAllProperties, productAndProperties])
    }

    useEffect(() => {
        /** When any ProductModal has selected a new product, the 'allProducts' is cleared
         * Each ProductModal must re-add it's product **/

        // Do not add this ProductModal's product to the list of allProducts, if a product has not been selected
        if (!product) return;

        addProductToAllProducts();
    }, [props.allProducts]);

    const addProductToAllProducts = () => {
        /** When any ProductModal has selected a new product, the 'allProducts' is cleared
         * Each ProductModal must re-add it's product **/

            // Does this product already exist in the allProducts list?
        const added = props.allProducts.find(_product => _product.id === product.id);

        // If not, add it
        if (!added) {
            /** Because multiple components are calling the setAllProducts in the same cycle
             * to add their product to the allProducts list,
             * we need to use a callback function to update the state with the previous setAllProducts state,
             * rather than just setting it directly **/
            props.setAllProducts((previousState) => {
                return [...previousState, product];
            });
        }
    };

    useEffect(() => {
        updateProduct_allProducts();
    }, [product]);

    function updateProduct_allProducts() {
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if (!product) return;
        let updatedProducts = props.allProducts.map(_product => {
            if (_product.id === product.id) return product;
            return _product;
        });

        props.setAllProducts(updatedProducts);
    }


    // ==== SPEC LIST ====

    // TODO cache this, or atleast use the state so to prevent multiple queries
    // The spec data for this product's category, in the original query format
    const [specList_originalQuery, setSpecList_originalQuery] = useState([
        /** {
         //      brand
         //      specs_by_importance{
         //          importance
         //          product_specs{
         //              id
         //              spec_field
         //              spec_values
         //          }
         //      }
         // } */
    ]);

    // A single array of all the possible spec fields that can be assigned to a product in the given category
    const [specList_array, setSpecList_array] = useState([
        /** {
         //     brand: 'Apple',
         //     importance: 'REQUIRED',
         //     id: '..',
         //     field: 'model_number',
         //     values: ['A1234', 'A1235', 'A1236']
         // }, */
    ]);

    useEffect(() => {
        createFlatSpecList();
    }, [specList_originalQuery]);

    function createFlatSpecList() {

        let allSpecs = [
            /** {
             //     brand: 'Apple',
             //     importance: 'REQUIRED',
             //     id: '..',
             //     field: 'model_number',
             //     values: ['A1234', 'A1235', 'A1236']
             // }, */
        ]

        /** The product_creation_specs query's data structure
         // {
         //      brand
         //      specs_by_importance{
         //          importance
         //          product_specs{
         //              id
         //              spec_field
         //              spec_values
         //          }
         //      }
         // } */

        if (isEmpty(specList_originalQuery)) return;

        // Function to clean non-digit characters from strings and convert to numbers if applicable
        function cleanAndConvert(value) {
            const cleaned = value.replace(/[^\d.-]/g, '');  // Remove all non-digit characters except the decimal point and minus
            return isNaN(cleaned) ? value : parseFloat(cleaned);
        }

        // sort the spec values, alphabetically for strings, and numerically for digital values
        // product_specs_by_brand.specs_by_importance.product_specs.spec_values[] <-- array of values for this specification
        specList_originalQuery.forEach(brandSpecs => {
            brandSpecs.specs_by_importance.forEach(importanceSpecs => {
                importanceSpecs.product_specs.forEach(spec => {
                    spec.spec_values.sort((a, b) => {
                        const numA = cleanAndConvert(a);
                        const numB = cleanAndConvert(b);
                        if (typeof numA === 'number' && typeof numB === 'number') {
                            return numB - numA;
                        }
                        return a.localeCompare(b);
                    });
                });
            });
        });

        // Create a flat copy of the creation spec data
        for (const brandData of specList_originalQuery) {

            for (const specs_by_importance of brandData.specs_by_importance) {

                for (const spec of specs_by_importance.product_specs) {

                    let specObj = {
                        brand: brandData.brand,
                        importance: specs_by_importance.importance,
                        // id: spec.id,
                        field: spec.spec_field,
                        values: spec.spec_values
                    }

                    allSpecs.push(specObj)
                }

            }

        }

        setSpecList_array(allSpecs);

    }

    const getSpecListForProductCategory = (_product) => {
        /** Get all REQUIRED and OPTIONAL specs for this category (the category of the product)
         * Additionally get all the ADDITIONAL properties for the brand */

        if (!_product) return;
        if (!isEmpty(specList_originalQuery)) return;

        // --- Retrieve spec data
        let queryArgs = `category:${_product.category} importance_levels:["REQUIRED", "OPTIONAL", "GENERAL"]`;
        let query = PRODUCT_CREATION_SPECS.replace('|placeholder|', queryArgs);

        let specsForThisCategoryAreCached = hasCache(query)
        if (specsForThisCategoryAreCached) {
            const cachedData = getCache(query);
            setSpecList_originalQuery(cachedData.data.product_specs_by_brand);

            getAdditionalSpecsForBrand(_product)
        } else {

            productCreationSpecs(
                {
                    category: _product.category,
                    importanceLevels: ["REQUIRED", "OPTIONAL", "GENERAL"],
                },
                (data) => {

                    setCache(
                        query,
                        data,
                        60 * 8 // 8 hours
                    )

                    setSpecList_originalQuery(data.product_specs_by_brand)

                    getAdditionalSpecsForBrand(_product)
                },
                (error) => {
                    console.log('getSpecListForProductCategory error', error)
                    if (error.message === 'Invalid category: unknown') {
                        props.showAlertModal('error', 'Invalid category', 'The category of this product is invalid');
                    }

                }
            );

        }

    }

    function getAdditionalSpecsForBrand(_product) {

        // --- Retrieve brand's ADDITIONAL spec data
        let queryArgs = `category:${_product.category} importance_levels:["ADDITIONAL"] brands:["${_product.brand}"]`;
        let query = PRODUCT_CREATION_SPECS.replace('|placeholder|', queryArgs);
        let specsForThisCategoryAreCached = hasCache(query)

        if (specsForThisCategoryAreCached) {
            const cachedData = getCache(query);
            setSpecList_originalQuery(prevState => {

                if (isEmpty(prevState)) return [];

                // Append this data to the existing data
                const additionalSpecsForBrand = cachedData.data.product_specs_by_brand[0].specs_by_importance[0];

                // Check that ADDITIONAL specs for this brand have not already been queried and added
                const alreadyExists = prevState.find(brandData => {
                    return brandData.brand === _product.brand && brandData.specs_by_importance.find(spec => spec.importance === 'ADDITIONAL');
                })
                // The ADDITIONAL specs for this brand have already been added
                if (alreadyExists) return prevState;

                // Find the brand in the original data, and append this new "additional" importance data
                let updatedData = prevState.map(brandData => {

                    // Find the product's brand
                    if (brandData.brand === _product.brand) {
                        let updatedBrandData = {...brandData};
                        updatedBrandData.specs_by_importance.push(additionalSpecsForBrand);
                        return updatedBrandData;
                    }

                    // Other brand
                    return brandData;
                });

                return updatedData;

            })

        } else {

            productCreationSpecs(
                {
                    category: _product.category,
                    importanceLevels: ["ADDITIONAL"],
                    brand: _product.brand,
                },
                (data) => {

                    setCache(
                        query,
                        data,
                        60 * 8 // 8 hours
                    )

                    setSpecList_originalQuery(prevState => {

                        // Append this data to the existing data
                        let newData = data.product_specs_by_brand;
                        const additionalSpecsForBrand = newData[0].specs_by_importance[0];

                        // Check that ADDITIONAL specs for this brand have not already been queried and added
                        const alreadyExists = prevState.find(brandData => {
                            return brandData.brand === _product.brand && brandData.specs_by_importance.find(spec => spec.importance === 'ADDITIONAL');
                        })
                        // The ADDITIONAL specs for this brand have already been added
                        if (alreadyExists) return prevState;

                        // Find the brand in the original data, and append this new "additional" importance data
                        let updatedData = prevState.map(brandData => {

                            // Find the product's brand
                            if (brandData.brand === _product.brand) {
                                let updatedBrandData = {...brandData};
                                updatedBrandData.specs_by_importance.push(additionalSpecsForBrand);
                                return updatedBrandData;
                            }

                            // Other brand
                            return brandData;
                        });

                        return updatedData;

                    })

                },
                props.onError
            );
        }
    }

    useEffect(() => automaticallyAddRequiredPropertiesToProduct(), [specList_array]);
    const automaticallyAddRequiredPropertiesToProduct = () => {
        /** If the Proto modal goes into "Edit" mode, and the "product creation specs" list is retrieved,
         * Automatically include (in this products properties) any missing Required and Optional specs */

        if (!product || !specList_array) return;

        if (props.mode !== 'edit') return;

        // Has this function already been run on this product?
        if (product.specsAutoAdded) return;

        let requiredSpecs = specList_array.filter(spec => spec.importance === 'REQUIRED');
        let optionalSpecs = specList_array.filter(spec => spec.importance === 'OPTIONAL');

        let specsToAdd = []


        // Add any missing required specs
        requiredSpecs.forEach(spec => {
            const productHasSpec = allProperties.find(property => property.name === spec.field);
            const fieldHasNotBeenAdded = !specsToAdd.find(property => property.name === spec.field);
            const fieldHasSelectableValues = spec.values.length > 0;
            if (!productHasSpec && fieldHasNotBeenAdded && fieldHasSelectableValues) {
                specsToAdd.push({name: spec.field, value: ''});
            }
        });

        // Add any missing optional specs
        optionalSpecs.forEach(spec => {
            const productHasSpec = allProperties.find(property => property.name === spec.field);
            const fieldHasNotBeenAdded = !specsToAdd.find(property => property.name === spec.field);
            const fieldHasSelectableValues = spec.values.length > 0;
            if (!productHasSpec && fieldHasNotBeenAdded && fieldHasSelectableValues) {
                specsToAdd.push({name: spec.field, value: ''});
            }
        });

        // Remove any of the ignore specs (these are GENERAL specs and handled separately)
        let ignoreSpecs = ['brand', 'model_numbers', 'ean_codes', 'date_released'];
        let existingSpecs = allProperties.filter(spec => !ignoreSpecs.includes(spec.name));
        specsToAdd = specsToAdd.filter(spec => !ignoreSpecs.includes(spec.name));

        let combinedProperties = [...existingSpecs, ...specsToAdd];

        removeNonCreationSpecProperties(combinedProperties);

    }

    function removeNonCreationSpecProperties(combinedProperties) {
        /** If a product has an existing spec (product.properties), that is not found in the product_creation_specs,
         * that spec must be excluded when editing the product.
         * When a product is submitted for an update "update_proto_product" it must only submit specs from
         * the list of creation specs */

        if (!product || isEmpty(specList_array)) return;

        let newProperties = combinedProperties.filter(property => {
            let spec = specList_array.find(spec => spec.field === property.name);
            return !!spec;
        });

        setAllProperties(newProperties);

    }


    // ==== BRAND VALIDATION ====

    useEffect(() => {
        // checkProductBrandIsValid();
    }, [props.mode, product, specList_originalQuery]);

    function checkProductBrandIsValid() {
        /** Check if the product's brand is present in the creation specs **/

        if (props.mode === 'view') return;
        if (!product || isEmpty(specList_originalQuery)) return;

        // Brand from the product object
        let brandSpec = specList_originalQuery.find(spec => spec.brand === product.brand);

        // Brand from the brand state
        if(!brandSpec) brandSpec = brand

        if (!brandSpec)
            props.showAlertModal(
                'info',
                'Brand not found',
                "The brand of this product is not present in the creation specs for this product's category");

    }


    // ==== COPY SPEC ====

    useEffect(() => {
        /** When the "copyProperty" state has been modified, copy that value to all the ProductModal's properties **/

        if (!props.copyProperty) return;

        let propertyInModifiedProduct = null;
        propertyInModifiedProduct = allProperties.find(property => property.name === props.copyProperty.name);

        if (propertyInModifiedProduct) propertyInModifiedProduct.value = props.copyProperty.value;
        else {
            propertyInModifiedProduct = {name: props.copyProperty.name, value: props.copyProperty.value};
            allProperties.push(propertyInModifiedProduct);
        }

        setProduct({...product});

    }, [props.copyProperty]);


    // ==== PRODUCT MUTATIONS ====

    const handleUpdateProtoProduct = (btnHandler) => {
        /**
         * Middleware function to check date difference and missing required/optional specs.
         * All checks are performed, and a *single* modal is shown if *any* check fails.
         */
        if (props.allProducts.length === 2) {
            const otherProduct = props.allProducts.find(p => p.id !== product.id);

            let modalType = 'info'; // Default modal type
            let modalTitle = ''; //Default Title
            let modalMessage = [];  // Store messages as an array of JSX elements
            let showModal = false;

            // --- Date Difference Check ---
            if (product.date_released && otherProduct.date_released) {
                const thisDate = moment(product.date_released);
                const otherDate = moment(otherProduct.date_released);

                // --- Calculate Differences ---
                // Use floating point for years to check if they cross a year boundary accurately
                const diffYearsExact = Math.abs(thisDate.diff(otherDate, 'years', true));
                // Get integer difference for display string calculation
                const diffYears = Math.floor(diffYearsExact);
                const diffMonths = Math.abs(thisDate.diff(otherDate, 'months'));
                const diffDays = Math.abs(thisDate.diff(otherDate, 'days'));

                // --- Create Display String (calculates the largest relevant unit) ---
                let dateDifferenceString = '';
                if (diffYears > 0) {
                    dateDifferenceString = `${diffYears} year${diffYears > 1 ? 's' : ''}`;
                    // Optional: Add remaining months if significant
                    // const remainingMonths = Math.abs(thisDate.subtract(diffYears, 'years').diff(otherDate, 'months'));
                    // if (remainingMonths > 0) {
                    //    dateDifferenceString += ` and ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`;
                    // }
                } else if (diffMonths > 0) {
                    dateDifferenceString = `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
                } else {
                    // Only show days if years and months are 0
                    dateDifferenceString = `${diffDays} day${diffDays > 1 ? 's' : ''}`;
                }

                 // --- Trigger Condition: Check if the years are different ---
                 // We can simply check if the year() component is different
                 const isDifferentYear = thisDate.year() !== otherDate.year();

                if (isDifferentYear) { // <-- Changed Condition
                    showModal = true;
                    modalTitle = 'Different Release Years'; // <-- Updated Title
                    modalMessage.push(
                        <div key="date-diff" className='py-4'>
                            <b>Release date: ({dateDifferenceString})</b> The release
                            dates of the two products are in different calendar years. {/* <-- Updated Message */}
                        </div>
                    );
                }
            }

            // --- Missing Required and Optional Specs Check ---
            const otherProductProperties = props.allProductsAllProperties.find(p => p.id === otherProduct.id)?.properties || [];
            const thisProductProperties = props.allProductsAllProperties.find(p => p.id === product.id)?.properties || [];

            // Find missing REQUIRED specs
            const missingRequiredSpecs = otherProductProperties.filter(otherProp => {
                const spec = specList_array.find(s => s.field === otherProp.name && s.brand === product.brand);
                return spec && spec.importance === 'REQUIRED' &&
                    (!thisProductProperties.some(thisProp => thisProp.name === otherProp.name) || !thisProductProperties.find(thisProp => thisProp.name === otherProp.name).value);
            });

            // Find missing OPTIONAL specs
            const missingOptionalSpecs = otherProductProperties.filter(otherProp => {
                const spec = specList_array.find(s => s.field === otherProp.name && s.brand === product.brand);
                return spec && spec.importance === 'OPTIONAL' &&
                    (!thisProductProperties.some(thisProp => thisProp.name === otherProp.name) || !thisProductProperties.find(thisProp => thisProp.name === otherProp.name).value);
            });

            if (missingRequiredSpecs.length > 0 || missingOptionalSpecs.length > 0) {
                showModal = true;
                modalTitle = !modalTitle ? 'Missing Specs' : modalTitle + ' & Missing Specs';

                if (missingRequiredSpecs.length > 0) {
                    const missingRequiredList = missingRequiredSpecs.map(spec => formatAsTitle(spec.name)).join(', ');
                    modalMessage.push(<div key="missing-required"><b>Missing Required:</b> {missingRequiredList}</div>);
                }

                if (missingOptionalSpecs.length > 0) {
                    const missingOptionalList = missingOptionalSpecs.map(spec => formatAsTitle(spec.name)).join(', ');
                    modalMessage.push(<div key="missing-optional"><b>Missing Optional:</b> {missingOptionalList}</div>);
                }
            }


            // --- Show Modal if Necessary ---
            if (showModal) {
                modalMessage.push(<div key="confirm-prompt" className='pt-4'>Are you sure you want to update?</div>) //Add the prompt

                props.showConfirmModal(
                    modalType,
                    modalTitle,
                    <div>{modalMessage}</div>,  // Wrap the messages in a div
                    'Update Product',
                    () => _updateProtoProduct(btnHandler),
                    () => btnHandler.reset()
                );
                return; // Important: Stop here, wait for confirmation
            }
        }

        // If no checks triggered the modal, proceed directly
        _updateProtoProduct(btnHandler);
    };

    const _updateProtoProduct = (btnHandler) => {
        /** Permanently update the product
         * - check that there are changes between the original and modified product states
         * - include the newProperties **/

        btnHandler.reset();

        const category = props.categories.find(category => category.category_d === product.category);
        if (!category) return props.showAlertModal('Error', 'Missing Category', 'Could not find the category data for this product');

        // Make sure the brand, model numbers, and EAN codes are included in the properties, warn if not
        if (!brand) return props.showAlertModal('info', 'Missing Brand', 'The brand is missing from the product properties');
        if (!modelNumbers) return props.showAlertModal('info', 'Missing Model Numbers', 'The model numbers are missing from the product properties');
        // if (!eanCodes) return props.showAlertModal('info', 'Missing EAN Codes', 'The EAN codes are missing from the product properties');
        if (!releaseDate) return props.showAlertModal('info', 'Missing Date Released', 'The date released is missing from the product properties');

        let _modelNumbers = "[]"
        if (modelNumbers) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _modelNumbers = modelNumbers.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _modelNumbers = `["${_modelNumbers?.join('", "')}"]`
        }

        let _eanCodes = "[]"
        if (eanCodes) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _eanCodes = eanCodes.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _eanCodes = `["${_eanCodes?.join('", "')}"]`
        }


        let _allProperties = allProperties;
        if (!allProperties.find(property => property.name === 'brand')) {
            const _brandProperty = {name: 'brand', value: brand};
            _allProperties = [...allProperties, _brandProperty];
        }

        console.log('saveProduct', product, allProperties, props.options.item)

        btnHandler.onLoading()

        //record history event
        addProductModalHistoryEvent('Save product submitted', product);

        updateProtoProductManually({
                protoId: product.id,
                category: category.category_d,
                providerImageUrl: !isEmpty(newImageUrl) ? newImageUrl : null,
                dataSpec: _allProperties,        // the properties from product and new properties
                dateReleased: releaseDate,     // A datetime in the format 'yyyy-mm-dd hh:mm:ss' e.g. '2021-03-02 14:15:00'
                modelNumbers: _modelNumbers,    // the list of product model numbers
                eanCodes: _eanCodes,        // the list of product EAN codes
                itemId: props.options.item?.id || null,
            },
            (data) => {
                props.showNotificationModal('success', 'Product saved', 'The product has been saved successfully and a new proto has been created.');
                console.log('successfully saved proto: ', data);

                addProductModalHistoryEvent('Save product success', product);

                let newProductId = data.update_result[0].new_proto_product.id;

                //Return the updated product if the page embedding this CompareAndEditProductModal has the callback function
                fetchFullProduct(newProductId)

                btnHandler.reset()
            },
            (error) => {
                addProductModalHistoryEvent('Save product failed', product);
                props.onError(error)
            }
        );

    };

    const deleteProtoProduct = () => {

        function _deleteProduct() {

            let mutation = `
            mutation delete {
              delete_proto_product(
                proto_id: "${product.id}"
                category: ${product.category}
                # allow_catalogue_products: false
              ){
                error{type, message}
                product{
                  id
                }
                log_messages
              }
            }`

            customGraphRequest(
                mutation,
                (data) => {
                    console.log('Product deleted', data);
                    props.showNotificationModal('success', 'Product deleted', 'The product has been deleted successfully.');
                    props.setOpen(false);
                },
                props.onError
            )

        }

        props.showConfirmModal(
            'warning',
            'Delete product',
            'Are you sure you want to delete this product?',
            'Delete product',
            _deleteProduct
        )

    }

    function createManualProduct() {

        const category = props.categories.find(category => category.category_d === product.category);
        if (!category) return props.showAlertModal('Error', 'Missing Category', 'Could not find the category data for this product');

        // Make sure the brand, model numbers, and EAN codes are included in the properties, warn if not
        if (!modelNumbers) return props.showAlertModal('info', 'Missing Model Numbers', 'The model numbers are missing from the product properties');
        if (!releaseDate) return props.showAlertModal('info', 'Missing Date Released', 'The date released is missing from the product properties');


        let _modelNumbers = "[]"
        if (modelNumbers) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _modelNumbers = modelNumbers.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _modelNumbers = `["${_modelNumbers?.join('", "')}"]`
        }

        let _eanCodes = "[]"
        if (eanCodes) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _eanCodes = eanCodes.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _eanCodes = `["${_eanCodes?.join('", "')}"]`
        }

        const format = (name) => name ? name.replaceAll(' ', '_').replaceAll('"', '\\"').toLowerCase() : '';

        let specs = '['
        specs += allProperties.map((spec) => {
            if (!spec.name || !spec.value) return null;
            return ` 
                {
                    field: "${format(spec.name)}" 
                    value: "${escapeDoubleQuotes(spec.value)}"
                }`;
        }).filter(spec => !!spec)
        specs += ']';

        let mutation = `
            mutation createProtoProductManually {
              create_proto_product_manually(
                category: ${category.category_d}
                brand: "${brand}"
                date_released: "${releaseDate}"
                model_numbers: ${_modelNumbers}
                ean_codes: ${_eanCodes}
                product_specs: ${specs}
                item_id: "${props.options.item.id}"
                
              ){
                error{type, message}
                proto_product{
                  id
                }
                duplicate_proto{
                  id
                }
                new_claim_item{
                  id
                }
              }
            }
        `


        console.log('createManualProduct', mutation)

        props.showConfirmModal(
            'success',
            'Create product',
            'Are you sure you want to create a proto product with the below specifications?',
            'Create product',
            () => {
                customGraphRequest(
                    mutation,
                    (data) => {
                        console.log('Product created', data);
                        props.showNotificationModal('success', 'Product created', 'The product has been created successfully.');
                        props.setOpen(false);
                    },
                    props.onError
                );
            }
        )


    }


    // ==== FETCH FULL PRODUCT ====

    const fetchFullProduct = (newProductId) => {
        /** When a product has been modified, only the ID is returned.
         * Run a filter search on the model number to get the full product*/

        if (!newProductId) return props.showAlertModal(
            'error',
            'No product ID',
            'A new product ID was not returned from the server.'
        )

        filterSearch(
            props.claimId,
            {
                inputSearchText: newProductId,
            },
            (data) => {
                console.log('fetchNewProto', data);
                let productFromFilterSearch = data.products[0] || null;

                // if there is a call back function that wants to new product on save, pass the new product
                if (props.options.onProductSaved && productFromFilterSearch) props.options.onProductSaved(productFromFilterSearch);
            },
            props.onError
        )
    }

    function logStates() {
        function logWithLabel(label, value) {
            console.log(`%c${label}: \n`, 'font-weight: bold', value);
        }

        logWithLabel('mode', props.mode);
        logWithLabel('product', product);
        logWithLabel('originalProduct', originalProduct);
        logWithLabel('productHasBeenModified', productHasBeenModified);
        logWithLabel('allProperties', allProperties);
        logWithLabel('specList_originalQuery', specList_originalQuery);
        logWithLabel('specList_array', specList_array);
        logWithLabel('brand', brand);
        logWithLabel('modelNumbers', modelNumbers);
        logWithLabel('eanCodes', eanCodes);
        logWithLabel('releaseDate', releaseDate);
        logWithLabel('allProducts', props.allProducts);
        logWithLabel('allProductsAllProperties', props.allProductsAllProperties);
        logWithLabel('props', props);
    }


    // ==== LINKED REPLACEMENTS ====

    useEffect(() => {
        fetchLinkedReplacementData();
    }, [showLinkedReplacements, props.selectedCountry]);

    function fetchLinkedReplacementData() {
        /** Fetch the linked replacements for this product */

        if (!product) return;

        const id = product.id;
        const countryCode = props.selectedCountry.code;

        if (!id) return;
        if (!countryCode || countryCode === 'WORLD') return;


        const query = `
        query linked_replacements{
          linked_replacements(
            proto_product_id:"${id}"
            country_code: ${countryCode}
          ){
            error{
              message
              type
            }
            linked_replacements{
              id
              type
              category
              replacements{
                product_id
                model_number
                common_name
                category
                type
                proto_product{
                  id          
                  brand
                  ean_codes
                  category
                  images{
                    thumbnail_web
                  }
                }
                manual_product{
                  id
                }
              }
            }
          }
        }
        `

        customGraphRequest(
            query,
            (data) => {
                setLinkedReplacementsData(data.linked_replacements);
            },
            props.onError
        )
    }

    function deleteLinkedReplacement(replacement, btnHandler) {


        let id = replacement.product_id || replacement.proto_product?.id || replacement.manual_product?.id;

        if (!id) return props.showAlertModal(
            'warning',
            'No product ID',
            'The product ID for this linked replacement is missing'
        )

        const mutation = `
            mutation delete_linked_replacements{
              delete_linked_replacements(
                proto_product_id: "${product.id}"
                country_code: ${props.selectedCountry.code}
                linked_product_id: "${id}"
              ){
                error{
                  message
                  type
                }
                linked_replacements{
                  id
                  replacements{
                    product_id
                    proto_product{
                      id
                    }
                    manual_product{
                      id
                    }
                    common_name
                  }
                }
              }
            }
        `

        btnHandler.onLoading();

        customGraphRequest(
            mutation,
            (data) => {
                btnHandler.reset();

                console.log('deleteLinkedReplacement', data);
                fetchLinkedReplacementData();
                props.showNotificationModal(
                    'success',
                    'Linked replacement removed',
                    'The linked replacement has been remove successfully'
                )
            },
            (error) => {
                btnHandler.reset();
                props.onError(error)
            }
        )
    }

    // Helper: Blocks UI and returns a function to unblock.
    function blockUI() {
        // Insert a page size overlay, to prevent the user from navigating away from the product, until PRP has returned

        const body = document.body;
        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlay.style.zIndex = '9999';
        overlay.style.display = 'flex';
        overlay.style.justifyContent = 'center';
        overlay.style.alignItems = 'center';
        overlay.style.color = 'white';
        overlay.style.fontSize = '1.5rem';

        // Create the text content as HTML elements
        const textDiv = document.createElement('div');

        const p1 = document.createElement('h1');
        p1.textContent = 'Generating...';
        textDiv.appendChild(p1);

        const p2 = document.createElement('p');
        const i = document.createElement('i');
        i.textContent = 'The modal will automatically reopen if accidentally closed';
        p2.appendChild(i);
        textDiv.appendChild(p2);

        overlay.appendChild(textDiv); // Append the text content to the overlay


        // Disable pointer events on body and headlessui-portal-root
        body.style.pointerEvents = 'none';
        const portalRoot = document.getElementById('headlessui-portal-root');
        if (portalRoot) {
            portalRoot.style.pointerEvents = 'none';
        }
        body.appendChild(overlay);

        // Return function to unblock UI after a delay
        return () => {
            body.style.pointerEvents = '';
            if (portalRoot) {
                portalRoot.style.pointerEvents = '';
            }
            body.removeChild(overlay);
        };
    }

    function prpRegenerateProduct() {
        // TODO: model_numbers:"" can be a list of model numbers ["", "", ""]

        let _modelNumbers = `""`;

        // Check if the modelNumbers state string (the comma-separated input) has content
        // Using !isEmpty is generally safer than just `if (modelNumbers)` as it handles null/undefined too
        if (!isEmpty(prpModelNumbers)) {
            // Split comma-separated string, trim whitespace, filter empty strings, escape quotes
            const modelNumbersArray = prpModelNumbers
                .split(',')                     // Split by comma
                .map(n => n.trim())             // Remove leading/trailing whitespace
                .filter(n => n !== '')          // Remove any empty strings resulting from ,, or leading/trailing commas
                .map(n => escapeDoubleQuotes(n)); // Escape any double quotes within the model numbers themselves

            // Only if the resulting array is not empty, format it as a GraphQL list string
            if (modelNumbersArray.length > 0) {
                _modelNumbers = `["${modelNumbersArray.join('", "')}"]`;
            }
            // If modelNumbersArray is empty after processing (e.g., input was just ", ," or ""),
            // _modelNumbers will correctly remain "[]"
        }

        const mutation = `
            mutation PRP_REGENERATE_PRODUCT {
              convert_to_ai_proto(
                proto_id: "${product.id}"
                category: ${product.category}
                model_numbers: ${_modelNumbers}
              ){
                error{type, message}
                proto_product{
                  id
                  ai_generated
                  category
                }
                response_info
                response_success
                log_messages
              }
            }
        `

        props.showToastNotificationModal(
            'info',
            'Regenerating product',
            'Approximately 40 seconds required',
            7000
        );

        addProductModalHistoryEvent('PRP Submitted', product);

        const unblock = blockUI();

        customGraphRequest(
            mutation,
            (data) => {
                unblock?.(); // Unblock UI on success

                // Re-open the modal if it was closed
                props.setOpen(true)

                console.log('PRP_REGENERATE_PRODUCT:', data);
                console.log('PRP Logs:', data.logs);

                props.showToastNotificationModal(
                    'success',
                    'Product successfully regenerated',
                    '',
                    15000
                )

                // Open new product in second modal
                if (data.proto_product) {
                    props.setShowModalTwo(true);
                    props.setOptions({product2: data.proto_product})

                    props.setFocusedProductProperty(null); // clear the current focused property to prevent a permanent focus/highlight
                    props.setMode('edit');
                }

                props.showAlertModal(
                    'success',
                    'Response info',
                    <div>{JSON.stringify(data.response_info)}</div>
                )

                addProductModalHistoryEvent('PRP Success', product);

            },
            (error) => {
                addProductModalHistoryEvent('PRP Failed', product);

                props.onError(error)
                // Re-open the modal if it was closed
                props.setOpen(true)

                unblock?.(); // Unblock UI on error
            }
        )

    }

    const allProps = {
        ...props,
        showLinkedReplacements, setShowLinkedReplacements,
        linkedReplacementsData, setLinkedReplacementsData,
        product, setProduct,
        allProperties, setAllProperties,
        originalProduct, setOriginalProduct,
        productHasBeenModified, setProductHasBeenModified,
        // newProperties, setNewProperties,
        specList_originalQuery, setSpecList_originalQuery,
        specList_array, setSpecList_array,

        brand, setBrand,
        modelNumbers, setModelNumbers,
        eanCodes, setEanCodes,
        releaseDate, setReleaseDate,
        newImageUrl, setNewImageUrl,

        getCombinedPropertiesOfAllProducts,

        deleteLinkedReplacement
    };


    // ==== COMPONENTS ====

    function getCombinedPropertiesOfAllProducts(filterByImportanceLevel) {
        let allProductProperties = [];

        // Temporary map to track unique property names
        const uniqueProperties = new Map();

        props.allProductsAllProperties.forEach(_product => {
            _product.properties.forEach(property => {
                if (!uniqueProperties.has(property.name)) {
                    uniqueProperties.set(property.name, true);
                    allProductProperties.push(property);
                }
            });
        });

        const importanceOrder = {
            "GENERAL": 0,
            "REQUIRED": 1,
            "OPTIONAL": 2,
            "ADDITIONAL": 3
        };

        const getPropertyImportance = (propertyName) => {
            const productBrand = brand; // Get the brand of the current product

            // Find a spec in specList_array that matches BOTH the field name AND the product's brand
            const spec = specList_array.find(spec =>
                spec.field === propertyName && spec.brand === productBrand
            );

            const importanceName = spec ? spec.importance : "ADDITIONAL"; // Default to ADDITIONAL if not found for this brand
            const importanceValue = importanceOrder[importanceName];
            return importanceValue !== undefined ? importanceValue : importanceOrder["ADDITIONAL"];
        };

        // FILTER
        if (filterByImportanceLevel)
            allProductProperties = allProductProperties.filter(property => getPropertyImportance(property.name) === importanceOrder[filterByImportanceLevel]);

        // OR ORDER
        else
            allProductProperties.sort((a, b) => {
                const importanceA = getPropertyImportance(a.name);
                const importanceB = getPropertyImportance(b.name);
                return importanceA - importanceB;
            });


        return allProductProperties;
    }

    // === Product header
    function ProductHeader(props) {

        function createSaveDeleteButtons() {
            /**
             * If the product is a none-manual proto:
             *  Show Update button
             *      update_proto_product_manually
             *  Show Delete button
             *      delete_proto_product
             *
             * If the product is a manual product:
             *  Show the Create button
             *  */

            const manualProductButtons = (
                <>
                    <Tooltip
                        position="top"
                        message={
                            <div>
                                <p>Create a new proto product</p>
                                <p>This will replace the item's requested product with the newly created product</p>
                            </div>}
                    >
                        <button
                            className='btn-raised-success'
                            onClick={createManualProduct}
                        >
                            <PlusIcon className="mr-1 inline h-5 w-5 align-top"/>
                            Create manual product
                        </button>
                    </Tooltip>
                </>
            );

            const protoProductButtons = (
                <>

                    {/* SAVE PROTO */}
                    <Tooltip message="Update this product to the below specifications and values" position="top">
                        <ApiButton
                            className='!btn-raised-success group'
                            onClick={handleUpdateProtoProduct}
                        >
                            <div className='group-hover:text-white flex gap-2'>
                                <CloudUploadIcon className="h-5 w-5 align-top"/>
                                Save
                            </div>
                        </ApiButton>
                    </Tooltip>

                    {/* DELETE */}
                    <LimitedOrganisationAccess
                        types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                        mainOrganisation={props.mainOrganisation}
                    >
                        <Tooltip message="Delete this product" position="top">
                            <button
                                className={'btn-raised-danger'}
                                onClick={deleteProtoProduct}
                            >
                                <XIcon className="mr-1 inline h-5 w-5 align-top"/>
                                Delete
                            </button>
                        </Tooltip>
                    </LimitedOrganisationAccess>
                </>
            );

            return (
                <>
                    {product.type === 'MANUAL' ? manualProductButtons : protoProductButtons}
                </>
            );

        }

        function onUploadProductImageURL(btnHandler) {

            const mutation = `
                mutation uploadProductImageURL    {    
                    update_proto_product_manually(
                        proto_id: "${product.id}"
                        provider_image_url: "${escapeDoubleQuotes(newImageUrl)}"
                        category: ${product.category}
                    ) {
                      error {type, message}
                      new_claim_item {
                          id
                      }
                      update_result {
                         new_proto_product{
                           id
                           category
                           images {
                              thumbnail_web
                           }
                         }
                      }
                   }
                }
            `

            customGraphRequest(
                mutation,
                (data) => {
                    console.log('Product image uploaded', data);
                    props.showToastNotificationModal(
                        'success',
                        'Product image uploaded',
                        ''
                    );

                    btnHandler.reset()

                    // If the response does not include the image, manually set it
                    let images = data.update_result[0]?.new_proto_product.images;
                    if (!images.thumbnail_web) images.thumbnail_web = newImageUrl;

                    const _product = {...product, images: images}

                    setProduct(_product);
                    setOriginalProduct(structuredClone(_product));

                    addProductModalHistoryEvent('Upload product image success', product);

                },
                (error) => {
                    props.onError(error);
                    btnHandler.reset()
                    addProductModalHistoryEvent('Upload product image fail', product);
                }
            )

        }

        return (
            <div className={classNames(
                props.showModalTwo ? 'h-[54rem]' : 'min-h-[30rem]'
            )}>

                <div className='flex gap-4'>

                    {/* PRODUCT IMAGE */}
                    <div className="w-1/2 relative">
                        <div className="rounded-lg overflow-hidden group">

                            {/* Image Display */}
                            <div className="relative">

                                <img
                                    src={product.images && product.images.thumbnail_web || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                    alt='Product'
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'
                                    }}
                                    className={classNames(
                                        'bg-white w-full h-full transition-all object-contain',

                                        // Hover for image upload
                                        'brightness-100 group-hover:brightness-50',
                                        'blur-none group-hover:blur-sm'
                                    )}
                                />

                                {/* Hover Input Container */}
                                <div
                                    className="absolute p-2 inset-0 flex flex-col items-center justify-center gap-2 opacity-0 group-hover:opacity-100"
                                >
                                    <div className="p-2 space-y-2 w-full">
                                        <p className='text-center text-white font-bold'>Upload a image for this
                                            product</p>
                                        <input
                                            type="text"
                                            className="input w-full text-xs"
                                            placeholder="Enter image URL. E.g: https://example.com/image.jpg"
                                            value={newImageUrl}
                                            onChange={(e) => setNewImageUrl(e.target.value)}
                                        />
                                        <ApiButton
                                            className="w-full !btn-raised hover:text-white"
                                            onClick={onUploadProductImageURL}
                                        >
                                            <CloudUploadIcon className="mr-1 inline h-5 w-5"/>
                                            Upload image
                                        </ApiButton>
                                    </div>

                                    <button
                                        className='btn-raised gap-0 rounded-full mt-10'
                                        onClick={() => {
                                            const searchTerm = `Official datasheet product image for: ${product.brand} | ${product.common_name} | ${modelNumbers}`;
                                            const googleSearchUrl = `https://www.google.com/images?q=${encodeURIComponent(searchTerm)}`;
                                            window.open(googleSearchUrl, '_blank');
                                        }}
                                    >
                                        Google &nbsp;
                                        <PhotographIcon className='h-7 w-7'/>
                                        <ExternalLinkIcon className='h-10 w-10'/>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>


                    {/* PRODUCT BUTTONS */}
                    <div className="w-1/2">

                        {/* TITLE, CATEGORY, INFO */}
                        <div className="">
                            <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12" onClick={logStates}>
                                {product.common_name}
                            </h2>

                            <div className='flex gap-4'>

                                <button
                                    className='btn-icon gap-0'
                                    onClick={() => {
                                        const searchTerm = `${product.brand}, ${product.common_name}, ${modelNumbers}`;
                                        const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchTerm)}`;
                                        window.open(googleSearchUrl, '_blank');
                                    }}
                                >
                                    <SearchIcon className='h-3 w-3'/>
                                    <ExternalLinkIcon className='h-5 w-5'/>
                                </button>
                                <button
                                    className='btn-icon gap-0'
                                    onClick={() => {
                                        const searchTerm = `Official datasheet product image for: ${product.brand} | ${product.common_name} | ${modelNumbers}`;
                                        const googleSearchUrl = `https://www.google.com/images?q=${encodeURIComponent(searchTerm)}`;
                                        window.open(googleSearchUrl, '_blank');
                                    }}
                                >
                                    <PhotographIcon className='h-3 w-3'/>
                                    <ExternalLinkIcon className='h-5 w-5'/>
                                </button>
                            </div>

                            <section>
                                <p className="mt-1 text-gray-700 capitalize">
                                    {formatAsTitle(product.category || product.category?.category_a || '')}
                                </p>

                                <p className="mt-1 font-medium text-xl text-gray-900">
                                    {product.date_released && product.date_released.split('-')[0]}
                                </p>
                            </section>
                        </div>

                        {/* BUTTONS */}
                        <div className="mt-6 flex flex-col gap-6 ">

                            {/* COMPARE */}
                            <div>

                                <p className='pb-2 text-sm'>Compare with another product</p>

                                <div className='flex gap-4'>
                                    {/* COMPARE */}
                                    <Tooltip
                                        message="Toggle a second modal to compare this product with another product"
                                        position="top">
                                        <button
                                            className="btn-raised"
                                            onClick={() => props.setShowModalTwo(!props.showModalTwo)}
                                        >
                                            {props.showModalTwo ?
                                                <>
                                                    <DocumentDuplicateIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                    Hide
                                                </>
                                                :
                                                <>
                                                    <DocumentDuplicateIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                    Compare
                                                </>
                                            }
                                        </button>
                                    </Tooltip>


                                    {/* SELECT PRODUCT */}
                                    <Tooltip
                                        message="Click to open the item search, where this product can be swapped out for another"
                                        position="top">
                                        <button
                                            className="btn-raised"
                                            onClick={() => setProduct(null)}
                                        >
                                            <DocumentSearchIcon className="h-5 w-5"/>
                                            Change
                                        </button>
                                    </Tooltip>
                                </div>


                            </div>

                            {/* EDIT */}
                            <div>

                                <p className='pb-2 text-sm'>Edit product</p>

                                <div className='flex justify-start gap-2 flex-wrap max-w-[25rem]'>

                                    {/* SAVE, CREATE, DELETE */}
                                    <LimitedOrganisationAccess
                                        types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                        mainOrganisation={props.mainOrganisation}
                                    >
                                        <Tooltip message="Toggle between viewing or editing this product"
                                                 position="top">
                                            <button
                                                className="btn-raised"
                                                onClick={() => {
                                                    props.setFocusedProductProperty(null); // clear the current focused property to prevent a permanent focus/highlight
                                                    props.setMode(props.mode === 'view' ? 'edit' : 'view');
                                                }}
                                            >
                                                {props.mode === 'view' ?
                                                    <>
                                                        <PencilAltIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                        Edit
                                                    </>
                                                    :
                                                    <>
                                                        <DocumentTextIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                        View
                                                    </>
                                                }
                                            </button>
                                        </Tooltip>
                                    </LimitedOrganisationAccess>

                                    {createSaveDeleteButtons()}

                                </div>

                            </div>

                            {/* PRP REGENERATE */}
                            <LimitedOrganisationAccess
                                types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                mainOrganisation={props.mainOrganisation}
                            >
                                <div>

                                    <p className='pb-2 text-sm'>AI Regenerate</p>

                                    <div className='flex w-full gap-2'>

                                        {/* PRP REGENERATE PRODUCT */}

                                        <Tooltip message="Regenerate/recreate this proto product" position="top">
                                            <button
                                                className="btn-raised"
                                                onClick={prpRegenerateProduct}
                                            >
                                                <SparklesIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                Regenerate
                                            </button>
                                        </Tooltip>

                                        <Tooltip
                                            content={
                                                <span>
                                                    Multiple values should be seperated by a comma <br/>
                                                    e.g: <i>MLKT3LL, MLLA3Y/A, MLLD3Y/A?ES</i>
                                                </span>
                                            }
                                        >
                                            <input
                                                className='input w-full shadow-none text-xs'
                                                placeholder='Optional model numbers..'
                                                value={prpModelNumbers}
                                                onChange={(e) => setPrpModelNumbers(e.target.value)}
                                            />
                                        </Tooltip>


                                    </div>

                                </div>
                            </LimitedOrganisationAccess>

                            {/* EXTRA */}
                            <div>
                                <p className='pb-2 text-sm'>Extra</p>

                                <div className='flex justify-start gap-2'>

                                    <LimitedOrganisationAccess
                                        types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                        mainOrganisation={props.mainOrganisation}
                                    >
                                        <Tooltip message="Display this product's linked replacements" position="top">
                                            <button
                                                className="btn-raised-light"
                                                onClick={() => {
                                                    setLinkedReplacementsData(null);
                                                    setShowLinkedReplacements(!showLinkedReplacements)
                                                }}
                                            >
                                                <AdjustmentsIcon className="mr-1 inline h-5 w-5 align-top"/>
                                                Mapping
                                            </button>
                                        </Tooltip>
                                    </LimitedOrganisationAccess>

                                    {/* Add to claim, Add replacement, Link to HAI, etc. */}
                                    {!isEmpty(props.options.claimActionButton) && props.options.claimActionButton}

                                </div>

                            </div>

                        </div>


                    </div>
                </div>

                <LimitedOrganisationAccess
                    types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                    mainOrganisation={props.mainOrganisation}
                >
                    {props.showModalTwo && (
                        <div>
                            <p className='pb-2 text-sm'>PRP Analyses</p>
                            {renderAnalysisStats()}
                        </div>
                    )}
                </LimitedOrganisationAccess>


            </div>
        );

    }

    function renderAnalysisStats() {

        // Date Difference Calculation
        let dateDifference = '';
        if (product && props.allProducts.length > 1) {
            const otherProduct = props.allProducts.find(p => p.id !== product.id);
            if (otherProduct && product.date_released && otherProduct.date_released) {
                const thisDate = moment(product.date_released);
                const otherDate = moment(otherProduct.date_released);
                const diffDays = thisDate.diff(otherDate, 'days'); // Don't use Math.abs
                const diffMonths = thisDate.diff(otherDate, 'months');
                const diffYears = thisDate.diff(otherDate, 'years');

                let prefix = diffDays >= 0 ? '+' : '-'; // Determine if it's + or -

                let absDays = Math.abs(diffDays);
                let absMonths = Math.abs(diffMonths);
                let absYears = Math.abs(diffYears);

                if (absDays < 31) dateDifference = `${prefix}${absDays} day${absDays === 1 ? '' : 's'}`;
                else if (absMonths < 12) dateDifference = `${prefix}${absMonths} month${absMonths === 1 ? '' : 's'}`;
                else dateDifference = `${prefix}${absYears} year${absYears === 1 ? '' : 's'}`;
            }
        }

        // --- Negative Specs (Missing Specs) ---
        const getNegativeSpecs = (importance) => {
            if (props.allProducts.length < 2) return {count: 0};

            const otherProduct = props.allProducts.find(p => p.id !== product.id);
            if (!otherProduct) return {count: 0};

            const otherProductProperties = props.allProductsAllProperties.find(p => p.id === otherProduct.id)?.properties || [];
            const thisProductProperties = props.allProductsAllProperties.find(p => p.id === product.id)?.properties || [];

            const missingSpecs = otherProductProperties.filter(otherProp => {
                const spec = specList_array.find(s => s.field === otherProp.name && s.brand === product.brand);
                return spec && spec.importance === importance && !thisProductProperties.some(thisProp => thisProp.name === otherProp.name) && otherProp.value;
            });

            return {count: missingSpecs.length};
        };

        const negativeRequired = getNegativeSpecs('REQUIRED');
        const negativeOptional = getNegativeSpecs('OPTIONAL');
        const negativeAdditional = getNegativeSpecs('ADDITIONAL');
        const totalNegativeSpecsCount = negativeRequired.count + negativeOptional.count + negativeAdditional.count;


        // --- Positive Specs (Extra Specs) ---
        const getPositiveSpecs = (importance) => {
            if (props.allProducts.length < 2) return {count: 0};

            const otherProduct = props.allProducts.find(p => p.id !== product.id);
            if (!otherProduct) return {count: 0};

            const otherProductProperties = props.allProductsAllProperties.find(p => p.id === otherProduct.id)?.properties || [];
            const thisProductProperties = props.allProductsAllProperties.find(p => p.id === product.id)?.properties || [];

            const extraSpecs = thisProductProperties.filter(thisProp => {
                const spec = specList_array.find(s => s.field === thisProp.name && s.brand === product.brand);
                return spec && spec.importance === importance && !otherProductProperties.some(otherProp => otherProp.name === thisProp.name) && thisProp.value;
            });

            return {count: extraSpecs.length};
        };

        const positiveRequired = getPositiveSpecs('REQUIRED');
        const positiveOptional = getPositiveSpecs('OPTIONAL');
        const positiveAdditional = getPositiveSpecs('ADDITIONAL');
        const totalPositiveSpecsCount = positiveRequired.count + positiveOptional.count + positiveAdditional.count;


        // --- Overall Match ---
        const calculateOverallMatch = () => {
            if (props.allProducts.length < 2) return {required: 'N/A', optional: 'N/A', additional: 'N/A'};

            const otherProduct = props.allProducts.find(p => p.id !== product.id);
            if (!otherProduct) return {required: 'N/A', optional: 'N/A', additional: 'N/A'};

            const otherProductProperties = props.allProductsAllProperties.find(p => p.id === otherProduct.id)?.properties || [];
            const thisProductProperties = props.allProductsAllProperties.find(p => p.id === product.id)?.properties || [];

            // Combine all unique spec names from both products
            const allSpecNames = new Set();
            otherProductProperties.forEach(prop => allSpecNames.add(prop.name));
            thisProductProperties.forEach(prop => allSpecNames.add(prop.name));


            const calculateMatchForImportance = (importance) => {
                let count = 0;
                allSpecNames.forEach(specName => {
                    const spec = specList_array.find(s => s.field === specName && s.brand === product.brand);
                    const thisProductHasSpec = thisProductProperties.some(prop => prop.name === specName && prop.value); // Check if "this" product has the spec AND a value.

                    if (spec && spec.importance === importance && thisProductHasSpec) {
                        count++;
                    }
                });

                return count;
            }


            const requiredMatchCount = calculateMatchForImportance("REQUIRED");
            const optionalMatchCount = calculateMatchForImportance("OPTIONAL");
            const additionalMatchCount = calculateMatchForImportance("ADDITIONAL");


            //Find total of each
            const totalRequired = Array.from(allSpecNames).filter(specName => {
                const spec = specList_array.find(s => s.field === specName && s.brand === product.brand)
                return spec && spec.importance === "REQUIRED"
            }).length
            const totalOptional = Array.from(allSpecNames).filter(specName => {
                const spec = specList_array.find(s => s.field === specName && s.brand === product.brand)
                return spec && spec.importance === "OPTIONAL"
            }).length
            const totalAdditional = Array.from(allSpecNames).filter(specName => {
                const spec = specList_array.find(s => s.field === specName && s.brand === product.brand)
                return spec && spec.importance === "ADDITIONAL"
            }).length

            return {
                required_match: requiredMatchCount,
                required_total: totalRequired,
                optional_match: optionalMatchCount,
                optional_total: totalOptional,
                additional_match: additionalMatchCount,
                additional_total: totalAdditional,
            };
        };

        const overallMatch = calculateOverallMatch();


        const otherProduct = props.modalCount === 0 ? 'right' : 'left';

        return (
            <div className='flex flex-col justify-around gap-4 w-full mb-4'>
                <p><b>Date difference:</b> {dateDifference}</p>

                {/* NEGATIVE SPECS */}
                <Tooltip content={
                    <div>
                        <b>Missing specs</b>
                        <hr/>
                        Specs that are found in the <b>{otherProduct}</b> product, but not in <b>this</b> product
                        <hr/>
                        <p><i>If this is a PRP item, then REQUIRED and OPTIONAL should be 0</i></p>
                        <hr/>
                        <p>There are a <b>total of {totalNegativeSpecsCount}</b> specs that are in
                            the {otherProduct} product
                            but not in this product</p>

                    </div>
                }
                         position='top'
                         style='max-w-max w-[14rem]'
                >
                    <p><b>Negative specs:</b></p>

                    {negativeRequired.count === 0 ?
                        <p className='text-gray-600'>No missing Required</p>
                        :
                        <p className='text-red-400'>
                            <i>The {otherProduct} product has <b>{negativeRequired.count}</b> Required specs that this
                                product does not have</i>
                        </p>

                    }
                    {negativeOptional.count === 0 ?
                        <p className='text-gray-600'>No missing Optional</p>
                        :
                        <p className='text-red-400'>
                            <i>The {otherProduct} product has <b>{negativeOptional.count}</b> Optional specs that this
                                product does not have</i>
                        </p>

                    }
                    {negativeAdditional.count === 0 ?
                        <p className='text-gray-600'>No missing Additional</p>
                        :
                        <p className='text-red-400'>
                            <i>The {otherProduct} product has <b>{negativeAdditional.count}</b> Additional specs that
                                this product does not have</i>
                        </p>

                    }
                </Tooltip>

                {/* POSITIVE SPECS */}
                <Tooltip content={
                    <div>
                        <b>Extra specs</b>
                        <hr/>
                        Specs that are found in <b>this</b> product, and not in the <b>{otherProduct}</b> product
                        <hr/>
                        <p>There are a <b>total of {totalPositiveSpecsCount}</b> specs that are in this product but not
                            in
                            the {otherProduct} product</p>
                    </div>
                }
                         position='top'
                         style='max-w-max w-[14rem]'
                >
                    <p><b>Positive specs:</b></p>
                    {positiveRequired.count === 0 ?
                        <p className='text-gray-600'>No extra Required</p>
                        :
                        <p className='text-green-600'>
                            <i>This product has <b>{positiveRequired.count}</b> Required specs that
                                the {otherProduct} product does not have</i>
                        </p>
                    }

                    {positiveOptional.count === 0 ?
                        <p className='text-gray-600'>No extra Optional</p>
                        :
                        <p className='text-green-600'>
                            <i>This product has <b>{positiveOptional.count}</b> Optional specs that
                                the {otherProduct} product does not have</i>
                        </p>
                    }

                    {positiveAdditional.count === 0 ?
                        <p className='text-gray-600'>No extra Additional</p>
                        :
                        <p className='text-green-600'>
                            <i>This product has <b>{positiveAdditional.count}</b> Additional specs that
                                the {otherProduct} product does not have</i>
                        </p>
                    }

                </Tooltip>

                <Tooltip
                    content='How closely do the two products match if we combine all the specs present in both products'>
                    <p><b>Combined specs match:</b></p>
                    <p className={classNames(
                        overallMatch.required_match !== overallMatch.required_total ? '' : 'text-gray-400'
                    )}>
                        <b>{Math.trunc(overallMatch.required_match / overallMatch.required_total * 100)}% - </b>
                        This product has <b>{overallMatch.required_match} Required</b> specs / of
                        combined <b>{overallMatch.required_total} specs</b>
                    </p>
                    <p className={classNames(
                        overallMatch.optional_match !== overallMatch.optional_total ? '' : 'text-gray-400'
                    )}>
                        <b>{Math.trunc(overallMatch.optional_match / overallMatch.optional_total * 100)}% - </b>
                        This product has <b>{overallMatch.optional_match} Optional</b> specs / of
                        combined <b>{overallMatch.optional_total} specs</b>
                    </p>
                    <p className={classNames(
                        overallMatch.additional_match !== overallMatch.additional_total ? '' : 'text-gray-400'
                    )}>
                        <b>{Math.trunc(overallMatch.additional_match / overallMatch.additional_total * 100)}% - </b>
                        This product has <b>{overallMatch.additional_match} Additional</b> specs / of
                        combined <b>{overallMatch.additional_total} specs</b>
                    </p>
                </Tooltip>
            </div>
        );
    }

    function addProductModalHistoryEvent(action, product) {
        if (!product || !product.id) {
            console.warn("addProductModalHistoryEvent called without a valid product.");
            return;
        }

        const newEvent = {
            action: action,
            id: product.id,
            common_name: product.common_name,
            model_numbers: product.model_numbers, //added model numbers
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
        };

        // Get existing history or initialize an empty array
        const existingHistory = JSON.parse(localStorage.getItem('productModalHistory')) || [];

        // Add the new event to the history
        const updatedHistory = [...existingHistory, newEvent];

        // Save the updated history to localStorage
        localStorage.setItem('productModalHistory', JSON.stringify(updatedHistory));
    }

    // === Product modal or Item search
    const renderEditProtoCard = () => {
        /** Render a card with all the fields of a product, editable **/

        if (!product) {
            return <div></div>;
        }

        function productIsMissingHighlightedProperty() {

            // Do not highlight if no property has been set yet
            if (!props.focusedProductProperty) return false;

            if (props.focusedProductProperty === 'Model numbers') return true;
            if (props.focusedProductProperty === 'EAN codes') return true;

            // Does this product have the highlighted property?
            let productHasProperty = allProperties?.find(property => property.name === props.focusedProductProperty);
            productHasProperty = Boolean(productHasProperty);
            return !productHasProperty;
        }

        function getMissingPropertiesCount() {
            /** Return the number of properties that this Product does not have, that are found in props.combinedProperties **/
            let missingProperties = getCombinedPropertiesOfAllProducts().filter(combinedProperty => {
                let productHasProperty = allProperties?.find(productProperty => productProperty.name === combinedProperty.name);
                return !productHasProperty; // return true if the product does not have this property
            });
            return missingProperties.length; // the total number of properties that are found in props.combinedProperties, but not in this product.properties
        }

        function ProductLinkedReplacements() {
            /** Display the linked replacements for this product */

            if (!showLinkedReplacements) return <></>;

            if (props.selectedCountry.code === 'WORLD') return (

                <tr className={`transition-all duration-500 rounded-md`}>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <i>Please select the country to view linked replacements</i>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <CountrySelection {...props} />
                    </td>
                </tr>
            );

            if (linkedReplacementsData === null) return (
                <tr className={`transition-all duration-500 rounded-md`}>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <i>Loading</i>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <LoadingSpinner className="h-3 w-3" body=' '/>
                    </td>
                </tr>
            );

            if (linkedReplacementsData && linkedReplacementsData.length === 0) return (
                <tr className={`transition-all duration-500 rounded-md`}>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <p>Linked replacements</p>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                        <i>No replacement data returned for this product</i>
                    </td>
                </tr>
            )

            const replacementData = linkedReplacementsData?.[0].replacements || null;
            if (!replacementData) return (
                <></>
            );

            function renderEans(eanCodes) {
                if (!eanCodes) return '-';
                return `${eanCodes?.[0]} + ${eanCodes?.length - 1} more`;
                ;
            }

            function copyProductDataToClipboard(product) {
                navigator.clipboard.writeText(JSON.stringify(product, null, 2));
                props.showToastNotificationModal(
                    'success',
                    'Product data copied',
                )
            }

            return (
                replacementData.map((product, index) => {
                    return <tr className={`transition-all duration-500 rounded-md`}>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <div onClick={() => copyProductDataToClipboard(product)}>
                                <Tooltip content={<div>
                                    <b>Click to copy this product's full data to clipboard</b>
                                    <p className='italic'>Common name</p>
                                    <p className='italic'>Model number - EAN Code</p>
                                    <p className='italic'>Brand - Category</p>
                                    <p className='italic'>Product ID</p>
                                </div>}
                                >
                                    <p>{product.common_name}</p>
                                    <Tooltip content={product.proto_product?.ean_codes?.join(', ')} position='right'>
                                        <p>M: {product.model_number} -
                                            E: {renderEans(product.proto_product?.ean_codes)}</p>
                                    </Tooltip>
                                    <p>{product.proto_product?.brand} - {formatAsTitle(product.category)}</p>
                                    <p className='text-xs'>{product.proto_product?.id}</p>
                                </Tooltip>
                            </div>
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <div className='w-full h-full flex items-center gap-4'>
                                <ImageZoom
                                    imageSrc={product.proto_product?.images?.thumbnail_web || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                    altText="Product Preview"
                                    className="w-full min-w-[5rem] max-h-[4rem] object-contain px-2 !z-[100]"
                                    zoomClassName={classNames(
                                        'border-[1px] border-stone-400 rounded-md',
                                        // Don't show the preview for the placeholder product image
                                        product.proto_product?.images?.thumbnail_web ? 'scale-[700%]' : 'scale-[100%]'
                                    )}
                                />

                                {/* COMPARE */}
                                <Tooltip message="Toggle a second modal to compare this product with another product"
                                         position="top">
                                    <button
                                        className="btn-raised"
                                        onClick={() => {
                                            props.setShowModalTwo(!props.showModalTwo);
                                            props.setOptions({product2: product.proto_product})
                                        }}
                                    >
                                        <DocumentDuplicateIcon className="mr-1 inline h-5 w-5 align-top"/>
                                        Compare
                                    </button>
                                </Tooltip>

                                {/* DELETE */}
                                <LimitedOrganisationAccess
                                    types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                    mainOrganisation={props.mainOrganisation}
                                >
                                    <Tooltip message="Remove this product as a linked replacement"
                                             position="top">

                                        <ApiButton
                                            onClick={(btnHandler) => {
                                                btnHandler.reset();
                                                props.showConfirmModal(
                                                    'warning',
                                                    'Delete linked replacement',
                                                    'Are you sure you want to delete this linked replacement?',
                                                    'Delete',
                                                    () => {
                                                        deleteLinkedReplacement(product, btnHandler)
                                                    }
                                                )
                                            }}
                                            className={'btn-raised-danger w-full flex justify-center'}
                                            loadingContent={<LoadingSpinner color='darkcyan' size='6' body=' '
                                                                            text='text-md'/>}
                                        >
                                            <XIcon className="h-5 w-5"/>
                                            Delete
                                        </ApiButton>

                                    </Tooltip>
                                </LimitedOrganisationAccess>

                            </div>
                        </td>
                    </tr>
                })
            );
        }

        function ProductHistoryTooltip() {
            const [history, setHistory] = useState([]);

            useEffect(() => {
                // Load history from local storage when the component mounts
                const loadedHistory = JSON.parse(localStorage.getItem('productModalHistory')) || [];
                setHistory(loadedHistory);
            }, []);

            const copyHistoryToClipboard = () => {
                if (history.length === 0) {
                    navigator.clipboard.writeText("No history stored.");
                    props.showToastNotificationModal('info', 'No history to copy');
                    return;
                }

                const historyText = history.map(event =>
                    `id: ${event.id}, common_name: ${event.common_name}, model: ${event.model_numbers}, action: ${event.action}, time: ${event.time}`
                ).join('\n---\n');  // Improved formatting

                navigator.clipboard.writeText(historyText);
                props.showToastNotificationModal('success', 'History copied to clipboard');
            };

            return (
                <Tooltip
                    content={
                        <div style={{cursor: 'pointer'}}>

                            <p>Click to copy the history</p>
                            <hr/>

                            <div className='max-h-[60rem] overflow-y-auto text-left justify-start'>
                                {history.length === 0 ?
                                    <p>No history available.</p>
                                    :
                                    [...history].reverse().map((event, index) => (
                                            <div className='pb-4'>
                                                <div key={index}>
                                                    <p><b>action:</b> {event.action}</p>
                                                    <b>time:</b> {moment(event.time).fromNow()} <span
                                                    className='text-xs'><i>{event.time}</i></span>
                                                    <p><b>id:</b> {event.id}</p>
                                                    <p><b>common_name:</b> {event.common_name}</p>
                                                </div>
                                                <hr/>
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                    }
                    position="right"
                    className='w-[22rem]'
                >
                    <h2 className="text-2xl font-extrabold text-gray-900 pr-12"
                        onClick={copyHistoryToClipboard}>History</h2>
                </Tooltip>
            );
        }

        return (
            <div className="flex w-fit px-2 px-4 ">
                <Transition.Child as={Fragment} {...standardTransition} >
                    <div className="flex w-full text-left transition px-4 my-8 max-w-4xl ">
                        <div
                            className="w-full relative flex items-start bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 ">

                            <button
                                type="button"
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                onClick={() => {
                                    // If modal 2, close modal 2
                                    if (props.showModalTwo) props.setShowModalTwo(false);
                                    // If modal 1, close modal 1
                                    else props.setOpen(false);
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6"/>
                            </button>


                            <div className="w-full gap-x-6 items-start">


                                {/* PRODUCT HEADER */}
                                {ProductHeader(allProps)}

                                {/* LINKED REPLACEMENTS */}
                                <div className={classNames(
                                    'my-20',
                                    showLinkedReplacements ? '' : 'hidden'
                                )}>

                                    <h2 className="text-2xl font-extrabold text-gray-900">
                                        Linked Replacement Products
                                    </h2>

                                    <table className='table-fixed w-full divide-y divide-gray-300'>

                                        <thead>
                                        <tr>
                                            <th className="px-4 py-4 text-sm font-bold text-gray-900">Linked
                                                Replacement
                                            </th>
                                            <th className="px-4 py-4 text-sm font-bold text-gray-900">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody className={classNames(
                                            'bg-white divide-y divide-gray-200',
                                        )}
                                        >

                                        {ProductLinkedReplacements()}

                                        </tbody>
                                    </table>
                                </div>


                                <div className="my-10">

                                    {/* TOTAL : MISSING  PROPERTIES*/}
                                    <div className="flex justify-between">
                                        <h2 className="text-2xl font-extrabold text-gray-900 pr-12">Properties</h2>

                                        {/* HISTORY */}
                                        <div className="flex justify-between">
                                            <ProductHistoryTooltip {...props} />
                                        </div>

                                        <div className="text-gray-900 pr-6">

                                            <Tooltip position='left'
                                                     content={'The total number of spec/properties that this product has'}>
                                                <b>Total:</b> {allProperties?.length} &nbsp;&nbsp;
                                            </Tooltip>

                                            <Tooltip position='left'
                                                     content={'The number of specs/properties that the OTHER product has but this product does not have'}>
                                                <b>Missing:</b> {getMissingPropertiesCount()}
                                            </Tooltip>
                                        </div>
                                    </div>


                                    {/* PROPERTIES */}
                                    <table className="mt-3 table-fixed w-full divide-y divide-gray-300 ">

                                        <thead>
                                        <tr>
                                            <th className="px-4 py-4 text-sm font-bold text-gray-900">Property</th>
                                            <th className="px-4 py-4 text-sm font-bold text-gray-900">Value</th>
                                        </tr>
                                        </thead>

                                        <tbody className={classNames('bg-white divide-y divide-gray-200',
                                            `transition-all duration-500 rounded-md outline outline-2 outline-offset-[10px]`,
                                            productIsMissingHighlightedProperty() ? 'outline-purple-200' : 'outline-transparent'
                                        )}
                                        >

                                        <ProductProperties {...allProps} />

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        );
    };

    const renderItemSelectionCard = () => {
        /** Render a card that contains the ClaimItemSearch component **/

        return (
            <Transition.Child as={Fragment} {...standardTransition} >
                <div className="relative max-h-max w-full max-w-4xl px-4 my-8 bg-white shadow-2xl">
                    <button
                        type="button"
                        className="absolute top-1 right-1 text-red-300 hover:text-gray-500 tailwind-tooltip-container"
                        onClick={() => {
                            // close modal 2
                            props.setShowModalTwo(false);
                        }}
                    >
                        <XIcon className="h-6 w-6"/>
                        <span className='tailwind-tooltip -top-[1rem] -left-[5rem]'>Close</span>
                    </button>

                    <ClaimItemSearch {...props} mode="editProductModal" editModal_setProduct={onSelectProduct}/>
                </div>
            </Transition.Child>
        );

    };

    function render() {
        // If no product is available for this modal, show the ClaimItemSearch
        if (!product) return renderItemSelectionCard();
        else return renderEditProtoCard();
    }

    return render();

}

function ProductProperties(props) {
    /** Display all the various properties */

    const onPropertyModified = (propertyName, newValue) => {
        /** When a property has been modified, find that property via its name or value, and update the state **/

        if (propertyName === 'brand') {
            props.setBrand(newValue);
            return;
        }
        if (propertyName === 'model_numbers') {
            props.setModelNumbers(newValue);
            return;
        }
        if (propertyName === 'ean_codes') {
            props.setEanCodes(newValue);
            return;
        }
        if (propertyName === 'date_released') {
            props.setReleaseDate(newValue);
            return;
        }
        if (propertyName === 'provider_image_url') {
            props.setNewImageUrl(newValue);
            return;
        }

        let modifiedProperty = props.allProperties?.find(property => property.name === propertyName);
        if (!modifiedProperty) {
            modifiedProperty = {name: propertyName, value: newValue};
            props.allProperties.push(modifiedProperty);
        }

        modifiedProperty.value = newValue;
        props.setProduct({...props.product});

        props.setProductHasBeenModified(true);
    };

    // === Required fields
    function ProductIdField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem]`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Product ID
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {props.product?.id || '-'}
                    </p>

                </td>
            </tr>
        );

    }

    function ModelNumberField(props) {

        const [showFullModelNumbers, setShowFullModelNumbers] = useState(false);

        const displayedModelNumbers = (props.mode === 'view' && props.modelNumbers.length > 20 && !showFullModelNumbers) ?
            <span>{props.modelNumbers.substring(0, 20)}...<span className='text-gray-400 text-xs cursor-pointer'> click to expand</span> </span>
            :
            props.modelNumbers
        ;

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem] outline outline-3 ${props.focusedProductProperty === 'model_numbers' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Model numbers
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p onClick={() => setShowFullModelNumbers(!showFullModelNumbers)}>
                            {displayedModelNumbers}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('model_numbers')}
                            onChange={(e) => {
                                onPropertyModified('model_numbers', e.target.value)

                            }}
                            value={props.modelNumbers}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Multiple values should be seperated by a comma <br/>
                            e.g: <i>MLKT3LL/A, MLLA3Y/A, MLLD3Y/A?ES</i>
                        </span>
                    }

                </td>
            </tr>
        );

    }

    function EANCodeField(props) {

        const [showFullEANCodes, setShowFullEANCodes] = useState(false);

        const displayedEANCodes = (props.mode === 'view' && props.eanCodes.length > 20 && !showFullEANCodes) ?
            <span>{props.eanCodes.substring(0, 20)}...<span className='text-gray-400 text-xs cursor-pointer'> click to expand</span> </span>
            :
            props.eanCodes
        ;

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem] outline outline-3 ${props.focusedProductProperty === 'ean_codes' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    EAN codes
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p onClick={() => setShowFullEANCodes(!showFullEANCodes)}>
                            {displayedEANCodes}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('ean_codes')}
                            onChange={(e) => {
                                onPropertyModified('ean_codes', e.target.value)
                            }}
                            value={props.eanCodes}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Multiple values should be seperated by a comma <br/>
                            e.g: <i>08182790, 08182791, 08182797</i>
                        </span>
                    }

                </td>
            </tr>
        );
    }

    function DateReleasedField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem] outline outline-3 ${props.focusedProductProperty === 'date_released' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Date Released
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p>
                            {props.releaseDate || ''}
                        </p>
                        :
                        <input
                            className="input"
                            type='date'
                            onFocus={() => props.setFocusedProductProperty('date_released')}
                            onChange={(e) => onPropertyModified('date_released', e.target.value)}
                            value={props.releaseDate}
                        />
                    }
                </td>
            </tr>
        );
    }

    function ImageURLField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem] outline outline-3 ${props.focusedProductProperty === 'provider_image_url' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Product Image URL
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p>
                            {props.newImageUrl || ''}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('provider_image_url')}
                            onChange={(e) => onPropertyModified('provider_image_url', e.target.value)}
                            value={props.newImageUrl}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Insert the URL to the product image<br/>
                            e.g: <i>https://example.com/image.jpg</i>
                        </span>
                    }

                </td>
            </tr>
        );
    }

    function BrandField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem]`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Brand
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {props.brand || '-'}
                    </p>

                </td>
            </tr>
        );
    }

    function CategoryField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md  h-[2rem]`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Category
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {formatAsTitle(props.product.category) || '-'}
                    </p>

                </td>
            </tr>
        );
    }

    const allProps = {
        ...props,
        onPropertyModified,
    }

    function AddSpec(props) {

        if (props.mode === 'view') return <></>;

        function standardPropertyFields() {

            return (
                <>

                    {/* GAP */}
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>

                    {/* + NEW PROPERTY */}
                    <tr>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <i>Additional specs</i>
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <div className="flex justify-end">
                                <NewPropertyButton
                                    {...props}
                                />
                            </div>
                        </td>
                    </tr>

                </>
            );
        }

        return standardPropertyFields(); // return standard input fields
    }

    function renderProperties() {

        return (

            <>

                {/* PRODUCT ID */}
                {ProductIdField(allProps)}


                {/* GENERAL REQUIRED FIELDS */}
                {BrandField(allProps)}
                {CategoryField(allProps)}
                <ModelNumberField {...allProps} />
                <EANCodeField {...allProps} />
                {DateReleasedField(allProps)}
                {ImageURLField(allProps)}

                <h3 className='pt-10'>Required</h3>
                {props.getCombinedPropertiesOfAllProducts('REQUIRED').map((combinedProperty, index) => (
                    <SingleProperty key={combinedProperty.name} {...allProps} combinedProperty={combinedProperty}/>
                ))}
                <h3 className='pt-10'>Optional</h3>
                {props.getCombinedPropertiesOfAllProducts('OPTIONAL').map((combinedProperty, index) => (
                    <SingleProperty key={combinedProperty.name} {...allProps} combinedProperty={combinedProperty}/>
                ))}
                <h3 className='pt-10'>Additional</h3>
                {props.getCombinedPropertiesOfAllProducts('ADDITIONAL').map((combinedProperty, index) => (
                    <SingleProperty key={combinedProperty.name} {...allProps} combinedProperty={combinedProperty}/>
                ))}


                {/* GAP */}
                <tr>
                    <td></td>
                    <td></td>
                </tr>

                {/* NEW PROPERTIES */}
                {AddSpec(allProps)}

                {/* GAP */}
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </>

        );
    }

    return renderProperties()

}

function SingleProperty(props) {
    /** Render a single property field, with the property name and value
     * - if the mode is 'view', the property is displayed as text
     * - if the mode is 'edit', the property is displayed as an Spec Select field
     * property - This property (e.g speakers) in the product
     * props.combinedProperty - This property (e.g speakers) from the list of all properties in all products
     * **/

    let property = props.allProperties.find(p => p.name === props.combinedProperty.name);
    if (!property) property = {name: props.combinedProperty.name, value: ''};

    const product = props.product;

    function propertyHasBeenModified(name) {
        /** Find this property in the originalProduct.properties, compare with modifiedValue, and return True or False  **/

        /** The Model number and EAN code properties aren't included in the originalProduct.properties
         * if(name === 'Model numbers' && modelNumbers != modifiedValue) return true
         * if(modifiedValue === 'EAN codes' &&  eanCodes != modifiedValue) return true **/

        let modifiedProperty = props.allProperties?.find(property => property.name === name);
        if (!modifiedProperty) return false;
        let modifiedValue = modifiedProperty.value;

        let property = props.originalProduct.properties?.find(property => property.name === name);
        if (!property) return true;

        const valueIsTheSame = property.value === modifiedValue;
        return !valueIsTheSame;
    }

    const onRemoveExistingProperty = (propertyToRemove_name) => {
        /** remove the given property, from the list of properties in the newProperties state **/

            // keep all properties except the property to remove
        let removed = props.allProperties.filter(property => property.name !== propertyToRemove_name);

        props.setAllProperties(removed);

    };

    function onTogglePropertyInputType(property) {
        property.inputType = property.inputType === 'input' ? 'select' : 'input';
        props.setProduct({...product});
    }

    function style_comparePropertyValueToOtherProducts(property) {
        /** Certain properties can be compared (red if lower, green if higher)
         * Find the property on all Products, and return different styling depending on whether the product's property
         * is greater than or less than the other products equivalent property**/

        if (!property.value) return '';
        if (property.value.includes(',')) return ''; // if the value is a list of values, don't compare
        if (property.value.includes('x')) return ''; // if the value is a resolution, don't compare
        if (isNaN(property.value.charAt(0))) return ''; // if the value does not start with a number, don't compare

        let productProperty = props.allProperties?.find(p => p.name === property.name);
        if (!productProperty) return ''; // if this product does not have this property, don't compare

        let comparison = 0; // 0 = equal, 1 = higher, -1 = lower

        props.allProducts.forEach(_product => {
            if (_product.id === product.id) return; // don't compare with itself

            let otherProductProperty = _product.properties?.find(p => p.name === property.name);
            if (!otherProductProperty) return ''; // if this product does not have this property, don't compare

            let otherProperty = parseFloat(otherProductProperty.value.replace(/\D/g, ''));
            let thisProperty = parseFloat(productProperty.value.replace(/\D/g, ''));
            if (otherProperty > thisProperty) comparison = -1;
            if (otherProperty < thisProperty) comparison = 1;
        });

        if (comparison === 0) return '';
        if (comparison === 1) return 'bg-green-100';
        if (comparison === -1) return 'bg-red-100';
    }

    function propertyCanBeRemoved(field) {
        /** Required and general properties can not be removed from a proto */

        // Base fields can not be removed
        if (field === 'brand' || field === 'model_numbers' || field === 'ean_codes' || field === 'date_released')
            return false;

        // Find the importance of this field
        let importance = props.specList_array.find(spec => spec.field === field)?.importance

        // Required and general fields can not be removed
        return !(importance === 'REQUIRED' || importance === 'GENERAL');

    }

    // TODO this should be a precalcualted
    let propertyExistsInProduct = props.allProperties.find(p => p.name === property.name);
    // let propertyIsNew = props.newProperties.find(p => p.name === property.name);

    const allProps = {
        ...props,
        property,
        propertyHasBeenModified,
        onRemoveExistingProperty,
        onTogglePropertyInputType,
        style_comparePropertyValueToOtherProducts,
        propertyCanBeRemoved,
        propertyExistsInProduct,
        // propertyIsNew

    }

    return <PropertyRow {...allProps} />

}

function PropertyRow(props) {

    const property = props.property;

    return (
        <tr key={property.name}
            onClick={() => props.setFocusedProductProperty(property.name)}
            className={
                classNames(
                    `transition-all duration-500 rounded-md outline outline-3 h-[2rem]`,

                    // When a property has been highlighted on one product, highlight the property on the other product
                    `${props.focusedProductProperty === property.name ? 'outline-cyan-200' : 'outline-transparent'}`,

                    // Highlight this property, if this product's property is less than or higher than another product's property
                    props.style_comparePropertyValueToOtherProducts(property),

                    // TODO this should be a precalcualted variable "propertyAbsentButPresentInOtherProducts"
                    // Highlight this property, if this product is missing this property, but the other product has the property
                    props.allProperties?.find(p => p.name === property.name) ? '' : 'bg-slate-200/75',

                    // Highlight this property, if this product has been modified
                    props.propertyHasBeenModified(property.name) ? 'bg-sky-100' : '',
                )
            }>

            {/* PROPERTY DISPLAY NAME */}
            <td className="px-4 py-2 text-sm text-gray-900">
                <div className='relative flex items-center gap-2'>

                    <div className="absolute left-[-2.3rem] top-0 h-full flex items-center text-gray-400">

                        {/* REQUIRED */}
                        {props.specList_array.find(spec => spec.field === property.name && spec.brand === props.brand)?.importance === 'REQUIRED' && (
                            <Tooltip content='Required' position='right' style='max-w-[15rem]'>
                                <LockClosedIcon className="h-4 w-4 text-red-400"/>
                            </Tooltip>
                        )}

                        {/* OPTIONAL */}
                        {props.specList_array.find(spec => spec.field === property.name && spec.brand === props.brand)?.importance === 'OPTIONAL' && (
                            <Tooltip content='Optional' position='right' style='max-w-[15rem]'>
                                <LockClosedIcon className="h-4 w-4 text-red-400"/>
                            </Tooltip>
                        )}

                        {/* ADDITIONAL */}
                        {/*{props.specList_array.find(spec => spec.field === property.name)?.importance === 'ADDITIONAL' && (*/}
                        {/*    <PlusIcon className="h-4 w-4"/>*/}
                        {/*)}*/}

                    </div>

                    {formatAsTitle(property.name)}
                </div>
            </td>

            {/* PROPERTY VALUE */}
            <td className="px-4 py-2 text-sm text-gray-900">

                {props.mode === 'view' ?

                    // VIEW MODE
                    <p>
                        {props.allProperties?.find(p => p.name === property.name)?.value}
                    </p>
                    :

                    // EDIT MODE
                    <div className='flex justify-between'>


                        {/* DIRECT TEXT INPUT OR SPEC SELECT*/}
                        {property.inputType === 'input' ?
                            <input
                                className="input"
                                onChange={(e) => props.onPropertyModified(property.name, e.target.value)}
                                value={props.allProperties?.find(p => p.name === property.name)?.value}
                            />
                            :
                            <SpecValueSelector
                                {...props}
                                specField={property.name}
                                property={property}
                                onChange={(e) => props.onPropertyModified(property.name, e.target.value)}
                            />
                        }


                        <div className='h-[2rem] w-[4rem] flex justify-end gap-2'>

                            {/* VALUE EDIT: DIRECT TEXT INPUT */}
                            {/* Hidden for now - may be re-enabled later */}
                            <button className="hidden m-0 p-2 btn-outline-light h-full tailwind-tooltip-container"
                                    onClick={() => props.onTogglePropertyInputType(property)}>
                                <PencilAltIcon className="h-4 w-4"/>
                                <span className='tailwind-tooltip -left-[4rem]'>Direct text input</span>
                            </button>

                            {/* REMOVE PROPERTY */}
                            <button className={classNames(
                                "m-0 p-2 btn-outline-light h-full",
                                props.propertyCanBeRemoved(property.name) ? '' : 'hidden'
                            )}
                                    onClick={() => props.onRemoveExistingProperty(property.name)}>
                                X
                            </button>

                            {/* COPY SPEC ACROSS */}
                            {/* we only want to see this button if there is a second modal open */}
                            <Tooltip message="Copy this property to the other product" position="left"
                                     style='max-w-[15rem]'>
                                <button
                                    className={classNames(
                                        "m-0 p-2 btn-outline-light h-full",
                                        props.showModalTwo ? 'block' : 'hidden'
                                    )}
                                    onClick={() => props.setCopyProperty(property)}
                                >
                                    {props.modalCount === 1 ?
                                        <ArrowLeftIcon className="h-3 w-3"/>
                                        :
                                        <ArrowRightIcon className="h-3 w-3"/>
                                    }
                                </button>
                            </Tooltip>
                        </div>

                    </div>

                }
            </td>
        </tr>
    );
}

function SpecValueSelector(props) {

    function getAllValuesForSpecField(specField) {
        // Get the values for the selected spec field

        if (isEmpty(props.specList_array)) return [];

        specField = specField.replaceAll(' ', '_').toLowerCase();

        let forBrand = props.specList_array.filter(spec => spec.brand === props.product.brand);
        let fieldData = forBrand.find(spec => spec.field === specField);
        let values = fieldData?.values || [];

        return values;
    }

    return (
        <select
            onChange={props.onChange}
            className="select max-w-[13rem]"
        >
            <optgroup label='Current'>
                <option value={props.property.value}>{props.property.value}</option>
            </optgroup>

            <optgroup label='Options'>
                {getAllValuesForSpecField(props.specField).map(value => {
                    return <option key={value} value={value}>{value}</option>
                })}
            </optgroup>

        </select>
    );
}

function NewPropertyButton(props) {

    function onAddNewProperty() {

        // Get the spec field that has been selected to add
        let selectedSpecToAdd = document.getElementById('addNewPropertySelect').value;

        if (isSpecAlreadyInProperties(selectedSpecToAdd)) return;

        let firstValue = ''

        const newProperty = {name: selectedSpecToAdd, value: firstValue}

        // Add the new property to the list of new properties
        props.setAllProperties([...props.allProperties, newProperty]
        )
    }

    function getSpecsByImportance(importanceFilter) {

        let specsFilteredByImportance = props.specList_array.filter(s =>
            s.brand === props.product.brand &&
            s.importance === importanceFilter // 'REQUIRED', 'ADDITIONAL', 'OPTIONAL', 'GENERAL'
        );

        // Sort the specs alphabetically, by field name
        specsFilteredByImportance.sort((a, b) => a.field.localeCompare(b.field));

        return specsFilteredByImportance;
    }

    function isSpecAlreadyInProperties(specField) {
        // Check if the spec field is already in the product's properties
        let specExists = props.allProperties.find(property => property.name === specField);
        if (specExists) return true;
        return false;
    }

    function formatSpecName(specField) {
        // Limit the spec.field string to 50 characters and append '...' if it's longer
        const displayField = specField.length > 50 ? `${specField.substring(0, 50)}...` : specField;

        return formatAsTitle(displayField);
    }

    return (
        <div className='flex'>

            <select
                id='addNewPropertySelect'
                className="select"
            >

                <optgroup label="Required">

                    {getSpecsByImportance('REQUIRED').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                    key={spec.importance + spec.field}
                                    value={spec.field}>
                                {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}

                </optgroup>


                <optgroup label="Optional">

                    {getSpecsByImportance('OPTIONAL').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                    key={spec.importance + spec.field}
                                    value={spec.field}>
                                {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}
                </optgroup>


                <optgroup label="Additional">

                    {getSpecsByImportance('ADDITIONAL').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                    key={spec.importance + spec.field}
                                    value={spec.field}>
                                {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}
                </optgroup>

            </select>


            <button className="btn-raised p-2 w-[8rem]"
                    onClick={onAddNewProperty}
            >
                + New property
            </button>

        </div>

    );

}



