// Library imports
import React, {useState} from "react";

// Local imports
import AlertModal from '../modals/AlertModal.jsx';
import {requestPasswordReset} from "../../utils/coreApi.js";
import {alertError} from "../../utils/helpers.js";


const RequestPasswordReset = () => {

    // UI states
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        "iconType": "success",
        "heading": "Password reset requested",
        "message": "Check your email for the reset link.",
    });

    const requestReset = (event) => {

        let emailField = document.getElementById("email");

        if (!emailField.checkValidity()) {
            return;
        }

        requestPasswordReset(
            emailField.value,
            (data) => {

                alertOptions["onClose"] = () => {
                    window.location = "/login"
                }
                setAlertOptions({...alertOptions});
                setAlertOpen(true);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );

        event.preventDefault();
        return false;
    };

    return (
        <>

            <AlertModal
                open={alertOpen}
                setOpen={setAlertOpen}
                options={alertOptions}
            />

            <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white">

                <div class="sm:mx-auto sm:w-full sm:max-w-md">
                    <img class="mx-auto h-24 w-auto" src="/logo512.png" alt="SLVRCLD logo"/>
                    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Reset password
                    </h2>
                </div>

                <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <p class="text-gray-900 mb-3">
                            Enter your email address to receive a password reset link.
                        </p>
                        <p className="text-gray-700 italic text-sm mb-5"> Note: If the email you enter is not registered
                            in our system, no email will be sent.</p>
                        <form class="space-y-6" action="#" method="POST">
                            <div>
                                <label for="email" class="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div class="mt-1">
                                    <input id="email" name="email" type="email" autoComplete="email" required
                                           class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                                </div>
                            </div>

                            <div id="errorBox" class="flex items-center justify-between hidden">
                                <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                     role="alert">
                                    <strong id="errorMessage" class="font-bold">Incorrect username of password.</strong>
                                </div>
                            </div>

                            <div className='w-full flex justify-center'>
                                <button id="resetButton" type="button" onClick={requestReset}
                                        class="btn">
                                    <svg class="hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Request password reset</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestPasswordReset;