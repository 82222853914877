import React from 'react';
import { useCookies } from 'react-cookie';
import {ExternalLinkIcon} from '@heroicons/react/outline';


export default function CookieConsent(props){

    const [cookies, setCookie] = useCookies(['acceptedCookiePolicy']);

    // TODO clear cookie on logout

    function onAgree(){
        /** Set cookie to not show consent banner again */

        const cookieAge_seconds = 60 * 60 * 24 * 365 // 1 year
        setCookie('acceptedCookiePolicy', '1',
            {
                path: '/', // Make this cookie is accessible on all pages
                maxAge: cookieAge_seconds, // Expiry time
                sameSite: true // Browser should only expose this cookie on this domain
            });

    }

    function onDecline(){
        props.showConfirmModal(
            "info",
            "Decline Cookies",
            "Are you sure you would like to decline to the use of cookies? You will be redirected away from this page",
            "Leave",
            ()=>{
                window.location.href = '/logout'
            },
            null)
    }

    if(!props.user){
        return <></>
    }

    if(cookies.acceptedCookiePolicy){
        return <></>
    }

    return (
        <div className="absolute h-1/4 p-6 min-h-[12rem] bg-stone-100 z-[100] bottom-16 left-16 right-16 shadow-2xl border border-2 border-stone-200">
            <div className="w-full h-full flex flex-col justify-center items-center gap-6">
                <p className="text-2xl font-bold">
                    By using this site you agree to our cookie policy
                </p>

                <p className="flex items-center gap-1">
                    We only use essential cookies to make our site work. By clicking “Accept,” you agree to our website’s cookie use as described in our

                   <a href="https://www.slvrcld.com/legal/terms-and-conditions" target="_blank"
                        className="text-blue-600 hover:text-blue-500 underline decoration-1	underline-offset-[3px] text-center flex justify-center items-center">
                        Privacy Policy
                        <ExternalLinkIcon className="h-5 w-5 ml-1"/>
                    </a>
                   and if not please do not make use of the site.
                </p>

                <div className="flex gap-4">
                    <button className="btn-outline-light shadow-none text-stone-400 m-0" onClick={onDecline}>Leave</button>
                    <button className="btn-success m-0 px-16" onClick={onAgree}>Accept</button>
                </div>
            </div>
        </div>
    );

}