import React, {useState} from "react";
import {classNames} from "../../utils/helpers";

export const ImageZoom = (props) => {
    /* This component creates a e-commence style pop-up preview when hovering over a image */

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="relative"
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                src={props.imageSrc}
                onError={props.onError}
                alt={props.altText}
                className={`${props.className}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            {isHovered && (
                <div className="absolute inset-0 z-[110] pointer-events-none">
                    <img
                        src={props.imageSrc}
                        alt={props.altText}
                        className={classNames(
                            'object-cover transition-transform transform',
                            props.zoomClassName || 'scale-[400%]'
                        )}
                    />
                </div>
            )}
        </div>
    );
};

/** EXAMPLE
    <ImageZoom
        imageSrc={product.images.thumbnail_web || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
        altText="Product Preview"
        className="w-full min-w-[4rem] max-h-[2rem] object-contain px-2 !z-[100]"
        zoomClassName={classNames(
            'border-[1px] border-stone-400 rounded-md',
            // Don't show the preview for the placeholder product image
            product.images.thumbnail_web ? 'scale-[400%]' : 'scale-[100%]'
        )}
    />
 */