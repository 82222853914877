import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import Logout from './containers/auth/Logout';
import PasswordReset from './containers/auth/PasswordReset';
import RequestPasswordReset from './containers/auth/RequestPasswordReset';
import NotFound from './containers/auth/NotFound';
import ConnectionError from './containers/auth/ConnectionError';
import AdminHAI from './containers/admin/AdminHAI';
import NavbarMain from './components/navbars/NavbarMain';
import {queryCurrentUser, queryFullClaim} from './utils/coreApi';
import AdminOrganisations, {EditOrganisationProfileModal} from './containers/admin/AdminOrganisations';
import ClaimPages from './ClaimPages';
import AlertModal from './containers/modals/AlertModal';
import NotificationTopRight from './components/NotificationTopRight';
import NotificationCenter from './components/NotificationCenter';
import ConfirmModal from './containers/modals/ConfirmModal';
import Footer from './components/Footer';
import Ratelimited from './components/Ratelimited';
import DevelopmentComponentDemo from './containers/admin/DevelopmentComponentDemo';
import AdminReports from './containers/admin/reports/AdminReports';
import PageBar from './components/navbars/PageBar';
import CookieConsent from './components/CookieConsent';
import {CookiesProvider} from 'react-cookie';
import AccessControl from './AccessControl';
import Safe from "./components/Safe";
import {useQuery} from "./components/useQuery";
import {ORGANISATIONS} from "./utils/graphqlTemplates";
import CustomContentModal from "./containers/modals/CustomContentModal";
import {Login} from "./containers/auth/Login";
import ClaimAttachments from "./containers/modals/ClaimAttachments";
import SLVRCLD_UI_Library from "./slvrcld_ui_library/SLVRCLD_UI_Library";

export default function App() {

    const [user, setUser] = useState(null);

    /** This is the organisation that the logged-in user belongs to
     * A user may only belong to a single organisation */
    const mainOrganisationHook = useQuery({
        dependsOn: [user], // Only run the query if the user has been set/retrieved
        queryStringFunction: () => {
            if (!user) return null
            let queryArgs = ` unique_reference_name:"${user.organisation.unique_reference_name || ''}"`;
            let query = ORGANISATIONS.replace('|placeholder|', queryArgs);
            return query.replace('query {', `query user_organisation {`);
        },
        onSuccess: (data) => {
            mainOrganisationHook.setState(data['organisations'][0])
        },
        // onError: onError, // TODO cannot access before initialisation
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const { state: mainOrganisation, setState: setMainOrganisation } = mainOrganisationHook

    /** list of organisations that share an account with the mainOrganisation
     * Each organisation in this list has an account, where it is 'organisation_1' or 'organisation_2', and the mainOrganisation is the other */
    // TODO this state is not used, but it is useful, so should be implemented with Cache and a delay to prevent blocking page load
    // const [organisations, setOrganisations] = useState(null);

    /** list of accounts. Each account is the mainOrganisation connecting to another organisation.
     * This does not nest down/branch out to accounts of the connected organisations,
     * these accounts are only accounts where the mainOrganisation is 'organisation_1' or 'organisation_2' in the account  **/
    // TODO this state is not used, but it is useful, so should be implemented with Cache and a delay to prevent blocking page load
    const [accounts, setAccounts] = useState(null);


    // The current selected country
    const [selectedCountry, setSelectedCountry] = useState({name: '', code: ''});

    const countrySelectionAccountsHook = useQuery({
        cacheKey: 'countryMinAccountSelection', cacheResponse: true, useExistingCache: true, skipQueryIfCache: true, cacheExpirationMin: 60 * 4, // 4 hours
    }); // the currently selected country
    const { state: countrySelectionAccounts, setState: setCountrySelectionAccounts } = countrySelectionAccountsHook

    // Certain pages inject custom content into the top navbar, such as the search bar and snappy claims modal
    const [navbarTopPageContent, setNavbarTopPageContent] = useState({}); // the currently selected country

    // Show modal state for the global EditOrganisationProfileModal
    const [showEditOrgProfileModal, setShowEditOrgProfileModal] = useState(false);

    // Show modal state for the global PageInfoModal
    const [pageInfoModalOpen, setPageInfoModalOpen] = useState(false);

    // Is this instance of portal running as SLVRCLD or Thesl
    const [portalRepresentative, setPortalRepresentative] = useState('SLVRCLD');

    // A copy of the latest claim query, each page uses this claim on page load whilst waiting for a updated claim query
    const [globalClaim, setGlobalClaim] = useState(null);
    function updateGlobalClaim(claimId, setClaim) {
        /* This function has 2 jobs
        * 1. Any pages that wants to query the claim object, will query it through this function
        * 2. If (from a previous query) a copy of the claim is stored in the globalClaim object, then immediately
        *   set the requested claim, and re-set it again once the claims query is returned */
        // claimId : the id of the claim that is being requested
        // setClaim : the function to set the claim state in the page

        // 1
        if(globalClaim) {

            // immediately set the page's claim state to the global claim
            if(globalClaim.id === claimId)
                setClaim(globalClaim);

            // The requested claim is not the same as the global claim, so reset the global claim
            else
                setGlobalClaim(null);

        }

        // 2
        // query the latest claim and update the global claim, and the page's claim state
        queryFullClaim(
            claimId,
            (data) => {
                const updatedClaim = data['claims']?.[0];
                showToastNotificationModal(
                    'success',
                    'Claim Updated',
                    '',
                    500
                );
                setGlobalClaim(updatedClaim);
                setClaim(updatedClaim);
            },
            (error) => onError
        );
    }

    // Modal states
    const [showClaimAttachmentsModalOpen, setShowClaimAttachmentsModal] = useState(false);
    const [claimAttachmentsOptions, setClaimAttachmentsOptions] = useState({
        'claim': null,
    });

    const [alertModalOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'iconType': '',
        'heading': '',
        'message': '',
        'onClose': null
    });
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions, setConfirmModalOptions] = useState({
        'iconType': '',
        'heading': '',
        'message': '',
        'onAccept': null,
        'onCancel': null
    });
    const [notificationCenterOpen, setNotificationCenterOpen] = useState(false);
    const [notificationCenterOptions, setNotificationCenterOptions] = useState({
        'iconType': '',
        'heading': '',
        'message': '',
        'onClose': null
    });
    const [customModalOpen, setCustomModalOpen] = useState(false);
    const [customModalOptions, setCustomModalOptions] = useState({
            content: '',
            dependencies: []
    });
    const [topRightNotificationOpen, setTopRightNotificationOpen] = useState(false);
    const [topRightNotificationOptions, setTopRightNotificationOptions] = useState({
        'iconType': '',
        'heading': '',
        'message': '',
        'onClose': null
    });

    useEffect(() => {
        /** On page load */
        if (user) return;
        getUser();
    }, []);

    useEffect(() => {
        /** Check the current route and set the representative */
        if(window.location.origin.includes('slvrcld')) setPortalRepresentative('SLVRCLD');
        if(window.location.origin.includes('thesl')) setPortalRepresentative('THESL');
    }, []);

    const getUser = () => {
        queryCurrentUser(
            null,
            (data) => {
                setUser(data['user']);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const showAlertModal = (type, heading, message, onCloseCallback = null) => {
        console.log(
          `%c showAlertModal: %c${type} - %c${heading}, ${message}`,
          "color: green; font-weight: bold;", // 1st %c
          "font-weight: bold;", // 2nd %c
          "font-style: italic;" // 3rd %c
        );
        setAlertOptions({
            'iconType': type,
            'heading': heading,
            'message': message,
            'onClose': onCloseCallback
        });
        setAlertOpen(true);
    };
    const onError = (error, callback) => {
        console.log('onError: ', error);
        console.error(error);
        setAlertOptions({
            'iconType': 'error',
            'heading': error['type'],
            'message': error['message'],
            'onClose': callback
        });
        setAlertOpen(true);
    };

    const showConfirmModal = (type, heading, message, confirmButtonText, onConfirmCallback, onCancelCallback, kwargs) => {
        console.log(
          `%c showConfirmModal: %c${type} - %c${heading}, ${message}`,
          "color: green; font-weight: bold;", // 1st %c
          "font-weight: bold;", // 2nd %c
          "font-style: italic;" // 3rd %c
        );
        setConfirmModalOptions({
            'iconType': type,
            'heading': heading,
            'message': message,
            'confirmButtonText': confirmButtonText ? confirmButtonText : 'OK',
            'onConfirm': onConfirmCallback,
            'onCancel': onCancelCallback,
            'inputValue': '',
            'kwargs': kwargs,
        });
        setConfirmModalOpen(true);
    };

    const showToastNotificationModal = (type, heading, message, displayTime = 2000, onCloseCallback = null) => {
        if(!type) type = 'success';
        console.log(
          `%c showToastNotificationModal: %c${type} - %c${heading}, ${message}`,
          "color: green; font-weight: bold;", // 1st %c
          "font-weight: bold;", // 2nd %c
          "font-style: italic;" // 3rd %c
        );
        setTopRightNotificationOptions({
            'iconType': type,
            'heading': heading,
            'message': message,
            'onClose': onCloseCallback
        });
        setTopRightNotificationOpen(true);

        setTimeout(() => setTopRightNotificationOpen(false), displayTime);
    };

    const showNotificationModal = (type, heading, message, onCloseCallback = null) => {
        console.log(
          `%c showNotificationModal: %c${type} - %c${heading}, ${message}`,
          "color: green; font-weight: bold;", // 1st %c
          "font-weight: bold;", // 2nd %c
          "font-style: italic;" // 3rd %c
        );
        setNotificationCenterOptions({
            'iconType': 'success',
            'heading': heading,
            'message': message,
            'onClose': onCloseCallback
        });
        setNotificationCenterOpen(true);
    };

    const showCustomModal = (bodyContent, onClose) => {
        setCustomModalOptions({
            content: bodyContent,
            onClose: onClose
        });
        setCustomModalOpen(true);
    };

    /** To prevent repeating handing props to children, we use a props wrapper class
     * This allows the use of {...props} leaving the base structure readable */
    const allProps = {
        user,
        setUser,
        getUser,

        mainOrganisation,
        setMainOrganisation,
        mainOrganisationHook,

        // TODO remove this state
        accounts,
        setAccounts,

        selectedCountry,
        setSelectedCountry,

        countrySelectionAccountsHook,
        countrySelectionAccounts,
        setCountrySelectionAccounts,

        navbarTopPageContent,
        setNavbarTopPageContent,

        showEditOrgProfileModal,
        setShowEditOrgProfileModal,

        portalRepresentative,
        setportalRepresentative: setPortalRepresentative,

        globalClaim, setGlobalClaim,
        updateGlobalClaim,

        onError,
        showAlertModal,
        showToastNotificationModal,
        showNotificationModal,
        showCustomModal,
        showConfirmModal,

        alertModalOpen,
        setAlertOpen,
        alertOptions,
        setAlertOptions,

        confirmModalOpen,
        setConfirmModalOpen,
        confirmModalOptions,
        setConfirmModalOptions,

        notificationCenterOpen,
        setNotificationCenterOpen,
        notificationCenterOptions,
        setNotificationCenterOptions,

        customModalOpen,
        setCustomModalOpen,
        customModalOptions,
        setCustomModalOptions,

        topRightNotificationOpen,
        setTopRightNotificationOpen,
        topRightNotificationOptions,
        setTopRightNotificationOptions,

        showClaimAttachmentsModalOpen,
        setShowClaimAttachmentsModal,
        claimAttachmentsOptions,
        setClaimAttachmentsOptions,

        pageInfoModalOpen,
        setPageInfoModalOpen
    };


    return (
        <BrowserRouter>

            {/* Root container uses flex-col to stack elements vertically */}
            <div className="flex flex-col relative w-screen h-screen body-background" onLoad={setPlatformZoom}>

                <Modals {...allProps} />

                <NavbarMain {...allProps} />

                {/*
                    flex-1: Makes this container take up remaining vertical space after Navbar.
                    overflow-y-scroll: Enables vertical scrolling if content overflows it's height.
                    flex flex-col: Arranges inner content vertically.
                */}
                <div className="flex-1 overflow-y-scroll flex flex-col">

                    <Safe><PageBar {...allProps} /></Safe>

                    <Safe><AllRoutes {...allProps} /></Safe>

                    {/* grow: Expands to push Footer to the bottom of the container */}
                    <div className="grow"></div>

                    <Footer {...allProps} />

                </div>

            </div>
        </BrowserRouter>
    );

}

function setPlatformZoom() {
    /** "Zoom out" the platform using CSS, for screens under a certain width. Check index.css
     * Due to some browsers behaving unexpectedly with zoom, only chrome is supported for this feature.
     *
     * https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
     // please note,
     // that IE11 now returns undefined again for window.chrome
     // and new Opera 30 outputs true for window.chrome
     // but needs to check if window.opr is not undefined
     // and new IE Edge outputs to true now for window.chrome
     // and if not iOS Chrome check
     // so use the below updated condition */
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
        // is Google Chrome on IOS
    } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome - add zoom styling
        document.querySelector('body').classList.add('platform-zoom');

        // adjust all elements using 'screen' (zoom:1 -> 100vh/vw, zoom:0.8 -> 125vh/vw)
        document.querySelectorAll('.h-screen').forEach(element => {
            element.classList.add('h-screen-zoomed');
            element.classList.add('w-screen-zoomed');
        });

    } else {
        // not Google Chrome
    }

}

const AllRoutes = (props) => {

    function access(child, organisationTypes) {
        return <AccessControl {...props} organisationTypes={organisationTypes}>{child}</AccessControl>;
    }

    props = {...props, access};

    return (
        <Routes>
            <Route element={<PrivateRoutes {...props} />}>

                {/* ALL PAGES RELATED TO CLAIMS */}
                <Route path="/*" element={<ClaimPages {...props} />}/>

                {/* ADMIN */}
                <Route path="/admin" element={access(<AdminHAI {...props} />, 'ADMIN_UP')}/>
                <Route path="/admin/hai" element={access(<AdminHAI {...props} />, 'ADMIN_UP')}/>

                <Route path="/admin/organisations" element={access(<AdminOrganisations {...props} />, 'ADMIN_UP')}/>
                <Route path="/organisation_admin" element={access(<AdminOrganisations {...props} />, 'ADMIN_UP')}/>
                <Route path="/admin/reports" element={access(<AdminReports {...props}/>, 'SUPPLIER_UP')}/>

                <Route path="/dev" element={<DevelopmentComponentDemo {...props}  />}/>
                <Route path="/slvrcld_ui_library" element={<SLVRCLD_UI_Library {...props}  />}/>

                <Route path="*" element={<NotFound {...props} />}/>

            </Route> {/* Private Routes */}

            <Route {...props} element={<ConnectionError {...props} />} path="/ConnectionError"/>

            {/* AUTHENTICATION */}
            <Route path="/logout" element={<Logout {...props} />}/>
            <Route path="/login" element={<Login {...props} />}/>
            <Route path="/password-reset/:token" element={<PasswordReset {...props} />}/>
            <Route path="/request-password-reset" element={<RequestPasswordReset {...props} />}/>
            <Route path="/ratelimited" element={<Ratelimited/>}/>

        </Routes>
    );
};

const Modals = (props) => {
    return (
        <>
            <Safe>
                <AlertModal
                    open={props.alertModalOpen}
                    setOpen={props.setAlertOpen}
                    options={props.alertOptions}
                />
            </Safe>

            <Safe>
                <NotificationTopRight
                    open={props.topRightNotificationOpen}
                    setOpen={props.setTopRightNotificationOpen}
                    message={props.topRightNotificationOptions}
                />
            </Safe>

            <Safe>
                <NotificationCenter
                    open={props.notificationCenterOpen}
                    setOpen={props.setNotificationCenterOpen}
                    message={props.notificationCenterOptions}
                />
            </Safe>

            <Safe>
                <CustomContentModal
                    open={props.customModalOpen}
                    setOpen={props.setCustomModalOpen}
                    {...props.customModalOptions}
                />
            </Safe>

            <ConfirmModal
                open={props.confirmModalOpen}
                setOpen={props.setConfirmModalOpen}
                options={props.confirmModalOptions}
                setOptions={props.setConfirmModalOptions}
            />

            <Safe>
                <ClaimAttachments
                    open={props.showClaimAttachmentsModalOpen}
                    setOpen={props.setShowClaimAttachmentsModal}
                    {...props}
                />
            </Safe>

            {props.showEditOrgProfileModal && <EditOrganisationProfileModal
                {...props}

                showModal={props.showEditOrgProfileModal}
                setShowModal={props.setShowEditOrgProfileModal}
                activeOrgInModal={props.mainOrganisation}
                setActiveOrgInModal={props.setMainOrganisation}

                setShowConfirmDeleteModal={props.showConfirmModal}
                setDeleteModalOptions={null}
            />}

            <Safe>
                <CookiesProvider>
                    <CookieConsent {...props}/>
                </CookiesProvider>
            </Safe>
        </>
    );
};
