// Library imports
import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ArrowCircleLeftIcon, ArrowDownIcon, UserIcon, XIcon as XIconSolid} from '@heroicons/react/solid';

// Local imports
import {
    calculateReplacements,
    createClaimComment,
    customGraphRequest,
    debounceQuery,
    searchLocalities,
    searchRegions,
    updateItemClaimantQuote,
    updateItemQuantity,
    uploadFile
} from '../../utils/coreApi.js';
import {
    calculateProperties,
    classNames,
    currencySymbol,
    escapeDoubleQuotes,
    formatAsTitle,
    isEmpty
} from '../../utils/helpers.js';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import Safe, {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";
import ApiButton from "../../components/base/ApiButton";
import {
    AnnotationIcon,
    ArrowSmRightIcon,
    CheckIcon,
    PhotographIcon,
    PlusIcon,
    XIcon
} from "@heroicons/react/outline";
import FilterSelect from "../../components/base/FilterSelect";
import {Combobox} from "@headlessui/react";
import {ClaimAttachmentsButton} from "../modals/ClaimAttachments";
import CurrencyInput from "../../components/base/CurrencyInput";
import Tooltip from "../../components/Tooltip";
import {ImageZoom} from "../../components/base/ImageZoom";
import {PageHeaderBar} from "../../slvrcld_ui_library/SLVRCLD_UI_Library";
import {SimpleSelect} from "../../components/base/SimpleSelect";
import {CountryMetaData} from "../../utils/constants";
import LimitedOrganisationAccess, {OrganisationType} from "../../components/LimitedOrganisationAccess";
import {useQuery} from "../../components/useQuery";

export default function ClaimCartReview(props) {

    const setTitle = useDocumentTitle("Cart Review");

    // React router
    const navigate = useNavigate();

    const {claimId} = useParams();

    const [claim, setClaim] = useState(null);

    const [showLocationInputs, setShowLocationInputs] = useState(false);

    const [selectedProtoProductsForPossibleItems, setSelectedProtoProductsForPossibleItems] = useState([]);

    const categoriesHook = useQuery({
        queryStringFunction: () => {
            return `
                query itemSearch_categories{
                  categories(sort_by:[{ field:"display_name" order:ASCENDING }]) {
                    error {
                      type
                      message
                    }
                    categories {
                      id
                      category_a
                      category_b
                      category_c
                      category_d
                      display_name
                    }
                  }
                }
            `
        },
        onSuccess: (data) => {
            categoriesHook.setState(data["categories"]);
        },
        cacheExpirationMin: 60 * 4, // 4 hours
        onError: props.onError,
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: selectableCategories, setState: setSelectableCategories} = categoriesHook;

    // On every page load
    useEffect(() => {

        if (claim === null) {
            props.updateGlobalClaim(claimId, setClaim);
        }

        if (claim) setTitle(`Cart : ${claim.insurer_claim_number}`)

    }, [claim]);

    useEffect(() => {
        /** ATTACHMENT MODAL
         * Set/Update the top navbar content when the claim is fetched/updated */

        if (!claim) return;

        props.setNavbarTopPageContent({
            'claim': claim,
            'content':
                <Safe>
                    <div className='flex w-full justify-end'>
                        <ClaimAttachmentsButton {...props} />
                    </div>
                </Safe>
        })
    }, [claim, props.claimAttachmentsOptions]);
    useEffect(() => {
        /** ATTACHMENT MODAL
         * Set the Attachments modal options.
         * Due to state issues, the options needs to be set separately from updating the ClaimAttachmentsButton */

        if (!claim) return;
        const options = {
            'claim': claim
        }
        props.setClaimAttachmentsOptions(options)
    }, [claim]);

    function quantifyClaim(buttonHandler) {
        createClaimComments();

        calculateReplacements(
            claimId,
            (data) => {
                buttonHandler.onSuccess();
                props.showToastNotificationModal(
                    'success',
                    'Claim quantified',
                    ''
                );
                setTimeout(() => {
                    navigate(`/profile/${claimId}`);
                }, 1000);
            },
            (error) => {
                buttonHandler.onError();
                props.onError(error);
            }
        );

    };

    function createClaimComments() {
        /* Mutation call to update the claim.comment field */

        const comment = document.getElementById('cartReviewText').value;

        if (comment) {

            let commentMessage = comment.replace(/\s/g, ' ');
            commentMessage = escapeDoubleQuotes(commentMessage);

            createClaimComment(
                claimId,
                commentMessage,
                (data) => {
                },
                props.onError
            );

        }
    }

    function submitPossibleItems(btnHandler) {

        let mutation = `
            mutation AddMultipleProductsToClaim{
              add_multiple_products_to_claim(
                claim_id: "${claimId}"
                products:[
                ${
            selectedProtoProductsForPossibleItems.filter(p => !p.manual).map(
                product => `
                        {
                            proto_id: "${product.id}"
                            quantity: ${product.quantity || 1}
                        },\n`
            )
        }
                
                ${
            selectedProtoProductsForPossibleItems.filter(p => p.manual === true).map(
                product => `
                        {
                            brand: "${product.brand}",
                            description: "${product.description}",
                            category: "${product.category.id}",
                            model_number: "${product.model_number}",
                            quantity: ${product.quantity || 1}
                        },\n`
            )
        }
                
                ]
              ){
                error{type, message}
                items{
                  id
                }
              }
            }
        `

        console.log('submitPossibleItems mutation', mutation);

        customGraphRequest(
            mutation,
            (data) => {

                props.showToastNotificationModal(
                    'Products added',
                    'The products have been added to the claim',
                    ''
                )
                props.updateGlobalClaim(claimId, setClaim);

                btnHandler.onSuccess()
            },
            (error) => {
                btnHandler.onError()
                props.onError(error)
            }
        )

    }

    const allProps = {
        ...props,
        claim, setClaim,
        claimId,
        navigate,
        showLocationInputs, setShowLocationInputs,
        selectedProtoProductsForPossibleItems, setSelectedProtoProductsForPossibleItems,
        selectableCategories, setSelectableCategories
    }

    // ---

    function updateClaimDetails(graphqlFieldStrings) {
        // Call update_claim mutation and update the modified fields
        /* example:
        * graphqlFieldStrings = [owned_by_id: '...' ]
        * */

        if (!graphqlFieldStrings) return;

        const mutation = `
            mutation UpdateClaim {
              update_claim(
                claim: "${claimId}"
                ${graphqlFieldStrings}
              ) {
                error {
                  type
                  message
                }
                claim {
                  id
                }
              }
            }
    `;

        customGraphRequest(
            mutation,
            (data) => {
                props.updateGlobalClaim(claimId, setClaim);
            },
            props.onError
        )

    }

    function cartItems() {

        if (claim === null) {
            return (
                <ul role="list" className="widget mt-0 divide-y divide-gray-200 w-full">
                    <div className="m-12"><LoadingSpinner body=''/></div>
                </ul>
            );
        }

        // Sort claim.items by date_created - newest items first
        const sortedItems = [...claim.items].sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

        return (
            <ul role="list" className="mt-0 grid grid-cols-2 gap-4 w-full">
                {sortedItems.map((item, itemIdx) => (
                    <SingleItem
                        key={item.id}
                        item={item}
                        itemIdx={itemIdx}
                        claim={claim}
                        {...allProps}
                    />
                ))}
            </ul>
        );
    }

    function possibleItems() {

        if (!claim) return <></>;

        if (isEmpty(claim.possible_items)) return <></>;

        // DEVELOPMENT DATA - Chrome lens extension's possible_items
        // claim.possible_items = placeholderLensChromeExtensionClaimPossibleProducts.claims[0].possible_items;

        return (
            <ul role="list" className="widget mt-0 divide-y divide-gray-200 w-full">

                <div className='flex w-full justify-between items-center pr-10'>
                    <PageHeaderBar
                        pageTitle="Possible Items"
                        pageDescription="Verify the below items to include them in the claim"
                    />

                    <Tooltip
                        content={<p>Convert the below <i>possible items</i> to the selected <b>products</b>, to include
                            them in the claim</p>}
                        classNames='max-w-[15rem]'
                    >
                        <ApiButton
                            onClick={submitPossibleItems}
                            className='btn h-fit'
                            content={'Add items to claim'}
                            successContent={'Items included in claim'}
                            errorContent='Failed to include items'
                        />
                    </Tooltip>

                </div>

                {claim.possible_items.map((possibleProduct, i) =>
                    <SinglePossibleItem
                        key={i}
                        possibleProduct={possibleProduct}
                        i={i}
                        claim={claim}
                        {...allProps}
                    />)}
            </ul>
        );
    }

    const [postalCode, setPostalCode] = useState(''); // the user supplied postal code
    const [regionData, setRegionData] = useState(''); // the region data for the postal code
    const [localityData, setLocalityData] = useState([]); // locality data for the postal code
    const [selectedLocality, setSelectedLocality] = useState(''); // the users selected locality

    useEffect(() => {
        if (!postalCode) setPostalCode(claim?.postal_code || '');
        if (claim && claim.locality) setSelectedLocality(claim.locality);
    }, [claim]);
    useEffect(() => {
        /** When the user has entered a Postal code, the Postal and Region data needs to be queried  */
        getPostalCodes();
    }, [postalCode]);
    const getPostalCodes = () => {
        /** Get the postal code data and region data for the entered postal code */

        if (!postalCode || !claim) return;

        searchRegions(
            postalCode,
            claim.organisation.info.country,
            (data) => {
                if (!data.regions.length > 0) {
                    setRegionData({
                        'regions': [
                            {'region_0': 'Invalid postal code'},
                            {'region_1': ''},
                            {'region_2': ''}
                        ]
                    });
                } else {
                    setRegionData(data);
                    updateClaimDetails(`postal_code: "${postalCode}"`);
                }
            },
            props.onError
        );

        searchLocalities(
            postalCode,
            claim.organisation.info.country,
            (data) => {
                console.log('Localities', data);
                setLocalityData(data?.localities || []);

                // auto select the first locality
                if (data['localities'][0]) setSelectedLocality(data['localities'][0]);
            },
            (error) => {
                console.error('Could not fetch locality data');
            }
        );

    };

    const [claimOrganisationUsers, setClaimOrganisationUsers] = useState(null);
    useEffect(() => {

        if (!claim || !claim.organisation) return;
        if (claimOrganisationUsers) return;

        const query = `
query ClaimOrganisationUsers {
  organisations(
    id: "${claim.organisation.id}"
  ) {
    error {
      type
      message
    }
    organisations {
      id
      users {
        id
        username
        info {
          first_name
          last_name
          full_name
        }
      }
    }
  }
}
        `

        customGraphRequest(
            query,
            (data) => {
                if (data.organisations && data.organisations[0] && data.organisations[0].users) {
                    // 1. Create a copy of the users array to avoid mutating the original data
                    const usersToSort = [...data.organisations[0].users];

                    // 2. Sort the users array alphabetically by full_name
                    usersToSort.sort((userA, userB) => {
                        const nameA = userA.info?.full_name || userA.username || ""; // Fallback to username if full_name is missing
                        const nameB = userB.info?.full_name || userB.username || ""; // Fallback to username if full_name is missing
                        return nameA.localeCompare(nameB); // Use localeCompare for accurate alphabetical sorting
                    });

                    // 3. Update the state with the sorted users within the organisation data
                    setClaimOrganisationUsers({
                        ...data.organisations[0], // Keep other organisation data
                        users: usersToSort
                    });
                } else {
                    setClaimOrganisationUsers(data.organisations[0]); // Set even if users array is empty or undefined to handle potential cases
                }
            },
            props.onError
        );
    }, [claim]);

    function showAddCCEmailModal() {

        function addCCEmail() {

            let ccFirstName = document.getElementById('ccFirstName').value;
            let ccLastName = document.getElementById('ccLastName').value;
            let ccEmail = document.getElementById('ccEmail').value;

            let mutation = `
                    mutation ClaimProfile_AddCCRecipient {
                      add_cc_recipients_to_claim(
                        claim_id: "${claim.id}",
                        cc_recipients: [
                          {
                            email: "${ccEmail}",
                            first_name: "${ccFirstName}",
                            last_name: "${ccLastName}",
                          }
                        ]
                      ){
                        error{type, message}
                        claim{
                          id
                        }
                      }
                    }
                `;

            customGraphRequest(
                mutation,
                (data) => {
                    props.showToastNotificationModal('success', 'CC email added', 'Reloading claim data...')
                    props.updateGlobalClaim(claimId, setClaim);
                    props.setCustomModalOpen(false);
                },
                props.onError
            )

        }

        let content = (
            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl'>Add CC Email Address</h1>

                <div className='w-full border-b-2 border-gray-200 my-4'></div>

                <div className="mb-4">
                    <div className="flex space-x-2 mb-2">
                        <input
                            type="text"
                            id='ccFirstName'
                            placeholder="First Name"
                            className="input flex-1"
                        />
                        <input
                            type="text"
                            id='ccLastName'
                            placeholder="Last Name"
                            className="input flex-1"
                        />
                    </div>
                    <input
                        type="email"
                        id='ccEmail'
                        placeholder="Email"
                        className="input w-full"
                    />
                </div>

                <div className="mt-5 mt-4 flex w-full justify-between">

                    <button
                        type="button"
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Close
                    </button>


                    <button
                        type="button"
                        className="btn"
                        onClick={addCCEmail}
                    >
                        <PlusIcon className="h-5 w-5 mr-2"/>
                        Add
                    </button>

                </div>

            </div>
        );

        props.showCustomModal(content);

    }

    const countryText = () => {

        // if (!props.selectedCountry || !props.selectedCountry.code) return 'Please select a country';
        if (!postalCode) return CountryMetaData.find(c => c.code === claim.country)?.name || '-';

        let regionText = ''

        if (regionData?.regions?.[0]) {
            const region = regionData.regions[0];

            if (region.region_0) regionText += region.region_0;
            if (region.region_1) regionText += (regionText ? ' - ' : '') + region.region_1;
            if (region.region_2) regionText += (regionText ? ' - ' : '') + region.region_2;
        }

        return regionText

    }

    function claimSummary() {

        if (claim === null) {
            return (
                <section
                    aria-labelledby="summary-heading"
                    className="widget p-8"
                >
                    <div className="mt-10 mb-8"><LoadingSpinner body=''/></div>
                </section>
            );
        }

        return (
            <section
                className="widget p-8 w-full mx-10"
            >
                <div className="py-6 flex items-start gap-12">

                    {/* LEFT */}
                    <div className="space-y-4 divide-y divide-gray-200 w-full">

                        {/* CLAIM NAME */}
                        <div className="flex items-center justify-between">
                            <p className="text-sm text-gray-600">Claim Number</p>
                            <input
                                type="text"
                                className="input max-w-[40rem]"
                                placeholder="Enter claim number"
                                defaultValue={claim.insurer_claim_number}
                                onBlur={(e) => {
                                    const newNew = e.target.value;
                                    if (newNew !== claim.insurer_claim_number)
                                        updateClaimDetails(`insurer_claim_number: "${newNew}"`)
                                }}
                            />
                        </div>

                        {/* OWNED BY */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Owned by</span>
                            </p>
                            <Safe>
                                <select
                                    defaultValue={claim?.owned_by?.id}
                                    onChange={(e) => updateClaimDetails(`owned_by_id: "${e.target.value}"`)}
                                >
                                    <optgroup
                                        label={`${claim.organisation.info.name} users`}
                                    >
                                        {claimOrganisationUsers?.users.map(user =>
                                            <option key={user.id}
                                                    value={user.id}
                                                    selected={claim?.owned_by?.id === user.id}
                                            >
                                                {user.info.first_name} {user.info.last_name}
                                            </option>
                                        )}
                                    </optgroup>

                                    {/* If the claim.owned_by is not in the claimOrganisationUsers, then add it */}
                                    {claim?.owned_by && !claimOrganisationUsers?.users.find(user => user.id === claim.owned_by.id) &&
                                        <option
                                            value={claim.owned_by.id}>{claim.owned_by.info.first_name} {claim.owned_by.info.last_name}</option>
                                    }
                                </select>
                            </Safe>
                        </div>

                        {/* POSTAL CODE */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Postal code</span>
                            </p>
                            <div className='flex items-center gap-8'>
                                <p>
                                    {countryText()}
                                </p>

                                <input
                                    type="text"
                                    onChange={(event) => {
                                        // Trim the edges of the postal code
                                        const value = event.target.value.trim();
                                        setPostalCode(value);
                                    }}
                                    placeholder='N/A'
                                    value={postalCode}
                                    className="input w-[6rem]"
                                />
                            </div>
                        </div>

                        {/* LOCALITY */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Locality</span>
                            </p>
                            <div className='flex items-center gap-8'>
                                <Safe>
                                    {localityData && localityData.length > 0 ?
                                        <select
                                            className="select w-full"
                                            value={selectedLocality ? selectedLocality : ""}
                                            onChange={(event) => {
                                                setSelectedLocality(event.target.value);
                                                updateClaimDetails(`locality: "${event.target.value}"`);
                                            }}
                                        >
                                            <option value="" className='text-gray-400'>
                                                No locality selected
                                            </option>
                                            {localityData && localityData?.map((locality) => (
                                                <option key={locality} value={locality}>
                                                    {locality}
                                                </option>
                                            ))}
                                        </select>
                                        :
                                        <p className='text-sm text-gray-400'>-</p>
                                    }

                                </Safe>
                            </div>
                        </div>

                        <LimitedOrganisationAccess
                            types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR, OrganisationType.INSURER]}
                            mainOrganisation={props.mainOrganisation}
                        >
                            {/* CC EMAILS */}
                            <div className="pt-4 flex items-center justify-between">
                                <p className="flex items-center text-sm text-gray-600">
                                    <span>CC emails</span>
                                </p>


                                <div className='flex items-center gap-8'>
                                    <Tooltip content='Add a CC email address'>
                                        <button
                                            className="btn-icon"
                                            onClick={showAddCCEmailModal}
                                        >
                                            <PlusIcon className="h-5 w-5"/>
                                        </button>
                                    </Tooltip>

                                    <div className='flex flex-col gap-2 w-fit divide-y divide-gray-200'>
                                        {claim.cc_recipients?.map(recipient => (
                                            <div key={recipient.id} className='flex items-center gap-4 pt-2'>
                                                <UserIcon className='h-7 w-7 text-gray-400'/>
                                                <div className='text-gray-500'>
                                                    <p>{recipient.first_name} {recipient.last_name} </p>
                                                    <p>{recipient.email}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>


                            </div>
                        </LimitedOrganisationAccess>


                    </div>

                    {/* DIVIDER */}
                    <div className="h-[10rem] border-l border-gray-200"></div>

                    {/* RIGHT */}
                    <div className="space-y-4 divide-y divide-gray-200 w-full">

                        {/* LOCATION */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Location</span>
                            </p>
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    className="input max-w-[40rem]"*/}
                            {/*    placeholder="Locality"*/}
                            {/*    defaultValue={claim.location_text}*/}
                            {/*/>*/}
                            <p>
                                {claim.location_text}
                            </p>
                        </div>

                        {/* UNIQUE ITEM COUNT */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Unique Item Count</span>
                            </p>
                            <p className="text-sm font-medium text-gray-900">{claim.item_count_unique}</p>
                        </div>

                        {/* TOTAL ITEM COUNT*/}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Total Item Count</span>
                            </p>
                            <p className="text-sm font-medium text-gray-900">{claim.item_count}</p>
                        </div>

                        {/* CLAIM NOTES */}
                        <div className="pt-4 flex items-center justify-between">
                            <p className="flex items-center text-sm text-gray-600">
                                <span>Claim Notes </span>
                            </p>
                            <textarea
                                id="cartReviewText"
                                className="input w-3/4 bg-white rounded-lg border border-gray-200">
                            </textarea>
                        </div>

                    </div>

                </div>

                {/* QUANTIFY */}
                <ApiButton
                    onClick={quantifyClaim}
                    className='btn w-full block text-center text-md p-4'
                    content={'Quantify'}
                    successContent={'Quantified'}
                    errorContent='Failed to quantify'
                />

            </section>
        );
    }

    return (
        <>
            <div className="px-10">

                <div className="flex flex-col flex-1">

                    <main className="flex-1 pb-8">

                        {/* Page header */}
                        <div className="page-header-bar mb-0">

                            <h1 className="page-header-title">
                                Cart Review
                            </h1>

                            <Link
                                type="button"
                                to={`/search/${claimId}`}
                                className="btn m-4 tooltip-container"
                            >
                                <span className="ml-auto mr-auto">
                                  <ArrowCircleLeftIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                  Back to search
                                </span>
                                <span
                                    style={{marginTop: '75px', marginLeft: '-17px', width: '152px'}}
                                    className="tooltip"
                                >
                                  Go to search page
                                </span>
                            </Link>
                        </div>


                        <div className="flex flex-col items-center gap-[2rem]">

                            {/* SUMMARY */}
                            {safe(claimSummary)}

                            {/* POSSIBLE ITEMS */}
                            {safe(possibleItems)}

                            {/* ITEMS */}
                            {safe(cartItems)}

                        </div>

                    </main>
                </div>

            </div>
        </>
    );

}

function SingleItem(props) {

    // If the claim was quantified (with this item) and then re-opened, disable this item
    const dateCreated = new Date(props.item.date_created);
    const dateQuantified = new Date(props.claim.date_quantified);
    const itemPreviouslyQuantified = dateCreated < dateQuantified;

    // The input element for uploading attachments
    const itemAttachmentUploadInputRef = useRef(null);

    function onUploadDocument(file) {
        /* Upload the given attachment */

        let sizeInMB = file.size / 1024 / 1024;
        sizeInMB = sizeInMB.toFixed(2);

        if (file.size > 7 * 1024 * 1024) { // 7 MB limit
            props.showAlertModal(
                'info',
                'File Too Large',
                <>
                    <p>The chosen file is {sizeInMB}MB, which exceeds the 7MB limit</p>
                    <p>Please compress or upload another smaller file</p>
                </>
            );
            return;
        }

        uploadFile(
            {
                objectId: props.claim.id,
                objectType: 'claims',
                fileName: file.name,
                file: file,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Attachment uploaded', '');

                // Manually add the attachment to the claim
                props.setClaim({...props.claim, attachments: [...props.claim.attachments, data.file]});

                // Update claim.attachments
                props.updateGlobalClaim(props.claimId, props.setClaim);

                linkAttachmentToItem(data.file, props.item);

            },
            props.onError
        );
    }

    function linkAttachmentToItem(attachment, item) {
        /* Link an attachment to an item */

        customGraphRequest(
            `mutation UpdateItemMetadata{
                update_item_metadata(
                    item_id: "${item.id}"
                    attachment_ids: ["${attachment.id}"]
                ){
                    error{ message, type }
                    item{id}
                }
            }`,
            (data) => {
                props.showToastNotificationModal(
                    'success', 'Attachment linked', ''
                )
                props.updateGlobalClaim(props.claimId, props.setClaim);

            },
            props.onError
        )
    }

    const customPriceChange = (itemID, price) => {
        console.log('Custom price change', itemID, price);
        if (!price) return props.showAlertModal('info', 'Invalid price', 'The price must be a positive number');

        updateItemClaimantQuote(
            itemID,
            price,
            (data) => {
                props.showToastNotificationModal('success', 'Claimant quote update');
            },
            props.onError
        );
    };

    function confirmDeleteItem(itemId) {
        /* Remove an item from the claim */

        let quantity = 0;

        updateItemQuantity(
            itemId,
            quantity,
            (data) => {
                props.updateGlobalClaim(props.claimId, props.setClaim);
                props.showToastNotificationModal('success', 'Item removed', 'The item has been removed from the claim');
            },
            props.onError
        );
    }

    function promptDeleteItem(item) {
        /* Prompt the user to confirm the deletion of an item */

        props.showConfirmModal(
            'warning',
            'Remove item',
            (<div>
                <p>Are you sure you want to remove:</p>
                <p><b>{item.product.common_name}</b></p>
            </div>),
            'Remove item',
            () => {
                confirmDeleteItem(item.id);
            }
        )

    }

    function updateQuantity(item, quantity) {
        /* Update the item's quantity */

        updateItemQuantity(
            item.id,
            quantity,
            (data) => {
                props.showToastNotificationModal('success', 'Quantity updated', '');
                props.updateGlobalClaim(props.claimId, props.setClaim);
            },
            props.onError
        );

    };

    function FormLabel({children, className}) {
        return (
            <p className={`mb-1 ${className}`}>
                {children}
            </p>
        );
    }

    function AttachmentListItem({attachment, item}) {

        const [downloadStatus, setDownloadStatus] = useState('IDLE');

        function getDownloadURL(onSuccess) {
            // Create download url

            if (downloadStatus === 'LOADING') return;
            setDownloadStatus('LOADING');

            // If the URL is already present, don't make another request
            if (attachment.previewUrl) return;

            let mutation = `
                query GenerateFileDownloadURL{
                   generate_file_download_url(
                    file_id: "${attachment.id}"
                    object_id: "${attachment.object_id}"
                    object_type: ${attachment.object_type}
                  ){
                    error{type, message}
                    url
                  }
                }
            `

            customGraphRequest(
                mutation,
                (data) => {
                    props.showToastNotificationModal('success', 'URL Generated', 'The download will start shortly');

                    // Set the URL in the attachment object
                    attachment.previewUrl = data.url;
                    props.setClaim({...props.claim})

                    onSuccess?.(data.url);
                },
                props.onError
            )
        }

        function renderPreview() {
            /** Show either a "load preview" or a preview of the file
             * Because the attachment.url is not directly publicly accessible,
             * a public URL must be generated first, which can then display the
             * document */

            const isImage = (url) => {
                // Function to check if the URL is an image
                return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
            };

            if (attachment.previewUrl) {
                return (
                    <ImageZoom
                        imageSrc={attachment.previewUrl || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        altText="Preview"
                        className="w-full w-[4rem] max-w-[7rem] h-[2rem] object-contain pr-2 !z-[100]"
                        zoomClassName={classNames(
                            'border-[1px] border-stone-400 rounded-md',
                            // Don't show the preview for the placeholder product image
                            attachment.previewUrl ? 'scale-[700%]' : 'scale-[100%]'
                        )}
                    />

                );
            }

            if (isImage(attachment.url)) {

                if (!attachment.previewUrl) {
                    return (
                        <Tooltip content='File preview' className='max-w-max'>
                            <div onMouseOver={() => getDownloadURL()}>
                                <PhotographIcon
                                    className='h-8 w-8 hover:h-12 hover:w-12 transition-all m-4 text-gray-600'/>
                            </div>
                        </Tooltip>
                    );
                }

                return (
                    <div onMouseOver={() => getDownloadURL()}>
                        <PhotographIcon className='h-8 w-8 hover:h-12 hover:w-12 transition-all m-4 text-gray-600'/>
                    </div>
                );
            }

            return (<></>);

        }

        return (
            <div className={classNames(
                'w-full flex gap-4 items-center h-[2rem]',
            )}>
                {/* PREVIEW */}
                {renderPreview()}

                {/* NAME */}
                <p className='text-gray-600 max-h-[5rem] overflow-y-hidden break-words'>
                    {attachment.file_name}
                </p>

                {/* REMOVE ATTACHMENT */}
                <Tooltip message="Remove this item from this attachment" classNames='max-w-[7rem]'>
                    <XIconSolid
                        className='h-5 w-5 text-gray-500 cursor-pointer'
                        onClick={() => {
                            props.showConfirmModal(
                                'warning',
                                'Remove attachment',
                                'Are you sure you want to remove this attachment?',
                                'Remove attachment',
                                () => {
                                    customGraphRequest(
                                        `mutation UpdateItemMetadata{
                                            update_item_metadata(
                                                item_id: "${item.id}"
                                                attachment_ids_to_remove: ["${attachment.id}"]
                                            ){
                                                error{ message, type }
                                                item{id}
                                            }
                                        }`,
                                        (data) => {
                                            props.showToastNotificationModal(
                                                'success', 'Purchase price updated', ''
                                            )
                                            props.updateGlobalClaim(props.claimId, props.setClaim);
                                        },
                                        props.onError
                                    )
                                }
                            )
                        }}
                    />
                </Tooltip>
            </div>
        );
    }

    function updateMetadata(itemId, metadata) {

        function isNullUndefined(value) {
            return value === null || value === undefined;
        }

        let mutation = `
        mutation UpdateItemMetadata{
            update_item_metadata(
                item_id: "${itemId}"
                ${isNullUndefined(metadata.clients_quote) ? '' : `replacement_quote: ${metadata.clients_quote}`}
                ${isNullUndefined(metadata.incident_location) ? '' : `incident_location: "${metadata.incident_location}"`}
                ${isNullUndefined(metadata.comment) ? '' : `comment: "${metadata.comment}"`}
                ${isNullUndefined(metadata.claimed_amount) ? '' : `claimed_amount: ${metadata.claimed_amount}`}
                ${isNullUndefined(metadata.date_purchased) ? '' : `date_purchased: "${metadata.date_purchased}"`}
                ${isNullUndefined(metadata.purchase_price) ? '' : `purchase_price: ${metadata.purchase_price}`}
            ){
                error{ message, type }
                item{id}
            }
        }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal(
                    'success', 'Updated', ''
                )
                props.updateGlobalClaim(props.claimId, props.setClaim);
            },
            props.onError
        )

    }

    return (
        <li key={props.item.id} className={classNames(
            'widget flex py-6 sm:py-10',
            itemPreviouslyQuantified ? 'bg-gray-100 px-8' : ''
        )}>

            {/* Hidden file input, for uploading attachments */}
            <input
                type="file"
                style={{display: 'none'}}
                ref={itemAttachmentUploadInputRef}
                onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                        onUploadDocument(file);
                    }
                }}
            />

            <div className="flex-shrink-0">
                <BasicImage
                    src={props.item.product.images && props.item.product.images.thumbnail_web}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeWidthRem="20"
                    sizeHeightRem={20 * 0.75} // 4:3 aspect ratio
                    className="rounded-md"
                />
            </div>

            <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                <div className="relative pr-9">

                    {/* ITEM */}
                    <div>
                        <div className="flex justify-start gap-10 items-center">
                            <h3 className="font-medium text-lg">
                                {props.item.is_manual ? props.item.product.brand || props.item.product.model_numbers[0] : props.item.product.common_name}
                            </h3>

                            {/* DELETE ITEM */}
                            <Tooltip
                                content="Remove this item from the claim"
                                classNames='max-w-[10rem]'
                            >
                                <XIcon
                                    className="h-5 w-5 transition hover:shadow cursor-pointer"
                                    onClick={() => promptDeleteItem(props.item)}
                                />
                            </Tooltip>

                            <div className='grow'></div>

                            {/* QUANTITY */}
                            <Tooltip content='Item quantity'>
                                <select
                                    defaultValue={props.item.quantity}
                                    onChange={(e) => updateQuantity(props.item, e.target.value)}
                                    className="select m-0 shadow-none mb-1 py-1 w-fit"
                                >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    {props.item.quantity > 10 && <option>{props.item.quantity}</option>}
                                </select>
                            </Tooltip>

                        </div>
                        <div className="mt-1 flex text-sm">
                            <p className="text-md text-gray-800">
                                {props.item.is_manual ? `${props.item.product.brand} | ${props.item.product.description}` : calculateProperties(props.item.product)}
                            </p>
                        </div>
                        <div className="mt-1 text-sm font-medium text-gray-900">
                            {props.item.product.model_number !== null ? (
                                <p className="text-sm text-gray-500">{props.item.product.model_number}</p>
                            ) : (
                                <p className="text-sm text-gray-500">{props.item.product.model_numbers.slice(0, 3).join(' | ')}</p>
                            )}
                            {props.item.is_manual ? `${formatAsTitle(props.item.category.category_a)} >  ${formatAsTitle(props.item.product.category)}` : ''}

                        </div>
                        {props.item.is_manual ? (
                            <div
                                className="mt-2 w-fit text-sm items-center px-2 py-1 rounded-xl text-sm font-medium bg-blue-500/60 text-white">
                                Manual
                            </div>
                        ) : <div></div>}
                    </div>


                    <div className="flex flex-col gap-4 py-5 text-sm font-medium text-gray-700">

                        <div className='absolute -top-[2rem] right-0 w-[3.5rem]'>

                        </div>

                        <div className='flex gap-8'>

                            {/* CLIENTS QUOTE */}
                            <div className='w-full'>
                                <FormLabel>Client's Quote</FormLabel>
                                <CurrencyInput
                                    currencySymbol={currencySymbol(props.claim)}
                                    defaultValue={props.item.claimant_quote}
                                    onBlur={(e) => {
                                        // Replace the value with 0 if it's an empty input
                                        let value = e.target.value ? e.target.value : 0;
                                        updateMetadata(props.item.id, {clients_quote: value});

                                        // TODO the above "replacement_quote" is not updating the item's claimant_quote
                                        // So additionally use the old method for now
                                        customPriceChange(props.item.id, value);
                                    }}
                                    className={classNames(
                                        'input pl-8 w-full',
                                    )}
                                />
                            </div>

                            {/* CLAIMED AMOUNT */}
                            <div className='w-full'>
                                <FormLabel>Claimed amount</FormLabel>
                                <CurrencyInput
                                    currencySymbol={currencySymbol(props.claim)}
                                    defaultValue={props.item.metadata?.claimed_amount}
                                    onBlur={(e) => {
                                        // Replace the value with 0 if it's an empty input
                                        let value = e.target.value ? e.target.value : 0;
                                        updateMetadata(props.item.id, {claimed_amount: value});
                                    }}
                                    className={classNames(
                                        'input pl-8 w-full',
                                    )}
                                />
                            </div>

                        </div>

                        <div className='flex gap-8'>

                            {/* PURCHASE DATE */}
                            <div className='w-full'>
                                <FormLabel>Purchased date</FormLabel>
                                <input
                                    type="date"
                                    id={props.item.id}
                                    className="input"
                                    defaultValue={
                                        props.item.metadata?.date_purchased
                                            ? new Date(props.item.metadata.date_purchased).toISOString().split('T')[0] // Format the date to YYYY-MM-DD
                                            : ''
                                    }
                                    onBlur={(e) => {
                                        if (!e.target.value) return;
                                        updateMetadata(props.item.id, {date_purchased: e.target.value});
                                    }}
                                />
                            </div>

                            {/* PURCHASE PRICE */}
                            <div className='w-full'>
                                <FormLabel>Purchased price</FormLabel>
                                <CurrencyInput
                                    currencySymbol={currencySymbol(props.claim)}
                                    defaultValue={props.item.metadata?.purchase_price}
                                    onBlur={(e) => {
                                        // Replace the value with 0 if it's an empty input
                                        let value = e.target.value ? e.target.value : 0;
                                        updateMetadata(props.item.id, {purchase_price: value});
                                    }}
                                    className={classNames(
                                        'input pl-8 w-full',
                                    )}
                                />
                            </div>

                        </div>

                        {/* LOCATION */}

                        <div className='relative'>

                            <div className={classNames(
                                props.showLocationInputs ? 'block' : 'hidden',
                            )}>
                                <FormLabel>Space</FormLabel>
                                <div className=''>
                                    <FilterSelect
                                        selectableOptions={
                                            JSON.parse(localStorage.getItem('itemIncidentLocations')) || ['Bedroom 1', 'Bedroom 2', 'Bedroom 3', 'Kitchen', 'Living Room']
                                        }
                                        noResults={'-'}

                                        onChange={(selectedString) => {

                                            if (!selectedString) return;

                                            // Parse the stored locations or use an empty array if not present
                                            let locations = JSON.parse(localStorage.getItem('itemIncidentLocations')) || [];
                                            if (!locations.includes(selectedString)) {
                                                locations.push(selectedString);
                                                // Store the updated locations array as a string
                                                localStorage.setItem('itemIncidentLocations', JSON.stringify(locations));
                                            }

                                            debounceQuery(
                                                'updateItemMetadata',
                                                500,
                                                () => updateMetadata(props.item.id, {incident_location: selectedString})
                                            );
                                        }}

                                        onBlur={(selectedString) => {

                                            if (!selectedString) return;

                                            // Parse the stored locations or use an empty array if not present
                                            let locations = JSON.parse(localStorage.getItem('itemIncidentLocations')) || [];
                                            if (!locations.includes(selectedString)) {
                                                locations.push(selectedString);
                                                // Store the updated locations array as a string
                                                localStorage.setItem('itemIncidentLocations', JSON.stringify(locations));
                                            }

                                            debounceQuery(
                                                'updateItemMetadata',
                                                500,
                                                () => updateMetadata(props.item.id, {incident_location: selectedString})
                                            );
                                        }}

                                        // Filter
                                        filterOptions={(selectableOptions, query) =>
                                            query === '' ?
                                                selectableOptions
                                                :
                                                [query, ...selectableOptions?.filter((option) =>
                                                    option
                                                        .toLowerCase()
                                                        .replace(/\s+/g, '') // remove all whitespace
                                                        .includes(query.toLowerCase().replace(/\s+/g, '')))
                                                ]
                                        }
                                        renderMainInput={(selected, query, setQuery) => (
                                            <div className="w-full relative">
                                                <Combobox.Button as="div" className="flex w-full items-center">
                                                    <Combobox.Input
                                                        className="input px-3 w-full"
                                                        displayValue={(selected) => selected ? selected : ''}
                                                        onChange={(event) => setQuery(event.target.value)}
                                                    />

                                                    <div className="absolute right-0 flex items-center pr-2">
                                                        <ArrowDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </Combobox.Button>
                                            </div>

                                        )}
                                        renderOption={(option) =>
                                            <Combobox.Option
                                                key={option}
                                                className='relative'
                                                value={option}
                                            >
                                                {({selected, active}) => (
                                                    <div className={classNames(
                                                        'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                                        selected ? 'font-bold' : 'font-normal', // Is the option selected
                                                        active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                                    )}>

                                                        {/* SELECTED CHECK */}
                                                        {selected &&
                                                            <div className={classNames(
                                                                'absolute inset-y-0 left-0 flex items-center pl-1',
                                                                active ? 'text-white' : 'text-sky-600'
                                                            )}>
                                                                <CheckIcon className={`h-6 w-6`}/>
                                                            </div>
                                                        }
                                                        {option}
                                                    </div>
                                                )}
                                            </Combobox.Option>
                                        }
                                    />
                                </div>
                            </div>

                            <div className='absolute -right-[2.5rem] top-[1.1rem]'>

                                <Tooltip message="Reveal the Spaces input">
                                    <button className={classNames(
                                        'btn-clear',
                                        props.showLocationInputs ? 'bg-gray-100' : ''
                                    )}
                                            onClick={() => props.setShowLocationInputs(!props.showLocationInputs)}
                                    >
                                        <AnnotationIcon className='h-5 w-5 text-gray-500'/>
                                    </button>
                                </Tooltip>
                            </div>

                        </div>

                        {/* COMMENT */}
                        <div>
                            <FormLabel>Comment</FormLabel>
                            <div className=''>
                                <textarea
                                    className="input w-full"
                                    defaultValue={props.item.metadata?.comment}
                                    onBlur={(e) => {
                                        // Replace the value with '-' if it's an empty input
                                        let value = e.target.value ? e.target.value : '-';
                                        debounceQuery(
                                            'updateItemMetadata',
                                            500,
                                            () => updateMetadata(props.item.id, {comment: value})
                                        );
                                    }}
                                />

                            </div>
                        </div>

                        <div className='flex gap-8'>

                            {/* ATTACHMENTS */}
                            <div className='w-full'>

                                <Tooltip message="Click to add an attachments to this claim and item">
                                    <p
                                        className='mb-1 tooltip_underline w-fit cursor-pointer'
                                        onClick={() => {
                                            itemAttachmentUploadInputRef.current.click()
                                        }}
                                    >
                                        Attachments +
                                    </p>
                                </Tooltip>

                                {props.item.metadata?.attachments?.map((attachment, index) => (
                                    <AttachmentListItem key={index} item={props.item} attachment={attachment}/>
                                ))}

                                {isEmpty(props.item.metadata?.attachments) &&
                                    <p className='italic text-gray-300 font-light'>No attachments</p>}
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </li>
    );

}

function SinglePossibleItem(props) {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [viewMode, setViewMode] = useState('PROTO'); // PROTO, MANUAL

    useEffect(() => {
        // When the selected product has been changed on any of the SinglePossibleItem components
        // Clear the parent component's selected products state, to trigger all SinglePossibleItem components to re-add
        props.setSelectedProtoProductsForPossibleItems([])
    }, [selectedProduct, quantity]);

    useEffect(() => {
        // When the parent component's selected products state has been cleared
        // Add the selected product to the parent component's selected products state again

        if (!selectedProduct) return;

        if (props.selectedProtoProductsForPossibleItems?.length === 0) {
            props.setSelectedProtoProductsForPossibleItems(prev =>
                [...prev, {...selectedProduct, quantity: quantity}]
            )
        }
    }, [props.selectedProtoProductsForPossibleItems]);

    /* lensItem:
        brand
        category{
            category_a
            ...
        }
        claimant_quote
        description
        model_number
        proto_products{
          id
          ...
        }
        quantity
    */
    let lensItem = props.possibleProduct;

    /** DEVELOPMENT Data */
        // lensItem.proto_products = placeholderLensChromeExtensionClaimPossibleProducts.claims[0].possible_items[0].proto_products

    const [manualItem_category, setManualItem_category] = useState(lensItem.category); // category Object. e.g: {id: '...', category_a:'...', category_b:'...', ...}
    const [manualItem_brand, setManualItem_brand] = useState(lensItem.brand);
    const [manualItem_modelNumber, setManualItem_modelNumber] = useState(lensItem.model_number);
    const [manualItem_description, setManualItem_description] = useState(lensItem.description);

    useEffect(() => {
        if (viewMode === 'MANUAL')
            setSelectedProduct({
                manual: true,
                brand: manualItem_brand,
                category: manualItem_category,
                model_number: manualItem_modelNumber,
                description: manualItem_description,
                quantity: quantity
            })
    }, [manualItem_brand, manualItem_modelNumber, manualItem_category, manualItem_description, quantity, viewMode]);

    function getSelectableMainCategories() {
        /** The main categories are found in the ProductCategory enum type in GraphQL
         enum ProductCategory {
         ACTION_SPORTS_CAMERAS
         AUDIO_AMPLIFIERS
         BLENDERS
         CAMCORDERS
         CAMERA_DRONES
         COFFEE_MAKERS
         COMPUTER_MONITORS
         DECODERS
         DIGITAL_CAMERAS
         DISHWASHERS
         ELECTRIC_KETTLES
         FREEZERS
         FRIDGES
         FRIDGE_FREEZERS
         GAME_CONSOLES
         HANDHELD_VACUUMS
         HEADPHONES
         HOBS
         INKJET_PRINTERS
         IRONS
         LASER_PRINTERS
         MICROWAVES
         NOTEBOOKS
         OVENS
         ROBOT_VACUUMS
         SIDE_BY_SIDE_FRIDGE_FREEZERS
         SMARTPHONES
         SMARTWATCHES
         SOUNDBARS
         TABLETS
         TOASTERS
         TUMBLE_DRYERS
         TVS
         UNKNOWN
         VACUUMS
         WASHER_DRYERS
         WASHING_MACHINES
         }*/

            // create a list of each category_a
        let catA = props.selectableCategories.map(category => category.category_a);

        // because "category_a" is repeated in many of the categories, but we only want 1 choice to appear in the dropdown
        // remove all the repeated category_a. only keep 1 instance of each category_a
        let uniqueCategoryA = [...new Set(catA)]; // list of strings, each element is a category_a. unique due to being a Set datatype
        // sort list of category_a by alphabetical order
        uniqueCategoryA.sort((a, b) => a.localeCompare(b));
        let uniqueCategories = [];
        for (const category_a of uniqueCategoryA) {
            let fullCategoryData = props.selectableCategories.find(cat => cat.category_a === category_a);
            if (fullCategoryData)
                uniqueCategories.push(fullCategoryData);
        }
        return uniqueCategories;
    }

    function getSelectableSubCategories() {
        if (!props.selectableCategories) return [];
        if (isEmpty(manualItem_category)) return [];

        let selectable = props.selectableCategories.filter(cat => cat.category_a === manualItem_category.category_a);

        return selectable;
    }

    const manualItem = {
        id: 'manual',
        common_name: 'Create Manual Item',
        brand: 'with captured information',
        category: 'with captured information',
        model_number: 'with captured information',
        model_numbers: [],
    }

    function selectedProtoAndDropdown() {

        return (
            <div className='h-full flex flex-col justify-around items-center'>

                <div className='flex w-full items-center gap-2'>
                    <SimpleSelect
                        width='w-full bg-white'
                        selectOptionClassName={classNames(
                            '!max-h-[40rem] overflow-y-scroll h-fit w-fit left-0 top-[-4rem] shadow-xl',

                            // If more than 2 products
                            lensItem.proto_products && lensItem.proto_products.length > 5 ? 'max-h-[40rem] -top-[40rem]' : ''
                        )}
                        selectedState={null}
                        selectableOptions={[manualItem, ...lensItem.proto_products] || []}
                        onChange={(product) => {
                            if (product.id === 'manual') {
                                setViewMode('MANUAL');
                            } else {
                                setSelectedProduct(product);
                            }
                        }}

                        renderOption={(product) =>
                            <div className='flex items-center w-full gap-[2rem] min-h-[5rem] max-h-[8rem] py-2'>

                                <div className='w-[5rem] shrink-0 max-h-[5rem]'>
                                    <BasicImage
                                        src={product?.images?.thumbnail_web}
                                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                        alt="Product"
                                        sizeWidthRem="5"
                                        sizeHeightRem={5 * 0.75} // 4:3 aspect ratio
                                        className="rounded-md"
                                    />
                                </div>

                                {/* DIVIDER */}
                                <div className='h-[5rem] w-[2px] bg-gray-400'></div>

                                {/* PROTO DETAILS */}
                                <div className='flex flex-col justify-center items-start gap-1'>

                                    {/* COMMON NAME */}
                                    <h3 className="font-medium text-lg">
                                        {product.common_name}
                                    </h3>

                                    {/* PROPERTIES */}
                                    <p className="text-sm text-gray-800">
                                        {calculateProperties(product)}
                                    </p>

                                    {/* MODEL NUMBER */}
                                    <div className="mt-1 text-sm text-gray-900">
                                        {/* model_number OR model_numbers */}
                                        {!product.model_number ?
                                            <p className="text-sm text-gray-500">{product.model_number}</p>
                                            :
                                            <p className="text-sm text-gray-500">{product.model_numbers.slice(0, 3).join(' | ')}</p>
                                        }
                                    </div>

                                    {/* CATEGORY */}
                                    <p className='font-md text-md'>{formatAsTitle(product.category)}</p>

                                </div>

                            </div>
                        }

                        renderSelected={(product) =>
                            <p>{lensItem.proto_products.length} Possible proto products</p>
                        }
                    />

                    <Tooltip
                        content='Clear the selected product'
                    >
                        <div
                            className="group cursor-pointer"
                            onClick={() => {
                                setSelectedProduct(null)
                                setViewMode('PROTO')
                            }}
                        >
                            <XIcon
                                className="btn-icon h-8 w-8 transition-transform duration-200 rotate-90 group-hover:rotate-0"/>
                        </div>
                    </Tooltip>
                </div>


                {/* MANUAL ITEM */}
                {viewMode === 'MANUAL' &&
                    <div className='flex flex-col gap-4 w-full'>

                        <div className='flex gap-4 w-full'>

                            <Tooltip content='Brand' containerClassName='w-full'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Brand"
                                    value={manualItem_brand}
                                    onChange={(e) => setManualItem_brand(e.target.value)}
                                />
                            </Tooltip>
                            <Tooltip content='Description' containerClassName='w-full'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Description"
                                    value={manualItem_description}
                                    onChange={(e) => setManualItem_description(e.target.value)}
                                />
                            </Tooltip>
                            <Tooltip content='Model Number' containerClassName='w-full'>
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Model Number"
                                    value={manualItem_modelNumber}
                                    onChange={(e) => setManualItem_modelNumber(e.target.value)}
                                />
                            </Tooltip>
                        </div>

                        <div className='flex gap-4'>
                            <select className="p-2 input mx-0"
                                    value={lensItem.category ? lensItem.category.category_a : ''}
                                    onChange={(e) => {
                                        let mainCat = props.selectableCategories.find(cat => cat.category_a === e.target.value);
                                        setManualItem_category(mainCat);
                                    }}>

                                {getSelectableMainCategories().map(cat =>
                                    <option
                                        value={cat.category_a}>{formatAsTitle(cat.category_a)}
                                    </option>)
                                }
                            </select>

                            <select className="p-2 input mx-0"
                                    value={lensItem.category ? lensItem.category.id : ''}
                                    onChange={(e) => setManualItem_category(props.selectableCategories.find(cat => cat.id === e.target.value))}>

                                {/* if category data has been fetched, and a main category has been selected, then filter all the categories to get the categories where "category_a" equals the main selected categories "category_a" */}
                                {getSelectableSubCategories().map(category => {
                                    if (category.category_c) // to prevent "duplicate" options try C first, then D, then B
                                        return <option value={category.id}>{category.category_c}</option>;
                                    if (category.category_d && category.category_d != 'UNKNOWN')
                                        return <option
                                            value={category.id}>{formatAsTitle(category.category_d)}</option>;
                                    if (category.category_b)
                                        return <option value={category.id}>{category.category_b}</option>;
                                    if (category.category_a)
                                        return <option value={category.id}>{category.category_a}</option>;
                                })}

                            </select>
                        </div>
                    </div>
                }

                {/* SELECTED PROTO */}
                {selectedProduct && viewMode === "PROTO" && (
                    <div className='flex gap-4 '>
                        <BasicImage
                            src={selectedProduct?.images?.thumbnail_web}
                            fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                            alt="Product Image"
                            sizeWidthRem="10"
                            sizeHeightRem={10 * 0.75} // 4:3 aspect ratio
                            className="rounded-md"
                        />

                        {/* PROTO DETAILS */}
                        <div>

                            {/* COMMON NAME */}
                            <h3 className="font-medium text-lg">
                                {selectedProduct.common_name}
                            </h3>

                            {/* PROPERTIES */}
                            <p className="text-sm text-gray-800">
                                {calculateProperties(selectedProduct)}
                            </p>

                            {/* MODEL NUMBER */}
                            <div className="mt-1 text-sm text-gray-900">
                                {/* model_number OR model_numbers */}
                                {!selectedProduct.model_number ?
                                    <p className="text-sm text-gray-500">{selectedProduct.model_number}</p>
                                    :
                                    <p className="text-sm text-gray-500">{selectedProduct.model_numbers.slice(0, 3).join(' | ')}</p>
                                }
                                {formatAsTitle(selectedProduct.category)}
                            </div>

                        </div>

                    </div>
                )}


            </div>

        )

    }

    if (isEmpty(props.possibleProduct)) return <></>;

    return (
        <li key={props.i} className={classNames(
            'flex',
        )}>

            <div className="flex justify-between items-center gap-8 px-8 w-full min-h-[14rem]">

                {/* ITEM */}
                <div className="w-[30%]">

                    <p className="font-medium text-lg">
                        {lensItem.description}
                    </p>

                    <Tooltip
                        content={<div>
                            <p>Brand</p>
                            <p>Description</p>
                            <p>Model</p>
                            <p>Category</p>
                        </div>}
                    >
                        <div className="mt-1 text-sm">
                            <p className="text-md text-gray-800"><b>B:</b>&emsp;{lensItem.brand}</p>
                            <p className="text-md text-gray-800"><b>D:</b>&emsp;{lensItem.description}</p>
                            <p className="text-md text-gray-800"><b>M:</b>&emsp;{lensItem.model_number}</p>
                            <p className="text-md text-gray-800"><b>C:</b>&emsp;
                                {formatAsTitle(lensItem.category.category_d) + '>' + formatAsTitle(lensItem.category.category_a) + '>' + formatAsTitle(lensItem.category.category_b)}
                            </p>
                        </div>
                    </Tooltip>


                    <div className="mt-1 text-sm font-medium text-gray-900">
                    </div>

                </div>

                {/* > */}
                <ArrowSmRightIcon className='w-[4rem] text-gray-400'/>

                {/* REPLACEMENT PROTO */}
                <div className="w-[70%] h-full">
                    {selectedProtoAndDropdown()}
                </div>


                {/* QUANTITY */}
                <Tooltip content='Item quantity'>
                    <select
                        defaultValue={lensItem.quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="select m-0 shadow-none mb-1 py-1 w-fit"
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        {lensItem.quantity > 10 && <option>{lensItem.quantity}</option>}
                    </select>
                </Tooltip>

            </div>

        </li>
    );

}
