// Library imports
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserIcon, ClipboardCheckIcon, PlusCircleIcon, RefreshIcon, PencilAltIcon, XIcon } from '@heroicons/react/solid'

// Local imports
import { classNames } from  '../../utils/helpers.js';


// Constants
const timeline = [
  {
    id: 1,
    statusBefore: 'PENDING',
    statusBeforeStyle: 'bg-purple-600 text-white',
    statusAfter: 'CLOSED',
    statusAfterStyle: 'bg-green-500 text-white',
    closedBy: 'Katherine Snyder',
    href: '#',
    date: '5m',
    datetime: '2020-09-30',
    icon: ClipboardCheckIcon,
    iconBackground: 'bg-cyan-500',
  },
  {
    id: 2,
    beforeQuantity: 1,
    afterQuantity: 3,
    item: 'Philips 20HF4003 20.0"',
    href: '#',
    date: '30m',
    datetime: '2020-09-30',
    icon: RefreshIcon,
    iconBackground: 'bg-cyan-500',
  },
  {
    id: 3,
    statusBefore: 'NEW',
    statusBeforeStyle: 'bg-indigo-600 text-white',
    statusAfter: 'PENDING',
    statusAfterStyle: 'bg-purple-600 text-white',
    href: '#',
    date: '2h',
    datetime: '2020-09-30',
    icon: ClipboardCheckIcon,
    iconBackground: 'bg-cyan-500',
  },
  {
    id: 4,
    content: 'Added item ',
    target: 'Philips 20HF4003 20.0"',
    href: '#',
    date: '2h',
    datetime: '2020-09-28',
    icon: PlusCircleIcon,
    iconBackground: 'bg-cyan-500',
  },
  {
    id: 5,
    content: 'Assigned to',
    target: 'Katherine Snyder',
    href: '#',
    date: '2 days ago',
    datetime: '2020-10-04',
    icon: UserIcon,
    iconBackground: 'bg-cyan-500',
  },
]

// ClaimInfoModal container
const ClaimInfoModal = (props) => {

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 "
                  onClick={() => props.setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-500 sm:mx-0 sm:h-10 sm:w-10">
                  <img className="h-10 w-10 rounded-full" src="/logo512.png" alt="" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    TEST-CLAIM-01
                    <br/><span className="small text-gray-500 font-normal text-sm">Delta</span>
                  </Dialog.Title>
                </div>
              </div>

              <div className="w-full mt-4 border-t border-gray-300">
              </div>

              <div className="mt-5">
                <div className="flow-root">
                  <ul className="-mb-8">
                    {timeline.map((event, eventIdx) => (
                      <li key={event.id}>
                        <div className="relative pb-8">
                          {eventIdx !== timeline.length - 1 ? (
                            <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  event.iconBackground,
                                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                )}
                              >
                                <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div className="mr-20">
                                <p className="text-sm text-gray-600">
                                  {'statusBefore' in event &&
                                    <div>
                                      <span className="mr-1">Transitioned from</span>
                                      <span
                                        className={classNames(
                                          event.statusBeforeStyle,
                                          'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                        )}
                                      >
                                        {event.statusBefore}
                                      </span>
                                      <span className="mr-1 ml-1">to</span>
                                      <span
                                        className={classNames(
                                          event.statusAfterStyle,
                                          'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                        )}
                                      >
                                        {event.statusAfter}
                                      </span>
                                    </div>
                                  }
                                  {'content' in event &&
                                    <span>
                                      {event.content}{' '}
                                      <a href={event.href} className="font-medium text-gray-900">
                                        {event.target}
                                      </a>
                                    </span>
                                  }
                                  {'closedBy' in event &&
                                    <span><br/>
                                      <span>Closed by </span>
                                      <a href={event.href} className="font-medium text-gray-900">
                                        {event.closedBy}
                                      </a>
                                    </span>
                                  }
                                  {'beforeQuantity' in event &&
                                    <span>
                                      <span>Updated item </span>
                                      <span className="font-bold text-black">{event.item}</span>
                                      <span> quantity from </span>
                                      <span className="font-bold text-black">{event.beforeQuantity}</span>
                                      <span> to </span>
                                      <span className="font-bold text-black">{event.afterQuantity}</span>
                                    </span>
                                  }
                                </p>
                              </div>
                              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                <time dateTime={event.datetime}>{event.date}</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 hover:bg-cyan-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => props.setOpen(false)}
                >
                  <PencilAltIcon className="flex-shrink-0 h-5 w-5 mr-1" aria-hidden="true" />
                  Edit
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => props.setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ClaimInfoModal;
