import React, {useEffect, useState} from 'react';
import {
    generateClaimsReport,
    generateSupplierClaimsReport,
} from '../../../utils/coreApi';
import {classNames} from '../../../utils/helpers';
import {
    DocumentReportIcon,
    DownloadIcon
} from '@heroicons/react/outline';
import Spreadsheet from 'react-spreadsheet';
import {useDocumentTitle} from "../../../components/PageTitle";
import {RadioGroup} from "@headlessui/react";
import {
    AdminInsurerSelection,
    AdminOrgSelection,
    orderOrganisationsAlphabetically,
    SupplierSelection,
    SupplierUser
} from "./ReportsOrganisationSelection";
import CountrySelection from "../../../components/CountrySelection";
import {useQuery} from "../../../components/useQuery";
import ApiButton from "../../../components/base/ApiButton";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";


export default function AdminReports(props) {

    useDocumentTitle("Reports");


    /** All ADMIN and INSURER organisations that are connected to the user's organisation (mainOrganisation) */
    const organisationsHook = useQuery({
        onLoadQueryDelaySeconds: 2,
        queryStringFunction: () => {
            /** The organisation and account data (for logged in user's organisation) needs to be retrieved */
            /** Get all the ['ADMIN', 'INSURER', 'SUPPLIER'] organisations that are connected to this organisation */

            let queryArgs = "";

            let orgTypes = ['ADMINISTRATOR', 'INSURER', 'SUPPLIER']
            queryArgs += ` types:[${orgTypes.join(", ")}]`;

            let status = 'ACTIVE'
            queryArgs += ` status: ${status}`;

            let minimalOrganisationsQuery = `
            query Reports_UserOrgConnectedOrganisations{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  info {
                    name
                    country
                    logo_image_url
                  }
                }
              }
            }
            `
            return minimalOrganisationsQuery.replace('|placeholder|', queryArgs);
        },
        onSuccess: (data) => {

            // Sort the organisations alphabetically
            let sortedOrganisations = orderOrganisationsAlphabetically(data['organisations']);

            setOrganisations(sortedOrganisations);
        },
        onError: props.onError,
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: organisations, setState: setOrganisations} = organisationsHook;


    /** All 'SELF', 'ADMINISTRATOR_INSURER', 'INSURER_INSURER' accounts that are connected to the user's organisation (mainOrganisation) */
    const accountsHook = useQuery({
        onLoadQueryDelaySeconds: 2,
        dependsOn: [props.mainOrganisation], /** When the organisation data has been retrieved, retrieve the account data to determine primary and sub organisations */
        queryStringFunction: () => {
            /** Get all the accounts that are connected to the currently logged in organisation */

            if (!props.mainOrganisation) return null

            let queryArgs = '';

            queryArgs += ` status: ACTIVE`;

            queryArgs += ` organisation: "${props.mainOrganisation.id}"`;

            let minimalAccountsQuery = `
                query Reports_UserOrgConnectedAccounts{ 
                    accounts(|placeholder|) {
                        error{
                            type
                            message
                        }
                
                        accounts
                        {
                            id
                            status
                            type
                            organisation_1{
                                id
                                type
                                unique_reference_name
                                info {
                                    name
                                    logo_image_url
                                }
                            }
                            organisation_2{
                                id
                                type
                                unique_reference_name
                                info{
                                    name
                                    logo_image_url
                                }
                            }
                
                            sub_accounts{
                                id
                                type
                                status
                                organisation_1{
                                    id
                                    type
                                    unique_reference_name
                                    info {
                                        name
                                        logo_image_url
                                    }
                                }
                                organisation_2{
                                    id
                                    type
                                    unique_reference_name
                                    info{
                                        name
                                        logo_image_url
                                    }
                                }
                
                            }
                
                        }
                
                    }
                }
            `

            return minimalAccountsQuery.replace('|placeholder|', queryArgs);

        },
        onSuccess: (data) => {
            setAccounts(data['accounts']);
        },
        onError: props.onError,
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true,
        useExistingCache: true,
        skipQueryIfCache: true,
    });
    const {state: accounts, setState: setAccounts} = accountsHook;

    // Data state
    const [generatedReportUrl, setGeneratedReportUrl] = useState(null);
    const [generatedReportData, setGeneratedReportData] = useState(null);

    const [selectedOrgToDraftReport, setSelectedOrgToDraftReport] = useState(null);

    const [dateFrom, setDateFrom] = useState('2024-01-01');
    const [dateUntil, setDateUntil] = useState('2024-01-01');

    useEffect(() => {

        // Set the date range to today
        let today = new Date();
        setDateFrom(formatDate(today));
        setDateUntil(formatDate(today));

        if (!props.mainOrganisation) return

        // There is no organisation selection for when a supplier is logged in and viewing the reports page
        if (props.mainOrganisation.type === "SUPPLIER") return;

    }, [props.mainOrganisation]);

    function onGenerateReport(buttonHandler) {

        if (!selectedOrgToDraftReport) {
            buttonHandler.onError('No organisation selected, please select an organisation');
            return;
        }

        let reportingOrganisation = selectedOrgToDraftReport;

        let useSupplierMutation = props.mainOrganisation.type === 'SUPPLIER';

        props.showToastNotificationModal('success', 'Generating Report for ' + reportingOrganisation.info.name, 'The claims report is being generated', 6000);

        if (useSupplierMutation) {
            console.log('Generate a supplier report');
            generateSupplierClaimsReport(
                reportingOrganisation.id,
                dateFrom,
                dateUntil,
                (data) => {
                    props.showToastNotificationModal('success', 'Report Generated', 'The claims report has successfully be generated');
                    setGeneratedReportUrl(data.url_xlsx);
                    initializeSpreadsheet(data.output_data);
                    buttonHandler.onSuccess('Report generated successfully');
                },
                (error) => {
                    props.onError(error);
                    buttonHandler.onError('Failed to generate report');
                }
            );
        } else {
            generateClaimsReport(
                reportingOrganisation.id,
                dateFrom,
                dateUntil,
                (data) => {
                    props.showToastNotificationModal('success', 'Report Generated', 'The claims report has successfully be generated');
                    setGeneratedReportUrl(data.url_xlsx);
                    initializeSpreadsheet(data.output_data);
                    buttonHandler.onSuccess('Report generated successfully');
                },
                (error) => {
                    props.onError(error)
                    buttonHandler.onError('Failed to generate report');
                }
            );
        }


    }

    function initializeSpreadsheet(reportSpreadsheetData) {

        /** Convert the array of arrays of strings, into an array of arrays of objects
         * https://iddan.github.io/react-spreadsheet/docs/usage
         */
        let spreadSheetData = [];
        for (const row of reportSpreadsheetData) {

            let newRow = [];
            for (const cell of row) {
                newRow.push({value: cell});
            }
            spreadSheetData.push(newRow);

        }

        setGeneratedReportData(spreadSheetData);
    }

    useEffect(() => {
        /** If a supplier is viewing the reports page, automatically select the supplier user's organisation */

        if (!props.mainOrganisation) return;

        if (props.mainOrganisation.type === "SUPPLIER") {
            setSelectedOrgToDraftReport(props.mainOrganisation);
        }

    }, [props.mainOrganisation]);

    function formatDate(date) {
        /** Pass in a vanilla JS Date object
         * Return the date formatted as YYYY-MM-DD */
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // months are zero-indexed, add one and pad
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const allProps = {
        ...props,
        formatDate,
        organisations, setOrganisations,
        accounts, setAccounts,
        dateFrom, setDateFrom,
        dateUntil, setDateUntil,
        generatedReportUrl, setGeneratedReportUrl,
        selectedOrgToDraftReport, setSelectedOrgToDraftReport,
        onGenerateReport,
        generatedReportData,
    };


    return (
        <div className="flex flex-col body">

            <Menu
                {...allProps}
            />

            <SpreadsheetPreview
                {...allProps}
            />

        </div>
    );

}


function Menu(props) {

    // ADMINS, ADMIN_INSURERS, SUPPLIERS
    const [orgFilter, setOrgFilter] = useState('ADMINS');

    function onChange(filter) {
        props.setSelectedOrgToDraftReport(null);
        setOrgFilter(filter)
    }

    function orgTypeRadios() {

        return (
            <RadioGroup value={orgFilter} onChange={onChange} className="radio-container">
                {/*<RadioGroup.Label className="block text-sm font-medium text-gray-700 mb-2">Plan</RadioGroup.Label>*/}

                <div className="flex justify-around items-center h-12">
                    <RadioGroup.Option value="ADMINS">
                        {({checked}) => (
                            <span
                                className={classNames(
                                    'radio-option',
                                    checked ? 'radio-option-selected' : ''
                                )}
                            >
                                Admins
                            </span>
                        )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="ADMIN_INSURERS">
                        {({checked}) => (
                            <span
                                className={classNames(
                                    'radio-option',
                                    checked ? 'radio-option-selected' : ''
                                )}
                            >
                                Insurers
                            </span>
                        )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="SUPPLIERS">
                        {({checked}) => (
                            <span
                                className={classNames(
                                    'radio-option',
                                    checked ? 'radio-option-selected' : ''
                                )}
                            >
                                Suppliers
                            </span>
                        )}
                    </RadioGroup.Option>
                </div>

            </RadioGroup>
        );

    }

    function orgSelection() {

        if (!props.selectedCountry || props.selectedCountry.code === 'WORLD') {
            return (
                <CountrySelection {...props} />
            );
        }

        const onChange = (org) => {
            props.setSelectedOrgToDraftReport(org);
        }

        if (props.accounts === null) return (
            <div className='h-[2rem]'>
                <LoadingSpinner body='Loading'/>
            </div>
        );

        if (orgFilter === 'ADMINS') return <AdminOrgSelection {...props} onChange={onChange}/>;
        if (orgFilter === 'ADMIN_INSURERS') return <AdminInsurerSelection {...props} onChange={onChange}/>;
        if (orgFilter === 'SUPPLIERS') return <SupplierSelection {...props} onChange={onChange}/>;
    }

    function organisationSelection() {

        if (!props.mainOrganisation) return <></>;

        if (props.mainOrganisation.type === 'SUPPLIER') {
            return <SupplierUser {...props} />
        }

        return (
            <>
                {orgTypeRadios()}

                {orgSelection()}
            </>
        );

    }

    return (
        <>

            <div className="page-header-bar">
                <h1 className="page-header-title">
                    Claim Report
                </h1>
            </div>


            <div className="widget flex w-full justify-around gap-12 p-8 mb-0">

                {/* ORGANISATION SELECTION */}
                <div className='flex flex-col justify-around w-1/3'>

                    {organisationSelection()}

                </div>


                {/* Start and end Daterange selection */}
                <div className="w-full flex flex-col justify-around gap-4 w-1/3 ">

                    <div className='flex justify-start w-full flex-wrap gap-1'>
                        <button className='btn-raised border-b-2 border-b-sky-200 ' onClick={() => {
                            // set the from date and until date to today
                            let today = new Date();
                            props.setDateFrom(props.formatDate(today));
                            props.setDateUntil(props.formatDate(today));
                        }}>
                            Today
                        </button>
                        <button className='btn-raised border-b-2 border-b-sky-200 ' onClick={() => {
                            // set the from date and until date to yesterday
                            let yesterday = new Date();
                            yesterday.setDate(yesterday.getDate() - 1);
                            props.setDateFrom(props.formatDate(yesterday));
                            props.setDateUntil(props.formatDate(yesterday));
                        }}>
                            Yesterday
                        </button>
                        <button className='btn-raised border-b-2 border-b-sky-300' onClick={() => {
                            // set the from date this week monday, and until date to this week sunday
                            let today = new Date();
                            let day = today.getDay();
                            let diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
                            let monday = new Date(today.setDate(diff));
                            let sunday = new Date(today.setDate(diff + 6));
                            props.setDateFrom(props.formatDate(monday));
                            props.setDateUntil(props.formatDate(sunday));
                        }}>
                            This week
                        </button>
                        <button className='btn-raised border-b-2 border-b-sky-300' onClick={() => {
                            // set the from date this last week monday, until the next sunday
                            let today = new Date();
                            let day = today.getDay();
                            let diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
                            let monday = new Date(today.setDate(diff));
                            let sunday = new Date(today.setDate(diff + 6));
                            let lastMonday = new Date(monday);
                            lastMonday.setDate(lastMonday.getDate() - 7);
                            let lastSunday = new Date(sunday);
                            lastSunday.setDate(lastSunday.getDate() - 7);
                            props.setDateFrom(props.formatDate(lastMonday));
                            props.setDateUntil(props.formatDate(lastSunday));
                        }}>
                            Last week
                        </button>
                        <button className='btn-raised border-b-2 border-b-blue-400' onClick={() => {
                            // set the from date to the 1st of this month, and the until date to the last day of this month
                            let today = new Date();
                            let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                            let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                            props.setDateFrom(props.formatDate(firstDay));
                            props.setDateUntil(props.formatDate(lastDay));
                        }}>
                            This month
                        </button>
                        <button className='btn-raised border-b-2 border-b-blue-400' onClick={() => {
                            // set the from date to the 1st of last month, and the until date to the last day of last month
                            let today = new Date();
                            let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                            let lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
                            props.setDateFrom(props.formatDate(firstDay));
                            props.setDateUntil(props.formatDate(lastDay));
                        }}>
                            Last month
                        </button>

                    </div>

                    <div>
                        <div>
                            <label>From date</label>
                            <input
                                type="date"
                                className="input w-full"
                                value={props.dateFrom}
                                onFocus={(e) => e.target.showPicker()}
                                onChange={(e) => {
                                    props.setDateFrom(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <label>Until date</label>
                            <input
                                type="date"
                                className="input w-full"
                                value={props.dateUntil}
                                onFocus={(e) => e.target.showPicker()}
                                onChange={(e) => {
                                    props.setDateUntil(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                </div>

                <div className="w-full flex flex-col justify-around w-1/3 ">
                    <ApiButton
                        onClick={props.onGenerateReport}
                        className='!w-full btn gap-2'
                        content={(<>
                            <DocumentReportIcon className="w-5 h-5"/>
                            Generate report for <span className='font-bold'>{props.selectedOrgToDraftReport?.info.name}</span>
                        </>)}
                        loadingContent={(<>
                            <LoadingSpinner color='darkcyan' size='6' body=''/>
                            Generating report for <span className='font-bold'>{props.selectedOrgToDraftReport?.info.name}</span>
                        </>)}
                        successContent={(<>Report generated for <span className='font-bold'>{props.selectedOrgToDraftReport?.info.name}</span></>)}
                        errorContent='Failed to generate report'
                        defaultStatus={'idle'}
                    />

                    <a className={`w-full btn gap-3 ${props.generatedReportUrl ? 'btn' : 'btn-disabled'}`}
                       href={props.generatedReportUrl}
                       download={`Report_${props.selectedSubOrganisation?.unique_reference_name || ''}_${props.dateFrom}_${props.dateUntil}`}>
                        <DownloadIcon className="w-5 h-5"/>
                        Download report
                    </a>

                </div>

            </div>


        </>

    );

}


function SpreadsheetPreview(props) {
    return (
        <div
            className="widget p-0 px-4 w-full overflow-x-scroll mb-20 mt-6 shadow border border-2 border-stone-200-50 rounded-md">
            {props.generatedReportData && <Spreadsheet data={props.generatedReportData}/>}
        </div>
    );
}



