import {
    ACCOUNTS,
    ADD_CATALOGUE_PRODUCT_TO_CLAIM,
    ADD_MANUAL_PRODUCT_TO_CLAIM,
    ADD_REPLACEMENT,
    ALL_SUPPLIERS,
    APPROVE_POST_TENDER_ITEM,
    CONVERT_MANUAL_ITEM_TO_CATALOGUE_ITEM,
    CATEGORIES,
    CLAIM_COMMENT,
    CLAIMS_SEARCH,
    CLAIMS_WITH_ITEMS,
    CREATE_CLAIM,
    CREATE_USER,
    CREATE_MANUAL_QUOTE,
    CREATE_MANUAL_REPLACEMENT,
    CREATE_SETTLEMENT,
    CREATE_CLAIM_COMMENT,
    CURRENT_USER,
    CHANGE_PASSWORD,
    DELETE_CLAIM,
    DELETE_ITEM,
    DELETE_MANUAL_QUOTE,
    DELETE_TENDER,
    DISMISS_ALL_PENDING_TENDER,
    DISMISS_TENDER,
    FILTER_SEARCH,
    GENERATE_REPORT_DOWNLOAD_URL,
    HAI_TASK_SEARCH,
    HAI_CHANGE_TASK,
    HAI_ASSIGN_TASK,
    ORGANISATIONS,
    QUANTIFY_CLAIM,
    SEARCH_LOCALITIES,
    REGIONS,
    SETTLE,
    SETTLEMENTS,
    SUPPLIER_DELIVERY_COST,
    UPDATE_ITEM_CLAIMANT_QUOTE,
    UPDATE_ITEM_QUANTITY,
    UPDATE_MANUAL_ITEM,
    UPDATE_QUOTE_PRICE,
    UPDATE_SETTLEMENT_VOUCHER_CODE,
    VERIFY_ALL_MANUAL_ITEMS,
    VERIFY_MANUAL_ITEM,
    ORGANISATION_OPTIONS,
    UPDATE_ORGANISATION,
    USER_FULL,
    CLAIMS_MINIMAL,
    CREATE_ORGANISATION,
    UPDATE_USER,
    SUPPLIER_SET_PREFERRED_REPLACEMENT,
    ROLES,
    DEACTIVATE_USER,
    DEACTIVATE_ORGANISATION,
    SNOOZE_CLAIM,
    DELETE_HAI_TASK,
    GENERATE_SUPPLIER_QUOTES_CATALOGUES,
    UPLOAD_FILE,
    GENERATE_SUPPLIER_DELIVERY_COSTS_SHEETS,
    LOAD_SUPPLIER_DELIVERY_COST_SHEET,
    LOAD_SUPPLIER_QUOTES_CATALOGUE_SHEET,
    GENERATE_CLAIMS_REPORTS,
    GENERATE_AI_PROTO,
    ACTIVATE_ACCOUNT,
    DEACTIVATE_ACCOUNT,
    CREATE_ACCOUNT,
    UPDATE_PROTO_PRODUCT_MANUALLY,
    ACTIVATE_ORGANISATION,
    IMPORT_SUPPLIER_QUOTES_CATALOGUE_WORKBOOK,
    GENERATE_SUPPLIER_CLAIMS_REPORT,
    IMPORT_SUPPLIER_DELIVERY_COST_WORKBOOK,
    PROTO_PRODUCT_PROPERTIES,
    DELETE_SETTLEMENT,
    VERIFY_SETTLEMENT_VOUCHER, CANCEL_SETTLEMENT_VOUCHER, PRODUCT_CREATION_SPECS, PRODUCT_PROPERTIES
} from './graphqlTemplates';

import {compareArrays, escapeDoubleQuotes, getBase64, sortClaimItems} from './helpers.js';
import ratelimited from "./ratelimit";


// Base functions

export function getRequest(relativeUrl, successCallback, errorCallback, headers = null) {

    apiRequest('GET', relativeUrl, successCallback, errorCallback, headers);
}

export function postRequest(relativeUrl, body, successCallback, errorCallback, headers = null) {

    apiRequest('POST', relativeUrl, successCallback, errorCallback, headers, body);
}

function graphqlRequest(query, successCallback, errorCallback) {

    let headers = {'Content-Type': 'application/json'};
    let body = JSON.stringify({'query': query});
    postRequest('graphql', body, successCallback, errorCallback, headers);

    // Developer modal
    if (window.onGraphRequest) window.onGraphRequest(query);
}

async function apiRequest(method, relativeUrl, successCallback, errorCallback, headers = null, body = null) {

    // Check if this browser is ratelimited
    if (ratelimited()) return;

    // RECAPTCHA - awaiting backend support
    /** let recaptchaIsValid = await passedRecaptcha()
     if(!recaptchaIsValid) return console.error('RECAPTCHA SCORE IS TOO LOW')
     */

    let url = process.env.REACT_APP_CORE_API_URL + relativeUrl;

    let options = {
        'method': method, 'credentials': 'include',
    };

    if (method === 'POST') {
        options['body'] = body;
    }

    if (headers != null) {
        options['headers'] = headers;
    }

    fetch(url, options)

        /** DEVELOPMENT Artificial delay to replicate production environment */
        // .then(async (response) => { if (window.location.hostname === 'localhost') await new Promise(r => setTimeout(r, 5000)); return response; })

        .then((response) => {

            if (!response.ok) {

                let fullPath = window.location.pathname

                /** Some paths do not require authentication. if a 401 is received whilst on these pages, do not redirect */
                // We use this filter method for partial/substring checking. "a" in "/a" = true
                let publicUnauthenticatedPath = ['/login', '/logout', '/password-reset', '/request-password-reset', '/ratelimited'].filter(path => path.includes(fullPath))

                if (response.status === 401) {
                    if (publicUnauthenticatedPath) {
                        // don't do anything
                    } else {
                        window.location.href = '/logout';
                        return;
                    }

                }

                if (response.status === 400 && relativeUrl === 'graphql') {
                    return response.json();
                }

                throw new Error(response.status + " " + response.statusText);
            }

            let contentType = response.headers.get('Content-Type');

            if (contentType.includes('application/json')) {
                return response.json();
            }

            return response.text();
        })
        .then((data) => {

            if (typeof data === 'object') {
                // Check if a GraphQL syntax error occurred

                if ('errors' in data) {

                    errorCallback({
                        'type': 'Invalid input', 'message': data['errors'][0]['message']
                    });
                    return;
                }

                // Extract the data from the inner dictionary
                data = Object.values(data['data'])[0];

                // Check if an API error occurred
                if ('error' in data && data['error'] != null) {
                    let type = data['error']['type'].replaceAll('_', ' ').toLowerCase();
                    let message = data['error']['message'];

                    errorCallback({
                        'type': type, 'message': message
                    });

                    return;
                }
            }

            successCallback(data);
        })
        .catch((error) => {
            // TODO state loop if ERR_CONNECTION_REFUSED

            console.error("Local Error Processing Response:", error);
            errorCallback({
                'type': 'Local Error Processing Response:',
                'message': 'Processing the response from the API has failed\n ' + String(error),
            });
        })

}


let pageTracking = {}
let path = ''

function logQueries(body) {
    /** This helper function will track all the queries and mutations made on a page and print the results to the developer console */

    if (!body) return

    if (path != window.location.pathname) {
        path = window.location.pathname;
        pageTracking = {}
    }

    let queryName = body.substring(23, 50);
    // let queryName = body.substring(23, 200);

    let braceIndex = queryName.indexOf('(')
    if (braceIndex === -1) braceIndex = queryName.indexOf('{')
    queryName = queryName.substring(0, braceIndex);

    queryName = queryName.trim()

    if (queryName in pageTracking) pageTracking[queryName]++;
    else pageTracking[queryName] = 1;

    console.log(pageTracking);

    if (queryName === "current_user") {
        // console.trace();
    }

}


let recaptchaPassed = false;
let resetRecaptchaTimeout = null;

async function passedRecaptcha(onSuccess, onFail) {

    if (recaptchaPassed) {
        return recaptchaPassed
    }

    let token;
    window.grecaptcha.ready(function () {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(_token => token = _token).catch(err => onFail());
    });

    const rawResponse = await fetch('https://www.google.com/recaptcha/api/siteverify', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY, response: token})
    });
    const content = await rawResponse.json();
    if (content.success) {
        if (content.score > 0.5) {
            passedRecaptcha = true;
            resetRecaptchaTimeout = setTimeout(() => passedRecaptcha = false, 1000 * 60 * 30) // 30 minutes
            return true
        } else {
            passedRecaptcha = false;
            return false
        }
    }

}


// Authentication functions

function loginApi(username, password, successCallback, errorCallback) {

    let formData = new FormData();
    formData.append('email', username);
    formData.append('password', password);

    postRequest('login', formData, successCallback, errorCallback);
}

function logoutApi(successCallback, errorCallback) {

    getRequest('logout', successCallback, errorCallback);
}

function requestPasswordReset(username, successCallback, errorCallback) {

    let formData = new FormData();
    formData.append('email', username);

    postRequest('request-password-reset', formData, successCallback, errorCallback);
}

function resetPassword(resetToken, password, confirmPassword, successCallback, errorCallback) {

    let formData = new FormData();
    formData.append('password', password);
    formData.append('password_2', confirmPassword);

    let url = 'password-reset/' + resetToken;
    postRequest(url, formData, successCallback, errorCallback);
}



/** An object to hold timeouts, in order to debounce API calls as inputs are updated */
const debounceTimers = {};
export function debounceQuery(queryName, debounceMS, query){

    /** If there is an existing timer to trigger this query, clear it */
    if (debounceTimers.queryName) clearTimeout(debounceTimers.queryName);

    /** Add a new timer to trigger this query */
    debounceTimers.queryName = setTimeout(query, debounceMS);
}


// API functions

function addCatalogueProductToClaim(claimId, productId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}" product:"${productId}"`;
    let query = ADD_CATALOGUE_PRODUCT_TO_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function addManualProductToClaim(args, successCallback, errorCallback) {

    let queryArgs = ``;

    if(args.claimId) queryArgs += ` claim:"${args.claimId}"`;
    else return errorCallback({type: 'Invalid input', message: 'claimId is required'});

    if(args.quantity) queryArgs += ` quantity:${args.quantity}`;

    if(args.categoryId) queryArgs += ` category:"${args.categoryId}"`;

    if(args.brand) queryArgs += ` brand:"${escapeDoubleQuotes(args.brand)}"`;

    if(args.description) queryArgs += ` description:"${escapeDoubleQuotes(args.description)}"`;

    if(args.modelNumber) queryArgs += ` model_number:"${escapeDoubleQuotes(args.modelNumber)}"`;

    if(args.eanCode) queryArgs += ` ean_code:"${escapeDoubleQuotes(args.eanCode)}"`;

    let query = ADD_MANUAL_PRODUCT_TO_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function addReplacement(itemId, productId, supplierId, reason, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" product:"${productId}" reason:"${reason}"`
    if(supplierId) queryArgs += ` supplier_id:"${supplierId}"`;
    let query = ADD_REPLACEMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function getAllSuppliers(successCallback, errorCallback) {
    let query = ALL_SUPPLIERS;
    graphqlRequest(query, successCallback, errorCallback);
}

function approvePostTenderItem(itemId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}"`
    let query = APPROVE_POST_TENDER_ITEM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function calculateReplacements(claimId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}"`
    let query = QUANTIFY_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function userChangePassword(password, successCallback, errorCallback) {
    let queryArgs = `password:"${password}"`
    let query = CHANGE_PASSWORD.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createClaimComment(claimId, commentMessage, successCallback, errorCallback) {
    let queryArgs = `claim:"${claimId}" message:"${escapeDoubleQuotes(commentMessage)}"`
    let query = CREATE_CLAIM_COMMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function snoozeClaim(claimId, snoozeAmount, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}" time_to_snooze:${snoozeAmount}`
    let query = SNOOZE_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function convertManualItemToCatalogueItem(itemId, productId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" product:"${productId}"`;
    let query = CONVERT_MANUAL_ITEM_TO_CATALOGUE_ITEM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createOrganisation(args, successCallback, errorCallback) {

    let queryArgs = ``;

    if(args.name) queryArgs += ` name:"${args.name}"`;
    else return errorCallback({type: 'Invalid input', message: 'Name is required'});

    if(args.orgType) queryArgs += ` type:${args.orgType}`;
    else return errorCallback({type: 'Invalid input', message: 'Organisation Type is required'});

    if(args.country) queryArgs += ` country:${args.country}`;
    else return errorCallback({type: 'Invalid input', message: 'Country is required'});

    if(args.currencyCode) queryArgs += ` currency:${args.currencyCode}`;
    else return errorCallback({type: 'Invalid input', message: 'Currency is required'});

    if(args.website) queryArgs += ` website:"${args.website}"`;
    else return errorCallback({type: 'Invalid input', message: 'Website is required'});

    if(args.uniqueReferenceName) queryArgs += ` unique_reference_name:"${args.uniqueReferenceName}"`;
    else return errorCallback({type: 'Invalid input', message: 'Reference name is required'});

    let query = CREATE_ORGANISATION.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createUser(args, successCallback, errorCallback) {
    // currently this function only supports 1 role
    // argument "roles" : requires a list of String id's, of roles that belong to that organisation
    let queryArgs = `has_playground_access:true `;

    if(args.organisationId) queryArgs += ` organisation:"${args.organisationId}"`;
    else return errorCallback({type: 'Invalid input', message: 'Organisation ID is required'});

    if(args.email) queryArgs += ` email:"${args.email}"`;
    else return errorCallback({type: 'Invalid input', message: 'Email is required'});

    if(args.first_name) queryArgs += ` first_name:"${args.first_name}"`;
    else return errorCallback({type: 'Invalid input', message: 'First name is required'});

    if(args.lastName) queryArgs += ` last_name:"${args.lastName}"`;
    else return errorCallback({type: 'Invalid input', message: 'Last name is required'});

    if(args.roleId) queryArgs += ` roles:["${args.roleId}"]`;
    else return errorCallback({type: 'Invalid input', message: 'Role ID is required'});

    if(args.permissions) queryArgs += ` permissions:[]`;

    let query = CREATE_USER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createClaim(args, successCallback, errorCallback) {

    let queryArgs = ``;

    if(args.organisationId) queryArgs += ` organisation:"${args.organisationId}"`;
    else return errorCallback({type: 'Invalid input', message: 'Organisation ID is required'});

    if(args.ownedById) queryArgs += ` owned_by:"${args.ownedById}"`;
    if(args.insurerClaimNumber) queryArgs += ` insurer_claim_number:"${escapeDoubleQuotes(args.insurerClaimNumber)}"`;
    if (args.insurerPolicyNumber) queryArgs += ` insurer_policy_number:"${escapeDoubleQuotes(args.insurerPolicyNumber)}"`;
    if (args.postalCode) queryArgs += ` postal_code:"${args.postalCode}"`;
    if (args.locality) queryArgs += ` locality:"${args.locality}"`;
    if (args.voucherClaim) queryArgs += ` is_voucher_claim:true`;

    if (args.ccEmailSets && args.ccEmailSets.length > 0) {
        const ccEmailsFormatted = args.ccEmailSets
            .map(set => `{first_name: "${escapeDoubleQuotes(set.firstName)}", last_name: "${escapeDoubleQuotes(set.lastName)}", email: "${escapeDoubleQuotes(set.email)}"}`)
            .join(", ");
        queryArgs += ` cc_recipients: [${ccEmailsFormatted}]`;
    }

    let query = CREATE_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createManualQuote(productId, supplierId, reason, price, validityPeriod, itemId, successCallback, errorCallback) {

    let queryArgs = `product:"${productId}" supplier:"${supplierId}" reason:"${escapeDoubleQuotes(reason)}" price:${price} validity_period:${validityPeriod} item_id:"${itemId}"`;
    let query = CREATE_MANUAL_QUOTE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createManualReplacement(args, successCallback, errorCallback) {

    let queryArgs = `item:"${args.itemId}" reason:"${escapeDoubleQuotes(args.reason.trim())}"`;

    if (args.brand !== '' && args.brand !== null) {
        queryArgs += ` brand:"${escapeDoubleQuotes(args.brand.trim())}"`;
    }

    if (args.description !== '' && args.description !== null) {
        queryArgs += ` description:"${escapeDoubleQuotes(args.description.trim())}"`;
    }

    if (args.modelNumber !== '' && args.modelNumber !== null) {
        queryArgs += ` model_number:"${escapeDoubleQuotes(args.modelNumber.trim())}"`;
    }

    if (args.eanCode !== '' && args.eanCode !== null) {
        queryArgs += ` ean_code:"${escapeDoubleQuotes(args.eanCode.trim())}"`;
    }

    let query = CREATE_MANUAL_REPLACEMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function setPreferredReplacement(args, successCallback, errorCallback) {

    let queryArgs = `
        item_id:"${args.itemId}" 
        product_id:"${args.productId}" 
        supplier_id:"${args.supplierId}" 
        new_quote_price:${args.price} 
        allow_deapi_update:${args.updateDeapi} 
        validity_period:${args.validity} 
        reason:"${args.reason}"
    `;

    let query = SUPPLIER_SET_PREFERRED_REPLACEMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function createSettlement(args, successCallback, errorCallback) {

    let queryArgs = `
        claim:"${args.claimId}" method:${args.method} supplier:"${args.supplierId}" value:${args.value} excess:${args.excess}
        last_name:"${args.lastName}" phone_number:"${args.phoneNumber}" settle: true   
    `;

    if (args.itemIds) {
        queryArgs += ` items:["${args.itemIds.join('","')}"]`;
    }

    if (args.purchase_order) {
        queryArgs += ` purchase_order:"${args.purchase_order}"`;
    }

    if (args.note) {
        queryArgs += ` note:"${args.note}"`
    }

    if (args.title) {
        queryArgs += ` title:"${args.title}"`;
    }

    if (args.firstName) {
        queryArgs += ` first_name:"${args.firstName}"`;
    }

    if (args.email) {
        queryArgs += ` email:"${args.email}"`;
    }

    // TODO uncomment once implemented on the backend
    // if (args.delivery_fee) {
    //     queryArgs += ` delivery_fee:"${args.delivery_fee}"`;
    // }

    let query = CREATE_SETTLEMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deactivateUser(userId, reason, successCallback, errorCallback) {

    let queryArgs = `user:"${userId}"`;

    if(reason) queryArgs += ` reason:"${reason}"`;

    let query = DEACTIVATE_USER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deactivateOrganisation(organisationId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" `;
    let query = DEACTIVATE_ORGANISATION.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function activateOrganisation(organisationId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" `;
    let query = ACTIVATE_ORGANISATION.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function activateAccount(accountId, successCallback, errorCallback) {

    let queryArgs = `account:"${accountId}" `;
    let query = ACTIVATE_ACCOUNT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deactivateAccount(accountId, successCallback, errorCallback) {

    let queryArgs = `account:"${accountId}" `;
    let query = DEACTIVATE_ACCOUNT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deleteClaim(claimId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}"`;
    let query = DELETE_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deleteItem(itemId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}"`;
    let query = DELETE_ITEM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deleteManualQuote(itemId, quoteId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" quote:"${quoteId}"`;
    let query = DELETE_MANUAL_QUOTE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function deleteTender(itemId, quoteId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" quote:"${quoteId}"`;
    let query = DELETE_TENDER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function dismissAllPendingTender(claimId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}"`;

    let query = DISMISS_ALL_PENDING_TENDER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function dismissTender(itemId, reason, supplierId, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" reason:${reason}`;

    if (supplierId !== null) {
        queryArgs += ` supplier:"${supplierId}"`;
    }

    let query = DISMISS_TENDER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function fetchClaim(claimId, setClaim, navigate, itemStatus = null) {

    // either change this
    // or change all fetchClaim implementations

    querySingleClaim(
        claimId,
        itemStatus,
        (data) => {

            let claim = data["claims"][0];
            claim = sortClaimItems(claim);

            setClaim(claim);
        },
        (error) => {
            console.error("Error while fetching claim.");
            console.error(error);
        }
    );

}

export function queryFullClaim(claimId, successCallback, errorCallback) {
    let queryArgs = `id: "${claimId}"`;

    // let queryArgs = params.map(param => `${param.key}:${param.value},`).join('\n');

    let query = CLAIMS_WITH_ITEMS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function fetchUser(setUser, navigate) {

    queryCurrentUser(null, (data) => {
        setUser(data["user"]);
    }, (error) => {
        console.error(error);
    });

}

function filterSearch(claimId, searchParameters, successCallback, errorCallback) {

    /** searchParameters = {
         inputSearchText: '',
         selectedCategory: SMARTPHONES
         selectedCategoryId: '',
         categoryFilters_NameValue: [
            {'name': 'brand', 'value': 'samsung'}
         ]
     }
     */

    let args = `claim:"${claimId}"`;

    if (searchParameters.inputSearchText) {
        /** args += ` query:"${escapeDoubleQuotes(searchParameters.inputSearchText.trim())}"`; // text input currently doesn't support " double quotes */
        args += ` query:"${escapeDoubleQuotes(searchParameters.inputSearchText.trim().replaceAll('"', "''"))}"`;
    }

    if (searchParameters.selectedCategory) args += ` category: ${searchParameters.selectedCategory}`;

    let filters = [];
    if(!searchParameters.categoryFilters_NameValue) searchParameters.categoryFilters_NameValue = [] // replace null with empty array
    for (const filter of searchParameters.categoryFilters_NameValue) {
        filters.push({
            "field": filter.name, "value": filter.value
        })
    }

    // If there are no category filters (brand, display, etc), don't add the filter_query argument
    if(filters){
        let filterStr = JSON.stringify(filters);
        filterStr = filterStr.replaceAll('"field"', "field")
        filterStr = filterStr.replaceAll('"value"', "value")
        args += ` filter_query: ${filterStr}`;
    }

    let searchQuery = FILTER_SEARCH.replace('|placeholder|', args);
    graphqlRequest(searchQuery, successCallback, errorCallback);
}

function filterSearchOLD(claimId, query, filterQuery, successCallback, errorCallback) {

    let args = `claim:"${claimId}"`;

    if (query !== null) {
        args += ` query:"${query.trim().replaceAll('"', "''")}"`;
    }

    if (filterQuery !== null) {
        let filters = [];

        for (const [key, value] of Object.entries(filterQuery)) {
            filters.push({
                "field": key, "value": value
            })
        }

        let filterStr = JSON.stringify(filters);
        filterStr = filterStr.replaceAll('"field"', "field")
        filterStr = filterStr.replaceAll('"value"', "value")
        args += ` filter_query: ${filterStr}`;
    }

    let searchQuery = FILTER_SEARCH.replace('|placeholder|', args);
    graphqlRequest(searchQuery, successCallback, errorCallback);
}

// TODO this has been replaced, remove it after PROD has been updated (change: proto_product_properties -> product_properties). For now it's here for backwards compatibility
export function protoProductProperties(args, successCallback, errorCallback){

    let queryArgs = ``;

    if (args.productId) {
        queryArgs += ` id:"${args.productId}"`;
    } else return errorCallback({type: 'Invalid input', message: 'Product ID is required'});

    if (args.productCategory) {
        queryArgs += ` category:${args.productCategory}`;
    } else return errorCallback({type: 'Invalid input', message: 'Product Category name is required'});

    let query = PROTO_PRODUCT_PROPERTIES.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

export function productProperties(args, successCallback, errorCallback){

    let queryArgs = ``;

    if (args.productId) {
        queryArgs += ` id:"${args.productId}"`;
    } else return errorCallback({type: 'Invalid input', message: 'Product ID is required'});

    if (args.productCategory) {
        queryArgs += ` category:${args.productCategory}`;
    } else return errorCallback({type: 'Invalid input', message: 'Product Category name is required'});

    let query = PRODUCT_PROPERTIES.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function HaiSearch(query, successCallback, errorCallback) {

    let args = `claim:"64083ed4fdff0d321259120a"`;

    if (query !== null) {
        args += ` query:"${query.trim().replaceAll('"', "''")}"`;
    }

    let searchQuery = FILTER_SEARCH.replace('|placeholder|', args);
    graphqlRequest(searchQuery, successCallback, errorCallback);
}

function generateReportDownloadUrl(claimId, report_type, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}" report_type:${report_type}`;
    let query = GENERATE_REPORT_DOWNLOAD_URL.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function haiTaskSearch(args, successCallback, errorCallback) {

    let queryArgs = `
        sort_by:{ field: "date_created", order: DESCENDING }  
        result_limit:${args.resultLimit ? args.resultLimit : 100} 
        page_number:${args.page ? args.page : 1}
    `;

    if(args.supplier) queryArgs += ` supplier_name:"${args.supplier}"`;
    if(args.status) queryArgs += ` status:${args.status}`;
    if(args.productCategory) queryArgs += ` category:${args.productCategory}`;
    if(args.sourceURL) queryArgs += ` source_url:"${args.sourceURL}"`;
    if(args.country) queryArgs += ` country_code:${args.country}`;
    if(args.taskType) queryArgs += ` task_type:${args.taskType}`;
    if(args.dateCreated) queryArgs += ` date_created:{date:"${args.dateCreated.trim()}", comparison:EQUALS}`;

    let query = HAI_TASK_SEARCH.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function haiTaskResolve(userID, taskID, resolve, dataHAI, status, successCallback, errorCallback) {

    let queryArgs = `user:"${userID}" task:"${taskID}" resolution:${resolve} `;
    if (dataHAI !== null) {
        queryArgs += ` data: [${dataHAI}]`;
    }
    if (status !== null) {
        queryArgs += ` status: ${status}`;
    }

    let query = HAI_CHANGE_TASK.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function haiTaskDelete(taskID, successCallback, errorCallback) {
    let queryArgs = `task: "${taskID}"`;
    let query = DELETE_HAI_TASK.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function haiAssignTask(userID, taskID, successCallback, errorCallback) {


    let queryArgs = `user:"${userID}" task:"${taskID}"`;
    let query = HAI_ASSIGN_TASK.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateSettlementVoucherCode(vtid, voucherCode, successCallback, errorCallback) {

    let queryArgs = `voucher_transaction_id:"${vtid}" supplier_voucher_code:"${voucherCode}"`;
    let query = UPDATE_SETTLEMENT_VOUCHER_CODE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function searchLocalities(postalCode, countryCode, successCallback, errorCallback) {
    let queryArgs = `postal_code:"${postalCode}" country_iso_code:${countryCode}`;
    let query = SEARCH_LOCALITIES.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function addFilterBasedItem(args, successCallback, errorCallback) {

    let queryArgs = `
        claim:"${args.claimId}" 
        category:"${args.categoryId}"
        brand:"${escapeDoubleQuotes(args.brand)}"
        description: "${escapeDoubleQuotes(args.description)}"
    `;

    let spec_query = 'spec_query:['
    for (const [key, value] of Object.entries(args.categoryFilters)) {
        spec_query += `{ field:"${escapeDoubleQuotes(key)}" value:"${escapeDoubleQuotes(value)}" }`
    }
    spec_query += ']'
    queryArgs += spec_query

    let query = ADD_MANUAL_PRODUCT_TO_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function addAiBasedItem(args, successCallback, errorCallback) {

    let queryArgs = `
        claim:"${args.claimId}"
        category:"${args.categoryId}"
        brand:"${escapeDoubleQuotes(args.brand)}"
        model_number:"${escapeDoubleQuotes(args.modelNumber)}"
        description: "${escapeDoubleQuotes(args.description)}"
        ai_generated: true
    `;

    // claimId, categoryId, quantity, brand, modelNumber, description, categoryFilters, propertySpecs

    // No category or filter selection is required for the AI item card to appear. Therefor categoryFilters may be blank
    // But ai_generated manual item mutation requires a spec query. So, make sure at minimum the brand is included.
    if (!('brand' in args.categoryFilters)) args.categoryFilters['brand'] = args.brand

    // Specification/Property query
    let spec_query = "spec_query:["
    // Add the selected category filters
    for (const [key, value] of Object.entries(args.categoryFilters)) {
        spec_query += `{ field:"${escapeDoubleQuotes(key)}" value:"${escapeDoubleQuotes(value)}" }, \n`
    }
    // Add the ai product generated properties/specs
    for (const property of args.propertySpecs) {
        spec_query += `{ field:"${escapeDoubleQuotes(property.name)}" value:"${escapeDoubleQuotes(property.value)}" }, \n`
    }
    spec_query += ']'

    queryArgs += spec_query

    let query = ADD_MANUAL_PRODUCT_TO_CLAIM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function searchRegions(postalCode, countryCode, successCallback, errorCallback) {
    let queryArgs = `postal_code:"${postalCode}" country_iso_code:${countryCode} auto_generate:true`;
    let query = REGIONS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function queryRegions(postalCode, countryCode, successCallback, errorCallback) {
    let queryArgs = ``;
    if (postalCode) queryArgs += ` postal_code:"${postalCode}" `;
    if (countryCode) queryArgs += ` country_iso_code:${countryCode} `;

    let query = '';
    if (!queryArgs) query = REGIONS.replace('(|placeholder|)', '');
    else query = REGIONS.replace('|placeholder|', queryArgs);

    graphqlRequest(query, successCallback, errorCallback);
}

function queryAccounts(orgId, typeOrg, successCallback, errorCallback, status= null, adhoc_only) {

    let queryArgs = "";
    let query = "";

    if (orgId) {
        queryArgs += ` organisation: "${orgId}"`;
    }

    if (typeOrg) {
        queryArgs += ` types: [${typeOrg}]`;
    }

    if (status) {
        queryArgs += ` status: ${status}`;
    }

    if(adhoc_only) {
        queryArgs += ` is_adhoc: ${adhoc_only}`;
    }

    if(queryArgs)
        query = ACCOUNTS.replace('|placeholder|', queryArgs);

    // If there are no arguments, remove the placeholder including the brackets
    else
        query = ACCOUNTS.replace('(|placeholder|)', '');

    graphqlRequest(query, successCallback, errorCallback);
}


function queryCategories(categories, setCategories, errorCallback) {

    graphqlRequest(CATEGORIES, (data) => {

        let oldIds = [];
        let newIds = [];

        if (categories !== null) {
            for (const category of categories) {
                oldIds.push(category.id);
            }
        }

        for (const category of data["categories"]) {
            newIds.push(category.id);
        }

        if (!compareArrays(oldIds, newIds)) {
            setCategories(data["categories"]);
        }
    }, errorCallback);
}

function queryCategoriesBasic(successCallback, errorCallback) {
    let query = CATEGORIES.replace('|placeholder|', '');
    graphqlRequest(query, successCallback, errorCallback);
}

function queryClaimComment(claimID, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimID}"`;

    let query = CLAIM_COMMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);

}

function queryCurrentUser(actionsToCheck, successCallback, errorCallback) {

    let queryArgs = "";
    let query = "";

    if (actionsToCheck !== null) {
        queryArgs += ` actions_to_check: [${actionsToCheck.join(',')}]`;
        query = CURRENT_USER.replace('|placeholder|', queryArgs);
    } else {
        query = CURRENT_USER.replace('(|placeholder|)', queryArgs);
    }

    graphqlRequest(query, successCallback, errorCallback);
}

function queryUserFull(userId, successCallback, errorCallback) {

    let queryArgs = "";
    let query = "";

    queryArgs += ` id:"${userId}"`;
    query = USER_FULL.replace('|placeholder|', queryArgs);

    graphqlRequest(query, successCallback, errorCallback);
}

function queryRolesForOrganisation(organisationId, successCallback, errorCallback) {
    let queryArgs = `organisation:"${organisationId}"`;
    let query = ROLES.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function queryClaims(args, successCallback, errorCallback, resultLimitPerPage = 25) {

    let queryArgs = '';
    if (args !== null) {
        queryArgs = args;
    }

    queryArgs += ` result_limit:25 sort_by:[{ field: "date_updated" order: DESCENDING}]`
    let query = CLAIMS_SEARCH.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function queryClaimsMinimal(organisationId, successCallback, errorCallback) {
    let queryArgs = `organisation:"${organisationId}"`;
    let query = CLAIMS_MINIMAL.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function customGraphRequest(query, successCallback, errorCallback) {
    graphqlRequest(query, successCallback, errorCallback);
}

function queryOrganisations(types, userActionsToCheck, unique_name, successCallback, errorCallback, status = null, queryName = null) {

    let queryArgs = "";
    let query = "";

    if (unique_name !== null) {
        queryArgs += ` unique_reference_name:"${unique_name}"`;
    }

    if (types !== null) {
        queryArgs += ` types:[${types.join(", ")}]`;
    }

    if (userActionsToCheck !== null) {
        queryArgs += ` user_actions_to_check:[${userActionsToCheck.join(", ")}]`;
    }

    if (status !== null) {
        queryArgs += ` status: ${status}`;
    }

    if (queryArgs === "") {
        query = ORGANISATIONS.replace('(|placeholder|)', queryArgs);
    } else {
        query = ORGANISATIONS.replace('|placeholder|', queryArgs);
    }

    if(queryName) {
        query = query.replace('query {', `query ${queryName} {`);
    }

    graphqlRequest(query, successCallback, errorCallback);
}

function queryOrganisationOptions(orgId, successCallback, errorCallback) {

    let queryArgs = "";
    let query = "";

    queryArgs += `id:"${orgId}"`;

    query = ORGANISATION_OPTIONS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function querySettlements(claimId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}"`;
    let query = SETTLEMENTS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function querySingleClaim(claimId, itemStatus, successCallback, errorCallback) {

    let queryArgs = `id:"${claimId}"`;

    if (itemStatus !== null) {
        queryArgs += ` item_status:${itemStatus}`
    }
    let query = CLAIMS_WITH_ITEMS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function settle(args, successCallback, errorCallback) {

    let queryArgs = `settlement:"${args.settlementId}" last_name:"${args.lastName}" phone_number:"${args.phoneNumber}"`;

    if (args.title) {
        queryArgs += ` title:"${args.title}"`;
    }

    if (args.firstName) {
        queryArgs += ` first_name:"${args.firstName}"`;
    }

    if (args.email) {
        queryArgs += ` email:"${args.email}"`;
    }

    let query = SETTLE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function supplierDeliveryCost(args, successCallback, errorCallback) {

    let queryArgs = `supplier: "${args.supplierId}" category: "${args.categoryId}"`

    if (args.postalCode) {
        queryArgs += ` postal_code:"${args.postalCode}"`;
    }

    let query = SUPPLIER_DELIVERY_COST.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateItemClaimantQuote(itemId, price, successCallback, errorCallback) {

    if (!price) price = 0;

    let queryArgs = `item:"${itemId}" price:${price}`
    let query = UPDATE_ITEM_CLAIMANT_QUOTE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateItemQuantity(itemId, quantity, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" quantity:${quantity}`
    let query = UPDATE_ITEM_QUANTITY.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateManualItem(args, successCallback, errorCallback) {

    let queryArgs = `item:"${args.itemId}"`;

    if (args.categoryId) {
        queryArgs += ` category:"${args.categoryId}"`;
    }

    if (args.brand) {
        queryArgs += ` brand:"${escapeDoubleQuotes(args.brand)}"`;
    }

    if (args.description) {
        queryArgs += ` description:"${escapeDoubleQuotes(args.description)}"`;
    }

    if (args.modelNumber) {
        queryArgs += ` model_number:"${escapeDoubleQuotes(args.modelNumber)}"`;
    }

    if (args.eanCode) {
        queryArgs += ` ean_code:"${args.eanCode}"`;
    }

    if (args.quantity) {
        queryArgs += ` quantity:${args.quantity}`;
    }

    let query = UPDATE_MANUAL_ITEM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateQuotePrice(itemId, quoteId, price, allow_deapi_update, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" quote:"${quoteId}" price:${price} allow_deapi_update:${allow_deapi_update}`;
    let query = UPDATE_QUOTE_PRICE.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateOrganisation(queryArgs, successCallback, errorCallback) {

    let query = UPDATE_ORGANISATION.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateUser(userId, firstName, lastName, successCallback, errorCallback) {
    let queryArgs = `user:"${userId}" first_name:"${firstName}" last_name:"${lastName}" `
    let query = UPDATE_USER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

/*
function uploadSnappyClaimsLogo(logoInBase64, filename, organisation, successCallback, errorCallback) {

    // slightly prone to errors https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
    let unique_id = (Math.random() + 1).toString(36).substring(7); // this will generate 5 random characters, alphabet and digits. e.g bzcw5
    let unique_filename = 'logo_' + organisation.unique_reference_name + '_' + unique_id + filename

    let queryArgs = `organisation:"${organisation.id}" image_data:"${logoInBase64}" file_name:"${filename}"`;
    let query = UPLOAD_SNAPPY_LOGO.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);

}
*/

function verifyAllManualItems(claimId, successCallback, errorCallback) {

    let queryArgs = `claim:"${claimId}"`;
    let query = VERIFY_ALL_MANUAL_ITEMS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function verifyManualItem(itemId, outcome, successCallback, errorCallback) {

    let queryArgs = `item:"${itemId}" outcome:${outcome}`;
    let query = VERIFY_MANUAL_ITEM.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function generateSupplierDeliveryCostsSheet(supplierUniqueReferenceName, successCallback, errorCallback) {

    let queryArgs = `suppliers:["${supplierUniqueReferenceName}"]`;
    let query = GENERATE_SUPPLIER_DELIVERY_COSTS_SHEETS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function generateSupplierQuotesCatalogSheet(supplierUniqueReferenceName, successCallback, errorCallback) {

    let queryArgs = `suppliers:["${supplierUniqueReferenceName}"]`;
    let query = GENERATE_SUPPLIER_QUOTES_CATALOGUES.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function loadSupplierDeliveryCostSheet(organisationId, fileUploadId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" file_upload_id:"${fileUploadId}" test_mode:false `;
    let query = LOAD_SUPPLIER_DELIVERY_COST_SHEET.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function loadSupplierQuotesCatalogueSheet(organisationId, fileUploadId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" file_upload_id:"${fileUploadId}"`;
    let query = LOAD_SUPPLIER_QUOTES_CATALOGUE_SHEET.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function importSupplierQuotesCatalogWorkbook(organisationId, fileUploadId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" file_upload_id:"${fileUploadId}" test_mode:false `;
    let query = IMPORT_SUPPLIER_QUOTES_CATALOGUE_WORKBOOK.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function importSupplierDeliveryCostWorkbook(organisationId, fileUploadId, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" file_upload_id:"${fileUploadId}" test_mode:false `;
    let query = IMPORT_SUPPLIER_DELIVERY_COST_WORKBOOK.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}


function uploadFile(args, successCallback, errorCallback) {

    getBase64(
        args.file,
        (fileBase64) => {
            _uploadFile({...args, fileBase64}, successCallback, errorCallback)
        },
        errorCallback
    );
}

function _uploadFile(args, successCallback, errorCallback) {

    const metaStart = args.fileBase64.indexOf(';base64,') // spreadsheet.sheet;base64,UEsDB1BQ....
    args.fileBase64 = args.fileBase64.slice(metaStart + 8) // UEsDB1BQ....

    let queryArgs = `
        object_id:"${args.objectId}" 
        object_type: ${args.objectType}
        file_name:"${args.fileName}" 
        file:$file
        
    `;

    if(args.contentType) queryArgs += `content_type: ${args.contentType}`;
    
    let query = UPLOAD_FILE.replace('|placeholder|', queryArgs);

    let body = JSON.stringify({
        'query': query,
        'variables': {
            'file': args.fileBase64
        }
    });

    let headers = {'Content-Type': 'application/json'};

    postRequest('graphql', body, successCallback, errorCallback, headers);
}

function generateClaimsReport(organisationId, fromDate, untilDate, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" start_date:"${fromDate}" end_date:"${untilDate}" `;
    let query = GENERATE_CLAIMS_REPORTS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function generateSupplierClaimsReport(organisationId, fromDate, untilDate, successCallback, errorCallback) {

    let queryArgs = `organisation:"${organisationId}" start_date:"${fromDate}" end_date:"${untilDate}" `;
    let query = GENERATE_SUPPLIER_CLAIMS_REPORT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function generateAiProto(category_name, brand, model_number, item_id, hai_task_id, successCallback, errorCallback) {

    let queryArgs = `
        category_name:"${category_name}" 
        brand:"${brand}"
        ${model_number ? `model_number:"${model_number}"` : 'model_number:null'}
        
        ${item_id ? `item_id:"${item_id}"` : ''}
        ${hai_task_id ? `hai_task_id:"${hai_task_id}"` : ''}
        `;

    let query = GENERATE_AI_PROTO.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

function updateProtoProductManually(args, successCallback, errorCallback) {

    // proto_id: "${protoId}"
        // provider_image_url:${args.providerImageUrl ? `"${args.providerImageUrl}"` : 'null'}
    let queryArgs = `
        proto_id: "${args.protoId}"
        category: ${args.category}
        provider_image_url:"${args.providerImageUrl || ''}"
        date_released:"${args.dateReleased}"
        model_numbers: ${args.modelNumbers}
        ean_codes: ${args.eanCodes}
    `;

    if(args.itemId) queryArgs += ` item_id:"${args.itemId}"`;

    const format = (name) => name ? name.replaceAll(' ', '_').replaceAll('"', '\\"').toLowerCase() : '';

    /** EXAMPLE: data_spec:{
        brand : "Samsung" ,
        model_number_intermediate : "85TU7000FX" ,
        display_diagonal : "15\"" ,
     }*/
    queryArgs += 'data_spec:{ \n'
    queryArgs += args.dataSpec.map((spec) => {
        if(!spec.name) return null;
        return ` ${format(spec.name)} : "${escapeDoubleQuotes(spec.value)}"`;
    }).filter(x=> !!x).join(', \n');
    queryArgs += '}'

    let query = UPDATE_PROTO_PRODUCT_MANUALLY.replace('|placeholder|', queryArgs);

    console.log(query);
    graphqlRequest(query, successCallback, errorCallback);
}

export function deleteSettlement(settlementId, successCallback, errorCallback) {

    let queryArgs = `settlement:"${settlementId}"`;
    let query = DELETE_SETTLEMENT.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

export function verifySettlementVoucher(args, successCallback, errorCallback) {

    let queryArgs = `settlement:"${args.settlement}" claim_number:"${args.claim_number}" method:${args.method} beneficiary_id:"${args.beneficiary_id}"`;
    let query = VERIFY_SETTLEMENT_VOUCHER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

export function cancelSettlementVoucher(args, successCallback, errorCallback) {

    let queryArgs = `settlement:"${args.settlement}" claim_number:"${args.claim_number}" reason:"${args.reason}"`;
    let query = CANCEL_SETTLEMENT_VOUCHER.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

export function productCreationSpecs(args, successCallback, errorCallback) {

    let queryArgs = `
        category:${args.category} 
        ${args.brand ? `brands: ["${args.brand}"]` : ''} 
        importance_levels:[${args.importanceLevels?.join(', ') || 'REQUIRED'}]`
    ;
    let query = PRODUCT_CREATION_SPECS.replace('|placeholder|', queryArgs);
    graphqlRequest(query, successCallback, errorCallback);
}

export {
    customGraphRequest,
    addCatalogueProductToClaim,
    addManualProductToClaim,
    addReplacement,
    approvePostTenderItem,
    convertManualItemToCatalogueItem,
    calculateReplacements,
    userChangePassword,
    createOrganisation,
    createUser,
    createClaim,
    snoozeClaim,
    createManualQuote,
    createManualReplacement,
    setPreferredReplacement,
    createSettlement,
    createClaimComment,
    deactivateUser,
    deactivateOrganisation,
    activateOrganisation,
    activateAccount,
    deactivateAccount,
    deleteClaim,
    deleteItem,
    deleteManualQuote,
    deleteTender,
    dismissAllPendingTender,
    dismissTender,
    fetchClaim,
    fetchUser,
    filterSearch,
    generateReportDownloadUrl,
    getAllSuppliers,
    haiTaskSearch,
    haiTaskResolve,
    haiTaskDelete,
    haiAssignTask,
    HaiSearch,
    loginApi,
    logoutApi,
    queryAccounts,
    queryCategories,
    queryCategoriesBasic,
    queryClaimComment,
    queryCurrentUser,
    queryUserFull,
    queryRolesForOrganisation,
    queryClaims,
    queryClaimsMinimal,
    queryOrganisations,
    queryOrganisationOptions,
    querySettlements,
    querySingleClaim,
    requestPasswordReset,
    resetPassword,
    searchLocalities,
    searchRegions,
    queryRegions,
    settle,
    addFilterBasedItem,
    addAiBasedItem,
    supplierDeliveryCost,
    updateItemClaimantQuote,
    updateItemQuantity,
    updateManualItem,
    updateQuotePrice,
    updateSettlementVoucherCode,
    updateOrganisation,
    updateUser,
    verifyAllManualItems,
    verifyManualItem,
    generateAiProto,
    generateSupplierDeliveryCostsSheet,
    generateSupplierQuotesCatalogSheet,
    loadSupplierDeliveryCostSheet,
    loadSupplierQuotesCatalogueSheet,
    importSupplierQuotesCatalogWorkbook,
    importSupplierDeliveryCostWorkbook,
    uploadFile,
    generateClaimsReport,
    generateSupplierClaimsReport,
    updateProtoProductManually,
};

