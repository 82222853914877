// Library imports
import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {classNames, isEmpty} from "../../utils/helpers";
import {
    DownloadIcon,
    ExternalLinkIcon,
    LinkIcon,
    PhotographIcon,
    XIcon
} from "@heroicons/react/outline";
import {customGraphRequest} from "../../utils/coreApi";
import Tooltip from "../../components/Tooltip";
import {ImageZoom} from "../../components/base/ImageZoom";
import ApiButton from "../../components/base/ApiButton";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {DropdownMenu} from "../../components/base/DropdownMenu";
import {SparklesIcon} from "@heroicons/react/solid";

export default function ClaimAttachments(props) {

    useEffect(() => {
        if (!props.open)
            props.onClose?.();
    }, [props.open]);

    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[10] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-center justify-center min-h-screen h-full">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.stopPropagation(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                {/* MODAL */}
                                <div
                                    className="relative w-[60rem] max-h-[70rem] overflow-y-scroll bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-1/2 p-8"
                                    onClick={(e) => {
                                        /**
                                         this is to prevent the parent grabbing the click events inside the modal box
                                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                                         **/
                                        e.stopPropagation();
                                    }}>
                                    <div>

                                        <div>

                                            {/* HEADING */}
                                            <div
                                                className={classNames(
                                                    'flex gap-4 p-2 pb-6 justify-center items-center',
                                                    'border-b-[2px] rounded-lg border-gray-400' // DIVIDER
                                                )}>

                                                <div
                                                    className="flex flex-col gap-6 items-center text-3xl font-light text-gray-900 text-center"
                                                >
                                                    Claim Attachments
                                                </div>

                                            </div>

                                            {/* BODY */}
                                            <div className='flex flex-col gap-8 py-8'>

                                                {/** DEV DATA */}
                                                {/*{placeholderClaimAttachments.attachments.map((attachment, index) =>*/}

                                                {props.claimAttachmentsOptions.claim?.attachments.map((attachment, index) =>
                                                    <>
                                                        <Attachment key={index} attachment={attachment} {...props} />
                                                        <hr/>
                                                    </>
                                                )}

                                            </div>

                                        </div>

                                        {/* X CLOSE */}
                                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>
                                        </div>

                                    </div>

                                    {/* FOOTER */}
                                    <div className="mt-2 mx-20 flex justify-between">
                                        <button
                                            type="button"
                                            className="btn-light shadow-none"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            Close
                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function Attachment(props) {

    const [fileDownloadURL, setFileDownloadURL] = useState(null);
    const [fileDownloadState, setFileDownloadState] = useState('NOT_DOWNLOADED');

    function downloadAttachment(url) {
        // Create a new anchor element
        const anchor = document.createElement('a');

        // Set the href attribute to the download URL
        anchor.href = url || fileDownloadURL;

        // Trigger a click event on the anchor element
        anchor.click();

        // Cleanup: remove the anchor element from the document
        anchor.remove();
    }

    function getDownloadURL(onSuccess, btnHandler) {
        // Create download url

        if (fileDownloadURL || fileDownloadState !== 'NOT_DOWNLOADED') return;

        setFileDownloadState('DOWNLOADING');

        let mutation = `
            query GenerateFileDownloadURL{
               generate_file_download_url(
                file_id: "${props.attachment.id}"
                object_id: "${props.attachment.object_id}"
                object_type: ${props.attachment.object_type}
              ){
                error{type, message}
                url
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal('success', 'URL Generated', 'The download will start shortly');
                setFileDownloadURL(data.url);
                onSuccess?.(data.url);
                btnHandler?.onSuccess()
            },
            (error) => {
                props.onError(error)
                btnHandler?.onError()
            }
        )
    }

    async function openFileInNewTab(fileUrl) {
        /** This function will download the file from AWS S3, and open the file as a blob in a new tab
         * Because S3 sets the header to Attachment by default, the file always downloads, rather than
         * opens for viewing in the browser.
         * To force the file to be opened and viewed, we manually download the file, set the file
         * mime type, and pass the blob to a new tab where the browser can decide how to handle it */

        try {
            // Fetch the file from AWS S3
            const response = await fetch(fileUrl);
            const arrayBuffer = await response.arrayBuffer();

            // Extract the file extension
            const urlParts = fileUrl.split('?')[0].split('/');
            const fileName = urlParts[urlParts.length - 1];
            const fileExtension = fileName.split('.').pop().toLowerCase();

            // Map of file extensions to MIME types
            const mimeTypes = {
                // Images
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'png': 'image/png',
                'gif': 'image/gif',
                'bmp': 'image/bmp',
                'webp': 'image/webp',
                'tiff': 'image/tiff',
                'svg': 'image/svg+xml',
                'ico': 'image/vnd.microsoft.icon',
                // Documents
                'pdf': 'application/pdf',
                'txt': 'text/plain',
                'csv': 'text/csv',
                'doc': 'application/msword',
                'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'xls': 'application/vnd.ms-excel',
                'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'ppt': 'application/vnd.ms-powerpoint',
                'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'odt': 'application/vnd.oasis.opendocument.text',
                'ods': 'application/vnd.oasis.opendocument.spreadsheet',
                'odp': 'application/vnd.oasis.opendocument.presentation',
                'rtf': 'application/rtf',
                'html': 'text/html',
                'htm': 'text/html',
                'md': 'text/markdown',
                'json': 'application/json',
                'xml': 'application/xml',
                // Audio
                'mp3': 'audio/mpeg',
                'wav': 'audio/wav',
                'ogg': 'audio/ogg',
                'oga': 'audio/ogg',
                'flac': 'audio/flac',
                'aac': 'audio/aac',
                'wma': 'audio/x-ms-wma',
                'm4a': 'audio/mp4',
                'aiff': 'audio/aiff',
                'au': 'audio/basic',
                'mid': 'audio/midi',
                'midi': 'audio/midi',
                // Video
                'mp4': 'video/mp4',
                'avi': 'video/x-msvideo',
                'mkv': 'video/x-matroska',
                'mov': 'video/quicktime',
                'wmv': 'video/x-ms-wmv',
                'flv': 'video/x-flv',
                'webm': 'video/webm',
                'mpeg': 'video/mpeg',
                'mpg': 'video/mpeg',
                '3gp': 'video/3gpp',
                '3g2': 'video/3gpp2',
                // Archives
                'zip': 'application/zip',
                'rar': 'application/vnd.rar',
                '7z': 'application/x-7z-compressed',
                'tar': 'application/x-tar',
                'gz': 'application/gzip',
                'bz': 'application/x-bzip',
                'bz2': 'application/x-bzip2',
                // Fonts
                'ttf': 'font/ttf',
                'otf': 'font/otf',
                'woff': 'font/woff',
                'woff2': 'font/woff2',
                'eot': 'application/vnd.ms-fontobject',
                // Executables and Scripts
                'js': 'application/javascript',
                'exe': 'application/vnd.microsoft.portable-executable',
                'dll': 'application/vnd.microsoft.portable-executable',
                'sh': 'application/x-sh',
                'bat': 'application/x-msdownload',
                'jar': 'application/java-archive',
                'py': 'text/x-script.python',
                'rb': 'application/x-ruby',
                'php': 'application/x-httpd-php',
                // Others
                'swf': 'application/x-shockwave-flash',
                'psd': 'image/vnd.adobe.photoshop',
                'ai': 'application/postscript',
                'eps': 'application/postscript',
                'apk': 'application/vnd.android.package-archive',
                'iso': 'application/x-iso9660-image',
                'dmg': 'application/x-apple-diskimage',
                'epub': 'application/epub+zip',
                'mobi': 'application/x-mobipocket-ebook',
                // Default
                'default': 'application/octet-stream',
            };

            // Get the MIME type based on the file extension
            const mimeType = mimeTypes[fileExtension] || 'application/octet-stream';

            // Create a new Blob with the correct MIME type
            const blob = new Blob([arrayBuffer], { type: mimeType });

            // Create a blob URL
            const blobUrl = URL.createObjectURL(blob);

            // Open the blob URL in a new tab
            window.open(blobUrl, '_blank');

            // Optional: Revoke the object URL after some time to release memory
            setTimeout(() => URL.revokeObjectURL(blobUrl), 60000); // Revoke after 1 minute
        } catch (error) {
            props.showAlertModal(
                'error',
                'Download Error',
                'Failed to download and open the file. Please try again.'
            )
        }
    }

    function renderPreview() {
        /** Show either a "load preview" or a preview of the file
         * Because the attachment.url is not directly publicly accessible,
         * a public URL must be generated first, which can then display the
         * document */

        const isImage = (url) => {
            // Function to check if the URL is an image
            return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
        };

        if (fileDownloadURL) {
            return (
                <ImageZoom
                    imageSrc={fileDownloadURL || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    altText="Preview"
                    onError={(e) => e.target.remove()}
                    className="w-full max-w-[7rem] max-h-[5rem] object-contain px-2 !z-[100]"
                    zoomClassName={classNames(
                        'border-[1px] border-stone-400 rounded-md',
                        // Don't show the preview for the placeholder product image
                        fileDownloadURL ? 'scale-[400%]' : 'scale-[100%]'
                    )}
                />
            );
        }

        if (isImage(props.attachment.url)) {

            if (!fileDownloadURL) {
                return (
                    <Tooltip content='File preview' className='max-w-[10rem]'>
                        <div onMouseOver={() => getDownloadURL()}>
                            <PhotographIcon className='h-8 w-8 hover:h-12 hover:w-12 transition-all m-4 text-gray-600'/>
                        </div>
                    </Tooltip>
                );
            }

            return (
                <div onMouseOver={() => getDownloadURL()}>
                    <PhotographIcon className='h-8 w-8 hover:h-12 hover:w-12 transition-all m-4 text-gray-600'/>
                </div>
            );
        }

        return (<></>);

    }

    function addItemToAttachment(item, attachment) {
        // Add the attachment to the item

        const mutation = `
            mutation UpdateItemMetadata{
              update_item_metadata(
                item_id: "${item.id}"
                attachment_ids: ["${attachment.id}"]
              ){
                error{ message, type }
                item{
                  id
                }
              }
            }
        `
        customGraphRequest(
            mutation,
            (data) => {
                props.showToastNotificationModal('success', 'Item Added', 'The attachment has been added to the item');
            },
            (error) => props.onError(error)
        )

    }

    return (
        <div className='w-full h-[4rem] flex justify-between items-center'>

            <p className='basis-[40%] text-left text-gray-600 pl-8 max-w-[20rem] max-h-[5rem] overflow-y-hidden break-words'>{props.attachment.file_name}</p>

            {/* PREVIEW */}
            <div className='basis-a flex justify-center'>
                {renderPreview()}
            </div>

            <div className='basis-[30%] flex justify-end gap-2'>

                {/* VIEW */}
                <Tooltip content={(<div>
                    <p>Conveniently view and/or play this file in a new tab</p>
                </div>)}>
                    <ApiButton
                        onClick={(btnHandler) => {
                            if (fileDownloadURL) {
                                btnHandler.reset();
                                openFileInNewTab(fileDownloadURL);
                            }
                            else {
                                getDownloadURL(
                                    (url) => {
                                        openFileInNewTab(url);
                                    },
                                    btnHandler
                                );
                            }
                        }}
                        className='btn-raised gap-2 h-fit'
                        content={<>
                            <ExternalLinkIcon className='h-6 w-6'/>
                            View
                        </>}
                        loadingContent={<LoadingSpinner color='darkcyan' size='6' body=' ' text='text-md'/>}
                    />
                </Tooltip>

                {/* DOWNLOAD */}
                <button
                    className='btn-raised gap-2 h-fit'
                    onClick={() => {
                        if (fileDownloadURL) {
                            downloadAttachment();
                            return;
                        }
                        getDownloadURL((url) => {
                            downloadAttachment(url);
                        });
                    }}
                >
                    <DownloadIcon className='h-6 w-6'/>
                    Download
                </button>

                {/* LINKED ITEMS */}
                <DropdownMenu
                    optionGroups={[
                        props.claimAttachmentsOptions.claim?.items.map(item =>
                            <button
                                className='btn-raised flex gap-2 border-none w-full m-0 justify-start text-left disabled:bg-gray-200'
                                disabled={item.metadata?.attachments?.find(_attachment => props.attachment.id == _attachment.id)}
                                onClick={() => addItemToAttachment(item, props.attachment)}
                            >
                                <img src={
                                    item.product.images && item.product.images.thumbnail_web ||
                                    'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'
                                }
                                     alt={''}
                                     className='w-8 h-8'/>
                                <p>{item.product.common_name}</p>
                            </button>
                        )
                        ||
                        [<div>No items</div>]
                    ]}
                    mainButton={
                        <Menu.Button
                            className="btn-outline">
                            <LinkIcon className='h-6 w-6'/>
                            Link
                        </Menu.Button>
                    }

                    renderOption={(option) => option}
                />

                {/* SLVRCLD Lens */}
                {props.claimAttachmentsOptions?.parseAttachment &&
                    <ApiButton
                        onClick={(btnHandler) => props.claimAttachmentsOptions.parseAttachment(
                            props.attachment.id,
                            () => {
                                // SUCCESS CALLBACK
                                props.showToastNotificationModal('success', 'Attachment Parsed', 'The attachment has been parsed successfully');
                                btnHandler.onSuccess();
                            },
                            () => {
                                // ERROR CALLBACK
                                btnHandler.onError();
                            },
                            () => {
                                // RETRY
                                btnHandler.onLoading()
                            }
                        )}
                        className='btn-raised gap-2 hover:text-inherit font-bold bg-gradient-to-br from-[#e9008b] to-[#331c65] bg-clip-text text-transparent'
                        content={<>
                            <SparklesIcon className='h-6 w-6 text-[#a8097d]'/>
                            AI
                        </>}
                        loadingContent={<LoadingSpinner color='darkcyan' size='6' body=' ' text='text-md'/>}
                    />
                }

            </div>
        </div>
    );

}

export function ClaimAttachmentsButton(props) {

    if (isEmpty(props.claimAttachmentsOptions.claim?.attachments)) return <></>;

    return (
        <button className='btn-light relative'
                onClick={() => props.setShowClaimAttachmentsModal(true)}
        >
            Attachments
            <span
                className={classNames(
                    'absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 transform translate-x-1/3 -translate-y-1/3',
                    'text-xs font-bold leading-none text-gray-400 bg-white rounded-full',
                    'border-[1px] border-gray-200',
                    props.claimAttachmentsOptions.claim?.attachments.length > 0 ? 'visible' : 'invisible'
                )}>
                    {props.claimAttachmentsOptions.claim?.attachments.length}
                </span>
        </button>
    );
}







