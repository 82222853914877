/** This file contains hidden components throughout PORTAL, that assist in development */



/** This component is present in the Tender page.
* A button next to the Supplier name, when clicked, will generate random quotes
* for the items in that supplier*/
import {customGraphRequest} from "../../utils/coreApi";
import Tooltip from "../Tooltip";

export function CreateRandomQuotesForSupplier(props) {


    function createRandomQuotesForSupplier(supplier_and_items) {

        let quotesCreated = 0;

        let supplier = supplier_and_items.supplier;
        let items = supplier_and_items.items;

        items.forEach(item => {

            // random price between 50 and 10000
            let price = Math.floor(Math.random() * 10000) + 50;

            // Chance to skip this item
            if (Math.random() < 0.5) {
                return;
            }

            quotesCreated++;

            const mutation = `
                mutation {
                  create_manual_quote(
                      item_id:"${item.id}"
                      product:"${item.product.id}" 
                      supplier:"${supplier.id}" 
                      price:${price} 
                      validity_period:1 
                      reason:"random quote" 
                  ) {
                    error {
                      type
                      message
                    }
                    quote {
                      id
                      status
                    }
                  }
                }
            `

            console.log('CREATE RANDOM QUOTE: ', price, item.product.common_name);

            customGraphRequest(
                mutation,
                (data) => {
                    props.fetchItemsTendering();
                },
                (error) => {
                    console.log('ERROR: ', error);
                },
            );
        });

        setTimeout(() => props.showToastNotificationModal(
            'success',
            `${quotesCreated} Random Quotes Created`,
            'Randomly priced quotes have been created for a 50% random selection of items for this supplier',
            ),
            1000
        )

    }

    return (
        <button
            className="btn-raised text-xs"
            onClick={() => createRandomQuotesForSupplier(props.supplier_and_items)}
        >
            <Tooltip
                position="left"
                content={<>
                    <h4>This is a local/dev only feature. </h4>
                    <hr/>
                    <p>Create a quote for each item in this Supplier</p>
                    <p>Each item has a 50% probability to be skipped</p>
                    <p>Settings:</p>
                    <p><i>Validity_period: 1</i></p>
                    <p><i>reason: random quote</i></p>
                </>}
            >
                Create random quotes
            </Tooltip>
        </button>

    );

}
