import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
    CreditCardIcon,
    ExternalLinkIcon,
    TrashIcon,
    SearchIcon,
    ClipboardCopyIcon
} from '@heroicons/react/outline';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    MinusSmIcon,
    PlusCircleIcon
} from '@heroicons/react/solid';


// Local imports
import {
    createClaim,
    customGraphRequest,
    debounceQuery,
    deleteClaim
} from '../../utils/coreApi.js';
import {
    alertError,
    classNames,
    compareDicts,
    isEmpty,
    searchParamsToDict,
} from '../../utils/helpers.js';

import CreateClaimModal from '../modals/CreateClaimModal';
import AlertModal from '../modals/AlertModal';
import ConfirmModal from '../modals/ConfirmModal';
import ClaimInfoModal from '../modals/ClaimInfoModal';
import NotificationCenter from '../../components/NotificationCenter';
import NotificationTopRight from '../../components/NotificationTopRight';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import PageInfoModal from '../modals/PageInfoModal';
import {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";
import moment from "moment-timezone";
import {
    HUMAN_READABLE_DATETIME_TIMEZONE,
    localMomentInstanceFromUTC,
} from "../../utils/time";
import {QueryStatus} from "../../components/useQuery";
import Tooltip from "../../components/Tooltip";


// Constants
const defaultFilterValues = [
    {
        'id': 'all',
        'count': 0,
        'title': 'All',
        'status': 'ALL',
        'bgColorClass': 'border border-1 border-purple-800 border-opacity-20',
        'isSelected': true
    },
    {
        'id': 'new',
        'count': 0,
        'title': 'New',
        'status': 'NEW',
        'bgColorClass': 'border border-1 border-indigo-600 border-opacity-20',
        'isSelected': false
    },
    {
        'id': 'pending',
        'count': 0,
        'title': 'Pending',
        'status': 'QUANTIFICATION_IN_PROGRESS',
        'bgColorClass': 'border border-1 border-orange-500 border-opacity-20',
        'isSelected': false
    },
    {
        'id': 'quantified',
        'count': 0,
        'title': 'Quantified',
        'status': 'QUANTIFIED',
        'bgColorClass': 'border border-1 border-green-600 border-opacity-20',
        'isSelected': false
    },
    {
        'id': 'deleted',
        'count': 0,
        'title': 'Deleted',
        'status': 'DELETED',
        'bgColorClass': 'border border-1 border-red-600 border-opacity-20',
        'isSelected': false
    }
];

const statusStyles = {
    'ALL': 'bg-blue-800 text-white',
    'DELETED': 'bg-red-600 text-white',
    'NEW': 'bg-indigo-600 text-white',
    'QUANTIFICATION_IN_PROGRESS': 'bg-orange-500 text-white',
    'QUANTIFIED': 'bg-green-600 text-white'
};

const claimSuccessMessage = {
    'heading': 'Claim created',
    'message': 'Redirecting to claim page...'
};

export default function ClaimsHome(props){

    useDocumentTitle("SLVRCLD");

    // PROPS IMPORT
    let user = props.user; // TODO replace all uses of 'user' with 'props.user'
    let selectedCountry = props.selectedCountry;

    // React router
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // UI states

    const [tableOrdering_DateCreated, setTableOrdering_DateCreated] = useState('DESC'); // '', ASC, DESC
    const [tableOrdering_DateExpirySLA, setTableOrdering_DateExpirySLA] = useState(''); // '', ASC, DESC

    const [createClaimOpen, setCreateClaimOpen] = useState(false);
    const [claimInfoOpen, setClaimInfoOpen] = useState(false);
    const [successMessageOpen, setSuccessMessageOpen] = useState(false);

    // Data states
    const [claims, setClaims] = useState(null);
    const [queryStatus, setQueryStatus] = useState(QueryStatus.NOT_QUERIED);

    const [pageNumber, setPageNumber] = useState(1);
    const [resultsPerPage] = useState(25);


    const [notificationRightOpen, setNotificationRightOpen] = useState(false);
    const [notificationRightMessage, setNotificationRightMessage] = useState({
                                                                                 'heading': '',
                                                                                 'message': ''
                                                                             });


    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
                                                         'heading': '',
                                                         'message': ''
                                                     });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions, setConfirmModalOptions] = useState({
                                                                       'heading': '',
                                                                       'message': '',
                                                                       'type': '',
                                                                       'buttonText': ''
                                                                   });

    useEffect(() => {
        getClaims();
    }, []);

    useEffect(() => {

        // prevent the table column state from duplicating the claim load on page load
        if(!claims && tableOrdering_DateCreated === 'DESC') return;

        getClaims();
    }, [tableOrdering_DateExpirySLA, tableOrdering_DateCreated]);

    const prevCountryCode = useRef({code: '', name: ''});

    useEffect(() => {

        /** To prevent the claims fetched twice on page load (page load fetch and country selected loaded fetch)
         * Only query if the selected country is changed (SA -> NL), and not initialised (null -> NL)  */
        if(prevCountryCode.current.code === '') {
            prevCountryCode.current = selectedCountry;
            return;
        }

        // If the selected country has changed, then update the claims data
        // Do not update if the create claim modal is open
        if (props.selectedCountry.code && !createClaimOpen) {
            getClaims();
        }
    }, [props.selectedCountry]);

    const clearSearchInput = () => {
        let query = {};

        document.getElementById('claimSearch').value = ''

        if (query['query'] === '') {
            delete query['query'];
        }

        setSearchParams(query);
        onSearchInput();
    }

    const onSearchInput = () => {
        let oldQuery = searchParamsToDict(searchParams);
        let claimSearch = document.getElementById('claimSearch');
        let query = {};
        Object.assign(query, oldQuery);
        query['query'] = claimSearch.value;

        if (query['query'] === '') {
            delete query['query'];
        }

        // If the query still has the same key=value pairs, don't set the state
        if (compareDicts(oldQuery, query)) {
            return;
        }

        setSearchParams(query);

        // short delay before calling the API to prevent a call on each key press
        debounceQuery(
            'getClaims_Dashboard',
            300,
            () => {
                getClaims(query);
            }
        );

        return false;
    }

    // Helper functions
    const getClaims = (query = null) => {

        let queryArgs = '';

        // if no query was passed, then create a query based on the previous query params
        if (query === null) {
            query = searchParamsToDict(searchParams);
        }

        // Load URL query parameters
        if (query !== null) {

            // has a 'status' filter been selected? [NEW, PENDING, QUANTIFIED, DELETED]
            for (let filter of defaultFilterValues) {
                if (filter['id'] === query['status']) {
                    queryArgs = `status: ${filter['status']}`; // yes it has, add the status to the query
                    break;
                }
            }

            if ('page_number' in query) {
                queryArgs += ` page_number: ${query.page_number}`;
            }
            else {
                queryArgs += ` page_number: 1`;
                setPageNumber(1);
            }

            // if we did not find the filter in the query, then make sure to remove the 'status' param
            if (queryArgs === '') {
                delete query['status'];
                setSearchParams(query);
            }

            // did the user type something in the search input?  query.query is a string of the users search input
            if (query.query !== undefined && query.query !== null) {
                let claimNumber = query.query;

                queryArgs += ` insurer_claim_number:"${claimNumber}"`;
            }
        }

        if (selectedCountry.code && selectedCountry.code !== 'WORLD') {
            queryArgs += ` country: ${selectedCountry.code}`;
        }

        // if the user has selected a column ordering, then add it to the query
        if(tableOrdering_DateExpirySLA !== '') {
            // order by last modified date
            queryArgs += ` sort_by:[{ field: "date_sla_expiry" order: ${tableOrdering_DateExpirySLA === 'ASC' ? 'ASCENDING' : 'DESCENDING'}}]`;
        }
        else if (tableOrdering_DateCreated !== '') {
            // order by date created
            queryArgs += ` sort_by:[{ field: "date_created" order: ${tableOrdering_DateCreated === 'ASC' ? 'ASCENDING' : 'DESCENDING'}}]`;
        } else{
            // DEFAULT order by date created - newest first
            queryArgs += ` sort_by:[{ field: "date_created" order: DESCENDING}]`;
        }


        let minimalClaimsQuery =
            `query dashboard_min_claims {
                claims(|placeholder|){
                    error {
                      type
                      message
                    }
                
                    claims {
                      insurer_claim_number
                      id
                      status
                      date_sla_expiry
                      date_updated
                      date_created
                      item_count
                      tat_minutes
                      organisation {
                        info {
                          name
                          logo_image_url
                        }
                      }
                      owned_by {
                        info {
                          first_name
                          last_name
                          profile_image_url
                        }
                      }
                      status_reason
                }
                
              }
            }
        `;

        queryArgs += ` result_limit:25`;
        minimalClaimsQuery = minimalClaimsQuery.replace('|placeholder|', queryArgs);

        setQueryStatus(QueryStatus.QUERYING);

        // get claim data
        customGraphRequest(
            minimalClaimsQuery,
            (data) => {

                let filters = defaultFilterValues;

                for (let filter of filters) {

                    filter['count'] = '-';


                    // TODO temporarily disabled PORTAL-733
                    // let statuses = data['statuses'];
                    // let status = filter['status'];
                    // if (status in statuses) {
                    //     filter['count'] = statuses[status]['count'];
                    // }
                }

                setClaims({
                    'claims': data['claims'],
                    'filters': filters
                });

                setQueryStatus(QueryStatus.QUERIED);
            },
            onError,
            resultsPerPage
        );
    };

    const onError = (error, callback = null) => {

        setAlertOptions({
                            'iconType': 'error',
                            'heading': error['type'],
                            'message': error['message'],
                            'onClose': callback
                        });
        setAlertOpen(true);
    };


    // onClick functions

    const onCreateClaim = (orgId, ownedById, insurerClaimNumber, insurerPolicyNumber, postalCode, locality, ccEmailSets, voucherClaim, buttonHandler) => {

        const isValidEmail = email => {
            const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,20}$/;
            return regex.test(email);
        };

        let clearToCreateClaim = true;

        // Validate ccEmailSets
        const validatedEmailSets = ccEmailSets.filter(set => {

            // Skip empty sets
            const empty = !set.firstName && !set.lastName && !set.email;
            if(empty) return false;

            const emailIsValid = isValidEmail(set.email);

            if(!emailIsValid){

                // Do not proceed to create the claim
                clearToCreateClaim = false;

                props.showAlertModal('info', 'Invalid CC Email',
                    <div>
                        <p>One of the CC Emails is invalid. Please ensure a valid email addresses is entered</p>

                        {/* DIVIDER */}
                        <div className="my-2 border-b border-gray-200"></div>

                        <p>First Name: {set.firstName}</p>
                        <p>Last Name: {set.lastName}</p>
                        <p className='text-red-500'>Email: {set.email}</p>
                    </div>);

                // Email set is invalid
                buttonHandler.onError('Invalid CC Email');
                return false;
            }

            // Email set is valid
            return true
        });

        if(!clearToCreateClaim)
            return;

        createClaim({
                organisationId:orgId,
                ownedById:ownedById,
                insurerClaimNumber:insurerClaimNumber,
                insurerPolicyNumber:insurerPolicyNumber,
                postalCode:postalCode,
                locality:locality,
                ccEmailSets:validatedEmailSets,
                voucherClaim:voucherClaim
            },
            (data) => {

                let claimId = data['claim']['id'];

                let url = `/search/${claimId}`;

                if(voucherClaim) url = `/voucher/${claimId}`;

                setCreateClaimOpen(false);
                setSuccessMessageOpen(true);
                // setTimeout(() => { window.location = url }, 1000);
                setTimeout(() => {
                    navigate(url);
                }, 500);
                buttonHandler.onSuccess('Claim created');
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
                buttonHandler.onError('Failed to create claim');
            }
        );
    };

    const onConfirmClicked = (options) => {

        let row = document.getElementById(options['claimId']);
        row.classList.add('bg-red-500');
        setTimeout(() => {
            row.classList.add('hidden');
        }, 300);

        deleteClaim(
            options['claimId'],
            (data) => {

                setNotificationRightMessage({
                                                'heading': 'Claim deleted',
                                                'message': 'Successfully deleted claim.'
                                            });
                setNotificationRightOpen(true);
                getClaims();

                setTimeout(() => {
                    setNotificationRightOpen(false);
                }, 1000);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };

    const promptDeleteClaim = (claimId, insurerClaimNumber) => {

        let message = `Please confirm deletion of the claim "${insurerClaimNumber}"`;
        let type = 'warning';
        let buttonText = 'Delete';

        setConfirmModalOptions({
                                   'heading': 'Delete claim',
                                   'message': message,
                                   'type': type,
                                   'buttonText': buttonText,
                                   'claimId': claimId
                               });

        setConfirmModalOpen(true);
    };

    const selectStatusFilter = (event, newStatus, queryClaims) => {

        let status = event?.currentTarget.id || newStatus;
        let query = searchParamsToDict(searchParams);

        if(!status) return;

        if (query === null) {
            query = {};
        }

        // To retrieve "all" claims, remove the status filter from the query
        if (status === 'all') {
            delete query['status'];
            setSearchParams(query);
        }

        // Include the filter in the next query
        else {
            query['status'] = status;
            setSearchParams(query);
        }

        if(queryClaims){
            getClaims(query)
        }

    };


    // HTML render functions

    const renderFilterButtons = () => {

        let query = searchParamsToDict(searchParams);

        let filters = defaultFilterValues;

        if (claims !== null) {
            filters = claims.filters;
        }

        if (query === null) {
            query = {'status': 'all'};
        }

        for (let filter of filters) {
            if (filter['id'] === query['status']) {
                filter['isSelected'] = true;
            }
            else {
                filter['isSelected'] = false;
            }
        }

        return (
            <ul className="flex justify-center gap-6 my-1">

                {filters.map((filter) => (

                    <li
                        key={filter.id}
                        id={filter.id}
                        onClick={(e) => selectStatusFilter(e, null, true)}
                        className={classNames(
                            'min-w-[8rem] flex shadow-sm rounded-md hover:shadow px-2',
                            'text-gray-800 text-sm',
                            'flex items-center justify-center gap-4',
                            filter.bgColorClass,
                            'hover:border-opacity-100 transition-all',
                            filter.isSelected ? 'shadow-lg border-opacity-100 !bg-white' : ''
                        )}>

                        {/* TODO temporarily disabled PORTAL-733 */}
                        {/* COUNT */}
                        {/*<div>*/}
                        {/*    {filter.count}*/}
                        {/*</div>*/}

                        {/*<p>|</p>*/}


                        {/* TITLE */}
                        <div>
                            {filter.title}
                        </div>
                    </li>

                ))}

            </ul>
        );
    };

    const renderClaimsTableHeader = () => {

        return (
            <thead>
            <tr>
                <th className="">
                    Claim Number
                </th>
                <th className="">
                    Owned By
                </th>
                <th className="text-center">
                    Status
                </th>
                <th className="text-center">
                    Status Reason
                </th>
                <th className="">
                    Items
                </th>

                {/* SLA */}
                <th className="text-center"
                    onClick={() => {

                        // reset the other column ordering
                        setTableOrdering_DateCreated('')

                        if (tableOrdering_DateExpirySLA === '') {
                            setTableOrdering_DateExpirySLA('ASC')

                            // When SLA is ACS, set the status filter to pending
                            selectStatusFilter(null, 'pending', false)
                        } else if (tableOrdering_DateExpirySLA === 'ASC') {
                            setTableOrdering_DateExpirySLA('DESC')

                            selectStatusFilter(null, 'pending', false)


                            // If no status was selected by the user (still pending from above code), then remove the pending filter
                            // if (searchParamsToDict(searchParams)?.status === 'pending')
                            //     selectStatusFilter(null, 'all', false)

                        } else if (tableOrdering_DateExpirySLA === 'DESC') {
                            setTableOrdering_DateExpirySLA('')

                            // clear the status filter (presuming it was set to pending with above code)
                            selectStatusFilter(null, 'all', false)
                        }

                    }}
                >
                    <Tooltip content={(<div><p>Click to order</p><p>All times displayed are in your browser's local time</p></div>)}>
                        <p className='flex justify-center gap-2'>
                            SLA

                            {/* > or > or nothing depending on if this column is ordered */}
                            {tableOrdering_DateExpirySLA === 'ASC' ?
                                <ChevronUpIcon className='w-5'/> :
                                tableOrdering_DateExpirySLA === 'DESC' ?
                                    <ChevronDownIcon className='w-5'/> :
                                    <MinusSmIcon className='w-5'/>
                            }
                        </p>
                    </Tooltip>

                </th>

                {/* DATE CREATED */}
                <th className=""
                    onClick={() => {

                        // reset the other column ordering
                        setTableOrdering_DateExpirySLA('')

                        if (tableOrdering_DateCreated === '') {
                            setTableOrdering_DateCreated('ASC')
                        } else if (tableOrdering_DateCreated === 'ASC') {
                            setTableOrdering_DateCreated('DESC')
                        } else if (tableOrdering_DateCreated === 'DESC') {
                            setTableOrdering_DateCreated('')
                        }

                    }}
                >
                    <Tooltip
                        content={(<div><p>Click to order</p><p>All times displayed are in your browser's local time</p></div>)}
                        className="font-normal"
                    >
                        <div className='flex justify-center'>
                            Date Created
                            &nbsp;
                            {/* > or > or nothing depending on if this column is ordered */}
                            {tableOrdering_DateCreated === 'ASC' ?
                                <ChevronUpIcon className='w-5'/> :
                                tableOrdering_DateCreated === 'DESC' ?
                                    <ChevronDownIcon className='w-5'/> :
                                    <MinusSmIcon className='w-5'/>
                            }
                        </div>
                    </Tooltip>

                </th>

                {/* ACTIONS */}
                <th className="text-end">
                    Actions
                </th>

            </tr>
            </thead>
        );
    };

    const renderClaimsTableRows = () => {

        if (claims === null) {
            return (
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-[10rem]'>
                        <LoadingSpinner body='' size='14'/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            );
        }

        return (
            <tbody className="bg-white divide-y divide-gray-200">
            {claims.claims.map(renderSingleClaim)}
            </tbody>
        );
    };

    const renderSingleClaim = (claim) => {

        let claimUrl = '#';

        if (claim.status === 'NEW') {
            claimUrl = '/search/' + claim.id;
        } else {
            claimUrl = '/profile/' + claim.id;
        }

        // Calculate the remaining time, and return the time string, and the total minutes
        let [_, remainingTimeTotalMinutes] = calculateRemainingSLATime(claim.date_sla_expiry);

        function dateCreated_HumanReadable() {

            const localDateCreatedMoment = localMomentInstanceFromUTC(claim.date_created);
            const localNowMoment = moment();

            if (localDateCreatedMoment.isSame(localNowMoment, 'day')) {
                return `Today, ${localDateCreatedMoment.format('HH:mm z')}`;
            } else if (localDateCreatedMoment.isSame(moment().subtract(1, 'days'), 'day')) {
                return `Yesterday, ${localDateCreatedMoment.format('HH:mm z')}`;
            } else if (localDateCreatedMoment.isSame(moment().add(1, 'days'), 'day')) {
                return `Tomorrow, ${localDateCreatedMoment.format('HH:mm z')}`;
            } else {
                return localDateCreatedMoment.format(HUMAN_READABLE_DATETIME_TIMEZONE);
            }
        }


        return (
            <tr id={claim.id} key={claim.id}
                className={classNames(
                    'group',
                    claim.status === 'QUANTIFICATION_IN_PROGRESS' && remainingTimeTotalMinutes > 0 && remainingTimeTotalMinutes <= 15 ? 'bg-red-200' : '',
                    claim.status === 'QUANTIFICATION_IN_PROGRESS' && remainingTimeTotalMinutes > 15 && remainingTimeTotalMinutes <= 30 ? 'bg-orange-100' : '',
                )}
                onClick={(e) => {

                    // Check if any text is selected
                    const selection = window.getSelection().toString();
                    if (selection.length > 0) {
                      // If text is selected, do not proceed with the click action
                      console.log('Text is selected, click action cancelled.');
                      e.stopPropagation();
                      return;
                    }

                    navigate(claimUrl)
                }}
            >

                {/* NAME */}
                <td
                    className="cursor-pointer text-left"
                >

                    <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                            <BasicImage
                                src={claim.organisation.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="2.6"
                                className="rounded-full"
                            />

                        </div>
                        <div className="ml-4">
                            <div
                                className="pb-1 flex text-sm font-medium hover:text-sky-600">

                                {/* CLAIM NAME */}
                                <p>{claim.insurer_claim_number}</p>

                                {/* COPY ICON */}
                                <div
                                    className="tailwind-tooltip-container transition-all opacity-0 group-hover:opacity-100 overflow-hidden"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(claim.insurer_claim_number);
                                        props.showToastNotificationModal(
                                            'success',
                                            'Claim Copied',
                                            ''
                                        );
                                    }}>
                                    <ClipboardCopyIcon
                                        className="ml-2 h-6 w-6 inline transition-all opacity-0 group-hover:opacity-100 text-gray-400 hover:text-sky-600"/>
                                    <span className="tailwind-tooltip -left-[200%] -top-[200%]">Click to copy the claim name</span>
                                </div>

                            </div>
                            <div className="text-sm text-gray-400">
                                <p>{claim.organisation.info.name}</p>
                            </div>
                        </div>
                    </div>

                </td>

                {/* OWNED BY */}
                <td className="cursor-pointer px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                    {`${claim.owned_by.info.first_name} ${claim.owned_by.info.last_name}`}
                </td>

                {/* STATUS */}
                <td className={classNames(
                    "cursor-pointer text-center whitespace-nowrap",
                )}>

                    <div className='flex justify-center items-center capitalize p-0 m-0'>

                        {/* STATUS */}
                        <div className={classNames(
                                statusStyles[claim.status],
                                'rounded-lg transition-all w-[95%] text-xs tracking-tighter',
                                'group-hover:shadow-lg group-hover:rounded-md',
                                'py-1 px-1'
                        )}>
                            {claim.status === 'QUANTIFICATION_IN_PROGRESS' ?
                                'Pending'
                                :
                                claim.status.replaceAll('_', ' ').toLowerCase()
                            }
                        </div>

                    </div>


                </td>

                {/* STATUS REASON */}
                <td className={classNames(
                    'cursor-pointer text-md text-center transition tailwind-tooltip-container',
                        ['tendering', 'post tender inspection', 'verification'].includes(claim.status_reason?.toLowerCase()) ? 'hover:bg-slate-200/50' : ''
                    )}
                >

                    {!['tendering', 'post tender inspection', 'verification'].includes(claim.status_reason?.toLowerCase()) ?

                        <p className=''>{claim.status_reason}</p>

                        :

                        <>
                            <a className="h-full min-h-[4rem]"
                               target="_blank"
                               // href={claimUrl} // default URL (claim profile) should the onClick fail
                               onClick={(e) => {
                                   e.preventDefault(); // Prevent default anchor action
                                    e.stopPropagation(); // Stop event from bubbling up

                                   // If the claim is quantification in progress, then navigate to the tender page
                                   if (claim.status_reason.toLowerCase() === 'tendering') {
                                       window.open(`/tender/${claim.id}`, '_blank');
                                   } else if (claim.status_reason.toLowerCase() === 'post tender inspection') {
                                       window.open(`/post/${claim.id}`, '_blank');
                                   } else if (claim.status_reason.toLowerCase() === 'verification') {
                                       window.open(`/claim/verification/${claim.id}`, '_blank');
                                   } else {
                                       window.open(claimUrl, '_blank');
                                   }
                               }}>
                                <div className='!min-h-[5.4rem] flex flex-col justify-center'>

                                    {/* STATUS_REASON */}
                                    <p className=''>{claim.status_reason}</p>

                                    {/* EXTERNAL LINK ICON */}
                                    <div
                                        className="transition-all h-1 group-hover:h-6 opacity-0 group-hover:opacity-100 overflow-hidden"
                                        >
                                        <ExternalLinkIcon
                                            className="h-7 w-7 inline transition-all opacity-0 group-hover:opacity-60 text-orange-600"/>
                                    </div>

                                </div>

                            </a>
                            <span className="tailwind-tooltip -left-[20%] -top-[3.5rem]"> Click to view this claim in a new window </span>
                        </>
                    }

                </td>

                {/* ITEMS */}
                <td className="py-4 text-sm text-center">
                    {claim.item_count}
                </td>

                {/* SLA */}
                <td className="py-4 text-sm text-center px-4">
                    {tableRowSLA(claim, remainingTimeTotalMinutes)}
                </td>

                {/* DATE CREATED */}
                <td className="py-4 text-sm text-center pl-4 pr-6">
                    <p className='pb-1'>{dateCreated_HumanReadable()}</p>
                </td>

                {/* ACTIONS */}
                <td className="">
                <div className="flex gap-2 justify-end pr-2">

                        {/* EDIT (external window) */}
                        <a className="flex items-center justify-center tailwind-tooltip-container py-4 "
                           target="_blank"
                           href={claimUrl}
                           onClick={(e) => e.stopPropagation()}
                        >
                            <ExternalLinkIcon className="h-7 w-7 text-sky-600 hover:text-sky-500" aria-hidden="true"/>
                            <span className="tailwind-tooltip -left-[200%]">
                                Click to view this claim <br></br> in a new window
                            </span>
                        </a>

                        {/* ISSUE VOUCHER */}
                        <a
                            href={`/voucher/${claim.id}`}
                            className={classNames(
                                'tailwind-tooltip-container inline-flex items-center text-sm font-medium rounded-md',
                                claim.status !== 'DELETED' ? 'text-sky-600 hover:text-sky-500' : 'pointer-events-none text-gray-400',
                                props.mainOrganisation?.type === 'SUPPLIER' ? 'hidden' : '' // Hide this button for suppliers
                            )}
                           onClick={(e) => e.stopPropagation()}
                        >
                            <CreditCardIcon className="h-7 w-7" aria-hidden="true"/>
                            <span className="tailwind-tooltip -left-[200%]">Issue Voucher</span>
                        </a>
                        <button
                            className={classNames(
                                'tooltip-container inline-flex items-center text-sm font-medium rounded-md',
                                claim.status !== 'DELETED' ? 'text-red-600 hover:text-red-400' : 'pointer-events-none text-gray-400',
                                props.mainOrganisation && 'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) ? '' : 'hidden' // Hide this button for non-admins
                            )}
                           onClick={(e) => {
                               e.stopPropagation()
                               promptDeleteClaim(claim.id, claim.insurer_claim_number)
                           }}
                        >
                            <TrashIcon className="mr-1 h-7 w-7" aria-hidden="true"/>
                            <span className="tooltip">Delete</span>
                        </button>
                    </div>
                </td>

            </tr>

        );
    };


    function tableRowSLA(claim, remainingTimeTotalMinutes){
        /** SLA
         * If quantification in progress AND not expired, show the remaining time and countdown
         * If quantification in progress AND expired, show nothing
         * If quantified, show the TAT
         * */

        function formatDuration(totalMinutes) {
            // duration in minutes, return a human readable string

            if (totalMinutes === 0) {
                return "Auto";
            }

            // Calculate the components
            const minutesPerDay = 60 * 24;
            const days = Math.floor(totalMinutes / minutesPerDay);
            const hours = Math.floor((totalMinutes % minutesPerDay) / 60);
            const minutes = totalMinutes % 60;

            // Build the result string
            let result = "";

            if (days > 0) {
                result += `${days} day${days > 1 ? 's' : ''} `;
            }

            if (hours > 0) {
                result += `${hours} hour${hours !== 1 ? 's' : ''} `;
            }

            if (minutes > 0 || (minutes === 0 && hours === 0 && days === 0)) {
                result += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
            }

            return result.trim();
        }

        function SLAHumanReadable(timestamp) {

            const target = localMomentInstanceFromUTC(timestamp)
            const now = moment();

            if (target.isSame(now, 'day')) {
                return `Today, ${target.format('HH:mm z')}`;
            } else if (target.isSame(moment().subtract(1, 'days'), 'day')) {
                return `Yesterday, ${target.format('HH:mm z')}`;
            } else if (target.isSame(moment().add(1, 'days'), 'day')) {
                return `Tomorrow, ${target.format('HH:mm z')}`;
            } else {
                return target.format(HUMAN_READABLE_DATETIME_TIMEZONE); // Localized format; adjust as needed
            }
        }

        if(claim.status === "QUANTIFIED"){
            return (
                <div className="text-sm text-gray-500">
                    <p className='text-gray-300'>TAT</p>
                    <p className="ml-1">{formatDuration(claim.tat_minutes)}</p>
                </div>
            )
        }

        const expired = remainingTimeTotalMinutes < 0;

        if(claim.status === "QUANTIFICATION_IN_PROGRESS" &&
            !expired &&
            claim?.status_reason?.toLowerCase() !== 'verification') {

            return (
                <>
                    <div className={classNames(
                        remainingTimeTotalMinutes < 15 ? 'text-red-600' : '',
                    )}>
                        {claim.status === "QUANTIFICATION_IN_PROGRESS" &&
                            <Countdown_ClaimSLA date_sla_expiry={claim.date_sla_expiry}/>
                        }
                    </div>

                    <div className="text-sm text-gray-500">
                        <p className="ml-1">{SLAHumanReadable(claim.date_sla_expiry)}</p>
                    </div>
                </>
            )
        }

        return <></>

    }

    const renderSmallPagination = () => {

        if (claims === null) {
            return (
                <div
                    className="w-full"
                >
                    <LoadingSpinner body=''/>
                </div>
            );
        }

        if (isEmpty(claims)) {
            return (
                <nav
                    className="bg-white px-3 py-3 flex items-center justify-between border-t border-gray-100 sm:px-3"
                    aria-label="Pagination"
                >
                    <div>No claims found.</div>
                </nav>
            );
        }

        const paginationLower = () => {
            return resultsPerPage * (pageNumber - 1);
        };
        const paginationUpper = () => {
            // on the final/last page, return this
            if (claims.claims.length < resultsPerPage) {
                let upperBound = resultsPerPage * pageNumber; // e.g 25 * 2 = 50
                let difference = resultsPerPage - claims.claims.length; // example: 25 - 15 = 10
                return upperBound - difference; // example: return 50 - 10 = 40 (40 total claims)
            }

            return resultsPerPage * (pageNumber);
        };

        return (
            <nav
                className="flex items-center justify-around w-fit-content table-header-cell gap-4"
            >
                {/* TEXT */}
                <div className='mx-2 my-1 py-3'>
                    Showing claims <span>{paginationLower() + 1}</span>-<span>{paginationUpper()}</span>
                </div>

                {/* PREVIOUS */}
                <button
                    onClick={() => nextClaimPagination(true)}
                    className={classNames('',
                        pageNumber < 2 ? 'btn-disabled' : 'btn-light')}
                    disabled={pageNumber < 2}
                >
                    Previous
                </button>

                {/* NEXT */}
                <button
                    onClick={() => nextClaimPagination(false)}
                    className={classNames('',
                        claims.claims.length === 0 ? 'btn-disabled' : 'btn-light')}
                    disabled={claims.claims.length === 0}
                >
                    Next
                </button>

            </nav>
        );
    };

    const nextClaimPagination = (moveToPreviousPage = false) => {

        let pageNum = pageNumber;

        if (moveToPreviousPage) {
            if (pageNumber > 1) {
                pageNum -= 1;
            }
        } else if (claims.claims.length !== 0) { // do not continue paging if the previous page is not 'full' aka resultsPerPage
            pageNum += 1;
        }

        setPageNumber(pageNum);

        let query = searchParamsToDict(searchParams);
        if (query === null) {
            query = {};
        }
        query.page_number = pageNum;
        query.result_limit = resultsPerPage;
        getClaims(query);

    };

    const claimsSearchForm = () => {

        return (
            <>

                {/* INPUT */}
                <input
                    id="claimSearch"
                    name="claimSearch"
                    className={`input h-14 text-lg pl-12 rounded-md border border-0 shadow-md pl-6`}
                    placeholder="Search claims"
                />


                {/* LOADING */}
                <div
                    className={classNames(
                        'absolute top-0 bottom-0 right-[8rem] h-full w-[5rem] transition hover:bg-sky-400 cursor-pointer tailwind-tooltip-container group',
                        queryStatus === QueryStatus.QUERYING ? 'visible' : 'hidden',
                    )}
                    onClick={onSearchInput}>
                    <LoadingSpinner size="8" color='#0284c7' body=' '/>
                    <span className="tailwind-tooltip top-[3rem]">
                        Loading claims, please wait
                    </span>
                </div>

                {/* SEARCH */}
                <div
                    className="absolute top-0 bottom-0 right-[4rem] h-full w-[4rem] transition hover:bg-sky-400 cursor-pointer tailwind-tooltip-container group"
                    onClick={onSearchInput}>
                    <div className="flex h-full items-center justify-center opacity-1 ">
                        <SearchIcon className="h-5 w-5 group-hover:text-white" aria-hidden="true"/>
                    </div>
                    <span className="tailwind-tooltip top-[3rem]">
                        <p>Search claims</p>
                    </span>
                </div>

                {/* CLEAR FILTERS X */}
                <div
                    className="absolute top-0 bottom-0 right-[0rem] h-full w-[4rem] px-2 transition hover:bg-red-400 cursor-pointer tailwind-tooltip-container"
                    onClick={clearSearchInput}>
                    <div className="flex h-full items-center justify-center opacity-1 ">
                        <svg xmlns="http://www.w3.org/2000/svg" stroke="gray"
                             className="h-full w-8 px-1 stroke-gray-400 hover:stroke-white" fill="none"
                             viewBox="0 0 24 24" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </div>
                    <span className="tailwind-tooltip top-[3rem]">
                      Clear search
                    </span>
                </div>

            </>
        );
    };

    return (
        <>

            <PageInfoModal
                pageInfoModalOpen={props.pageInfoModalOpen}
                setPageInfoModalOpen={props.setPageInfoModalOpen}
                heading="Claim Home"
                body={<ul className="list-disc space-y-3">
                    <li>In this page, you can view all the claims for this organisation and all its connected
                        organisations.
                    </li>
                    <li>Filter down by STATUS - All, New, Pending, Quantified, Deleted</li>
                    <li>Only 25 claims are displayed at a time, so please page through them via the Previous and Next
                        pagination buttons
                    </li>
                    <li>Claims can be created, edited and deleted in this page</li>
                </ul>}
            />

            <CreateClaimModal
                open={createClaimOpen}
                setOpen={setCreateClaimOpen}
                onCreateClaim={onCreateClaim}
                {...props}
            />

            {/* TODO : change usage to base modal */}
            <NotificationCenter
                open={successMessageOpen}
                message={claimSuccessMessage}
            />
            {/* TODO : change usage to base modal */}
            <NotificationTopRight
                open={notificationRightOpen}
                setOpen={setNotificationRightOpen}
                message={notificationRightMessage}
            />
            {/* TODO : change usage to base modal */}
            <AlertModal
                open={alertOpen}
                setOpen={setAlertOpen}
                options={alertOptions}
            />
            {/* TODO : change usage to base modal */}
            <ConfirmModal
                open={confirmModalOpen}
                setOpen={setConfirmModalOpen}
                options={confirmModalOptions}
                onConfirmClicked={onConfirmClicked}
            />


            <div className="w-full h-full body">


                <ClaimInfoModal open={claimInfoOpen} setOpen={setClaimInfoOpen}/>

                <div className="flex-1 pb-8">

                    <div className='page-header-bar items-center'>
                        <h1 className="page-header-title">Claims </h1>

                        <button
                            id="createClaimButton"
                            onClick={() => setCreateClaimOpen(true)}
                            type="button"
                            className={classNames(
                                'btn m-0 ml-6 p-3',
                                props.mainOrganisation?.type === 'SUPPLIER' ? 'hidden' : '' // Hide this button for suppliers
                            )}

                        >
                            <PlusCircleIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                            Create claim
                        </button>

                    </div>

                    {/* CLAIMS */}
                    <div className="widget flex flex-col gap-6 mt-2 ">

                        <div
                            className="relative w-full flex w-full text-gray-400 h-14"
                        >
                            {claimsSearchForm()}
                        </div>

                        {/* HEADER */}
                        <div className='flex my-3 justify-between'>
                            {safe(renderFilterButtons)}
                            {safe(renderSmallPagination)}
                        </div>

                        {/* CLAIMS */}
                        <table className="">

                            {/* HEADER */}
                            {safe(renderClaimsTableHeader)}

                            {/* BODY */}
                            {safe(renderClaimsTableRows)}

                        </table>

                    </div>

                </div>

            </div>
        </>
    );

}

// Countdown component that takes the expiry date as prop
export const Countdown_ClaimSLA = ({ date_sla_expiry }) => {

    const [remainingTimeString, setRemainingTimeString] = useState('');
    const [remainingTimeTotalMinutes, setRemainingTimeTotalMinutes] = useState(0);

    const intervalFlicker = useRef(false);

    useEffect(() => {
        const updateRemainingTime = () => {

            const localTimeStamp = localMomentInstanceFromUTC(date_sla_expiry)
            let [newRemainingTimeString, newRemainingTimeTotalMinutes] = calculateRemainingSLATime(localTimeStamp);

            // Remove the seconds ":00" from the tail of the time string
            let removedSeconds = newRemainingTimeString.replace(/:00$/, '');

            if(intervalFlicker.current) removedSeconds = removedSeconds.replaceAll(':', ' ');

            setRemainingTimeString(removedSeconds);
            setRemainingTimeTotalMinutes(newRemainingTimeTotalMinutes);
            intervalFlicker.current = !intervalFlicker.current // flip the boolean
        };

        updateRemainingTime();
        const intervalId = setInterval(updateRemainingTime, 1000);

        return () => clearInterval(intervalId);
    }, [date_sla_expiry]);

    return (
        <p className={`${remainingTimeTotalMinutes < 15 ? 'text-red-600' : ''}`}>
            {remainingTimeString}
        </p>
    );
};


export function calculateRemainingSLATime(isoDateString) {
    // utcTime: 2021-05-28T13:30:00Z
    // returns: 00:00:00, or ''(if the time has passed)

    if(!isoDateString) return ["", null];

    const now = moment();
    let expiryDate = moment.utc(isoDateString).tz(moment.tz.guess());
    const difference = expiryDate.diff(now);

    // If the difference is negative, the time has already passed
    if (difference <= 0) {
        return ["", null];
    }

    let seconds = Math.floor(difference / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    // seconds %= 60;
    minutes %= 60;

    // Remove seconds - not needed and distracting flicking on the dashboard
    seconds = 0;

    // Convert days to hours and add to the total hours
    let days = Math.floor(hours / 24);
    hours = (days * 24) + (hours % 24); // Combine days into hours

    let differenceAsMinutes = Math.floor(difference / 1000 / 60); // 1000 -> microseconds to second / 60 -> seconds to minutes

    return [`${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`, differenceAsMinutes];
}

function padZero(number) {
    return number.toString().padStart(2, '0');
}
