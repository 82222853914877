import React, {useRef} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {customGraphRequest, queryCurrentUser} from "./utils/coreApi";

export default function PrivateRoutes (props) {

    const navigate = useNavigate();
    const hasCheckedCurrentUser = useRef("UNAUTHENTICATED");

    function confirmAuthentication() {
        /** When a reload is performed, the autheticated state is lost.
         * In order to re-set the authenticated state, we make a single queryCurrentUser query
         * If the response is 401 error, then this user is not authenticated
         * Else set the authenticated state to true */

        // Make a queryCurrentUser query
        // If the user is returned, set props.setAuthenticated(true)
        // If a 401, then set props.setAuthenticated(false) and redirect to login

        if(props.user){
            hasCheckedCurrentUser.current = "AUTHENTICATED"
            return
        }

        if(hasCheckedCurrentUser.current === "AUTHENTICATED" || hasCheckedCurrentUser.current === "INPROGRESS" ){
            return
        }

        hasCheckedCurrentUser.current = "INPROGRESS"

        const query = ` query PrivateRoutes_MinimalQuery {
          current_user {
            error {
              type
              message
            }
            user {
              id
              username
            }
          }
        }
        `

        customGraphRequest(
            query,
            (data) => {
                hasCheckedCurrentUser.current = "AUTHENTICATED"
            },
            (error) => {
                hasCheckedCurrentUser.current = "FAILED"
                navigate('/logout')
            }
        );

    }

    if(props.user){
        return <Outlet/>
    }
    else{
        confirmAuthentication();
        return <Outlet/>;
    }

}

