import Tooltip from "../../components/Tooltip";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import React from "react";
import ApiButton from "../../components/base/ApiButton";

export default function DevelopmentComponentDemo(props) {


    function modals() {
        return (
            <div className="flex flex-col justify-center items-center gap-5">


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showConfirmModal('success', 'type: success', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                    <button className="btn"
                            onClick={() => {
                                props.showConfirmModal('convert', 'type: convert', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                    <button className="btn-danger"
                            onClick={() => {
                                props.showConfirmModal('warning', 'type: warning', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showAlertModal('success', 'type: success', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                    <button className="btn-light"
                            onClick={() => {
                                props.showAlertModal('info', 'type: info', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                    <button className="btn-danger"
                            onClick={() => {
                                props.showAlertModal('error', 'type: error', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showNotificationModal('success', 'type: success', 'test', 'test', (options) => alert('onClose()'));
                            }}
                    >
                        Show Notification modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showToastNotificationModal('success', 'type: success', 'test', 2000, (options) => alert('onClose'));
                            }}
                    >
                        Show Toast notification
                    </button>
                </div>

                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {

                                let content = (
                                    <div className="w-[40rem] h-[40rem] flex flex-col justify-center items-center">
                                        <p>Custom content modal body</p>
                                    </div>
                                );

                                props.showCustomModal(
                                    content,
                                    (options) => alert('onClose')
                                );
                            }}
                    >
                        Show Custom Modal
                    </button>
                </div>


            </div>
        );
    }

    function spinner(){
        return (
            <div>
                <div className='flex flex-col justify-center gap-5'>
                    <LoadingSpinner/>
                    <LoadingSpinner color='darkcyan' body='Retrieving products' text='text-md'/>
                    <LoadingSpinner body='Loading' />
                </div>
            </div>
        );
    }

    function apiButton(){

        function test(btnHandler) {

        }

        return (
            <div className='flex gap-10'>
                <ApiButton
                    onClick={test}
                    content='default'
                />
                <ApiButton
                    onClick={test}
                    className={'btn m-0 w-fit flex justify-center'}
                    content='Login'
                    loadingContent={<LoadingSpinner color='darkcyan' size='5' body='Authenticating'
                                                    text='text-md !text-white'/>}
                    successContent='Credentials accepted'
                    errorContent='Incorrect username or password'
                />

            </div>

        );
    }

    return (<div className="w-full h-full flex flex-col align-center justify-center gap-[10rem]">

        <div className='flex justify-around gap-5 outline outline-2 outline-gray-200'>
            {modals()}

        </div>

        <div className='flex justify-around gap-5 outline outline-2 outline-gray-200'>

            {spinner()}

        </div>

        <div className='flex justify-around gap-5 outline outline-2 outline-gray-200'>

            {apiButton()}

        </div>


    </div>);

}