import React from 'react';

function LimitedEnvironmentAccess(props) {
    /** Render a component based on the environment
     * This is a handy component to show or hide components based on the environment
     * e.g: show a component only in the localhost environment
     * */

    const isLocalEnv = window.location.hostname === "localhost" || false;
    const isDevEnv = window.location.hostname.includes("portal-dev.slvrcld") || false;
    const isProdEnv = window.location.hostname.includes("portal.slvrcld") || false;

    const isValidEnvironment = () => {

        const local = props.local || false;
        const dev = props.dev || props.development || false;
        const prod = props.prod || props.production || false;

        if(props.all) return true;

        if(local === true && isLocalEnv === true) return true;

        if(dev === true && isDevEnv === true) return true;

        if(prod === true && isProdEnv === true) return true;

        return false;

    };

    return (
        isValidEnvironment() ?
            props.children
            :
            <></>
    );
}

export default LimitedEnvironmentAccess;

/** EXAMPLE

<LimitedEnvironmentAccess
    local={true}
    dev={true} // or development={true}
    prod={true} // or production={true}
    all={true}
>
    //... your component
</LimitedEnvironmentAccess>

 */
