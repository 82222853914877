import React, {useEffect, useState} from "react";
import {BasicImage} from "../../../components/base/BasicImage";
import {SimpleSelect} from "../../../components/base/SimpleSelect";
import {queryAccounts} from "../../../utils/coreApi";
import {consoleColors, isEmpty} from "../../../utils/helpers";


export function orderOrganisationsAlphabetically(orgs, reverse = false) {
    /* Given a list of Organisation objects, order the list according to the organisation.info.name field */

    // If the list is empty, skip operations and just return it
    if (orgs.length === 0) return orgs;

    return orgs.sort((a, b) => {
        const nameA = a.info.name.toUpperCase();
        const nameB = b.info.name.toUpperCase();

        let comparison = 0;
        if (nameA < nameB) {
            comparison = -1;
        } else if (nameA > nameB) {
            comparison = 1;
        }

        // If reverse is true, reverse the sorting order
        return reverse ? comparison * -1 : comparison;
    });
}

export function AdminOrgSelection(props) {

    const [selectableOrgs, setSelectableOrgs] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        determineSelectableOrgs();
    }, [props.accounts]);

    // Function to determine selectable organisations
    const determineSelectableOrgs = () => {
        // If accounts data is not available, return
        if (!props.accounts) return;

        // Filter admin organisations from the accounts data
        const adminOrganisations = props.accounts.reduce((acc, account) => {
            if (account.organisation_1.type === 'ADMINISTRATOR') {
                acc.push(account.organisation_1);
            } else if (account.organisation_2.type === 'ADMINISTRATOR') {
                acc.push(account.organisation_2);
            }
            return acc;
        }, []);

        // Using a Set for efficient duplicate removal based on organisation ID
        const uniqueOrgIds = new Set();
        let uniqueAdminOrganisations = adminOrganisations.filter(org => {
            if (org.id && !uniqueOrgIds.has(org.id)) {
                uniqueOrgIds.add(org.id);
                return true;
            }
            return false;
        });

        // Order the admin organisations alphabetically
        uniqueAdminOrganisations = orderOrganisationsAlphabetically(uniqueAdminOrganisations);

        // If no organisation is selected and there are admin organisations available, select the first one
        if (!selected && uniqueAdminOrganisations.length > 0) onChange(uniqueAdminOrganisations[0]);

        // Set the selectable organisations
        setSelectableOrgs(uniqueAdminOrganisations);
    }


    function onChange(org) {
        setSelected(org)
        props.onChange(org)
    }

    return (
        <div className='mt-4'>
            <SimpleSelect
                width='w-full'
                selectedState={selected}
                selectableOptions={selectableOrgs}
                onChange={onChange}
                renderOption={(org) =>
                    <div className='flex gap-2'>
                        <BasicImage
                            src={org?.info.logo_image_url}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="1.2"
                            className="rounded-full"
                        />
                        <span>{org?.info.name || ''}</span>
                    </div>
                }

                /* TODO REMOVE THIS use {active}*/
                renderSelected={(org) =>
                    org ?
                        <div className='flex gap-2'>
                            <BasicImage
                                src={org?.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="1.2"
                                className="rounded-full"
                            />
                            <span>{org?.info.name || ''}</span>
                        </div> : '-'
                }
            />
        </div>

    );

}


export function AdminInsurerSelection(props) {
    /** Requires accounts data
     * To determine primary and sub organisations
     * */

    const [selectablePrimaryOrgs, setSelectablePrimaryOrgs] = useState([]);
    const [selectableSubOrgs, setSelectableSubOrgs] = useState([]);

    const [selectedPrimaryOrganisation, setSelectedPrimaryOrganisation] = useState(null);
    const [selectedSubOrganisation, setSelectedSubOrganisation] = useState(null);


    useEffect(() => {
        /** Primary organisations */
        updatePrimaryOrganisations();
    }, [props.selectedCountry, props.organisations, props.mainOrganisation, props.accounts]);

    useEffect(() => {
        /** Sub organisations
         * when the selected primary organisation has changed, query for any sub organisations */
        updateSubOrganisations();
    }, [selectedPrimaryOrganisation]);

    function updatePrimaryOrganisations() {

        if (!props.organisations) return;
        if (!props.accounts) return;

        let primarySubResultsLogs = [];

        // we only want to display organisations from the selected country
        let organisationsForSelectedCountry = props.organisations.filter(org => org.info.country === props.selectedCountry.code);

        // only INSURER organisations must display in the CreateClaimModal
        let insurerTypeOrganisations = organisationsForSelectedCountry.filter(org => org.type === 'INSURER' || org.type === 'ADMINISTRATOR');

        // we only care about INSURER_INSURER accounts in order to determine what organisations are primary and what are sub
        let insurerInsurerAccounts = props.accounts.filter(account => account.type === 'INSURER_INSURER' && account.status === 'ACTIVE');

        // add all the accounts in the 'sub_accounts' fields
        props.accounts.forEach(account => {
            // Accounts & Primary/Sub organisations : https://silvercloud.atlassian.net/wiki/spaces/POR/pages/1104084993/Accounts+Primary+Sub+organisations
            let accountsForOrg2 = account.sub_accounts; // the sub_accounts field is the 'accounts' query for the organisation_2

            // Same filtering logic as above
            let _insurerInsurerAccounts = accountsForOrg2.filter(account => account.type === 'INSURER_INSURER' && account.status === 'ACTIVE');

            insurerInsurerAccounts = insurerInsurerAccounts.concat(_insurerInsurerAccounts);
        });

        // TODO do not include deactivate accounts

        let _primaryOrganisations = [];
        for (const org of insurerTypeOrganisations) {

            // if this organisation exists in an account, where it is organisation_2, then this organisation is a sub org, not a primary org
            let thisIsASubOrganisation = insurerInsurerAccounts.find(account => account.organisation_2.id === org.id);

            // If this organisation is type INSURER, and is only organisation_1 in all accounts, then it is a Primary organisation
            if (!thisIsASubOrganisation) _primaryOrganisations.push(org);

            const isPrimary = isEmpty(thisIsASubOrganisation);
            primarySubResultsLogs.push(logPrimarySubOrganisation(org, isPrimary, thisIsASubOrganisation));

        }

        // Log the results
        console.log(`\n\n${consoleColors.bold('PRIMARY/SUB ORGANISATION RESULTS')}`)
        primarySubResultsLogs.forEach(log => console.log(log));

        _primaryOrganisations = orderOrganisationsAlphabetically(_primaryOrganisations);

        if (isEmpty(_primaryOrganisations)) {
            // if there are no primary organisations - then set the mainOrganisation as the single primary organisation
            // this is the case for e.g insurer agents
            // but only set the mainOrganisation, if it is type INSURER. this is the case for admin organisations
            if (props.mainOrganisation.type === 'INSURER') {
                setSelectablePrimaryOrgs([props.mainOrganisation]);
                setSelectedPrimaryOrganisation(props.mainOrganisation);
            } else {
                setSelectablePrimaryOrgs(null);
                setSelectedPrimaryOrganisation(null);
            }


        } else {
            // else set the list of selectable organisations
            setSelectablePrimaryOrgs(_primaryOrganisations);

            // automatically select the first primary organisation
            if (!selectedPrimaryOrganisation && _primaryOrganisations.length > 0) {
                const firstPrimaryOrg = _primaryOrganisations[0];
                setSelectedPrimaryOrganisation(firstPrimaryOrg);
                props.onChange(firstPrimaryOrg)
            }

        }
    }

    function updateSubOrganisations() {

        setSelectedSubOrganisation(null);
        setSelectableSubOrgs([]);

        if (!selectedPrimaryOrganisation) return;

        // query all accounts for the selected primary organisation
        queryAccounts(selectedPrimaryOrganisation.id, 'SELF, ADMINISTRATOR_INSURER, INSURER_INSURER',
            (data) => {

                console.log(`\n\n${consoleColors.bold('CALCULATING SUB ORGANISATIONS')}`)
                console.log(`${consoleColors.bold('FOR PRIMARY ORGANISATION:')} ${selectedPrimaryOrganisation.unique_reference_name}`)

                // only show organisations where the 'primaryOrganisation' is organisation_1
                let accountsForPrimaryOrganisation = data['accounts'].filter(account => account.organisation_1.id === selectedPrimaryOrganisation.id);
                let subOrganisations = accountsForPrimaryOrganisation.filter(account => account.status === 'ACTIVE' && account.organisation_2);

                // TODO the accounts query now supports users, the account directly for org1 and org2, rather then reverse looking up in the organisations state
                subOrganisations = subOrganisations.map(subOrg => {
                    // because accounts query does not include users, find this org in the organisations state and check there
                    let thisOrg = props.organisations.find(org => org.id === subOrg.organisation_2.id);

                    if (!thisOrg) {
                        // this organisation does not exist in the organisations state, do not include it
                        console.log(`${consoleColors.red('EXCLUDING:')} ${subOrg} \n  ${consoleColors.blue('REASON:')} org not present in 'all organisations' ${props.organisations}`);

                        return false;
                    }

                    // TODO this is being temporarily disabled,
                    // if (!thisOrg.users.length && !selectedPrimaryOrganisation.users.length) {
                    //     // this organisation has no users, do not include it
                    //     console.log(`${consoleColors.red('EXCLUDING:')} ${thisOrg.unique_reference_name} \n  ${consoleColors.blue('REASON:')} empty users list in both primary and sub orgs`);
                    //     return false;
                    // }

                    console.log(`${consoleColors.green('INCLUDING SUB ORG:')} ${consoleColors.bold(thisOrg.unique_reference_name)}`)
                    return subOrg.organisation_2; // this organisation has users, include it
                }).filter(e => !!e); // !!e is a boolean cast, the filter is used to remove nulls

                // Order the sub organisations alphabetically
                subOrganisations = orderOrganisationsAlphabetically(subOrganisations);

                // if there are no sub organisations, then set the state to an empty array
                if (isEmpty(subOrganisations)) subOrganisations = [];

                // automatically select the first sub organisation
                // and add the primary organisation as an option - because it is an insurer organisation as well
                if (subOrganisations) {
                    // setSelectedSubOrganisation(subOrganisations[0]); // automatically select the first sub organisation
                }

                setSelectableSubOrgs(subOrganisations);

            },
            (error) => {
                console.error(error);
            }
        );

    }

    function logPrimarySubOrganisation(org, isPrimary, org2Acc) {

        const cc = consoleColors;

        let logMessage = ''

        /*
            test_admin_global: primary organisation
            as there are no active INSURER_INSURER accounts where it is organisation_2
        */
        if (isPrimary) {
            logMessage = [
                `${cc.bold(org.unique_reference_name)}: ${cc.green('primary organisation')} `,
                `\t ${cc.italic('as there are no active INSURER_INSURER accounts where it is organisation_2')}`,
                '\n'
            ]

        }

        /*
            standard_sub_insurer2: sub organisation
            there is an INSURER_INSURER active account where it is organisation_2
            organisation_1: standard_insurance
            organisation_2: standard_sub_insurer2
        */
        else {
            logMessage = [
                `${cc.bold(org.unique_reference_name)}: ${cc.red('sub organisation')} `,
                `\t ${cc.italic('there is an INSURER_INSURER active account where it is organisation_2')}`,
                `\t ${cc.bold('organisation_1:')} ${org2Acc.organisation_1?.unique_reference_name}`,
                `\t ${cc.bold('organisation_2:')} ${org2Acc.organisation_2?.unique_reference_name}`,
                '\n'
            ]
        }

        return logMessage.join('\n');

    }

    return (

        <div className='flex flex-col gap-2 mt-9'>

            <label>Primary Organisation</label>
            <SimpleSelect
                width='w-full'
                selectedState={selectedPrimaryOrganisation}
                selectableOptions={selectablePrimaryOrgs}
                onChange={(org) => {
                    setSelectedPrimaryOrganisation(org)
                    props.onChange(org)
                }}
                renderOption={(org) =>
                    <div className='flex gap-2'>
                        <BasicImage
                            src={org?.info.logo_image_url}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="1.2"
                            className="rounded-full"
                        />
                        <span>{org?.info.name || ''}</span>
                    </div>
                }

                renderSelected={(org) =>
                    org ?
                        <div className='flex gap-2'>
                            <BasicImage
                                src={org?.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="1.2"
                                className="rounded-full"
                            />
                            <span>{org?.info.name || ''}</span>
                        </div> : '-'
                }

            />

            <label>Report Organisation (optional)</label>
            <SimpleSelect
                width='w-full'
                selectedState={selectedSubOrganisation}
                selectableOptions={selectableSubOrgs}
                onChange={(org) => {
                    setSelectedSubOrganisation(org)
                    props.onChange(org)
                }}
                renderOption={(org) =>
                    <div className='flex gap-2'>
                        <BasicImage
                            src={org?.info.logo_image_url}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="1.2"
                            className="rounded-full"
                        />
                        <span>{org?.info.name || ''}</span>
                    </div>
                }

                renderSelected={(org) =>
                    org ?
                        <div className='flex gap-2'>
                            <BasicImage
                                src={org?.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="1.2"
                                className="rounded-full"
                            />
                            <span>{org?.info.name || ''}</span>
                        </div> : '-'
                }
            />

        </div>

    );


}


export function SupplierSelection(props) {

    const [selectableOrgs, setSelectableOrgs] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        determineSelectableOrgs();
    }, [props.accounts]);

    const determineSelectableOrgs = () => {

        if (!props.accounts) return

        const adminOrganisations = props.accounts.reduce((acc, account) => {
            if (account.organisation_1.type === 'SUPPLIER') {
                acc.push(account.organisation_1);
            } else if (account.organisation_2.type === 'SUPPLIER') {
                acc.push(account.organisation_2);
            }
            return acc;
        }, []);

        // Using a Set for efficient duplicate removal based on organisation ID
        const uniqueOrgIds = new Set();
        let uniqueAdminOrganisations = adminOrganisations.filter(org => {
            if (org.id && !uniqueOrgIds.has(org.id)) {
                uniqueOrgIds.add(org.id);
                return true;
            }
            return false;
        });

        // Order the admin organisations alphabetically
        uniqueAdminOrganisations = orderOrganisationsAlphabetically(uniqueAdminOrganisations);

        if (!selected && uniqueAdminOrganisations.length > 0) onChange(uniqueAdminOrganisations[0]);
        setSelectableOrgs(uniqueAdminOrganisations);
    }

    function onChange(org) {
        setSelected(org)
        props.onChange(org)
    }

    return (
        <div className='mt-4'>
            <SimpleSelect
                width='w-full'
                selectedState={selected}
                selectableOptions={selectableOrgs}
                onChange={onChange}
                renderOption={(org) =>
                    <div className='flex gap-2'>
                        <BasicImage
                            src={org?.info.logo_image_url}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="1.2"
                            className="rounded-full"
                        />
                        <span>{org?.info.name || ''}</span>
                    </div>
                }

                renderSelected={(org) =>
                    org ?
                        <div className='flex gap-2'>
                            <BasicImage
                                src={org?.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="1.2"
                                className="rounded-full"
                            />
                            <span>{org?.info.name || ''}</span>
                        </div> : '-'
                }
            />
        </div>
    );

}


export function SupplierUser(props) {
    /* Shown to supplier users, rather than the above organisation selections */

    return (
        <div className='h-full'>
            <label>Supplier</label>
            <input
                type="text"
                className="input"
                value={props.mainOrganisation.info.name}
                readOnly
                disabled
            />
        </div>
    );

}