/*
 * Helper functions to be used throughout the project
 */

import moment from "moment-timezone";
import {CountryMetaData, CountryTypes} from "./constants";

export const alertError = (setAlertOpen, setAlertOptions, error, callback = null) => {

    setAlertOptions({
        'iconType': 'error',
        'heading': error['type'],
        'message': error['message'],
        'onClose': callback
    });
    setAlertOpen(true);
};

export function classNames(...classes){
    return classes.filter(Boolean).join(' ');
}

export const calculateProperties = (product) => {

    let properties = [];

    if(isEmpty(product)) return '';

    if (product['date_released']) {
        let year = product['date_released']?.split('-')[0];
        properties.push(year);
    }

    let productProperties = product.properties || [];

    let topProperties = productProperties.slice(0, 5);

    topProperties.forEach((property) => {
        if (property['name'] !== 'brand') {

            if (property['value'] === 'Yes') {
                properties.push(property['display_name']);
            } else if (property['value'] !== 'No' && property['value'] !== 'Sometimes') {
                properties.push(property['value']);
            }
        }
    });

    return properties.join(' | ');

};

export const compareArrays = (arrayOne, arrayTwo) => {

    if (arrayOne === null || arrayTwo === null) {
        return false;
    }

    if (arrayOne.length !== arrayTwo.length) {
        return false;
    }

    for (const value of arrayOne) {

        if (!arrayTwo.includes(value)) {
            return false;
        }
    }

    return true;

};

export const compareDicts = (dictOne, dictTwo) => {

    if (dictOne === null || dictTwo === null) {
        return false;
    }

    if (Object.keys(dictOne).length !== Object.keys(dictTwo).length) {
        return false;
    }

    for (const [key, value] of Object.entries(dictOne)) {

        if (dictTwo[key] !== value) {
            return false;
        }
    }

    return true;

};

export const subArray = (arr, start, end) => {
    if (!end) {
        end = -1;
    }
    return arr.slice(start, arr.length + 1 - (end * -1));
};

export const isEmpty = (obj) => {

    if (!obj) return true;

    let propertyNames = Object.getOwnPropertyNames(obj);

    if (propertyNames.length === 0) {
        return true;
    }

    if (propertyNames.length === 1 && propertyNames[0] === 'length') {
        return true;
    }

    return false;
};

export const searchParamsToDict = (searchParams) => {

    let query = {};

    for (const [key, value] of searchParams) {
        query[key] = value;
    }

    // If query is empty, return null
    if (Object.getOwnPropertyNames(query).length === 0) {
        return null;
    }

    return query;

};

export function escapeDoubleQuotes(text){
    // " --> \"
    // convert all " double quotes into escaped double quotes \"
    // the double slash \\ is because the \ character is a special character,
    // so in order to add a literal single slash, we must have 2 consecutive slashes

    if(!text) return text;

    return text.replaceAll('"', '\\"');
}

export function capitalizeFirst(str){
    return str.toString().charAt(0).toUpperCase() + str.toString().slice(1);
}

export function formatAsTitle(textToFormat){

    // e.g: diagonal_display_length --> Diagonal display length
    // e.g: ACTION_SPORTS_CAMERAS --> Action sports cameras

    if (!textToFormat) return '';
    if(typeof textToFormat !== 'string') return '';

    let text = textToFormat;

    text = text.toLowerCase();

    // replace underscores _ with spaces
    text = text.replaceAll('_', ' ');

    // capitalize the first letter
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return text;

}

export function allQuotesInItem(item){
    /** This function returns all the quotes available for an item
     * All the quotes are extracted from the "supplier_panel" and "possible_replacements" keys in the "item" object
     * Does not include any null quotes or products */

    let allQuotes = [
        // { quote, supplier, product }
    ];


    // Retrieve all the quotes inside the "possible replacements" field
    if (item.possible_replacements) {
        let quotesInPossibleReplacements = item.possible_replacements.filter(replacementData => {
            let quote = replacementData.quote;
            let product = replacementData.product;
            let supplier = replacementData.quote?.supplier || null;

            if (quote && product && supplier) {
                return {
                    'quote': quote,
                    'product': product,
                    'supplier': supplier
                };
            }
        });
        allQuotes.push(quotesInPossibleReplacements);
    }


    // Retrieve all the quotes inside the "supplier panel" field
    // The quote and product objects can be null in the recommended_placement field in the supplier_panel. Check and skip these
    if (item.supplier_panel) {
        let quotesInSupplierPanel = item.supplier_panel.filter(supplier_with_quote_and_product => {
            let quote = supplier_with_quote_and_product.recommended_replacement.quote;
            let product = supplier_with_quote_and_product.recommended_replacement.product;
            let supplier = supplier_with_quote_and_product;

            if (quote && product && supplier) {
                return {
                    'quote': quote,
                    'product': product,
                    'supplier': supplier
                };
            }
        });
        allQuotes.push(quotesInSupplierPanel);
    }

    // Remove duplicate quotes
    let quotes = [];
    for (const quoteSupplierProductData of allQuotes) {
        let alreadyAdded = quotes.find(e => e.quote?.id === quoteSupplierProductData.quote?.id);
        alreadyAdded = !isEmpty(alreadyAdded); // if its empty, we want false. isEmpty will return true
        if (!alreadyAdded) {
            quotes.push(quoteSupplierProductData);
        }
    }

    return quotes;

}

export function allReplacementDataForItem(item){

    // replacement data = a quote, and product

    /** The structure of the "item" payload
     Item :
     meta info - category, date, is_manual, etc...
     product: the original proto/manaul product added in the ClaimItemSearch page
     replacement: the final selected replacement data (quote product) for this item
     supplier_pannel: this holds the "recommended replacement data" for each supplier, for each item
     possible_replacements: a list of "recommended replacement (quote product)" but with multiple quotes, 1 per supplier
     */

        // NO NULL - a replacement data should contain a valid quote, product, and supplier

    let allReplacements_QuoteProduct = [
            // {
            //     'quote': {...},
            //     'product': {...},
            //     'supplier': {...}
            // },
        ];

    // get all quotes from possible replacements
    let possibleReplacements = item.possible_replacements;

    for (let replacementData of possibleReplacements) {
        // replacementData: { product:{...}, quotes:[] }
        let product = replacementData.product;
        let allQuotesForProduct = replacementData.quotes;

        for (let quote of allQuotesForProduct) {

            let _replacement = {
                'quote': quote,
                'product': product,
                'supplier': quote.supplier
            };

            if (!isEmpty(_replacement.quote) && !isEmpty(_replacement.product) && !isEmpty(_replacement.supplier)) {
                allReplacements_QuoteProduct.push(_replacement);
            }

        }

    }

    return allReplacements_QuoteProduct;

}

export function getBase64(file, onSuccess, onError){
    // Create a base64 formatted string from a file (presumably from an input element)

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (){
        let base64 = reader.result;
        onSuccess(base64);
    };
    reader.onerror = function (e){
        console.log('Error: Failed to parse a base64 string from file - ', e);
        onError({
            'type': 'Base64 Failed',
            'message': 'Failed to parse a base64 string from the input file'
        });
    };
}

// TODO can this be changed to use time.js localMomentInstanceFromUTC
export function toLocalTime(utcTimeString, customFormat){
    // utcTime: 2021-05-28T13:30:00Z
    // returns: 2021-05-28T13:30:00+10:00

    const isoFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

    let localTime = moment.utc(utcTimeString, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').tz(moment.tz.guess());

    if(customFormat){
        return localTime.format(customFormat);
    }

    // "2023-09-05 14:55:39 KST"
    return localTime.format('YYYY-MM-DD HH:mm:ss z')

}

export const consoleColors = {
    // Standard Colors
    'red': (_text) => `\x1b[31m${_text}\x1b[0m`,
    'green': (_text) => `\x1b[32m${_text}\x1b[0m`,
    'yellow': (_text) => `\x1b[33m${_text}\x1b[0m`,
    'blue': (_text) => `\x1b[34m${_text}\x1b[0m`,
    'magenta': (_text) => `\x1b[35m${_text}\x1b[0m`,
    'cyan': (_text) => `\x1b[36m${_text}\x1b[0m`,
    'white': (_text) => `\x1b[37m${_text}\x1b[0m`,

    // Bright Colors
    'brightRed': (_text) => `\x1b[91m${_text}\x1b[0m`,
    'brightGreen': (_text) => `\x1b[92m${_text}\x1b[0m`,
    'brightYellow': (_text) => `\x1b[93m${_text}\x1b[0m`,
    'brightBlue': (_text) => `\x1b[94m${_text}\x1b[0m`,
    'brightMagenta': (_text) => `\x1b[95m${_text}\x1b[0m`,
    'brightCyan': (_text) => `\x1b[96m${_text}\x1b[0m`,
    'brightWhite': (_text) => `\x1b[97m${_text}\x1b[0m`,

    // Background Colors
    'bgBlack': (_text) => `\x1b[40m${_text}\x1b[0m`,
    'bgRed': (_text) => `\x1b[41m${_text}\x1b[0m`,
    'bgGreen': (_text) => `\x1b[42m${_text}\x1b[0m`,
    'bgYellow': (_text) => `\x1b[43m${_text}\x1b[0m`,
    'bgBlue': (_text) => `\x1b[44m${_text}\x1b[0m`,
    'bgMagenta': (_text) => `\x1b[45m${_text}\x1b[0m`,
    'bgCyan': (_text) => `\x1b[46m${_text}\x1b[0m`,
    'bgWhite': (_text) => `\x1b[47m${_text}\x1b[0m`,

    // Bright Background Colors
    'bgBrightBlack': (_text) => `\x1b[100m${_text}\x1b[0m`, // Gray
    'bgBrightRed': (_text) => `\x1b[101m${_text}\x1b[0m`,
    'bgBrightGreen': (_text) => `\x1b[102m${_text}\x1b[0m`,
    'bgBrightYellow': (_text) => `\x1b[103m${_text}\x1b[0m`,
    'bgBrightBlue': (_text) => `\x1b[104m${_text}\x1b[0m`,
    'bgBrightMagenta': (_text) => `\x1b[105m${_text}\x1b[0m`,
    'bgBrightCyan': (_text) => `\x1b[106m${_text}\x1b[0m`,
    'bgBrightWhite': (_text) => `\x1b[107m${_text}\x1b[0m`,

    // Text Styles
    'bold': (_text) => `\x1b[1m${_text}\x1b[0m`,
    'dim': (_text) => `\x1b[2m${_text}\x1b[0m`,
    'italic': (_text) => `\x1b[3m${_text}\x1b[0m`,
    'underline': (_text) => `\x1b[4m${_text}\x1b[0m`,
    'blink': (_text) => `\x1b[5m${_text}\x1b[0m`,
    'reverse': (_text) => `\x1b[7m${_text}\x1b[0m`, // Swap foreground & background
    'hidden': (_text) => `\x1b[8m${_text}\x1b[0m`,  // Hidden text
    'strikethrough': (_text) => `\x1b[9m${_text}\x1b[0m`,

    // Reset
    'reset': (_text) => `\x1b[0m${_text}`
};

// Function to combine multiple styles
export const combineStyles = (text, ...styles) => {
    return styles.reduce((acc, style) => consoleColors[style] ? consoleColors[style](acc) : acc, text);
};

/** Example usage of multiple styles
console.log(combineStyles('Hello, world!', 'brightRed', 'bgYellow', 'bold', 'underline')); */

export function currencySymbol(claim){
    /* This function will attempt to return a currency symbol
    * via multiple methods
    * 1. claim.organisation.info.currency_symbol
    * 2. claim.organisation.info.currency (3 letter code)
    * 3. claim.organisation.info.country (2 letter code)
    * 4. claim.country (2 letter code)
    * */

    let symbol = ''

    let currencySymbol = null;
    if(!claim?.organisation?.info) return '';

    // 1. claim.organisation.info.currency_symbol
    if(claim.organisation?.info?.currency_symbol)
        symbol = claim.organisation.info.currency_symbol;

    // 2. claim.organisation.info.currency (3 letter code)
    currencySymbol = CountryMetaData.find(country => country.currencyCode === claim.organisation.info.currency)?.currency;
    if(currencySymbol)
        symbol = currencySymbol;

    // 3. claim.organisation.info.country (2 letter code)
    currencySymbol = CountryMetaData.find(country => country.code === claim.organisation.info.country)?.currency;
    if(currencySymbol)
        symbol = currencySymbol;

    // 4. claim.country (2 letter code)
    currencySymbol = CountryMetaData.find(country => country.code === claim.country)?.currency;
    if(currencySymbol)
        symbol = currencySymbol;

    return symbol || '';
}

export function sortClaimItems(claim){
    /* Sort the items in the supplied claim object */

    if(!claim?.items) return claim;

    try {
        claim.items.sort((a, b) => new Date(a["date_created"]) - new Date(b["date_created"]));
    } catch (e) {
        console.error("Error while sorting claim items.");
        console.error(e);
    }

    return claim;
}