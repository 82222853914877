// Library imports

import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';

import {PencilAltIcon, XIcon} from '@heroicons/react/solid';
import ApiButton from "../../../components/base/ApiButton";


export default function UpdateQuotePriceModal(props){

    // onClick functions
    const [updateCatalogPrice, setUpdateCatalogPrice] = useState(false);

    const onUpdateClicked = (buttonHandler) => {

        let price = document.getElementById('price').value;
        props.options['price'] = price;

        props.options['update_catalog_price'] = updateCatalogPrice;

        if (props.options.onConfirmClicked) {
            props.options.onConfirmClicked(props.options, buttonHandler);
        } else if (props.onConfirmClicked) {
            props.onConfirmClicked(props.options, buttonHandler);
        }

        return false;
    };

    function getCurrencySymbol (){
        if(props.options?.quote?.currency_symbol){
            return props.options?.quote?.currency_symbol;
        }
        else if (props.options?.quote?.currency){
            return props.options?.quote?.currency;
        }
        else {
            return '';
        }
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {
            }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block w-[25rem] align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle px-6 pt-4">
                            <div>
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 "
                                        onClick={() => props.setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center mt-1 h-10 w-10 rounded-full bg-cyan-600 sm:mx-0 sm:h-10 sm:w-10">
                                        <PencilAltIcon className="text-white h-6 w-6" aria-hidden="true"/>
                                    </div>
                                    <div className="font-bold text-xl text-left ml-4">
                                        Update Quote Price
                                        <p className="text-sm font-normal text-gray-600">
                                            The quote's price may be updated below.
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full mt-3 border-t border-gray-300"></div>

                                <div className="mt-5">
                                    <div className="flow-root">
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="">

                                                {/* PRICE */}
                                                <div className="">
                                                    <label htmlFor="price" className="text-sm font-medium text-gray-900">
                                                        New Price
                                                    </label>
                                                    <div className="flex">
                                                        <p className="input w-min bg-gray-100 rounded-none m-0 px-2 flex items-center pointer-events-none overflow-hidden text-sm text-gray-700">
                                                            {getCurrencySymbol()}
                                                        </p>
                                                        <input
                                                            type="text"
                                                            name="price"
                                                            id="price"
                                                            defaultValue={props.options !== null && props.options.quote !== null ? props.options.quote.price : ''}
                                                            onBlur={(e) => {
                                                                const newPrice = e.target.value;
                                                                // calculate the difference between the new price and the original price in percentage
                                                                let priceChangePercentage = Math.abs((newPrice - props.options.quote.price) / props.options.quote.price);

                                                                // is the new price more than 10% different from the original price?
                                                                if(priceChangePercentage > 0.1){
                                                                    // prevent default
                                                                    e.preventDefault();

                                                                    props.showConfirmModal(
                                                                        'info',
                                                                        'Substantial Price Change',
                                                                        (<div>
                                                                            <p>The new price is <b>{(priceChangePercentage * 100).toFixed(0)}%</b> different from the original price</p>
                                                                            <p>Are you sure you want to proceed?</p>
                                                                            <p>Click <b>cancel</b> to revert to original price</p>
                                                                        </div>),
                                                                        'Proceed',
                                                                        () => {
                                                                            document.getElementById('price').value = newPrice;
                                                                        },
                                                                        () => {
                                                                            document.getElementById('price').value = props.options.quote.price;
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                            className="input rounded-none"
                                                            placeholder="0.00"
                                                            aria-describedby="price"
                                                        />
                                                    </div>
                                                </div>

                                                {/* UPDATE CATALOG PRICE */}
                                                <div className="flex justify-start items-center gap-3 mt-6 px-2 tooltip-container">
                                                    <input
                                                        type="checkbox"
                                                        value={updateCatalogPrice}
                                                        onChange={(e) => setUpdateCatalogPrice(e.target.checked)}
                                                        className="input w-min p-[10px] "
                                                        disabled={props.options?.quote?.is_manual || false}
                                                    />

                                                    <label htmlFor="price" className={`block text-sm font-medium text-gray-900 ${props.options?.quote?.is_manual ? 'text-gray-300' : ''}`}>
                                                        Update catalogue price
                                                    </label>

                                                    {props.options?.quote?.is_manual ?
                                                        <span className="tooltip text-sm ml-14 p-3 w-[10rem] bg-black">Manually created quotes only apply to this claim</span>
                                                        :
                                                        <span className="tooltip text-sm ml-14 p-3 w-[10rem]">Also apply this update to the catalogue's price</span>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 flex gap-4 w-full justify-end">
                                    <ApiButton
                                        onClick={onUpdateClicked}
                                        className='btn'
                                        content={'Update'}
                                        successContent={'Quote updated'}
                                        errorContent='Failed to update quote'
                                        defaultStatus={'idle'}
                                    />
                                    <button
                                        type="button"
                                        className="btn-light"
                                        onClick={() => props.setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
